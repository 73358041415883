import {
    Exclude,
    Expose,
    plainToClass,
    Transform,
    Type
} from 'class-transformer'
import { TaxonomicStatusOnlyListItem } from './taxon-status-only-list-item';
import { ApiProperty } from '@nestjs/swagger';

@Exclude()
export class TaxonTreeInfoDto {
    constructor(data: Record<string, unknown>) {
        Object.assign(this, data);
    }

    @Expose() id: number
    @Expose() name: string
    @Expose() author: string | null
    @Expose() isSynonym: boolean
    @Expose() rankID: number
    @Expose() parentID: number | null
    @Expose() acceptedID: number | null

}
