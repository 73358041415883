<div id='main'>
    <div>
        <h1>
            <symbiota2-editable-text key='taxon.profile.title'></symbiota2-editable-text> - {{taxonName}}
        </h1>
    </div>
    <div *ngIf='taxonomicStatus'>
        <div>
            <b *ngIf='taxon'>{{taxon.scientificName}}</b>
            &nbsp;<span *ngIf='taxon.author'>{{taxon.author}}</span>
            <a *ngIf='taxonomicStatus.parentTaxonID' href='taxon/profile/{{taxonomicStatus.parentTaxonID}}' style='text-decoration: none'>
                <mat-icon>arrow_upward</mat-icon>
            </a>
        </div>
        <div *ngIf='taxonomicStatus.family'>
            <b>Family: </b>{{taxonomicStatus.family}}
        </div>
    </div>
    <button *ngIf='userCanEdit' style='float:right' mat-icon-button>
        <mat-icon color="primary" (click)="goToLink(taxonID)">edit</mat-icon>
    </button>
    <mat-tab-group *ngIf="blocks.length > 0" mat-align-tabs="start">
        <mat-tab color="primary" *ngFor='let block of blocks' label='{{block.caption}}'>
            <symbiota2-expansion-panel label='{{block.taxon.scientificName}}'>
                <table>
                    <tr>
                        <td>
                            <a *ngIf='image' href='image/details/{{image.id}}'>
                                <img [src]="localize(image.thumbnailUrl)"/>
                            </a>
                            <span *ngIf='!image'>
                                <symbiota2-editable-text key='taxon.profile.no.image'></symbiota2-editable-text>
                            </span>
                            <br/>
                            <span *ngIf='image' style='text-align:right'>{{image.photographerName}}</span>
                        </td>
                        <td>
                            <div *ngIf='block.source' style='text-align:right'>
                                {{block.source}}
                                <br/><br/>
                            </div>
                            <span *ngIf="block.descriptionStatements?.length > 0">
                            <span *ngFor="let d of block.descriptionStatements">
                                <b *ngIf='d.displayHeader == 1'>{{d.heading}}</b>&nbsp;
                                <div [innerHTML]="d.statement"></div>
                            </span>
                            <span *ngIf='block.descriptionStatements?.length == 0'>
                                <symbiota2-editable-text key='taxon.profile.no.description'></symbiota2-editable-text>
                            </span>
                        </span>
                        </td>
                    </tr>
                </table>
            </symbiota2-expansion-panel>
        </mat-tab>
    </mat-tab-group>
    <div *ngIf="blocks.length == 0">
        <table>
            <tr>
                <td>
                    <a *ngIf='image' href='image/details/{{image.id}}'>
                        <img src="localize(image.thumbnailUrl)"/>
                    </a>
                    <span *ngIf='!image'>
                        <symbiota2-editable-text key='taxon.profile.no.image'></symbiota2-editable-text>
                    </span>
                    <br/>
                    <span *ngIf='image' style='text-aligh:right'>{{image.photographerName}}</span>
                </td>
                <td>
                    <symbiota2-editable-text key='taxon.profile.no.description'></symbiota2-editable-text>
                </td>
            </tr>
        </table>
    </div>
    <mat-grid-list *ngIf='images.length > 0' cols='4' rowHeight='.8:1'>
        <mat-grid-tile *ngFor='let image of images'>
            <div class="img-with-text">
                <a *ngIf='image' href='image/details/{{image.id}}'>
                    <img src="localize(image.thumbnailUrl)"/>
                </a>
                <p>{{image.photographerName}}</p>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
