import * as path from 'path';
import * as process from "process";

export class AppEnv {
    defaultLanguage = "";
    appTitle = "";
    apiUrl = "";
    tilesUrl = "";
    tilesToken = "";
    tilesAttribution = "";
    enableFooter = Boolean(process.env.SYMBIOTA2_FOOTER=="true" || true);
    kibanaServer = "";
    kibanaPort = "";
    kibanaDashboardSpatialModule = "";
    kibanaDashboardImageSearch = "";
    kibanaDashboardCollectionStats = "";

    constructor(environ: Record<string, unknown>) {
        Object.assign(this, environ);
    }
}
