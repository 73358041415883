import { Component } from '@angular/core';
import { AppConfigService } from '@symbiota2/ui-common';

import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagePlugin } from '../../image-plugin.module';

@Component({
    selector: 'symbiota2-image-search-dashboard',
    templateUrl: './image-search-dashboard-page.html',
    styleUrls: ['./image-search-dashboard-page.scss']
})
export class ImageSearchDashboardPage {

    public dashboardUrl = '' // http://localhost:5601/app/dashboards?auth_provider_hint=anonymous1#/view/fc340bf0-82df-11ec-b9ae-27af917973e5';

    constructor(
        private readonly appConfig: AppConfigService,
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        public sanitizer: DomSanitizer) {
        this.dashboardUrl = appConfig.kibanaServer() + ":" + appConfig.kibanaPort() + appConfig.kibanaDashboardImageSearch();
    }

    ngOnInit(): void {
        // Navigate directly to the dashboard to avoid CSP errors!
        document.location.href = this.dashboardUrl
    }

}
