/* eslint-disable no-var */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collection } from '../../dto/Collection.output.dto';
import { CollectionService } from '../../services/collection.service';
import { ApiUserRoleName } from '@symbiota2/data-access';
import { UserOutputDto } from '@symbiota2/api-auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CollectionRoleInput,
    CollectionRoleOutput,
} from '../../dto/CollectionRole.dto';
import { CollectionRoleAsyncValidators } from '../../validators/CollectionRoleValidators';
import { MatDialog } from '@angular/material/dialog';
import { CollectionPermissionsConfirmDialogComponent } from '../collection-permissions-confirm-dialog/collection-permissions-confirm-dialog.component';
import { UserService } from '@symbiota2/ui-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'symbiota2-collection-permissions',
    templateUrl: './collection-permissions.component.html',
    styleUrls: ['./collection-permissions.component.scss'],
})
export class CollectionPermissionsComponent implements OnInit {
    admins: Observable<CollectionRoleOutput[]>;
    editors: Observable<CollectionRoleOutput[]>;
    rareSpeciesReaders: Observable<CollectionRoleOutput[]>;

    collID: number;

    collection: Collection;

    userList: Observable<UserOutputDto[]>;

    newPermissionForm: FormGroup = this.fb.group(
        {
            user: ['', Validators.required],
            userName: ['', Validators.required],
            role: ['', Validators.required],
        },
        {
            asyncValidators: CollectionRoleAsyncValidators.userHasRole(
                this.collections
            ),
        }
    );

    constructor(
        private readonly collections: CollectionService,
        private readonly fb: FormBuilder,
        private dialog: MatDialog,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly user: UserService,
    ) {}
 
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.collID = params['collectionID'];
            console.log(this.collID); 
        });
        this.user.currentUser.subscribe(user => {
            if (!user.canAssignCollectionRole(+this.collID)) {
                this.router.navigate([''])
            }
        })
        this.updateRoles();
    }

    private updateRoles(): void {
        this.admins = this.getCollectionRoles().pipe(
            map((role) => {
                return role.details.filter(detail => detail.access === ApiUserRoleName.COLLECTION_ADMIN)
            })
        );

        this.editors = this.getCollectionRoles().pipe(
            map((role) => {
                return role.details.filter(detail => detail.access === ApiUserRoleName.COLLECTION_EDITOR)
            })
        );

        this.rareSpeciesReaders = this.getCollectionRoles().pipe(
            map((role) => {
                return role.details.filter(detail => detail.access === ApiUserRoleName.RARE_SPECIES_READER)
            })
        );
    }

    getCollection(): Observable<Collection> {
        return this.collections.currentCollection.pipe(
            map((collection) => {
                return collection;
            })
        );
    }

    private getCollectionRoles(): Observable<any> {
        return this.collections.getCurrentRoles();
    }
    
    onUserSelect(user: UserOutputDto) {
        this.newPermissionForm.get('user').setValue(user.uid);
        this.newPermissionForm
            .get('userName')
            .setValue(`${user.username}(${user.lastName},${user.firstName})`);
    }

    onApplyRoles(): void {
        const uid: number = this.newPermissionForm.get('user').value;
        const userName: string = this.newPermissionForm.get('userName').value;
        const role: ApiUserRoleName = this.newPermissionForm.get('role').value;
 
        var dialogText: string;
        dialogText = `Assign ${role} to user(${userName})?`;
            this.dialog
                .open(CollectionPermissionsConfirmDialogComponent, {
                    data: dialogText,
                })
                .afterClosed()
                .subscribe((res: boolean) => {
                    if (res) {
                        this.collections.assignCollectionRole(uid, role).subscribe((res) => this.updateRoles())
                    }
                })
        
    }

    onRemoveRole(role): void {
        var dialogText: string;
        dialogText = `Remove ${role.access} role from ${role.username}?`;
        console.log('role in removeRole: ', role)
        this.dialog
            .open(CollectionPermissionsConfirmDialogComponent, {
                data: dialogText,
            })
            .afterClosed()
            .subscribe((res: boolean) => {
                if (res) {
                    this.collections
                        .unassignCollectionRole(role.assignedForUID, role.tablePrimaryKey, role.access)
                        .subscribe((res) => {
                            this.updateRoles();
                        });
                }
            });
    }

    public get apiUserRoleName(): typeof ApiUserRoleName {
        return ApiUserRoleName;
    }
}
