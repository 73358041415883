import { Component, OnInit } from "@angular/core";
import { ROUTE_USER_LIST, UserService } from "@symbiota2/ui-common";
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CreateUserData } from '../../dtos/create-user-data';
import { UserRoleValues } from "../../dtos/user-role-name";
import { passwordContainsCharClasses, passwordsMatch } from "../create-user-profile/validators";
import { from, of } from 'rxjs';
import { startWith, map, tap, mergeAll } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'symbiota2-add-user',
    templateUrl: './add-user.page.html',
    styleUrls: ['./add-user.page.scss'],
})
export class AddUserPage implements OnInit {
    roles: string[] = [];

    readonly PASSWORD_MIN_CHARS = 8;

    readonly firstNameField = new FormControl('', [Validators.required]);
    readonly lastNameField = new FormControl('', [Validators.required]);
    readonly usernameField = new FormControl('', [Validators.required]);
    readonly passwordField = new FormControl(
        '',
        [
            Validators.required,
            Validators.minLength(this.PASSWORD_MIN_CHARS),
            passwordContainsCharClasses
        ]
    );
    readonly passwordAgainField = new FormControl(
        '',
        [(passwordAgainField) => passwordsMatch(this.passwordField, passwordAgainField as FormControl)]
    );
    readonly emailAddressField = new FormControl(
        '',
        [Validators.required, Validators.email]
    );
    // readonly roleField = new FormControl(
    //     '', [Validators.required]
    // )

    readonly addUserForm = new FormGroup({
        username: this.usernameField,
        firstName: this.firstNameField,
        lastName: this.lastNameField,
        password: this.passwordField,
        passwordAgain: this.passwordAgainField,
        email: this.emailAddressField,
        // role: this.roleField,
    });

    constructor(
        private readonly userService: UserService,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        //this.roles = UserRoleValues;
        this.getAllRoles()
    }

    async getAllRoles() {
        this.userService.getAllRoles().subscribe(async roles => {
            roles.map(role => {
                this.roles.push(role.name);
            })
        })
    }

    addUser() {
        const {passwordAgain, ...userData} = this.addUserForm.getRawValue();
        //const data: CreateUserData = {...userData};
        this.userService.addUser({...userData})
            // .pipe(
            //     map(() => {
            //         if (this.roleStatus === 'add') {
            //             return this.userService.assignRole(uid, role)
            //         }
            //         else if (this.roleStatus === 'edit') {
            //             return this.userService.updateUserRole(uid, role)
            //         } else if (this.roleStatus === 'unassign') {
            //             return this.userService.unassignUserRole(uid)
            //         }
            //         return of(null)
            //     }),
            //     mergeAll()
            // )
            .subscribe(()=> {
            this.router.navigate([ROUTE_USER_LIST])
        })
    }

    // assignRole(e: string) {
    //     return e;
    // }
}