import { NavBarLink, SymbiotaUiPlugin } from '@symbiota2/ui-common';
import { Route } from '@angular/router';

export class LocalMenu {
    public static routes(): Route[] {
        return [];
    }

    static navBarLinks(): NavBarLink[] {
        return [];
    }
}

