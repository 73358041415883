<div id="main">
    <div>
        <h1>
            <symbiota2-editable-text key='image.contributors.search.title'></symbiota2-editable-text>
        </h1>
    </div>

    <symbiota2-editable-text key='image.contributors.search.criteria' hide='true'></symbiota2-editable-text>
    <symbiota2-editable-text key='image.contributors.search.images' hide='true'></symbiota2-editable-text>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="{{'image.contributors.search.criteria' | translate}}">
            <!-- Collection select -->
            <div class="expansion-panel">
                <symbiota2-expansion-panel expanded=false label="Collection Select">
                    <symbiota2-collection-checkbox-selector
                        [collectionIDs]="this.collectionIDs.value"
                        (collectionIDsChange)="this.collectionIDs.setValue($event)"
                        [selectAll]="true"
                    >
                    </symbiota2-collection-checkbox-selector>
                </symbiota2-expansion-panel>
            </div>

            <!-- Configure name list -->
            <div class="expansion-panel">
                <symbiota2-editable-text key='taxonomy.viewer.configure.label' hide='true'></symbiota2-editable-text>
                <symbiota2-expansion-panel expanded=false label='{{"taxonomy.viewer.configure.label" | translate}}'>
                    <mat-radio-group
                        aria-labelledby="configure-radio-group-label"
                        class="configure-radio-group"
                        (change)='configureChangeAction()'
                        [(ngModel)]="kindOfName">
                        <mat-radio-button value="Scientific">{{"taxonomy.viewer.option.scientific" | translate}}</mat-radio-button>&nbsp;&nbsp;
                        <mat-radio-button value="Common">{{"taxonomy.viewer.option.common" | translate}}</mat-radio-button>
                        <symbiota2-editable-text key='taxonomy.viewer.option.scientific' hide='true'></symbiota2-editable-text>
                        <symbiota2-editable-text key='taxonomy.viewer.option.common' hide='true'></symbiota2-editable-text>

                        <div>
                            <br/>
                             <mat-form-field appearance="fill">
                                <mat-label>{{"taxonomy.viewer.label.authority" | translate}}</mat-label>
                                <mat-select field-class="symbiota2-field-class"
                                            placeholder='{{"taxonomy.viewer.label.authority" | translate}}'
                                            [(value)]="taxonomicAuthorityID"
                                            (selectionChange)="authorityChangeAction()">
                                    <mat-option *ngFor="let authority of taxonomicAuthorityList"
                                                [value]="authority.id" >{{ authority.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <symbiota2-editable-text key='taxonomy.viewer.label.authority' hide='true'></symbiota2-editable-text>
                        </div>

                        <div *ngIf='kindOfName == "Scientific"'>
                            <mat-checkbox class="example-margin" [(ngModel)]='hasAuthors'
                            >{{"taxonomy.viewer.label.checkbox.authors" | translate}}</mat-checkbox>
                            <symbiota2-editable-text key='taxonomy.viewer.label.checkbox.authors' hide='true'></symbiota2-editable-text>

                        </div>

                        <div *ngIf='kindOfName == "Common"'>
                            <symbiota2-editable-text key='taxonomy.viewer.label.language' hide='true'></symbiota2-editable-text>
                            <mat-form-field appearance="fill">
                                <mat-label>{{"taxonomy.viewer.label.language" | translate}}</mat-label>
                                <mat-select field-class="symbiota2-field-class"
                                            placeholder='{{"taxonomy.viewer.label.language" | translate}}'
                                            [(value)]="language"
                                            (selectionChange)="languageChangeAction(language)">
                                    <mat-option *ngFor="let lang of languageList"
                                                [value]="lang" >{{ lang }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-radio-group>
                </symbiota2-expansion-panel>
            </div>

            <div class="expansion-panel">
                <symbiota2-editable-text key='image.search.names.panel' hide='true'></symbiota2-editable-text>
                <symbiota2-expansion-panel expanded=true label='{{"image.search.names.panel" | translate}}'>
                    <div fxLayout="row wrap">
                        <div class="form-field w-4">
                            <mat-form-field *ngIf='kindOfName == SCIENTIFIC_NAME'>
                                <mat-label >{{"image.search.by.scientific.name" | translate}}</mat-label>
                                <mat-chip-list #scinameList>
                                    <mat-chip *ngFor="let sciname of scinames" (removed)="removeSciname(sciname)">
                                        {{sciname}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input type="text"
                                           #scinameInput
                                           placeholder='{{"image.search.by.scientific.name" | translate}}'
                                           aria-label="String"
                                           matInput
                                           (keyup)="onKey($event)"
                                           [matChipInputFor]="scinameList"
                                           [formControl]="nameControl"
                                           [matAutocomplete]="autoSciName"
                                    >
                                </mat-chip-list>
                                <mat-autocomplete autoActiveFirstOption #autoSciName="matAutocomplete" (optionSelected)="selectedSciname($event)" >
                                    <mat-option *ngFor="let option of nameOptions" [value]="option.name">
                                        {{nameFor(option.name)}} <small *ngIf='hasAuthors'>{{option.author}}</small>
                                    </mat-option>

                                </mat-autocomplete>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.by.scientific.name' hide='true'></symbiota2-editable-text>

                            <mat-form-field *ngIf='kindOfName == COMMON_NAME'>
                                <mat-label >{{"image.search.by.common.name" | translate}}</mat-label>
                                <mat-chip-list #commonNameList>
                                    <mat-chip *ngFor="let commonName of commonNames" (removed)="removeCommonName(commonName)">
                                        {{commonName}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input type="text"
                                           #commonNameInput
                                           placeholder='{{"image.search.by.common.name" | translate}}'
                                           aria-label="String"
                                           matInput
                                           (keyup)="onCommonNameKey($event)"
                                           [matChipInputFor]="commonNameList"
                                           [formControl]="commonNameControl"
                                           [matAutocomplete]="autoCommonName"
                                    >
                                </mat-chip-list>
                                <mat-autocomplete autoActiveFirstOption #autoCommonName="matAutocomplete" (optionSelected)="selectedCommonName($event)" >
                                    <mat-option *ngFor="let option of commonNameOptions" [value]="option.name">
                                        {{option.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.by.common.name' hide='true'></symbiota2-editable-text>
                         </div>
                    </div>
                </symbiota2-expansion-panel>
            </div>

            <div class="expansion-panel">
                <symbiota2-editable-text key='image.search.image.attributes' hide='true'></symbiota2-editable-text>
                <symbiota2-expansion-panel expanded=false label='{{"image.search.image.attributes" | translate}}'>
                    <div fxLayout="row wrap">
                        <div class="form-field w-5">
                            <mat-form-field>
                                <mat-label>{{"image.search.by.photographers" | translate}}</mat-label>
                                <mat-chip-list #photographerNameList>
                                    <mat-chip *ngFor="let name of photographerNames" (removed)="removePhotographerName(name)">
                                        {{name}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input type="text"
                                           #photographerNameInput
                                           placeholder='{{"image.search.by.photographers" | translate}}'
                                           aria-label="String"
                                           matInput
                                           (keyup)="onPhotographerNameKey($event)"
                                           [matChipInputFor]="photographerNameList"
                                           [formControl]="photographerNameControl"
                                           [matAutocomplete]="autoPhotographerName"
                                    >
                                </mat-chip-list>
                                <mat-autocomplete autoActiveFirstOption #autoPhotographerName="matAutocomplete" (optionSelected)="selectedPhotographerName($event)" >
                                    <mat-option *ngFor="let option of photographerOptions" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.by.photographers' hide='true'></symbiota2-editable-text>
                        </div>
                    </div>

                    <div fxLayout="row wrap">
                        <div class="form-field w-2">
                            <mat-form-field>
                                <mat-label>{{"image.search.by.tag.keys" | translate}}</mat-label>
                                <mat-select [formControl]="tagKeyForm" multiple>
                                    <mat-option *ngFor="let tagKey of tagKeyOptions" [value]="tagKey.tagKey">{{tagKey.descriptionEn}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.by.tag.keys' hide='true'></symbiota2-editable-text>

                        </div>
                        <div class="form-field w-2">
                            <mat-form-field>
                                <mat-label>{{"image.search.by.keywords" | translate}}</mat-label>
                                <input matInput type='text' [(ngModel)]='keywordValue'>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.by.keywords' hide='true'></symbiota2-editable-text>

                        </div>
                        <div class="form-field w-2">
                            <mat-form-field>
                                <mat-label>{{"image.search.by.date.range" | translate}}</mat-label>
                                <mat-date-range-input
                                    [rangePicker]="rangePicker">
                                    <input matStartDate
                                           placeholder='{{"image.search.by.start.date" | translate}}'
                                           [(ngModel)] = startDate
                                    >
                                    <input matEndDate
                                           placeholder='{{"image.search.by.end.date" | translate}}'
                                           [(ngModel)] = endDate
                                    >
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePicker>

                                </mat-date-range-picker>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.by.date.range' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.by.start.date' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.by.end.date' hide='true'></symbiota2-editable-text>
                        </div>
                    </div>
                </symbiota2-expansion-panel>
            </div>
            <div class="expansion-panel">
                <symbiota2-editable-text key='image.search.display.configuration' hide='true'></symbiota2-editable-text>
                <symbiota2-expansion-panel expanded=false label='{{"image.search.display.configuration" | translate}}'>
                    <div fxLayout="row wrap">
                        <div class="form-field w-1">
                            <mat-form-field>
                                <mat-label>{{"image.search.limit.label" | translate}}</mat-label>
                                <mat-select [(value)]="kindOfLimit">
                                    <mat-option value="{{ALL_IMAGES}}">{{"image.search.all.images" | translate}}</mat-option>
                                    <mat-option value="{{ONE_PER_TAXON}}">{{"image.search.limit.taxons" | translate}}</mat-option>
                                    <mat-option value="{{ONE_PER_OCCURRENCE}}">{{"image.search.limit.occurrences" | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.limit.label' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='"image.search.all.images' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.limit.taxons' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.limit.occurrences' hide='true'></symbiota2-editable-text>

                        </div>
                        <div class="form-field w-1">
                            <mat-form-field>
                                <mat-label>{{"image.search.by.image.type" | translate}}</mat-label>
                                <mat-select [formControl]="imageTypeForm" multiple>
                                    <mat-option value="{{ALL_IMAGES}}">{{"image.search.all.images" | translate}}</mat-option>
                                    <mat-option value="{{IMAGE_TYPE_OBSERVATION}}">{{"image.search.occurrence.images" | translate}}</mat-option>
                                    <mat-option value="{{IMAGE_TYPE_FIELD_IMAGE}}">{{"image.search.image.vouchered" | translate}}</mat-option>
                                    <mat-option value="{{IMAGE_TYPE_SPECIMEN}}">{{"image.search.field.observations" | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <symbiota2-editable-text key='image.search.by.image.type' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.all.images' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.occurrence.images' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.image.vouchered' hide='true'></symbiota2-editable-text>
                            <symbiota2-editable-text key='image.search.field.observations' hide='true'></symbiota2-editable-text>

                        </div>
                    </div>
                </symbiota2-expansion-panel>
            </div>

            <br/>
            <button
                mat-raised-button
                color="primary"
                type="submit"
                (click)="onSubmit()"
            >
                {{"image.search.search.button" | translate}}
            </button>
            <symbiota2-editable-text key='image.search.search.button' hide='true'></symbiota2-editable-text>

            &nbsp;&nbsp;
            <button
                mat-raised-button
                color="warn"
                type="submit"
                (click)="onClear()"
            >
                {{"image.search.clear.button" | translate}}
            </button>
            <symbiota2-editable-text key='image.search.clear.button' hide='true'></symbiota2-editable-text>

        </mat-tab>
        <mat-tab *ngIf='submitted' label="{{'image.contributors.search.images' | translate}}">
            <div *ngIf='!data2 || data2.length == 0'>
                <h2>
                    <symbiota2-editable-text key='image.search.none.found'></symbiota2-editable-text>
                </h2>
            </div>
            <div *ngIf='submitted && data2?.length > 0'>
                <div fxFill fxLayout="column" fxLayoutAlign="space-between">

                    <mat-grid-list cols='{{data.length > 5? 5 : data.length}}' rowHeight='150px' gutterSize='5'>
                        <mat-grid-tile  *ngFor="let image of data">
                            <a [routerLink]="['/' + imageDetailsRoute, image.id]" class='imagelink'>
                                <img height="100" [src]="localize(image.thumbnailUrl)">
                            </a>
                            <mat-grid-tile-footer style='background-color:white; justify-content:center'>
                                <a [routerLink]="['/' + imageDetailsRoute, image.id]" style='text-align:center'>
                                    <i style='text-align:center'>{{image.taxon?.scientificName}}</i>
                                </a>
                            </mat-grid-tile-footer>
                        </mat-grid-tile>
                    </mat-grid-list>

                    <mat-paginator [length]="data2.length"
                                   [pageSize]="size"
                                   [pageIndex]="page"
                                   [pageSizeOptions]="pageSizeOptions"
                                   (page)="getData($event)">
                    </mat-paginator>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
