<div id='main'>
    <div id='header' fxLayoutAlign='start center' fxLayoutGap='1rem'>
        <h1>
            <symbiota2-editable-text key='image.folder.uploader.title'></symbiota2-editable-text>
        </h1>
    </div>
    <div *ngIf="userCanEdit" class='my2' fxLayout='row' fxLayoutGap='1rem'>
        <symbiota2-file-upload-field [formControl]='fileInput'></symbiota2-file-upload-field>
        <button
            mat-flat-button color='primary'
            (click)='onUpload()'
            [disabled]='!fileInput.value'
        >
            {{"image.folder.uploader.upload.button" | translate}}
        </button>
        <symbiota2-editable-text key='image.folder.uploader.upload.button' hide='true'></symbiota2-editable-text>
    </div>
    <div *ngIf="!userCanEdit" class='my2' fxLayout='row' fxLayoutGap='1rem'>
        <symbiota2-editable-text key='image.folder.uploader.lacks.edit.permission'></symbiota2-editable-text>
    </div>
</div>
