import { ApiUserRole, ApiUserRoleName } from '@symbiota2/data-access';
import { Expose, Type } from 'class-transformer';

@Expose()
export class UserRole implements ApiUserRole {
    id: number;
    uid: number;

    @Type(() => String)
    name: ApiUserRoleName;
}


@Expose()
export class AssignedRole {
    tableName: string | null;
    tablePrimaryKey: number | null;
    secondaryVariable: string | null;
    access: ApiUserRoleName | null;
    assignedByUID: number | null;
    assignedForUID: number | null;
    default: boolean | false;
}
