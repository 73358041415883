<div id='main'>

    <div>
        <h1>
            <symbiota2-editable-text key='image.library.title'></symbiota2-editable-text>
        </h1>
    </div>
    <symbiota2-editable-text key='image.library.intro'></symbiota2-editable-text>
    <div>
        <a href="images/library/Family">{{"image.library.browse.family" | translate}}</a>
        <symbiota2-editable-text key='image.library.browse.family' hide='true'></symbiota2-editable-text>

    </div>
    <div>
        <a href="images/library/Genus">{{"image.library.browse.genus" | translate}}</a>
        <symbiota2-editable-text key='image.library.browse.genus' hide='true'></symbiota2-editable-text>

    </div>
    <div>
        <symbiota2-editable-text key='image.library.browse.species'></symbiota2-editable-text>

        <ul>
            <a href="images/library/A">A</a> | <a href="images/library/B">B</a> | <a href="images/library/C">C</a> |
            <a href="images/library/D">D</a> | <a href="images/library/E">E</a> | <a href="images/library/F">F</a> |
            <a href="images/library/G">G</a> | <a href="images/library/H">H</a> | <a href="images/library/I">I</a>
            <br/>
            <a href="images/library/J">J</a> | <a href="images/library/K">K</a> | <a href="images/library/L">L</a> |
            <a href="images/library/M">M</a> | <a href="images/library/O">O</a> | <a href="images/library/P">P</a> |
            <a href="images/library/Q">Q</a> | <a href="images/library/R">R</a> | <a href="images/library/S">S</a>
            <br/>
            <a href="images/library/T">T</a> | <a href="images/library/U">U</a> | <a href="images/library/V">V</a> |
            <a href="images/library/W">W</a> | <a href="images/library/X">X</a> | <a href="images/library/Y">Y</a> |
            <a href="images/library/Z">Z</a>
        </ul>

    </div>

    <symbiota2-expansion-panel label='{{"image.library.names" | translate}}'>
    <!-- <div *ngIf='countOfNames == 0'>
        <h2>{{"image.library.none.found" | translate}}</h2>
    </div> -->
        <symbiota2-editable-text key='image.folder.uploader.upload.button' hide='true'></symbiota2-editable-text>

        <div *ngIf='countOfNames > 0'>

        <mat-paginator [length]="countOfNames"
                       [pageSize]="size"
                       [pageIndex]="page"
                       [pageSizeOptions]="pageSizeOptions"
                       (page)="getData($event)">
        </mat-paginator>

        <mat-list *ngFor='let name of names'>
            <mat-list-item *ngIf="level == 'Family'" role="listitem">
                <a href='image/library/{{name.id}}'  (click)="loadDescendants(name.id)" style='color:inherit;text-decoration:none'>{{name.name.toUpperCase()}}</a>
            </mat-list-item>
            <mat-list-item *ngIf="level == 'Genus'" role="listitem">
                <a href='image/library/{{name.id}}'  (click)="loadDescendants(name.id)" style='color:inherit;text-decoration:none'>{{name.name}}</a>
            </mat-list-item>
            <mat-list-item *ngIf="level == 'Species'" role="listitem">
                <a href='taxon/profile/{{name.id}}' target='_blank' style='color:inherit;text-decoration:none'><i>{{name.name}}</i></a>
            </mat-list-item>
        </mat-list>

    </div>
    </symbiota2-expansion-panel>
</div>
