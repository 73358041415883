<symbiota2-expansion-panel label='{{"taxon.status.placement.label" | translate}}'>
    <symbiota2-editable-text key='taxon.status.placement.label' hide='true'></symbiota2-editable-text>

    <mat-form-field appearance="fill">
        <mat-label>{{"taxonomy.viewer.label.authority" | translate}}</mat-label>
        <mat-select field-class="symbiota2-field-class"
                    placeholder='{{"taxonomy.viewer.label.authority" | translate}}'
                    [(value)]="currentAuthorityID"
                    (selectionChange)="authorityChangeAction()">
            <mat-option *ngFor="let authority of taxonomicAuthorityList"
                        [value]="authority.id" >{{ authority.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <symbiota2-editable-text key='taxonomy.viewer.label.authority' hide='true'></symbiota2-editable-text>


    <div><b><symbiota2-editable-text key='taxon.status.status.label'></symbiota2-editable-text></b>:
        <span style="color:blue" *ngIf='!currentInConflict && currentIsAccepted'>{{"taxon.status.accepted" | translate}}</span>
        <span style="color:#ff0000" *ngIf='!currentInConflict && !currentIsAccepted'>{{"taxon.status.not.accepted" | translate}}</span>
        <span style="color:#ff0000" *ngIf='currentInConflict'>{{"taxon.status.in.conflict" | translate}}</span>
    </div>

    <div>
        <b><symbiota2-editable-text key='taxon.status.parent.taxon.label'></symbiota2-editable-text></b>:
        <a [routerLink]="['/taxon/editor', currentParentID]" target="_blank" class='taxonlink'>{{currentParentName}}</a>
        <span *ngIf='!currentInConflict'>
                <button *ngIf='userCanEdit' mat-icon-button>
                    <mat-icon color="primary" (click)="openDialog('Update Parent',{})">edit</mat-icon>
                </button>
            </span>
        <!-- If in conflict then must resolve before doing anything -->
        <span *ngIf='currentInConflict'>
                                                                    &nbsp; &nbsp;
                <button *ngIf='userCanEdit'
                        color="primary" (click)="resolveConflictedAsAccepted()">
                    {{"taxon.status.editor.updated.in.conflict.accepted.button" | translate}}
                </button>
                <symbiota2-editable-text key='taxon.status.editor.updated.in.conflict.accepted.button' hide='true'></symbiota2-editable-text>

            </span>
    </div>

    <div *ngIf='!currentIsAccepted'>
        <b><symbiota2-editable-text key='taxon.status.accepted.taxon.label'></symbiota2-editable-text></b>:
        <a [routerLink]="['/taxon/editor', currentAcceptedID]" target="_blank" class='taxonlink'>{{currentAcceptedName}}</a>
        <span *ngIf='!currentInConflict'>
                <button *ngIf='userCanEdit' mat-icon-button>
                    <mat-icon color="primary" (click)="openDialog('Update Accepted',{})">edit</mat-icon>
                </button>
            </span>
        <!-- If in conflict then must resolve before doing anything -->
        <span *ngIf='currentInConflict'>
                                &nbsp; &nbsp;
                <button *ngIf='userCanEdit' color="primary" (click)="resolveConflictedAsSynonym()">
                    {{"taxon.status.editor.updated.in.conflict.synonym.button" | translate}}
                </button>
                <symbiota2-editable-text key='taxon.status.editor.updated.in.conflict.synonym.button' hide='true'></symbiota2-editable-text>

            </span>
    </div>
    <div *ngIf='currentIsAccepted && synonymList.length > 0'>
        <!-- List of synonnyms
        -->
        <b><symbiota2-editable-text key='taxon.status.synonyms.label'></symbiota2-editable-text>:</b>
        <mat-list role='list'>
            <mat-list-item *ngFor="let syn of synonymList">
                {{syn.name}}
            </mat-list-item>
        </mat-list>
    </div>
</symbiota2-expansion-panel>
