<div id="main" fxLayout="column" fxLayoutAlign="center center">
    <div class="col-header" fxLayout="row" fxLayoutAlign="start start">

        <div class="title-banner" fxLayout="column" fxLayoutAlign="space-between">
            <div fxLayout="row">
                <h1 id="title">
                    <symbiota2-editable-text key='collection.dwc.title'></symbiota2-editable-text>
                </h1>
            </div>
        </div>
    </div>
    <div id=body>
        <symbiota2-dwc-table id="table"></symbiota2-dwc-table>
    </div>
</div>
