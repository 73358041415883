<div id="main">
    <div>
      <h1>{{ "core.layout.header.topnav.checklist.regional.link" | translate }}</h1>
    </div>

    <div *ngFor="let project of checklistProjects">
      <mat-card class="checklist-projects">
        <mat-card-title-group>
          <mat-card-title><a  class="checklist-link-heading" [routerLink]="project.id">{{ project.name }}</a></mat-card-title>
          <mat-card-subtitle>{{ project.managers }}</mat-card-subtitle>
        </mat-card-title-group>
        <mat-card-content>
          {{ project.fullDescription }}
        </mat-card-content>
      </mat-card>
    </div>
</div>
  