import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertService, UserService } from "@symbiota2/ui-common";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

@Component({
  selector: 'symbiota2-permission-create-form',
  templateUrl: 'permission-create-form.html',
  styleUrls: ['permission-create-form.scss']
})
export class PermissionCreateFormComponent {
    FC_NAME = '';

    constructor(
        private userService: UserService,
        private alertService: AlertService
    ) {}
  
    public createPermissionFormGroup = new FormGroup({
        [this.FC_NAME]: new FormControl('', [Validators.required]),
    })
    
    onCreatePermission() {
        const data = {
            name: (this.createPermissionFormGroup.get(this.FC_NAME).value).toLowerCase().replace(/ /g, '_')
        };
        
        this.userService.createNewPermission(data)
        .subscribe(() => {
            this.createPermissionFormGroup.reset()
        })
    }
}
