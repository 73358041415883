import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, ROUTE_PERMISSION_LIST, UserService } from "@symbiota2/ui-common";

@Component({
  selector: 'symbiota2-update-permission-form',
  templateUrl: 'update-permission-form.html',
  styleUrls: ['update-permission-form.scss']
})
export class UpdatePermissionFormComponent implements OnInit {
    FC_NAME = '';
    permissionId = null;

    constructor(
        private readonly userService: UserService,
        private readonly alertService: AlertService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        this.permissionId = this.route.snapshot.params.id;
        this.getPermission(this.permissionId)
    }
  
    public updatePermissionFormGroup = new FormGroup({
        [this.FC_NAME]: new FormControl('', [Validators.required]),
    })

    getPermission(id: number) {
        this.userService.getOnePermission(id).subscribe(per => {
            this.updatePermissionFormGroup.patchValue({
                [this.FC_NAME]: per.name.replace(per.name[0], per.name[0].toUpperCase()).replace(/_/g, ' ')
            });
        })
    }
    
    onUpdatePermission() {
        const data = {
            name: (this.updatePermissionFormGroup.get(this.FC_NAME).value).toLowerCase().replace(/ /g, '_')
        };
        
        this.userService.updatePermission(this.permissionId, data)
        .subscribe(() => {
            this.router.navigate([ROUTE_PERMISSION_LIST])
        })
    }
}
