/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ApiOccurrenceListItem } from '@symbiota2/data-access';
import { CollectionListItem } from '@symbiota2/ui-plugin-collection';
import { ImageListItem } from '@symbiota2/ui-plugin-image';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class OccurrenceListItem implements ApiOccurrenceListItem {
    @Expose() id: number;
    @Expose() collectionID: number;
    @Expose() catalogNumber: string;
    @Expose() taxonID: number | null;
    @Expose() scientificNameComputed: string;
    @Expose() scientificName: string;
    @Expose() latitude: number | null;
    @Expose() longitude: number | null;
    @Expose() dbpk: string;
    @Expose() basisOfRecord: string;
    @Expose() occurrenceGUID: string;
    @Expose() otherCatalogNumbers: string;
    @Expose() ownerInstitutionCode: string;
    @Expose() datasetID: string;
    @Expose() family: string;
    @Expose() genus: string;
    @Expose() specificEpithet: string;
    @Expose() taxonRank: string;
    @Expose() infraspecificEpithet: string;
    @Expose() taxonRemarks: string;
    @Expose() identifiedBy: string;
    @Expose() kingdom: Promise<string>;
    @Expose() phylum: Promise<string>;
    @Expose() class: Promise<string>;
    @Expose() order: Promise<string>;
    @Expose() year: string;
    @Expose() day: string;
    @Expose() month: string;
    @Expose() dateIdentified: Date | null;
    @Expose() identificationReferences: string;
    @Expose() identificationRemarks: string;
    @Expose() identificationQualifier: string;
    @Expose() typeStatus: string;
    @Expose() recordedByNames: string;
    @Expose() recordNumber: string;
    @Expose() recordedByID: string;
    @Expose() associatedCollectors: string;
    @Expose() latestDateCollected: string;
    @Expose() startDayOfYear: number | null;
    @Expose() endDayOfYear: number | null;
    @Expose() verbatimEventDate: string;
    @Expose() habitat: string;
    @Expose() substrate: string;
    @Expose() fieldNotes: string;
    @Expose() fieldNumber: string;
    @Expose() eventID: string;
    @Expose() occurrenceRemarks: string;
    @Expose() informationWithheld: string;
    @Expose() dataGeneralizations: string;
    @Expose() associatedOccurrences: string;
    @Expose() associatedTaxa: string;
    @Expose() dynamicProperties: string;
    @Expose() verbatimAttributes: string;
    @Expose() behavior: string;
    @Expose() reproductiveCondition: string;
    @Expose() cultivationStatus: number | null;
    @Expose() establishmentMeans: string;
    @Expose() lifeStage: string;
    @Expose() sex: string;
    @Expose() individualCount: string;
    @Expose() samplingProtocol: string;
    @Expose() samplingEffort: string;
    @Expose() preparations: string;
    @Expose() locationID: string;
    @Expose() country: string;
    @Expose() stateProvincecounty: string;
    @Expose() municipality: string;
    @Expose() waterBody: string;
    @Expose() locality: string;
    @Expose() localitySecurity: number | null;
    @Expose() localitySecurityReason: string;
    @Expose() geodeticDatum: string;
    @Expose() coordinateUncertaintyInMeters: number | null;
    @Expose() footprintWKT: string;
    @Expose() coordinatePrecision: number;
    @Expose() locationRemarks: string;
    @Expose() verbatimCoordinates: string;
    @Expose() verbatimCoordinateSystem: string;
    @Expose() georeferenceProtocol: string;
    @Expose() degreeOfEstablishment: string;
    @Expose() associatedSequences: string;
    @Expose() earliestAgeOrLowestStage: string;
    @Expose() earliestEonOrLowestEonothem: string;
    @Expose() earliestEpochOrLowestSeries: string;
    @Expose() earliestEraOrLowestErathem: string;
    @Expose() earliestPeriodOrLowestSystem: string;
    @Expose() eventRemarks: string;
    @Expose() eventTime: string;
    @Expose() measurementRemarks: string;
    @Expose() parentEventID: string;
    @Expose() associatedOrganisms: string;
    @Expose() associatedReferences: string;
    @Expose() countryCode: string;
    @Expose() datasetName: string;
    @Expose() subgenus: string;
    @Expose() georeferenceSources: string;
    @Expose() georeferenceVerificationStatus: string;
    @Expose() georeferenceRemarks: string;
    @Expose() minimumElevationInMeters: number | null;
    @Expose() maximumElevationInMeters: number | null;
    @Expose() verbatimElevation: string;
    @Expose() minimumDepthInMeters: number | null;
    @Expose() maximumDepthInMeters: number | null;
    @Expose() verbatimDepth: string;
    @Expose() previousIdentifications: string;
    @Expose() disposition: string;
    @Expose() storageLocation: string;
    @Expose() genericColumn1: string;
    @Expose() genericColumn2: string;
    @Expose() modified: Date | null;
    @Expose() language: string;
    @Expose() observerUID: number | null;
    @Expose() processingStatus: string;
    @Expose() recordEnteredBy: string;
    @Expose() duplicateQuantity: number | null;
    @Expose() labelProject: string;
    @Expose() dynamicFields: string;
    @Expose() initialTimestamp: Date | null;
    @Expose() lastModifiedTimestamp: Date

    @Expose()
    @Type(() => CollectionListItem)
    collection: CollectionListItem;

    @Expose()
    @Type(() => ImageListItem)
    images: ImageListItem;

    constructor(occurrence: ApiOccurrenceListItem) {
        Object.assign(this, occurrence);
    }
}
