import { Component, Input, OnInit, Output } from '@angular/core';
import { NavBarLink } from '@symbiota2/ui-common';
import { EventEmitter } from 'stream';
import { CheckEmailService } from '../../app.service';

@Component({
    selector: 'symbiota2-navbar-menu',
    templateUrl: './navbar-menu.component.html',
    styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent {
    @Input() title: string;
    @Input() items: NavBarLink[];

    constructor(private checkEmail: CheckEmailService) {}

    isStringURL(testString: string): boolean {
        return this.checkEmail.isStringURL(testString);
    }

}
