<mat-tab-group mat-align-tabs="start" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
    <mat-tab label="{{'collection.collection_checkbox_selector.tab.label.both' | translate}}">
        <symbiota2-editable-text key='collection.collection_checkbox_selector.tab.label.both' hide='true'></symbiota2-editable-text>
    </mat-tab>
    <mat-tab label="{{'collection.collection_checkbox_selector.tab.label.specimens' | translate}}">
        <symbiota2-editable-text key='collection.collection_checkbox_selector.tab.label.specimens' hide='true'></symbiota2-editable-text>
    </mat-tab>
    <mat-tab label="{{'collection.collection_checkbox_selector.tab.label.observations' | translate}}">
        <symbiota2-editable-text key='collection.collection_checkbox_selector.tab.label.observations' hide='true'></symbiota2-editable-text>
    </mat-tab>
</mat-tab-group>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- Leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <!-- Fake button for padding -->
        <button mat-icon-button disabled></button>
        <mat-checkbox
            [checked]="collectionSelections.isSelected(node.id)"
            (change)="toggleCollection($event.source, node.id)"
        >
            <div fxLayout='row' fxLayoutAlign='start center'>
                <div class='logo'>
                    <symbiota2-collection-logo
                        src='{{ node.icon }}'
                        size='2rem'
                    >
                    </symbiota2-collection-logo>
                </div>
                <span>{{ node.name }}</span>
            </div>
        </mat-checkbox>
    </mat-tree-node>

    <!-- Expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChildren">
        <div class="mat-tree-node">
            <button
                mat-icon-button
                matTreeNodeToggle
                [disabled]="node.isRootNode()"
            >
                <!-- ngIf b/c root node doesn't need to collapse -->
                <mat-icon
                    *ngIf="!node.isRootNode()"
                    class="mat-icon-rtl-mirror"
                >
                    {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
                </mat-icon>
            </button>
            <mat-checkbox
                i18n
                [checked]="nodeAllChildrenSelected(node)"
                [indeterminate]="nodeSomeChildrenSelected(node)"
                (change)="toggleCategory($event.source, node)"
            >
                {{(
                node.isRootNode() ?
                    ("collection.collection_checkbox_selector.select_all" | translate) :
                    node.name
            )}}
            </mat-checkbox>
        </div>
        <ul *ngIf="treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
        </ul>
    </mat-nested-tree-node>
</mat-tree>
