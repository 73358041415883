/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ChecklistTaxonLinkDto } from "../../dto/checklist-taxon-link";
import { ChecklistService } from "../../services/checklist/checklist.service";
import { ChecklistTaxaFilterService } from "../filter-form-service";

@Component({
    selector: 'checklist-taxon-search-options',
    templateUrl: './checklist-search-filter.html',
    styleUrls: ['./checklist-search-filter.component.scss'],
})
export class ChecklistTaxonSearchOptionsComponent implements OnInit {
    @Output() checklistFitlerFormChange = new EventEmitter()
    checklistSearchOptionsForm: FormGroup;
    taxon: string;
    checklistID: number;
    projectID: number;
    successMessage = '';
    errorMessage = '';
    hasError = false;
    hasSuccess = false;

    constructor(private readonly checklistService: ChecklistService,
                private readonly route: ActivatedRoute, 
                private checklistTaxaFilter: ChecklistTaxaFilterService) {}

    ngOnInit() {
        this.checklistSearchOptionsForm = new FormGroup({
            //taxonSearch: new FormControl('', [Validators.required]),
            taxonSearch: new FormControl(null),
            taxonCommonNames: new FormControl(false),
            taxonSynonyms: new FormControl(true),
            // searchOptions: new FormControl('commonNameRadio'),
            filterCentral: new FormControl(null),
            filterCommonNames: new FormControl(false),
            filterImages: new FormControl(false),
            filterVouchers: new FormControl(false),
            filterAuthors: new FormControl(false),
            filterOrderAsc: new FormControl(false)
        })

        this.projectID = parseInt(this.route.params['_value'].projectId);
        this.checklistID = parseInt(this.route.params['_value'].checklistId);

    }




    async onAddTaxon(data): Promise<void> {
        if (this.checklistSearchOptionsForm.valid) {
            this.checklistTaxaFilter.getFormData(data)
        }
    }
}