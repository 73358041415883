import { Subject } from 'rxjs'
import { AlertService, ApiClientService, AppConfigService, UserService } from '@symbiota2/ui-common'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

export interface Image {
    sciName: string, 
    id: number, 
    author: string, 
    family: string, 
    images: [], 
    commonNames: [], 
    synonym?: {
        id: number,
        scientificName: string
    }[]
}

@Injectable()
export class FlashCardGameService {
    // private images$ = new Subject()
    images: [] = []
    // private jwtToken = this.user.currentUser.pipe(map((user) => user.token))

    constructor(
        private readonly alerts: AlertService,
        private readonly user: UserService,
        private readonly apiClient: ApiClientService,
        private readonly httpService: HttpClient,
        private readonly appConfig: AppConfigService) 
    {}


    addImages(images) {
        this.images = images;
        // this.images$.next(images)
    }

    getImages() {
        return this.images;
    }

}
