import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { UserOutputDto } from '@symbiota2/api-auth';
import { UserPanel } from '../../pages/userlist-page/userpanel-data';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'symbiota2-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})

export class UserlistComponent {
  @Input() userList: UserOutputDto[];
  userData: UserData[];
  panelOpenState = true;

  private _userList: UserPanel[];

  @Input() set userPanelList(userPanelList: UserPanel[]) {
    if(userPanelList) {
     this._userList = userPanelList;
    }
  }

  get getUserList(): UserPanel[] {
    return this._userList;
  }

  displayedColumns: string[] = ['firstName', 'lastName', 'userName', 'role'];
  dataSource: MatTableDataSource<UserData>

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
}

export interface UserData {
  position?: number;
  firstName: string;
  lastName: string;
  userName: string;
  role: string;
  actions?: {edit: string, delete: string}
}

// // export interface PeriodicElement {
// //   name: string;
// //   position: number;
// //   weight: number;
// //   symbol: string;
// //   action: {edit: string, delete: string};
// // }

// // const ELEMENT_DATA: PeriodicElement[] = [
// //   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg',action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K', action: {edit: 'Edit', delete: 'Delete'}},
// //   {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca', action: {edit: 'Edit', delete: 'Delete'}},
// // ];