import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'symbiota2-collection-dwc-page',
  templateUrl: './collection-dwc-page.component.html',
  styleUrls: ['./collection-dwc-page.component.scss']
})
export class CollectionDwcPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
