<div id='main'>
    <div>
        <h1><symbiota2-editable-text key='knowledge.graph.list.title'></symbiota2-editable-text></h1>
    </div>

    <div *ngIf='graphs.length > 0'>
        <b style='font-size:125%'>
            <symbiota2-editable-text key='knowledge.graph.graphs.created.title'></symbiota2-editable-text>
        </b>
        <table id="graphs">
            <tr>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.name.header'></symbiota2-editable-text>
                </th>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.updatedAt.header'></symbiota2-editable-text>
                </th>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.size.header'></symbiota2-editable-text>
                </th>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.actions.header'></symbiota2-editable-text>
                </th>
            </tr>
            <tr *ngFor='let graph of graphs'>
                <td>
                    {{graph.name}}
                </td>
                <td>
                    {{graph.updatedAt}}
                </td>
                <td>
                    {{graph.size}}
                </td>
                <td>
                    <button mat-button mat-raised-button color="primary" (click)="doDownload(graph.name)">
                        {{'knowledge.graph.download.action.label' | translate}}
                    </button>
                    <symbiota2-editable-text key='knowledge.graph.download.action.label' hide='true'></symbiota2-editable-text>
                    &nbsp;
                    <button  *ngIf='userCanEdit' mat-button (click)="doDelete(graph.name)" mat-raised-button color="warn">
                        {{'knowledge.graph.delete.action.label' | translate}}
                    </button>
                    <symbiota2-editable-text key='knowledge.graph.delete.action.label' hide='true'></symbiota2-editable-text>
                    &nbsp;
                    <button  *ngIf='userCanEdit' mat-button (click)="doRebuild(graph.name)" mat-raised-button>
                        {{'knowledge.graph.rebuild.action.label' | translate}}
                    </button>
                    <symbiota2-editable-text key='knowledge.graph.rebuild.action.label' hide='true'></symbiota2-editable-text>
                </td>
            </tr>
        </table>
    </div>

    <p>
        &nbsp;
    </p>
    <div *ngIf='graphsToBuild.length > 0'>
        <b style='font-size:125%'>
            <symbiota2-editable-text key='knowledge.graph.graphs.to.create.title'></symbiota2-editable-text>
        </b>
        <table >
            <tr>
                <th>
                    {{'knowledge.graph.table.name.header' | translate}}
                </th>
                <th *ngIf='userCanEdit'>
                    {{'knowledge.graph.table.actions.header' | translate}}
                </th>
            </tr>
            <tr *ngFor='let graph of graphsToBuild'>
                <td>
                    {{graph.name}}
                </td>
                <td *ngIf='userCanEdit'>
                    <button mat-button mat-raised-button color="primary" (click)="doBuild(graph.name)">
                        {{'knowledge.graph.build.action.label' | translate}}
                    </button>
                </td>
            </tr>
        </table>
    </div>

</div>
