
<form>
    <button *ngIf='userCanEdit' style='float:right' mat-icon-button>
        <mat-icon color="primary" (click)="onAddCommonName()">add</mat-icon>
    </button>
    <br/>
    <mat-table #table [dataSource]="dataSource">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>{{"taxon.editor.common.name" | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.vernacularName}}
            </mat-cell>
        </ng-container>

        <!-- Notes Column -->
        <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef>{{"taxon.editor.common.notes" | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.notes}}
            </mat-cell>
        </ng-container>

        <!-- Language Column -->
        <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef>{{"taxon.editor.common.language" | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.language}}
            </mat-cell>
        </ng-container>

        <!-- Source Column -->
        <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef>{{"taxon.editor.common.source" | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.source}}
            </mat-cell>
        </ng-container>

        <!-- Sort Sequence Column -->
        <ng-container matColumnDef="sortSequence">
            <mat-header-cell *matHeaderCellDef>{{'taxon.editor.common.sortSequence' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.sortSequence}} </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef> <div *ngIf='userCanEdit'>{{'taxon.editor.common.action' | translate}}</div> </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div *ngIf='userCanEdit'>
                    <button mat-icon-button>
                        <mat-icon color="primary" (click)="openDialog('Update',element)">edit</mat-icon>
                    </button>
                    <button mat-icon-button>
                        <mat-icon color="warn" (click)="openDialog('Delete',element)">delete</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</form>
