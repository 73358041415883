import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_ROLES_LIST, UserService } from '@symbiota2/ui-common';
import { AddRolePermissions } from '../../dtos/create-user-data';
import { Permission } from '../../dtos/permission';

@Component({
    selector: 'symbiota2-create-role-page',
    templateUrl: './create-role.page.html',
    styleUrls: ['./create-role.page.scss']
})
export class CreateRolePage {
    createRoleForm: FormGroup;
    permissions: Permission[] = [];


    readonly nameField = new FormControl('', [Validators.required]);


    constructor(
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
    ) {}

    async ngOnInit() {
        this.createRoleForm = this.formBuilder.group({
            name: '',
            permissions: this.formBuilder.array([])
        })

        this.userService.getAllPermissions().subscribe(permissions => {
            this.permissions = permissions;
            this.permissions.forEach(p => {
                this.permissionArray.push(
                    this.formBuilder.group({
                        value: false,
                        id: p.id
                    })
                )
            })
        })
    }

    get permissionArray(): FormArray {
        return this.createRoleForm.get('permissions') as FormArray;
    }

    createRole(): void {
        const formData =  this.createRoleForm.getRawValue();

        const data:AddRolePermissions = {
            role: { name: formData.name },
            permissions: formData.permissions.filter(p => p.value === true).map((p) => p.id)
        }
        //const { name, permissions}: AddRolePermissions = data;
        this.userService.addNewRole(data)
            .subscribe(()=> {
            this.router.navigate([ROUTE_ROLES_LIST])
        })
    }
}