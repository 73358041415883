<div id='main'>
    <div fxLayout='row' fxLayoutGap='1rem' fxLayoutAlign='start center'>
        <div id='header' fxLayout='row' fxLayoutGap='1rem'>
            <div fxLayout='column' fxLayoutGap='0.5rem'>
                <h1>
                    <symbiota2-editable-text key='taxonomy.uploader.field.map.title'></symbiota2-editable-text>
                </h1>
                <p>
                    <symbiota2-editable-text key='taxonomy.uploader.field.map.instructions'></symbiota2-editable-text>
                </p>
            </div>
        </div>
    </div>

    <div id='table-container'>
        <mat-paginator [hidePageSize]='true' [pageSizeOptions]='[10]'></mat-paginator>
        <table mat-table [dataSource]='dataSource'>
            <ng-container matColumnDef="uploadField">
                <th mat-header-cell *matHeaderCellDef>{{"taxonomy.uploader.field.map.data.field" | translate}}</th>
                <td mat-cell *matCellDef="let row">{{ row.fieldName }}</td>
            </ng-container>

            <ng-container matColumnDef="apiFields">
                <th mat-header-cell *matHeaderCellDef>{{"taxonomy.uploader.field.map.symbiota2.field" | translate}}</th>
                <td mat-cell *matCellDef="let row">
                    <symbiota2-taxonomy-field-map-select
                        (valueChanged)='setFieldMapValue(row.fieldName, $event)'
                        [value]='row.value'
                        [options]='row.options'
                    >
                    </symbiota2-taxonomy-field-map-select>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef='["uploadField", "apiFields"]'></tr>
            <tr mat-row *matRowDef='let row; columns: ["uploadField", "apiFields"];'></tr>
        </table>
        <symbiota2-editable-text key='taxonomy.uploader.field.map.data.field' hide='true'></symbiota2-editable-text>
        <button
            class='begin'
            mat-raised-button color='primary'
            [disabled]='!requiredFieldsMapped'
            (click)='onSubmit()'>
            {{"taxonomy.uploader.field.map.begin.upload.button" | translate}}
        </button>
        <symbiota2-editable-text key='taxonomy.uploader.field.map.begin.upload.button' hide='true'></symbiota2-editable-text>
    </div>
</div>

