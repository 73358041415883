<div id='main'>
    <div *ngIf="!userCanEdit" class='my2' fxLayout='row' fxLayoutGap='1rem'>
        <symbiota2-editable-text key='taxon.delete.lacks.permission'></symbiota2-editable-text>
    </div>
    <div *ngIf="userCanEdit" class='my2'>
        <symbiota2-expansion-panel label="{{'taxon.delete.label' | translate}}">
            <symbiota2-editable-text key='taxon.delete.label' hide='true'></symbiota2-editable-text>
            <div *ngIf='children.length > 0'>
                <symbiota2-editable-text key='taxon.delete.has.children.message'></symbiota2-editable-text>
                <ul *ngFor='let child of children'>
                    <li><a href='{{editorRoute}}/{{child.taxon.id}}'>{{child.taxon.scientificName}}</a></li>
                </ul>
            </div>
            <div *ngIf='children.length == 0'>
                <div *ngIf='!prepared'>
                    <button mat-button (click)="prepareToDeleteTaxon()" mat-raised-button color="warn">
                        {{'taxon.prepare.delete.button' | translate}}
                    </button>
                    <symbiota2-editable-text key='taxon.prepare.delete.button' hide='true'></symbiota2-editable-text>
                </div>
                <div *ngIf='prepared'>
                    <div color='warn'>
                        <symbiota2-editable-text key='taxon.delete.warning.message'></symbiota2-editable-text>
                    </div>
                    <ul>
                        <li > <symbiota2-editable-text key='taxon.delete.taxon.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='imageIdsToDelete.length>0'>
                            {{imageIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.images.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='imageTagIdsToDelete.length>0'>
                            {{imageTagIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.image.tags.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='occurrenceIdsToDelete.length>0'>
                            {{occurrenceIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.occurrences.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='blockIdsToDelete.length>0'>
                            {{blockIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.blocks.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='descriptionIdsToDelete.length>0'>
                            {{descriptionIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.descriptions.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='commonNameIdsToDelete.length>0'>
                            {{commonNameIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.common.names.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='taxonLinkIdsToDelete.length>0'>
                            {{taxonLinkIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.links.label'></symbiota2-editable-text>
                        </li>
                        <li *ngIf='taxonResourceLinkIdsToDelete.length>0'>
                            {{taxonResourceLinkIdsToDelete.length}}
                            <symbiota2-editable-text key='taxon.delete.resource.links.label'></symbiota2-editable-text>
                        </li>
                    </ul>
                    <br/>
                    <button mat-button (click)="deleteTaxon()" mat-raised-button color="warn">
                        {{'taxon.delete.button' | translate}}
                    </button>
                    <symbiota2-editable-text key='taxon.delete.button' hide='true'></symbiota2-editable-text>
                </div>
            </div>
        </symbiota2-expansion-panel>
    </div>
</div>
