<div id='main'>
    <div>
        <h1>
            <symbiota2-editable-text key='taxonomy.upload.problem.ranks.title'></symbiota2-editable-text>
        </h1>
    </div>
    <mat-list role="list">
        <mat-list-item *ngFor="let name of names" role="listitem">{{name}}</mat-list-item>
    </mat-list>
</div>

