import { Component, Input, OnInit } from '@angular/core';
import { NavBarLink, UserService } from '@symbiota2/ui-common';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'symbiota2-sitemap-unordered-list',
  templateUrl: './sitemap-unordered-list.component.html',
  styleUrls: ['./sitemap-unordered-list.component.scss']
})
export class SitemapUnorderedListComponent {
  @Input() categoryTitle: string;
  @Input() categoryLinks: NavBarLink[];

  constructor() {
    //
  }

}
