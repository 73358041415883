import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { formToQueryParams, LoadingService } from '@symbiota2/ui-common';
import { OccurrenceService } from '../../services/occurrence.service';
import {
    ApiOccurrenceFindAllParams,
    ApiOccurrenceListItem, ApiTaxonSearchCriterion
} from '@symbiota2/data-access';
import { Observable } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { switchMap, tap } from 'rxjs/operators';
import { OccurrenceList } from '../../dto/occurrence-list';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { OccurrenceListItem } from '../../dto';
import { OccurrenceSearchResultModalComponent } from '../../components';
import { ROUTE_COLLECTION_PROFILE } from '@symbiota2/ui-plugin-collection';
import { Q_PARAM_COLLID } from '../../../constants';
import { ROUTE_SEARCH_DOWNLOAD } from '../../routes';

@Component({
    selector: "symbiota2-occurrence-search-results",
    templateUrl: "./occurrence-search-results-page.component.html",
    styleUrls: ["./occurrence-search-results-page.component.scss"]
})
export class OccurrenceSearchResultsPage implements OnInit {
    @ViewChild(MatTable) table: MatTable<ApiOccurrenceListItem>;

    public limit = 1000;
    public offset = 0;
    public newLimit;
    tempData

    readonly SHOW_COLUMNS = [
        'occurrenceID',
        'catalogNumber',
        'scientificNameComputed',
        'scientificName',
        'collection',
        'latitude',
        'longitude'
    ];
    occurrences: Observable<OccurrenceList>;
    testData: any

    constructor(
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        private readonly loading: LoadingService,
        private readonly matDialog: MatDialog,
        private readonly occurrenceService: OccurrenceService) {
            console.log('queryParams inside occurrence-search-results-page .ts: ', this.queryParams)
        }

    get queryParams(): ParamMap {
        return this.currentRoute.snapshot.queryParamMap;
    }

    ngOnInit() {

            // TODO: Clean this up
            const collectionIDs = this.queryParams.getAll(Q_PARAM_COLLID).map(
                (id) => parseInt(id)
            );
            const taxonSearchCriterion = this.queryParams.get('taxonSearchCriterion');
            const taxonSearchStr = this.queryParams.get('taxonSearchStr');
            const includeSynonymsStr = this.queryParams.get('includeSynonyms')
            const includeSynonyms = includeSynonymsStr ? includeSynonymsStr === 'true' : null

            const country = this.queryParams.get('country');
            const stateProvince = this.queryParams.get('stateProvince');
            const county = this.queryParams.get('county');
            const locality = this.queryParams.get('locality');
            const minimumElevationInMeters = parseInt(this.queryParams.get('minimumElevationInMeters'));
            const maximumElevationInMeters = parseInt(this.queryParams.get('maximumElevationInMeters'));

            const minLatitude = parseInt(this.queryParams.get('minLatitude'));
            const minLongitude = parseInt(this.queryParams.get('minLongitude'));

            const maxLatitude = parseInt(this.queryParams.get('maxLatitude'));
            const maxLongitude = parseInt(this.queryParams.get('maxLongitude'));

            const collectorLastName = this.queryParams.get('collectorLastName');

            const minEventDateStr = this.queryParams.get('minEventDate');
            const maxEventDateStr = this.queryParams.get('maxEventDate');

            const minEventDate = minEventDateStr ? new Date(minEventDateStr) : null;
            const maxEventDate = maxEventDateStr ? new Date(maxEventDateStr) : null;

            const catalogNumber = this.queryParams.get('catalogNumber');

            const limitToSpecimensStr = this.queryParams.get('limitToSpecimens');
            const limitToImagesStr = this.queryParams.get('limitToImages');
            const limitToGeneticStr = this.queryParams.get('limitToGenetic');

            const limitToSpecimens = limitToSpecimensStr ? limitToSpecimensStr === 'true' : null;
            const limitToImages = limitToImagesStr ? limitToImagesStr === 'true' : null;
            const limitToGenetic = limitToGeneticStr ? limitToGeneticStr === 'true' : null;

            if (collectionIDs.length > 0) {
                const findParams: Omit<ApiOccurrenceFindAllParams, 'limit' | 'offset'> = {
                    collectionID: collectionIDs,
                    taxonSearchCriterion: taxonSearchStr ? taxonSearchCriterion as ApiTaxonSearchCriterion : null,
                    taxonSearchStr: taxonSearchStr,
                    includeSynonyms,
                    county,
                    country,
                    stateProvince,
                    locality,
                    minimumElevationInMeters,
                    maximumElevationInMeters,
                    minLatitude,
                    minLongitude,
                    maxLatitude,
                    maxLongitude,
                    catalogNumber,
                    collectorLastName,
                    limitToSpecimens,
                    limitToImages,
                    limitToGenetic,
                    minEventDate: minEventDate ? minEventDate.toISOString() : null,
                    maxEventDate: maxEventDate ? maxEventDate.toISOString() : null,
                };

                this.testData = findParams;

                this.occurrences = this.occurrenceService.searchResults.occurrences.pipe(
                    tap(() => {
                        if (this.table) {
                            this.table.renderRows();
                        }
                    })
                );



                // Initial fetch, remaining are updated when searchResults.page()
                // is called

                this.occurrenceService.searchResults.setQueryParams(findParams);
                this.occurrenceService.searchResults.changePage(this.limit, this.offset);
                this.occurrenceService.searchResults.occurrences.subscribe(async data => this.newLimit = data.count)
            }
            else {
                this.router.navigate(
                    ['..'],
                    {
                        relativeTo: this.currentRoute,
                        queryParams: this.currentRoute.snapshot.queryParams
                    }
                );
            }

            // console.log('collectionIDs: ', collectionIDs)
            // console.log('taxonSearchCriterion: ', taxonSearchCriterion)
            // console.log('taxonSearchStr: ', taxonSearchStr)
            // console.log('country: ', country)
            // console.log('stateProvince" ', stateProvince)
            // console.log('county: ', county)
            // console.log('locality: ', locality)
            // console.log('minimumElevationInMeters: ', minimumElevationInMeters)
            // console.log('maximumElevationInMeters: ', maximumElevationInMeters)
            // console.log('minLatitude: ', minLatitude)
            // console.log('minLongitude: ', minLongitude)
            // console.log('maxLatitude: ', maxLatitude)
            // console.log('maxLongitude: ', maxLongitude)
            // console.log('collectorLastName: ', collectorLastName)
            // console.log('minEventDateStr: ', minEventDateStr)
            // console.log('maxEventDateStr: ', maxEventDateStr)
            // console.log('minEventDate: ', minEventDate)
            // console.log('maxEventDate: ', maxEventDate)
            // console.log('catalogNumber: ', catalogNumber)
            // console.log('limitToSpecimensStr: ', limitToSpecimensStr)
            // console.log('limitToImagesStr: ', limitToImagesStr)
            // console.log('limitToGeneticStr: ', limitToGeneticStr)
            // console.log('limitToSpecimens: ', limitToSpecimens)
            // console.log('limitToImages: ', limitToImages)
            // console.log('limitToGenetic: ', limitToGenetic)

    }


    collectionURL(id: number): string {
        return `/${ROUTE_COLLECTION_PROFILE.replace(':collectionID', id.toString())}`;
    }

    onPageChanged(e: PageEvent) {
        this.limit = e.pageSize;
        this.offset = this.limit * e.pageIndex;
        this.occurrenceService.searchResults.changePage(this.limit, this.offset);

        // console.log('limit: ', this.limit)
        // console.log('offset: ', this.offset)
        // console.log('event: ', e)
    }

    onOccurrenceSelected(occurrence: OccurrenceListItem) {
        console.log('check here: ', occurrence)
        this.matDialog.open(
            OccurrenceSearchResultModalComponent,
            {
                panelClass: 'mat-dialog-panel',
                width: '90vw',
                height: '90%',
                data: occurrence
            }
        );
    }


    async sendData() {

            this.occurrenceService.setData(this.newLimit)

            this.router.navigate(
            [`../${ROUTE_SEARCH_DOWNLOAD}`],
            { queryParams: { ...this.testData } }
            );
    }
}
