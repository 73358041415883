<div id='main'>
    <div>
        <h1><symbiota2-editable-text key='knowledge.graph.list.title'></symbiota2-editable-text></h1>
    </div>

    <div *ngIf='units.length > 0'>
        <b style='font-size:125%'>
            <symbiota2-editable-text key='knowledge.graph.graphs.created.title'></symbiota2-editable-text>
        </b>
        <table id="graphs">
            <tr>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.name.header'></symbiota2-editable-text>
                </th>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.updatedAt.header'></symbiota2-editable-text>
                </th>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.size.header'></symbiota2-editable-text>
                </th>
                <th>
                    <symbiota2-editable-text key='knowledge.graph.table.actions.header'></symbiota2-editable-text>
                </th>
            </tr>
            <tr *ngFor='let unit of units'>
                <td>
                    {{unit.rankName}}
                </td>
                <td>
                    {{unit.kingdomName}}
                </td>
                <td>
                    <symbiota2-editable-text key='knowledge.graph.download.action.label' hide='true'></symbiota2-editable-text>
                    <button  *ngIf='userCanEdit' mat-button (click)="doDelete(unit.rankName)" mat-raised-button color="warn">
                        {{'knowledge.graph.delete.action.label' | translate}}
                    </button>
                    <symbiota2-editable-text key='knowledge.graph.delete.action.label' hide='true'></symbiota2-editable-text>
                    <symbiota2-editable-text key='knowledge.graph.rebuild.action.label' hide='true'></symbiota2-editable-text>
                </td>
            </tr>
        </table>
    </div>
</div>
