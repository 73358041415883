<div id="update-user-container">
  <mat-card class="update-user-card">
    <mat-card-header>
      <mat-card-title>Update User Form</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form id="update-user-form" [formGroup]="updateUserForm">
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" required>
          </mat-form-field>
        </p>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <div style="flex: 0 0 60%">
            <h3>Current Roles</h3>
            <p *ngFor="let role of hasRoles" [id]="role.id">{{ role?.name }} 
              <input type="checkbox" [checked]="true" (change)="unCheckRole($event, role.id)">
            </p>
          </div>
          <div style="flex: 0 0 1">
            <mat-form-field appearance="fill">
              <mat-label>Role</mat-label>
              <mat-select formControlName="roles" (selectionChange)="updateRole($event.value)">
                <mat-option value="update_role" [disabled]="true">Add Role</mat-option>
                <mat-option *ngFor="let r of roles" [value]="r.id" [disabled]="checkhasRoles(hasRoles, r.id)">{{ r.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button class="color-delete" [disabled]="hasRoles?.length === 0" (click)="unassignRoles()">Unassign All</button>
          </div>
        </div>
      </form>
    </mat-card-content>

    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="updateUser(uid)" >Save</button>
    </mat-card-actions>

  </mat-card>
  
  
  
  
  
  </div>