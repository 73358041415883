<div id='main'>
    <div>
        <h1><symbiota2-editable-text key='image.details.title'></symbiota2-editable-text></h1>
    </div>
    <table>
        <tr>
            <td>
                <a *ngIf="image" [href]="localize(image?.url)"><img [src]="localize(image?.thumbnailUrl)"/></a>
                <div style='text-align:center'>
                    <symbiota2-editable-text key='image.details.clickon'></symbiota2-editable-text>
                </div>
            </td>
            <td>
                <button *ngIf='userCanEdit' style='float:right' mat-icon-button>
                    <mat-icon color="primary" (click)="openDialog('Update',image)">edit</mat-icon>
                </button>
                <div>
                    <b><symbiota2-editable-text key='image.details.sciname'></symbiota2-editable-text>
                    </b>: {{taxon?.scientificName}} {{taxon?.author}}
                </div>
                <div>
                    <b><symbiota2-editable-text key='image.details.caption'></symbiota2-editable-text>
                    </b>: {{image?.caption}}
                </div>
                <div>
                    <b><symbiota2-editable-text key='image.details.photographer'></symbiota2-editable-text>
                    </b>: {{image?.photographerName}}
                </div>
                <div>
                    <b><symbiota2-editable-text key='image.details.manager'></symbiota2-editable-text>
                    </b>: {{image?.owner}}
                </div>
                <div>
                    <b><symbiota2-editable-text key='image.details.image.source'></symbiota2-editable-text>
                    </b>: <a [href]='localize(image?.originalUrl)'>{{image?.originalUrl}}</a>
                </div>
                <div>
                    <b><symbiota2-editable-text key='image.details.image.locality'></symbiota2-editable-text>
                    </b>: {{image?.locality}}
                </div>
                <div>
                    <b><symbiota2-editable-text key='image.details.image.notes'></symbiota2-editable-text>
                    </b>: <span [innerHTML]="image?.notes"></span>
                </div>
            </td>
        </tr>
    </table>
</div>
