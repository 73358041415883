<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
        #drawer
        class="sidenav"
        fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
    >
        <mat-toolbar>{{
            'core.layout.header.topnav.home_link' | translate
        }}</mat-toolbar>
        <mat-nav-list fxLayout="column">
            <ng-template *ngTemplateOutlet="login"></ng-template>
            <symbiota2-navbar-menu
                *ngFor="let category of linkCategories() | async"
                [title]="category"
                [items]="categoryLinks(category) | async"
            >
            </symbiota2-navbar-menu>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="isHandset$ | async"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <a routerLink="/" class="invisible-btn">
                <img
                    id="logo-btn"
                    src="./assets/images/layout/SCAN_Logo_White.png"
                    alt="{{
                        'core.layout.header.banner.symbiota_logo_alt_text'
                            | translate
                    }}"
                />
            </a>
            <div id="navbar">
                <div
                    id="left-links"
                    fxFlex
                    fxLayout
                    fxLayoutAlign="start center"
                    fxHide.lt-md
                >
                    <div
                        fxLayout
                        fxLayoutGap="0rem"
                        fxLayoutAlign="center center"
                    >
                        <symbiota2-navbar-menu
                            *ngFor="let category of linkCategories() | async"
                            [title]="category"
                            [items]="categoryLinks(category) | async"
                        >
                        </symbiota2-navbar-menu>
                    </div>
                </div>
                <div fxLayoutAlign="end center" fxHide.xs>
                    <ng-template *ngTemplateOutlet="login"></ng-template>
                    <ng-template #login>
                        <ng-container
                            *ngIf="currentUser$ | async as user; else loginMenu"
                        >
                            <div id="user-menu">
                                <button
                                    mat-button
                                    [matMenuTriggerFor]="userMenu"
                                >
                                    {{
                                        'core.layout.header.topnav.welcome_line'
                                            | translate
                                                : { firstName: user.firstName }
                                    }}
                                    <mat-icon>expand_more</mat-icon>
                                </button>
                                <mat-menu #userMenu="matMenu">
                                    <a
                                        [routerLink]="ROUTE_PROFILE"
                                        mat-menu-item
                                    >
                                        {{
                                            'core.layout.header.topnav.my_profile_link'
                                                | translate
                                        }}
                                    </a>

                                    <div>
                                        <button mat-menu-item [matMenuTriggerFor]="subMenu" *ngIf="adminLinks()"> {{ adminLinks('key') | translate }}</button>
                                        <mat-menu #subMenu="matMenu">
                                            <ng-container *ngFor="let sub of adminLinks('value')">
                                                <!-- <ng-container *ngIf="isStringURL(sub.name); else route"> -->
                                                    <!-- <ng-container *ngIf="sub.name.startsWith('http://') || sub.name.startsWith('https://'); else route"> -->
                                                   <ng-container>
                                                    <a mat-menu-item href="{{sub.url}}">
                                                        {{ sub.name | translate }}
                                                    </a>
                                                </ng-container>
                                                <!-- <ng-template #route>
                                                    <a mat-menu-item [routerLink]="sub.url" onclick="window.history.go(0)">
                                                        {{ sub.name | translate }}
                                                    </a>
                                                </ng-template> -->
                                            </ng-container>
                                        </mat-menu>
                                    </div>

                                    <button mat-menu-item (click)="onLogout()">
                                        {{
                                            'core.layout.header.topnav.logout_link'
                                                | translate
                                        }}
                                    </button>
                                </mat-menu>
                            </div>

                            <div
                                *ngIf="{
                                    notifications: notifications | async,
                                    count: notificationCount | async
                                } as notificationData"
                            >
                                <button
                                    mat-icon-button
                                    [matMenuTriggerFor]="notificationMenu"
                                    [disabled]="notificationData.count === 0"
                                >
                                    <mat-icon
                                        id="notification-icon"
                                        matBadgePosition="below after"
                                        matBadgeColor="accent"
                                        matBadgeSize="small"
                                        [matBadge]="notificationData.count"
                                        [matBadgeHidden]="
                                            notificationData.count === 0
                                        "
                                    >
                                        notifications
                                    </mat-icon>
                                </button>
                                <mat-menu #notificationMenu="matMenu">
                                    <div
                                        mat-menu-item
                                        class="notification"
                                        *ngFor="
                                            let n of notificationData.notifications
                                        "
                                        (click)="onNotificationClicked(n)"
                                    >
                                        <p class="createdAt">
                                            <ng-container
                                                *ngIf="
                                                    isToday(n.createdAt);
                                                    else notToday
                                                "
                                            >
                                                {{
                                                    n.createdAt
                                                        | date: 'shortTime'
                                                }}
                                            </ng-container>
                                            <ng-template #notToday>
                                                {{
                                                    n.createdAt
                                                        | date: 'longDate'
                                                }}
                                            </ng-template>
                                        </p>
                                        <p>{{ n.message }}</p>
                                        <mat-divider></mat-divider>
                                    </div>
                                    <div id="notification-actions">
                                        <button
                                            mat-flat-button
                                            (click)="clearNotifications()"
                                        >
                                            Clear all
                                        </button>
                                    </div>
                                </mat-menu>
                            </div>
                        </ng-container>
                    </ng-template>

                    <ng-template #loginMenu>
                        <div fxLayoutGap="0.5rem">
                            <button mat-button (click)="onLogin()">
                                {{
                                    'core.layout.header.topnav.login_link'
                                        | translate
                                }}
                            </button>
                        </div>
                    </ng-template>

                    <mat-divider vertical id="divider"></mat-divider>

                    <mat-select
                        id="lang-select"
                        [ngModel]="currentLang"
                        (ngModelChange)="useLanguage($event)"
                    >
                        <mat-select-trigger>
                            <mat-icon>translate</mat-icon>
                        </mat-select-trigger>
                        <mat-option value="en">English</mat-option>
                        <mat-option value="ar">Arabic</mat-option>
                        <mat-option value="zh">Chinese</mat-option>
                        <mat-option value="fa">Farsi</mat-option>
                        <mat-option value="fr">French</mat-option>
                        <mat-option value="de">German</mat-option>
                        <mat-option value="hi">Hindi</mat-option>
                        <mat-option value="it">Italian</mat-option>
                        <mat-option value="ja">Japanese</mat-option>
                        <mat-option value="pt">Portuguese</mat-option>
                        <mat-option value="ru">Russian</mat-option>
                        <mat-option value="es">Spanish</mat-option>
                        <mat-option value="ur">Urdu</mat-option>
                    </mat-select>

                    <div *ngIf='!apiIsUp;else api_up'>
                        <button style='margin: 4px' mat-raised-button color="warn">
                            {{'core.layout.header.topnav.api'| translate}}<br/>
                            {{'core.layout.header.topnav.api_down'| translate}}
                        </button>
                    </div>
                    <ng-template #api_up>
                        <div fxLayoutGap="0.5rem">
                            <button mat-button (click)="onHealth()">
                                {{
                                'core.layout.header.topnav.health_link'
                                    | translate
                                }}
                            </button>
                        </div>
                    </ng-template>

                    <div *ngIf='currentUser$ | async as user'>
                        <!-- <div *ngIf='user.canEditProject(user.uid)'> -->
                        <div>
                            <div *ngIf='editing' fxLayoutGap="0.rem">
                                <button class="md-small-button" mat-button id='view-button' (click)="turnOffEditing()">
                                    {{
                                    'core.layout.header.topnav.noedit_button'
                                        | translate
                                    }}
                                </button>
                            </div>
                            <div *ngIf='!editing' fxLayoutGap="0rem">
                                <button class="md-small-button" mat-button id='edit-button'  (click)="turnOnEditing()">
                                    {{
                                    'core.layout.header.topnav.edit_button'
                                        | translate
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-toolbar>
        <ng-content> </ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
