<div id=main>

    <div>
        <h1>
            <symbiota2-editable-text key='taxonomy.viewer.title'></symbiota2-editable-text>
        </h1>
    </div>

    <!-- Configure name list -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel expanded=false label='{{"taxonomy.viewer.configure.label" | translate}}'>
            <symbiota2-editable-text key='taxonomy.viewer.configure.label' hide='true'></symbiota2-editable-text>
            <mat-radio-group
                aria-labelledby="configure-radio-group-label"
                class="configure-radio-group"
                (change)='configureChangeAction()'
                [(ngModel)]="kindOfName">
                <mat-radio-button value="Scientific">
                    <symbiota2-editable-text key='taxonomy.viewer.option.scientific'></symbiota2-editable-text>
                </mat-radio-button>&nbsp;&nbsp;
                <mat-radio-button value="Common">
                    <symbiota2-editable-text key='taxonomy.viewer.option.common'></symbiota2-editable-text>
                </mat-radio-button>

                <div>
                    <br/>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'taxonomy.viewer.label.authority' | translate}}</mat-label>
                        <mat-select field-class="symbiota2-field-class"
                                    placeholder='{{"taxonomy.viewer.label.authority" | translate}}'
                                    [(value)]="taxonomicAuthorityID"
                                    (selectionChange)="authorityChangeAction()">
                            <mat-option *ngFor="let authority of taxonomicAuthorityList"
                                        [value]="authority.id" >{{ authority.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <symbiota2-editable-text key='taxonomy.viewer.label.authority' hide='true'></symbiota2-editable-text>

                </div>

                <div *ngIf='kindOfName == "Scientific"'>
                    <mat-checkbox class="example-margin" [(ngModel)]='hasAuthors'
                    ><symbiota2-editable-text key='taxonomy.viewer.label.checkbox.authors'></symbiota2-editable-text></mat-checkbox>
                </div>

                <div *ngIf='kindOfName == "Common"'>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'taxonomy.viewer.label.language' | translate}}</mat-label>
                        <mat-select field-class="symbiota2-field-class"
                                    placeholder='{{"taxonomy.viewer.label.language" | translate}}'
                                    [(value)]="language"
                                    (selectionChange)="languageChangeAction(language)">
                            <mat-option *ngFor="let lang of languageList"
                                        [value]="lang" >{{ lang }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <symbiota2-editable-text key='taxonomy.viewer.label.language' hide='true'></symbiota2-editable-text>

                </div>
            </mat-radio-group>
        </symbiota2-expansion-panel>
    </div>

    <!-- Taxonomy -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel label='{{"taxonomy.viewer.label.taxonomy" | translate}}'>
            <symbiota2-editable-text key='taxonomy.viewer.label.taxonomy' hide='true'></symbiota2-editable-text>
            <form class="name-autocomplete-form" (ngSubmit)="onSubmit()" autocomplete='off'>
                <mat-form-field class="name-autocomplete-full-width">
                    <mat-label *ngIf='kindOfName == "Scientific"'>{{'taxonomy.viewer.label.name.enter.scientific' | translate}}</mat-label>
                    <mat-label *ngIf='kindOfName == "Common"'>{{'taxonomy.viewer.label.name.enter.common' | translate}}</mat-label>
                    <input *ngIf='kindOfName == "Scientific"' type="text"
                           placeholder='{{"taxonomy.viewer.label.name.scientific" | translate}}'
                           aria-label="String"
                           autocomplete="off"
                           matInput
                           (keyup)="onKey($event)"
                           [formControl]="nameControl"
                           [matAutocomplete]="auto">
                    <input *ngIf='kindOfName == "Common"' type="text"
                           placeholder='{{"taxonomy.viewer.label.name.common" | translate}}'
                           aria-label="String"
                           autocomplete="off"
                           matInput
                           (keyup)="onKey($event)"
                           [formControl]="nameControl"
                           [matAutocomplete]="auto">

                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectedSciname($event)">
                        <mat-option *ngFor="let option of nameOptions" [value]="option.name">
                            {{option.name}} <small *ngIf='hasAuthors'>{{option.author}}</small>
                        </mat-option>

                    </mat-autocomplete>

                </mat-form-field>
                <symbiota2-editable-text key='taxonomy.viewer.label.name.enter.scientific' hide='true'></symbiota2-editable-text>
                <symbiota2-editable-text key='taxonomy.viewer.label.name.enter.common' hide='true'></symbiota2-editable-text>

                <mat-label *ngIf="looking && !nameFound" style='color:red'>
                    <b><symbiota2-editable-text key='taxonomy.viewer.name.error'></symbiota2-editable-text></b>
                    <br/>
                </mat-label>
            </form>
            <br/>
            <mat-checkbox class="example-margin" [(ngModel)]='includeAuthors'><symbiota2-editable-text key='taxonomy.viewer.label.checkbox.include.authors'></symbiota2-editable-text></mat-checkbox>
            &nbsp; &nbsp;
            <mat-checkbox class="example-margin" [(ngModel)]='includeRank'><symbiota2-editable-text key='taxonomy.viewer.label.checkbox.show.rank.name'></symbiota2-editable-text></mat-checkbox>
            &nbsp; &nbsp;
            <mat-checkbox class="example-margin" [(ngModel)]='showAllRanks' (ngModelChange)="refreshTree()"><symbiota2-editable-text key='taxonomy.viewer.label.checkbox.show.all.ranks'></symbiota2-editable-text></mat-checkbox>
            &nbsp; &nbsp;
            <mat-checkbox class="example-margin" [(ngModel)]='expandableTree'><symbiota2-editable-text key='taxonomy.viewer.label.checkbox.expandable.tree'></symbiota2-editable-text></mat-checkbox>

            <symbiota2-expansion-panel
                *ngIf='possibleTaxons.length > 1'
                label='{{"taxonomy.viewer.label.choose.taxon" | translate}}'
            >
                <symbiota2-editable-text key='taxonomy.viewer.label.choose.taxon' hide='true'></symbiota2-editable-text>

                <mat-selection-list
                    #names
                    (selectionChange)="nameListChange(names.selectedOptions.selected)"
                    [multiple]="false"
                >
                    <mat-list-option *ngFor="let txn of possibleTaxons" [value]="txn.taxonID">
                        {{txn.name}} {{txn.author}}
                    </mat-list-option>
                </mat-selection-list>
            </symbiota2-expansion-panel>

            <div *ngIf='this.problematicRouting'>
                <br/>
                <span style="color:red;">Problematic routing</span>
            </div>

            <div>

                <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="trackByTaxonID">
                    <!--
                    <cdk-tree-node *cdkTreeNodeDef="let node"
                                   cdkTreeNodePadding
                                   cdkTreeNodePaddingIndent="7"
                                   class="example-tree-node">
                        <button mat-icon-button disabled></button>
                        {{node.node.name}}: top<br/>
                        <span>
                        <ul *ngIf='!node.node.synonym'>

                            <button *ngIf='expandableTree && !node.node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="loadChildren(node.node);node.node.expanded=true">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_right'}}
                                </mat-icon>
                            </button>
                            <button *ngIf='expandableTree && node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="closeNode(node);node.expanded=false">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_drop_down'}}
                                </mat-icon>
                            </button>

                            <li *ngIf="node.node.rankID >= genusRankID; then italicizeName else normalName" class="example-tree"></li>
                        </ul>
                        <ul *ngIf='isSynonym(node.node)'>
                            <li *ngIf="node.node.rankID >= genusRankID; then italicizeSynonym else normalSynonym" class="example-tree"></li>
                        </ul>
                    </span>
                    </cdk-tree-node>
                    -->

                    <cdk-tree-node *cdkTreeNodeDef="let node" cdkTreeNodePadding
                        cdkTreeNodePaddingIndent="7" class="example-tree-node">
                        <ng-template #italicizeName>
                            <small *ngIf='includeRank'>{{rankFor(node.node.rankID)}}&nbsp;</small>
                            <a [routerLink]="['/taxon/profile', node.node.taxonID]" target=”_blank” class='taxonlink'><i>{{node.node.name}}</i></a>
                            <small *ngIf='includeAuthors'>&nbsp;{{node.node.author}}</small>
                            <span *ngIf='node.node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                        </ng-template>
                        <ng-template #normalName>
                            <small *ngIf='includeRank'>{{rankFor(node.node.rankID)}}&nbsp;</small>
                            <a [routerLink]="['/taxon/profile', node.node.taxonID]" target=”_blank” class='taxonlink'>{{node.node.name}}</a>
                            <small *ngIf='includeAuthors'>&nbsp;{{node.node.author}}</small>
                            <span *ngIf='node.node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                        </ng-template>
                        <ng-template #italicizeSynonym>
                            <small *ngIf='includeRank'>{{rankFor(node.node.rankID)}}&nbsp;</small>
                            <a [routerLink]="['/taxon/profile', node.node.taxonID]" target=”_blank” class='taxonlink'>[<i>{{node.node.name}}</i>]</a>
                            <small *ngIf='includeAuthors'>&nbsp;{{node.node.author}}</small>
                        </ng-template>
                        <ng-template #normalSynonym>
                            <small *ngIf='includeRank'>{{rankFor(node.node.rankID)}}&nbsp;</small>
                            <a [routerLink]="['/taxon/profile', node.node.taxonID]" target=”_blank” class='taxonlink'>[{{node.node.name}}]</a>
                            <small *ngIf='includeAuthors'>&nbsp;{{node.node.author}}</small>
                        </ng-template>
                        <button *ngIf='expandableTree'
                                mat-icon-button [attr.aria-label]="'toggle ' + node.node.name"
                                (click)="loadChildren(node.node,treeControl.isExpanded(node))"
                                cdkTreeNodeToggle>
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{node.node.expanded ? 'arrow_drop_down' : 'arrow_right'}}
                                <!--
                                {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
                                -->
                            </mat-icon>
                        </button>
                        <!-- {{node.node.name}}:  nested -->
                        <span>
                        <span *ngIf='!node.node.synonym'>
                            <!--
                            <button *ngIf='expandableTree && !node.node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="loadChildren(node.node);node.node.expanded=true">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_right'}}
                                </mat-icon>
                            </button>
                            <button *ngIf='expandableTree && node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="closeNode(node);node.expanded=false">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_drop_down'}}
                                </mat-icon>
                            </button>
                            -->
                            <span *ngIf="node.node.rankID >= genusRankID; then italicizeName else normalName" class="example-tree"></span>
                        </span>
                        <span *ngIf='isSynonym(node.node)'>
                            <span *ngIf="node.node.rankID >= genusRankID; then italicizeSynonym else normalSynonym" class="example-tree"></span>
                        </span>
                    </span><br/>
                    </cdk-tree-node>

                </cdk-tree>
            </div>



            <!--
            <mat-tree *ngIf='this.showAllRanks' #tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <ng-template #italicizeName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'><i>{{node.name}}</i></a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <ng-template #normalName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>{{node.name}}</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <ng-template #italicizeSynonym>
                        <span *ngIf='expandableTree'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>[<i>{{node.name}}</i>]</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                    </ng-template>
                    <ng-template #normalSynonym>
                        <span *ngIf='expandableTree'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>[{{node.name}}]</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                    </ng-template>
                    <span>
                        <ul *ngIf='!node.synonym'>
                            <button *ngIf='expandableTree && !node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="loadChildren(node);node.expanded=true">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_right'}}
                                </mat-icon>
                            </button>
                            <button *ngIf='expandableTree && node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="closeNode(node);node.expanded=false">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_drop_down'}}
                                </mat-icon>
                            </button>
                            <li *ngIf="node.rankID >= genusRankID; then italicizeName else normalName" class="example-tree"></li>
                        </ul>
                        <ul *ngIf='isSynonym(node)'>
                            <li *ngIf="node.rankID >= genusRankID; then italicizeSynonym else normalSynonym" class="example-tree"></li>
                        </ul>
                    </span>

                </mat-tree-node>

                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
                    <ng-template #italicizeName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'><i>{{node.name}}</i></a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <ng-template #normalName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>{{node.name}}</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <li *ngIf='!node.expanded' class="example-tree">
                        <button *ngIf='expandableTree' mat-icon-button matTreeNodeToggle
                                (click)="loadChildren(node);node.expanded=true">
                            <mat-icon class="mat-icon-button">
                                {{'arrow_right'}}
                            </mat-icon>
                        </button>
                        <div *ngIf="node.rankID >= genusRankID; then italicizeName else normalName"></div>
                    </li>
                    <li *ngIf='node.expanded' class="example-tree">
                        <button *ngIf='expandableTree' mat-icon-button matTreeNodeToggle
                                (click)="closeNode(node);node.expanded=false">
                            <mat-icon class="mat-icon-button">
                                {{'arrow_drop_down'}}
                            </mat-icon>
                        </button>
                        <div *ngIf="node.rankID >= genusRankID; then italicizeName else normalName"></div>
                    </li>
                    <ul>
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </mat-nested-tree-node>
            </mat-tree>

            <mat-tree *ngIf='!this.showAllRanks' #tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <ng-template #italicizeName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'><i>{{node.name}}</i></a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <ng-template #normalName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>{{node.name}}</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <ng-template #italicizeSynonym>
                        <span *ngIf='expandableTree'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>[<i>{{node.name}}</i>]</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                    </ng-template>
                    <ng-template #normalSynonym>
                        <span *ngIf='expandableTree'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>[{{node.name}}]</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                    </ng-template>
                    <span *ngIf='this.visibleRanksMap.get(node.rankID)'>
                        <ul *ngIf='!node.synonym'>
                            <button *ngIf='expandableTree && !node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="loadChildren(node);node.expanded=true">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_right'}}
                                </mat-icon>
                            </button>
                            <button *ngIf='expandableTree && node.expanded' mat-icon-button matTreeNodeToggle
                                    (click)="closeNode(node);node.expanded=false">
                                <mat-icon class="mat-icon-button">
                                    {{'arrow_drop_down'}}
                                </mat-icon>
                            </button>
                            <li *ngIf="node.rankID >= genusRankID; then italicizeName else normalName" class="example-tree"></li>
                        </ul>
                        <span *ngIf='isSynonym(node)'>
                            <ul>
                                <li *ngIf="node.rankID >= genusRankID; then italicizeSynonym else normalSynonym" class="example-tree"></li>
                            </ul>
                        </span>
                    </span>
                </mat-tree-node>

                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
                    <ng-template #italicizeName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'><i>{{node.name}}</i></a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <ng-template #normalName>
                        <small *ngIf='includeRank'>{{rankFor(node.rankID)}}&nbsp;</small>
                        <a [routerLink]="['/taxon/profile', node.taxonID]" target=”_blank” class='taxonlink'>{{node.name}}</a>
                        <small *ngIf='includeAuthors'>&nbsp;{{node.author}}</small>
                        <span *ngIf='node.taxonID == selectedTaxonID'>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == genusRankID"
                            keyword='genus.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID == familyRankID"
                            keyword='family.keyword'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        <symbiota2-kibana-dashboard-link
                            *ngIf="node.rankID > genusRankID"
                            keyword='sciname'
                            term='{{node.name}}'>
                        </symbiota2-kibana-dashboard-link>
                        </span>
                    </ng-template>
                    <li *ngIf='!node.expanded && this.visibleRanksMap.get(node.rankID)' class="example-tree">
                        <button *ngIf='expandableTree' mat-icon-button matTreeNodeToggle
                                (click)="loadChildren(node);node.expanded=true">
                            <mat-icon class="mat-icon-button">
                                {{'arrow_right'}}
                            </mat-icon>
                        </button>
                        <div *ngIf="node.rankID >= genusRankID; then italicizeName else normalName"></div>
                    </li>
                    <li *ngIf='node.expanded && this.visibleRanksMap.get(node.rankID)' class="example-tree">
                        <button *ngIf='expandableTree' mat-icon-button matTreeNodeToggle
                                (click)="closeNode(node);node.expanded=false">
                            <mat-icon class="mat-icon-button">
                                {{'arrow_drop_down'}}
                            </mat-icon>
                        </button>
                        <div *ngIf="node.rankID >= genusRankID; then italicizeName else normalName"></div>
                    </li>
                    <ul *ngIf='this.visibleRanksMap.get(node.rankID)'>
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                    <span *ngIf='!this.visibleRanksMap.get(node.rankID)'>
                        <ng-container matTreeNodeOutlet></ng-container>
                    </span>
                </mat-nested-tree-node>
            </mat-tree>

-->

        </symbiota2-expansion-panel>
    </div>

</div>
