<div fxLayout='row' id=main>
    <div fxFlex='1 1 100%'>
        <div
            *ngIf='mapLoaded'
            id='spatialModuleMap'
            leaflet
            leafletDraw
            [leafletOptions]='mapOptions'
            [leafletDrawOptions]='drawOptions'
            (leafletZoomChange)='onMapViewChanged()'
            (leafletCenterChange)='onMapViewChanged()'
            (leafletDrawCreated)='onDrawPoly($event)'
            (leafletMapReady)='onMapReady($event)'
        >
        </div>
    </div>
</div>
