<form id="form" [formGroup]="uploadDwcForm">
    <div id="main">
        <div id="header" fxLayoutAlign="start center" fxLayoutGap="1rem">
            <ng-container *ngIf="collections.currentCollection | async as collection">
                <symbiota2-collection-logo [src]="collection.icon" [collectionID]="collection.id">
                </symbiota2-collection-logo>
            </ng-container>
            <h1>
                <symbiota2-editable-text key='plugins.occurrence.upload.ipt.header'></symbiota2-editable-text>
            </h1>
        </div>
        <mat-card id="institution" class="mat-elevation-z0 s2-field" fxLayout="column">
            <mat-card-title>
                <symbiota2-editable-text key='plugins.occurrence.upload.ipt.instruction'></symbiota2-editable-text>
            </mat-card-title>
            <mat-card class="mat-elevation-z0 s2-field outline">
                <mat-radio-group formControlName="uploadOption" fxLayout="row">
                    <mat-radio-button value="link"></mat-radio-button>
                    <mat-card id="create-inst" class="mat-elevation-z0 s2-field no-padding">
                        <mat-card-title>
                            <symbiota2-editable-text key='plugins.occurrence.upload.iptheader'></symbiota2-editable-text>
                        </mat-card-title>
                        <mat-card-subtitle id="ipsubtitle" style="margin-bottom: 4%;">
                            <symbiota2-editable-text key='plugins.occurrence.upload.iptsubtitle'></symbiota2-editable-text>
                        </mat-card-subtitle>
                        <div id="two-field-row" fxLayout="row" fxLayoutGap="1rem">
                            <mat-form-field>
                                <input matInput id="linkInput" placeholder="{{
                                    'plugins.occurrence.upload.ipt.hint'
                                        | translate
                                }}" formControlName="iptLink" />
                                <symbiota2-editable-text key='plugins.occurrence.upload.ipt.hint' hide='true'></symbiota2-editable-text>

                            </mat-form-field>
                            <button mat-flat-button color="primary" id="link_button" (click)="onLinking()"
                                [disabled]="!linkEnabled">
                                {{ "plugins.occurrence.upload.ipt.button" | translate }}
                            </button>
                            <symbiota2-editable-text key='plugins.occurrence.upload.ipt.button' hide='true'></symbiota2-editable-text>
                            <!--[disabled]="uploadEnabled && !dwcaLink.value"-->
                        </div>
                    </mat-card>
                    <mat-radio-button value="zip"> </mat-radio-button>
                    <mat-card id="select-inst" class="mat-elevation-z0 s2-field no-padding option">
                        <mat-card-title>
                            <symbiota2-editable-text key='plugins.occurrence.upload.dwcheader'></symbiota2-editable-text>
                        </mat-card-title>
                        <mat-card-subtitle>
                            <symbiota2-editable-text key='plugins.occurrence.upload.dwcsubtitle'></symbiota2-editable-text>
                        </mat-card-subtitle>

                        <div class="my2" fxLayout="row" fxLayoutGap="1rem">
                            <symbiota2-file-upload-field [formControl]="fileInput" [disabled]="!uploadZipEnabled">
                            </symbiota2-file-upload-field>
                            <button mat-flat-button color="primary" (click)="onUploadDwca()"
                                [disabled]="!fileInput.value">
                                {{'plugins.occurrence.upload.dwc.upload.button' | translate}}
                            </button>
                            <symbiota2-editable-text key='plugins.occurrence.upload.dwc.upload.button' hide='true'></symbiota2-editable-text>
                        </div>


                    </mat-card>

                    <mat-radio-button value="csv"> </mat-radio-button>
                    <mat-card id="upload-csv" class="mat-elevation-z0 s2-field no-padding option">
                        <mat-card-title>
                            <symbiota2-editable-text key='plugins.occurrence.upload.csvheader'></symbiota2-editable-text>
                        </mat-card-title>
                        <mat-card-subtitle style="margin-bottom: 1rem;">
                            <symbiota2-editable-text key='plugins.occurrence.upload.csvsubtitle'></symbiota2-editable-text>
                        </mat-card-subtitle>

                        <div class="my2" fxLayout="row" fxLayoutGap="1rem">
                            <symbiota2-file-upload-field [formControl]="fileInputCsv" [disabled]="!uploadCsvEnabled">
                            </symbiota2-file-upload-field>
                            <button mat-flat-button color="primary" (click)="onUploadCsv()"
                                [disabled]="!fileInputCsv.value">
                                {{'plugins.occurrence.upload.csv.upload.button' | translate}}
                            </button>
                            <symbiota2-editable-text key='plugins.occurrence.upload.csv.upload.button' hide='true'></symbiota2-editable-text>
                        </div>


                    </mat-card>
                </mat-radio-group>
            </mat-card>
        </mat-card>


    </div>
</form>