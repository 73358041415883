<mat-card id="main" *ngIf="collection$ | async as collection">
    <mat-card-title id="title" fxLayoutAlign="start center"
        ><mat-icon color="primary">unarchive</mat-icon><symbiota2-editable-text key='collection.dwc.publishing.card.title.text'></symbiota2-editable-text></mat-card-title
    >
    <mat-card-content fxLayout="column" fxLayoutGap="1rem">
        <mat-card id="tool-info" fxLayout="row" fxLayoutGap="8px">
            <mat-icon>info</mat-icon>
            <symbiota2-editable-paragraph key='collection.dwc.publishing.card.content.para.text'></symbiota2-editable-paragraph>
        </mat-card>
        <div fxLayout="row" fxLayoutGap="1rem">
            <mat-card class="symbiota2-info-mat-card" fxFlex="40">
                <mat-card-title><symbiota2-editable-text key='collection.dwc.publishing.card.content.title.text'></symbiota2-editable-text></mat-card-title>
                <form fxLayout="column" [formGroup]="publishArchiveForm">
                    <!-- TODO: <mat-checkbox>Include Determination History</mat-checkbox> -->
                    <mat-checkbox formControlName="includeImageURLs"
                        ><symbiota2-editable-text key='collection.dwc.publishing.card.content.image.check.label'></symbiota2-editable-text></mat-checkbox
                    >
                    <mat-checkbox formControlName="redactSensitiveLocalities"
                        ><symbiota2-editable-text key='collection.dwc.publishing.card.content.redact.check.label'></symbiota2-editable-text></mat-checkbox
                    >
                    <button
                        mat-raised-button
                        id="publish-btn"
                        color="primary"
                        [disabled]="!publishArchiveForm.valid"
                        (click)="onPublishArchive()"
                    >{{'collection.dwc.publishing.card.content.action.label' | translate}}
                    </button>
                    <symbiota2-editable-text key='collection.dwc.publishing.card.content.action.label' hide='true'></symbiota2-editable-text>
                </form>
            </mat-card>
            <mat-card class="symbiota2-primary-info-mat-card" fxFlex="60">
                <mat-card-title fxLayoutAlign="start end"
                    ><mat-icon>description</mat-icon><symbiota2-editable-text key='collection.dwc.publishing.archive.card.title.text.label'></symbiota2-editable-text></mat-card-title
                >
                <mat-card-content>
                    <ng-container
                        *ngIf="
                            archiveInfo$ | async as archive;
                            else elseTemplate
                        "
                    >
                        <p>
                            <b><symbiota2-editable-text key='collection.dwc.publishing.archive.card.content.dwc.file.label'></symbiota2-editable-text>: </b>
                                <symbiota2-dwc-download-link [archive]=archive></symbiota2-dwc-download-link>
                        </p>
                        <p>
                            <b><symbiota2-editable-text key='collection.dwc.publishing.archive.card.content.description.text'></symbiota2-editable-text>:</b>
                            <symbiota2-editable-text key='collection.dwc.publishing.archive.card.content.description.label.text'></symbiota2-editable-text>
                            {{ collection.collectionName }}
                        </p>
                        <p>
                            <b><symbiota2-editable-text key='collection.dwc.publishing.archive.card.content.file_size.text'></symbiota2-editable-text>:</b>
                            {{ formatBytes(archive.size, 2) }}
                        </p>
                        <p>
                            <b><symbiota2-editable-text key='collection.dwc.publishing.archive.card.content.last_update.text'></symbiota2-editable-text>:</b>
                            {{ lastUpdate.toDateString() }}
                        </p>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <symbiota2-editable-text key='collection.dwc.publishing.archive.card.content.no_dwc_archives.para.text'></symbiota2-editable-text>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>
</mat-card>
