<div class="main">
    <div class="title-box">
        <h1>Flash card quiz</h1>
        <select #game_type (change)="changeGameType(game_type.value)">
            <option default>Species</option>
            <option>Genera</option>
            <option>Family</option>
        </select>
    </div>

    <div class="game-box" *ngFor="let item of activeData; let i = index">

        <ng-container *ngIf="i === activeIndex">

                <div class="image-box">
                    <img [src]="item.images[currentImage].url.startsWith('/imglib') ? 
                    'https://openherbarium.org/' + 
                    item.images[currentImage].url : 
                    item.images[currentImage].url" 
                    [alt]="item.images[currentImage].alt"
                    (click)="fullSize = true">
                </div>

            <ng-container *ngIf="fullSize">
                <div class="checklist_img_full_size_overlay">
                    <img [src]="item.images[currentImage].url.startsWith('/imglib') ? 
                    'https://openherbarium.org/' + 
                    item.images[currentImage].url : 
                    item.images[currentImage].url" 
                    [alt]="item.images[currentImage].alt"
                    >
                    <button class="checklist-image-close-button" (click)="fullSize = false">
                        <svg xmlns="http://www.w3.org/2000/svg" class="checklist-image-close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </ng-container>
            <div class="image-controls">
                <a (click)="getNextRandomItem(item, true)">Skip this one</a>
                <p>Image {{currentImage + 1}} of {{ item.images.length }}</p>
                <a (click)="(currentImage + 1) === item.images.length ? 
                currentImage = 0 : currentImage = currentImage + 1">Next image</a>
            </div>
            <!-- for testing -->
            <!-- <p>active index : {{ activeIndex }} {{ item.scientificName}} currentIndex: {{i}} 
                 total: {{ activeData.length}}  : {{ displayCommonName }}
            </p> -->
            <div class="species-box">
                <select #selectName class="species-select" (change)="checkAnswer(selectName.value, item)">
                    <option disabled selected>{{ gameType === 'Species' ? 'Name' : gameType === 'Genera' ? 'Genus' : gameType}} of above species</option>
                    <ng-container *ngIf="gameType=='Species'">
                        <option *ngFor="let species of sortData(imagesWithDetails, 'species'); let i = index">{{ species.scientificName }} 
                            <strong>{{ displayCommonName ? showCommonName(species) : '' }}</strong>
                        </option>
                    </ng-container>
                    <ng-container *ngIf="gameType=='Genera'">
                        <option *ngFor="let g of sortData(genera, 'genera'); let i = index">{{ g.scientificName.split(' ')[0] }}</option>
                    </ng-container>
                    <ng-container *ngIf="gameType=='Family'">
                        <option *ngFor="let f of sortData(family, 'family'); let i = index">{{ f }}</option>
                    </ng-container>
                </select>
                <!-- <select #selectName class="species-select" (change)="checkAnswer(selectName.value, item)" *ngIf="gameType=='Genera'">
                    <option disabled selected>{{ gameType }}</option>
                    <option *ngFor="let data of activeData; let i = index">{{ data.scientificName }}</option>
                </select>
                <select #selectName class="species-select" (change)="checkAnswer(selectName.value, item)" *ngIf="gameType=='Family'">
                    <option disabled selected>{{ gameType }}</option>
                    <option *ngFor="let f of family; let i = index">{{ f }}</option>
                </select> -->
                <span class="species-info"><strong>{{ firstTryCount }}</strong> identified correctly on first try</span>
                <span class="species-info"><strong>{{ identifiedImages }}</strong> out of <strong>{{ imagesWithDetails.length }}</strong> species identified&nbsp;(remained: <strong>{{ activeData.length }}</strong>)</span>
                <a style="margin: 10px 0" (click)="getHelp(item, true)">Tell me what it is!</a>

                <div class="option-box" *ngIf="gameType === 'Species'">
                    <h3 style="margin: 0">Options</h3>
                    <select class="species-select" style="margin:0" #quizFilter (change)="filterQuiz(quizFilter.value, item)">
                        <option disabled selected>Filter quiz by taxonomic group</option>
                        <option *ngFor="let filter of sortData(filterItems, 'filter'); let i = index">{{ filter }}</option>
                    </select>
                    <div>
                        <input type="checkbox" [(ngModel)]="displayCommonName"> Display common names
                    </div>
                </div>
                <a style="color:rgb(235, 0, 0) !important" (click)="resetGame()">Reset game</a>
            </div>
        </ng-container>
    </div>

    <div class="game-end" *ngIf="activeData.length <= 0">
                                                                    <!--100, 80, 60, 30, 0 -->
        <ng-container *ngIf="imagesWithDetails.length !== 0 && imagesWithDetails.length === identifiedImages">
            <!-- Top score -->
            <p class="score-title-text">Fantastic!</p>
            <p>You identified {{firstTryCount}} images correctly on first try.</p>
            <p>You identified all {{ imagesWithDetails.length }} images correctly.</p>
            <p>Skipped: {{ skippedItem }}</p>
            <p>Got help on: {{ helpCount }}</p>
        </ng-container>

        <ng-container *ngIf="imagesWithDetails.length !== 0 && identifiedImages >= (imagesWithDetails.length * 0.8) && identifiedImages < imagesWithDetails.length">
            <p class="score-title-text">Well Done!</p>
            <p>You identified {{firstTryCount}} images correctly on first try.</p>
            <p>You identified {{identifiedImages}} out of {{ imagesWithDetails.length }}</p>
            <p>Skipped: {{ skippedItem }}</p>
            <p>Got help on: {{ helpCount }}</p>
        </ng-container>

        <ng-container *ngIf="imagesWithDetails.length !== 0 && identifiedImages >= (imagesWithDetails.length * 0.5) && identifiedImages < (imagesWithDetails.length * 0.8)">
            <p class="score-title-text">Good job! keep it up.</p>
            <p>You identified {{firstTryCount}} images correctly on first try.</p>
            <p>You identified {{identifiedImages}} out of {{ imagesWithDetails.length }}</p>
            <p>Skipped: {{ skippedItem }}</p>
            <p>Got help on: {{ helpCount }}</p>
        </ng-container>

        <ng-container *ngIf="imagesWithDetails.length !== 0 && identifiedImages >= (imagesWithDetails.length * 0.2) && identifiedImages < (imagesWithDetails.length * 0.5)">
            <p class="score-title-text">Good try. keep it up.</p>
            <p>You identified {{firstTryCount}} images correctly on first try.</p>
            <p>You identified {{ identifiedImages }} out of {{ imagesWithDetails.length }}</p>
            <p>Skipped: {{ skippedItem }}</p>
            <p>Got help on: {{ helpCount }}</p>
        </ng-container>

        <ng-container *ngIf="imagesWithDetails.length !== 0 && identifiedImages >= 0 && identifiedImages < (imagesWithDetails.length * 0.2)">
            <p class="score-title-text">It looks like you need to know more about plants.</p>
            <p>You identified {{firstTryCount}} images correctly on first try.</p>
            <p>You identified {{identifiedImages}} out of {{ imagesWithDetails.length }}</p>
            <p>Skipped: {{ skippedItem }}</p>
            <p>Got help on: {{ helpCount }}</p>
        </ng-container>

        <p class="p-default-text" *ngIf="imagesWithDetails.length === 0"><a style="color: white !important" routerLink="../../projects">Go to checklists to select a game.</a></p>

    </div>
</div>