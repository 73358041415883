<div mat-dialog-content>
        <form *ngIf="action == 'Update Parent'" class="name-autocomplete-form">
                <mat-form-field class="name-autocomplete-full-width">
                    <mat-label *ngIf='kindOfName == "Scientific"'>{{"taxon.status.parent.editor.dialog.parent.taxon.label" | translate}}</mat-label>
                    <input *ngIf='kindOfName == "Scientific"' type="text"
                           placeholder='{{"taxon.status.parent.editor.dialog.parent.taxon.placeholder" | translate}}'
                           aria-label="String"
                           matInput
                           (keyup)="onKey($event)"
                           [formControl]="nameControl"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of nameOptions" [value]="option.id">
                            {{option.name}} <small>{{option.author}}</small>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            <symbiota2-editable-text key='taxon.status.parent.editor.dialog.parent.taxon.label' hide='true'></symbiota2-editable-text>
            <symbiota2-editable-text key='taxon.status.parent.editor.dialog.parent.taxon.placeholder' hide='true'></symbiota2-editable-text>

        </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="doAction()">{{action}}</button>
    <button mat-button (click)="closeDialog()" mat-raised-button color="warn">{{'taxon.editor.dialog.cancel' | translate}}</button>
    <symbiota2-editable-text key='taxon.editor.dialog.cancel' hide='true'></symbiota2-editable-text>
</div>
