import { Component, OnInit } from '@angular/core';
import { AppConfigService, NavBarLink, PluginService, User, UserService } from '@symbiota2/ui-common';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, mergeAll } from 'rxjs/operators';
import { customLinksEnd, customLinksStart } from '../navbar/custom-navbarlinks';

@Component({
    selector: 'symbiota2-sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.scss']
})
export class Sitemap implements OnInit {
    categories$: Observable<string[] | null >;

    constructor(
        private readonly plugins: PluginService, 
        private readonly userService: UserService,
        private readonly appConfig: AppConfigService
        ) {}
    
    pluginLinks$ = this.plugins.navBarLinks$;
    user: User;
    currentUser$ = this.userService.currentUser;

    ngOnInit(): void {

        this.currentUser$
            .pipe(
                filter(user => user !== null),
                map(user => {
                    //this.user = user;
                    return user.roles.some(role => console.log('roleHere: ', role));
                })
            )
        
        this.currentUser$.subscribe(user => {
            if (user?.roles.some(role => role.name === 'SuperAdmin')) {
                this.pluginLinks$ = this.pluginLinks$.pipe(
                    map((navMap: Map<string, NavBarLink[]>) => {
                    //add custom navbar elements if superAdmin
                    return new Map<string, NavBarLink[]>([
                            ...customLinksStart.entries(),
                            ...navMap.entries(),
                            ...customLinksEnd.entries(),
                        ]);
                    
                }))
            } else {
                this.pluginLinks$ = this.pluginLinks$.pipe(
                    map((navMap: Map<string, NavBarLink[]>) => {
                    //add custom navbar elements if superAdmin
                    return new Map<string, NavBarLink[]>([
                            ...navMap.entries(),
                        ]);
                    
                }))
            }
            this.pluginLinks$.subscribe((pluginLinks) => {
                const enableFooter = this.appConfig.enableFooter()
                if(!enableFooter) {
                    return this.categories$ =  null;
                }
                return this.categories$ = this.getCategories();
            });
        })

    }

    getCategories(): Observable<string[]> {
        return this.pluginLinks$.pipe(map((pls) => [...pls.keys()]));
    }

    getCategoryLinks(category: string): Observable<NavBarLink[]> {
        return this.pluginLinks$.pipe(map((pls) => pls.get(category)));
    }

}
 