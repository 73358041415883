import { I18N_API_BASE, KIND_OF_TRANSLATION_PATH } from '../../routes';
import {
    Q_PARAM_KEY,
    Q_PARAM_LANGUAGE,
    Q_PARAM_ORIGINALLY_TRANSLATABLE,
    Q_PARAM_TRANSLATABLE,
    Q_PARAM_VALUE
} from '../../../constants';
import { ImageQueryBuilder } from '../../../../../ui-plugin-image/src/lib/services/image/image-query-builder';

export class I18nQueryBuilder {
    protected baseUrl: string
    protected namesUrl: URL
    protected nameUrl: URL
    protected url: URL

    constructor(apiBaseUrl: string) {
        this.baseUrl = apiBaseUrl
        this.url = new URL(`${apiBaseUrl}/${I18N_API_BASE}`)
    }

    findTranslateType(): TranslateTypeBuilder {
        return new TranslateTypeBuilder(this.baseUrl);
    }

    upload(): UploadBuilder {
        return new UploadBuilder(this.baseUrl);
    }

    /*
    deleteNotranslate(): DeleteNotranslateBuilder {
        return new DeleteNotranslateBuilder(this.baseUrl);
    }

     */

    build(): string {
        return this.url.toString()
    }
}

class TranslateTypeBuilder extends I18nQueryBuilder {
    private _language: string = null
    private _key: string = null

    language(language: string): TranslateTypeBuilder {
        this._language = language;
        return this;
    }

    key(key: string): TranslateTypeBuilder {
        this._key = key;
        return this;
    }

    build(): string {
        this.url.pathname = `${this.url.pathname}/${KIND_OF_TRANSLATION_PATH}/${this._key}/${this._language}`
        return super.build()
    }
}

class CreateOneBuilder extends I18nQueryBuilder {
    protected _myID: number;

    myID(id: number): CreateOneBuilder {
        this._myID = id
        return this
    }

    build(): string {
        return super.build();
    }
}

class UploadBuilder extends I18nQueryBuilder {
    private _language: string = null
    private _key: string = null
    private _value: string = null
    private _translatable: boolean = false
    private _originallyTranslatable: boolean = false

    language(language: string): UploadBuilder {
        this._language = language;
        return this;
    }

    key(key: string): UploadBuilder {
        this._key = key;
        return this;
    }

    value(value: string): UploadBuilder {
        this._value = value;
        return this;
    }

    translatable(): UploadBuilder {
        this._translatable = true;
        return this;
    }

    originallyTranslatable(): UploadBuilder {
        this._originallyTranslatable = true;
        return this;
    }

    build(): string {
        this.url.pathname = `${this.url.pathname}`;
        if (this._language) {
            this.url.searchParams.append(Q_PARAM_LANGUAGE, this._language)
        }
        if (this._key) {
            this.url.searchParams.append(Q_PARAM_KEY, this._key)
        }
        if (this._value) {
            this.url.searchParams.append(Q_PARAM_VALUE, this._value)
        }
        this.url.searchParams.append(Q_PARAM_TRANSLATABLE, this._translatable? "1": "0")
        this.url.searchParams.append(Q_PARAM_ORIGINALLY_TRANSLATABLE, this._originallyTranslatable? "1": "0")
        return super.build();
    }
}

/*
class DeleteNotranslateBuilder extends I18nQueryBuilder {
    private _language: string = null
    private _key: string = null
    private _value: string = null
    private _translatable: boolean = false

    language(language: string): DeleteNotranslateBuilder {
        this._language = language;
        return this;
    }

    key(key: string): DeleteNotranslateBuilder {
        this._key = key;
        return this;
    }

    value(value: string): DeleteNotranslateBuilder {
        this._value = value;
        return this;
    }

    translatable(): DeleteNotranslateBuilder {
        this._translatable = true;
        return this;
    }

    build(): string {
        this.url.pathname = `${this.url.pathname}/notranslate`;
        if (this._language) {
            this.url.searchParams.append(Q_PARAM_LANGUAGE, this._language);
        }
        if (this._key) {
            this.url.searchParams.append(Q_PARAM_KEY, this._key);
        }
        if (this._value) {
            this.url.searchParams.append(Q_PARAM_VALUE, this._value);
        }
        this.url.searchParams.append(Q_PARAM_TRANSLATABLE, this._translatable? "1": "0");
        console.log("url is " + this.url.toString())
        return super.build();
    }

}

 */
