import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UserService, ROUTE_ROLES_LIST, User, ROUTE_PERMISSION_LIST } from '@symbiota2/ui-common';

import { MatCell, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDataDialogComponent } from '../../components/delete-data-dialog/delete-data-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'symbiota2-permissionlist-page',
  templateUrl: './permissionlist-page.component.html',
  styleUrls: ['./permissionlist-page.component.scss'],
})

export class PermissionlistPageComponent implements OnInit {
  currentUser$ = this.userService.currentUser;
  user: User;
  roleData: PermissionData[];
  
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    public dialog: MatDialog,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  displayedColumns: string[] = ['access_id', 'permission', 'actions'];
  dataSource: MatTableDataSource<PermissionData>

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatCell) mat_cell: QueryList<MatCell>;

  ngOnInit(): void {
    this.roleData = [];

    this.currentUser$.subscribe(user => this.user = user)

    // this.userService.getAllRoles().subscribe(roles => {
    //     roles.map(role => {
    //         this.roleData.push({
    //             rid: role.id,
    //             role: role.name
    //         })
    //     })
    //     this.dataSource = new MatTableDataSource<RoleData>(this.roleData)
    //     this.dataSource.paginator = this.paginator;
    // })

    this.userService.getAllPermissions().subscribe(permissions => {
      permissions.map(per => {
        this.roleData.push({
          access_id: per.id,
          permission: per.name.replace(per.name[0], per.name[0].toUpperCase()).replace(/_/g, ' ')
        })
      })
      this.dataSource = new MatTableDataSource<PermissionData>(this.roleData)
      this.dataSource.paginator = this.paginator;
    })
  }
  
  deletePermission(id: number): void {
    const dialogRef = this.dialog.open(DeleteDataDialogComponent, { data: 'permission'});

    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this.userService.deletePermission(id).subscribe(() => {
            this.router.navigate([ROUTE_PERMISSION_LIST])
        })
      }
    })
  }
}

export interface PermissionData {
  access_id: number;
  permission: string;
  actions?: {edit: string, delete: string}
}