<div>
    <!-- Test to see if can edit -->
    <button *ngIf='userCanEdit' style='float:right' mat-icon-button>
        <mat-icon color="primary" (click)="onAddDescriptionBlock()">add</mat-icon>
    </button>
    <br/>
    <div *ngFor='let block of blocks'>
        <symbiota2-expansion-panel label='{{block.caption}}'>
            <div *ngIf='userCanEdit'>
                <button style='float:right' mat-icon-button>
                    <mat-icon color="primary" (click)="openDialog('Update',block)">edit</mat-icon>
                </button>
                <button style='float:right' mat-icon-button>
                    <mat-icon color="warn" (click)="openDialog('Delete',block)">delete</mat-icon>
                </button>
            </div>
            <div><b><symbiota2-editable-text key='taxon.description.editor.caption'></symbiota2-editable-text></b>: {{block.caption}}</div>
            <div><b><symbiota2-editable-text key='taxon.description.editor.language'></symbiota2-editable-text></b>: {{block.language}}</div>
            <div><b><symbiota2-editable-text key='taxon.description.editor.source'></symbiota2-editable-text></b>: {{block.source}}</div>
            <div><b><symbiota2-editable-text key='taxon.description.editor.sourceUrl'></symbiota2-editable-text></b>: {{block.sourceUrl}}</div>
            <div><b><symbiota2-editable-text key='taxon.description.editor.notes'></symbiota2-editable-text></b>: {{block.notes}}</div>
            <div><b><symbiota2-editable-text key='taxon.description.editor.displayLevel'></symbiota2-editable-text></b>: {{block.displayLevel}}</div>
            <symbiota2-expansion-panel label='{{"taxon.description.editor.statements" | translate}}'>
                <symbiota2-editable-text key='taxon.description.editor.statements' hide='true'></symbiota2-editable-text>
                <button *ngIf='userCanEdit' style='float:right' mat-icon-button>
                    <mat-icon color="primary" (click)="onAddStatement(block)">add</mat-icon>
                </button>
                <div *ngFor='let statement of block.descriptionStatements'>
                    <div *ngIf='userCanEdit'>
                        <button style='float:right' mat-icon-button>
                            <mat-icon color="primary" (click)="openStatementDialog('Update',statement)">edit</mat-icon>
                        </button>
                        <button style='float:right' mat-icon-button>
                            <mat-icon color="warn" (click)="openStatementDialog('Delete',statement)">delete</mat-icon>
                        </button>
                    </div>
                    <div>
                        <b><symbiota2-editable-text key='taxon.description.editor.statement.heading'></symbiota2-editable-text></b>: {{statement.heading}}
                        <br/>
                        <b><symbiota2-editable-text key='taxon.description.editor.statement.displayHeader'></symbiota2-editable-text></b>: {{statement.displayHeader}}
                        <br/>
                        <b><symbiota2-editable-text key='taxon.description.editor.statement.sortSequence'></symbiota2-editable-text></b>: {{statement.sortSequence}}
                        <br/>
                        <b><symbiota2-editable-text key='taxon.description.editor.statement.statement'></symbiota2-editable-text></b>:  <span [innerHTML]="statement.statement"></span>
                    </div>
                    <hr/>
                </div>
            </symbiota2-expansion-panel>
        </symbiota2-expansion-panel>
    </div>
</div>
