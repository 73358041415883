<!-- TODO: i18n -->
<div id='container' fxLayout='column' fxLayoutAlign='center center' fxLayoutGap='1rem'>
    <h1>Retrieve username</h1>
    <mat-form-field>
        <mat-label>Email address</mat-label>
        <input matInput [formControl]='controlEmail'>
    </mat-form-field>
    <button
        mat-raised-button
        color='primary'
        [disabled]='form.value["email"].length === 0 || !form.valid'
        (click)='onSubmit()'
    >
        Submit
    </button>
</div>
