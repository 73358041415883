<div id="create-role-container">
    <mat-card class="create-role-card">
      <mat-card-header>
        <mat-card-title>Create Role Form</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form id="create-role-form" [formGroup]="createRoleForm">
          <div class="form-label">
            <h4>Permissions: </h4>
            
          </div>
          <div class="form-data">
            <h4>Name: </h4>
            <p>
              <mat-form-field class="name-field">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" id="name" required>
              </mat-form-field>
            </p>
            <p class="permission-list" formArrayName="permissions">
              <ng-container *ngFor="let p of permissions; let i=index" [formGroupName]="i">
                <mat-checkbox 
                  formControlName="value"
                >{{p?.name}}</mat-checkbox>
              </ng-container>
            </p>
          </div>
          
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="createRoleForm.invalid" (click)="createRole()">Save</button>
      </mat-card-actions>
  
  
    </mat-card>
    
    
    </div>