import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from "../alert";
import { MonitoringService } from './monitoring.service';
import { ApiClientModule } from '@symbiota2/ui-common';

@NgModule({
    imports: [
        AlertModule,
        ApiClientModule,
    ],
    providers : [
        MonitoringService
    ],
})
export class MonitoringModule { }
