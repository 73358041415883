<div id="main">
    <h1><symbiota2-editable-text key='plugins.occurrence.search.results.title'></symbiota2-editable-text></h1>
    <!-- <mat-icon style="float:right">download</mat-icon> -->
    <div>
        <symbiota2-editable-text style="float:right; margin-left: 10px" key='plugins.occurrence.search.results.download_specimen_data.label.text' hide='true'></symbiota2-editable-text>
        <button mat-raised-button style="float:right; margin-left: 10px"
            *ngIf="occurrences | async as occurrences"
            (click)="sendData()"
            >
            {{'plugins.occurrence.search.results.download_specimen_data.label.text' | translate}}
        </button>
        <symbiota2-editable-text style="float:right; margin-left: 10px" key='plugins.occurrence.search.results.copy_url.label.text' hide='true'></symbiota2-editable-text>
        <button mat-raised-button style="float:right">
            {{'plugins.occurrence.search.results.copy_url.label.text' | translate}}
        </button>
    </div>
    <div style="clear:both"></div>
    <div *ngIf="occurrences | async as occurrences" class="mat-elevation-z1">
        <mat-paginator
            #paginator
            [length]="occurrences.count"
            [pageSize]="limit"
            [pageSizeOptions]="[100, 500, 1000]"
            (page)="onPageChanged($event)"
        >
        </mat-paginator>
        <table mat-table [dataSource]="occurrences.data">
            <ng-container matColumnDef="occurrenceID">
                <th mat-header-cell *matHeaderCellDef><symbiota2-editable-text key='plugins.occurrence.search.results.table.symbiota_id.label.text'></symbiota2-editable-text></th>
                <td mat-cell *matCellDef="let occurrence">
                    {{ occurrence.id }}

                </td>
            </ng-container>

            <ng-container matColumnDef="catalogNumber">
                <th mat-header-cell *matHeaderCellDef><symbiota2-editable-text key='plugins.occurrence.search.results.table.catalog_number.label.text'></symbiota2-editable-text></th>
                <td mat-cell *matCellDef="let occurrence" (click)="onOccurrenceSelected(occurrence)">
                    {{ occurrence.catalogNumber }}

                </td>
            </ng-container>

            <ng-container matColumnDef="collection">
                <th mat-header-cell *matHeaderCellDef><symbiota2-editable-text key='plugins.occurrence.search.results.table.collection.label.text'></symbiota2-editable-text></th>
                <td mat-cell *matCellDef="let occurrence">
                    {{ occurrence.collection.collectionName }}

                </td>
            </ng-container>

            <ng-container matColumnDef="scientificName">
                <th mat-header-cell *matHeaderCellDef><symbiota2-editable-text key='plugins.occurrence.search.results.table.scientific_name.label.text'></symbiota2-editable-text></th>
                <td mat-cell *matCellDef="let occurrence" [routerLink]="['/taxon/profile/', occurrence.taxonID]">
                    {{ occurrence.scientificName }}

                </td>
            </ng-container>

            <ng-container matColumnDef="scientificNameComputed">
                <th mat-header-cell *matHeaderCellDef><symbiota2-editable-text key='plugins.occurrence.search.results.table.scientific_name_computed.label.text'></symbiota2-editable-text></th>
                <td mat-cell *matCellDef="let occurrence" [routerLink]="['/taxon/profile/', occurrence.taxonID]">
                    {{ occurrence.scientificNameComputed }}

                </td>
            </ng-container>

            <ng-container matColumnDef="latitude">
                <th mat-header-cell *matHeaderCellDef><symbiota2-editable-text key='plugins.occurrence.search.results.table.latitude.label.text'></symbiota2-editable-text></th>
                <td mat-cell *matCellDef="let occurrence">
                    {{ occurrence.latitude | number: '2.1-1' }}

                </td>
            </ng-container>

            <ng-container matColumnDef="longitude">
                <th mat-header-cell *matHeaderCellDef><symbiota2-editable-text key='plugins.occurrence.search.results.table.longitude.label.text'></symbiota2-editable-text></th>
                <td mat-cell *matCellDef="let occurrence">
                    {{ occurrence.longitude | number: '2.1-1' }}

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="SHOW_COLUMNS">G</tr>
            <tr
                mat-row
                class="table-row"
                *matRowDef="let occurrence; columns: SHOW_COLUMNS"
            ></tr>
        </table>
    </div>
</div>
