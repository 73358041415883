import {
    IsEnum, IsInt,
    IsNotEmpty,
    IsOptional,
    IsArray,
} from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { UserRole } from '@symbiota2/api-database';
import { ApiCreateUserRoleData, ApiUserRoleName } from '@symbiota2/data-access';

/**
 * Object representing the body of a POST request for adding a new role to
 * a user
 */
export class UserRoleInputDto implements ApiCreateUserRoleData {
    constructor(rolePermissions: Partial<UserRole>) {
        Object.assign(this, rolePermissions)
    }

    @IsNotEmpty()
    @IsOptional()
    name: ApiUserRoleName;

    @IsArray()
    @IsOptional()
    permissions: number[];

    // @Type(() => Number)
    // @IsInt()
    // @IsOptional()
    // tablePrimaryKey?: number;

    @IsOptional()
    details: { 
        tableName: string | null,
        tablePrimaryKey: number | null,
        secondaryVariable: string | null,
        access: ApiUserRoleName | null, 
        assignedByUID: number | null,
        assignedForUID: number | null,
        default: boolean | false,
    }[];
}
