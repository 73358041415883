import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UserService, ROUTE_ROLES_LIST, User } from '@symbiota2/ui-common';

import { MatCell, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDataDialogComponent } from '../../components/delete-data-dialog/delete-data-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'symbiota2-rolelist-page',
  templateUrl: './rolelist-page.component.html',
  styleUrls: ['./rolelist-page.component.scss'],
})

export class RolelistPageComponent implements OnInit {
  currentUser$ = this.userService.currentUser;
  user: User;
  roleData: RoleData[];
  
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    public dialog: MatDialog,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  displayedColumns: string[] = ['rid', 'role', 'actions'];
  dataSource: MatTableDataSource<RoleData>

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatCell) mat_cell: QueryList<MatCell>;

  ngOnInit(): void {
    this.roleData = [];

    this.currentUser$.subscribe(user => this.user = user)

    this.userService.getAllRoles().subscribe(roles => {
        roles.map(role => {
            this.roleData.push({
                rid: role.id,
                role: role.name
            })
        })
        this.dataSource = new MatTableDataSource<RoleData>(this.roleData)
        this.dataSource.paginator = this.paginator;
    })
  }
  
  deleteRole(rid: number): void {
    const dialogRef = this.dialog.open(DeleteDataDialogComponent, { data: 'role'});

    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this.userService.deleteRole(rid).subscribe(data => {
            this.router.navigate([ROUTE_ROLES_LIST])
        })
      }
    })
  }
}

export interface RoleData {
  rid: number;
  role: string;
  actions?: {edit: string, delete: string}
}