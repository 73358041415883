import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';

import * as d3 from "d3"
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ApiClientService, UserService } from '@symbiota2/ui-common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'graphpage',
    templateUrl: './graph-page.html',
    styleUrls: ['./graph-page.component.scss'],
})

export class GraphPageComponent implements OnInit {


    constructor(
                        private readonly alertService: AlertService,
                        private router: Router,
                        private formBuilder: FormBuilder,
                        private readonly translate: TranslateService,
                        public dialog: MatDialog,
                        private readonly apiClient: ApiClientService,
                        private currentRoute: ActivatedRoute,
                        ) {
    }


    /*
    Called when Angular starts
    */
    ngOnInit() {

    }



    /*
    Internal routine to encapsulate the show error message at the bottom in case something goes awry
    */
    private showError(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showError(r)
        })
    }

    /*
    Internal routine to encapsulate the show message at the bottom to confirm things actually happened
    */
    private showMessage(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showMessage(r)
        })
    }

}


