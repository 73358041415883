<div id="main">
    <div>
        <h1><symbiota2-editable-text key='plugins.occurrence.search.title'></symbiota2-editable-text></h1>
    </div>

    <div id="buttons">
        <button mat-flat-button color="primary" [disabled]="searchDisabled" (click)="onSearch()">
            {{'plugins.occurrence.search.button.text' | translate}}
        </button>
        <symbiota2-editable-text key='plugins.occurrence.search.button.text' hide='true'></symbiota2-editable-text>
    </div>

    <!-- Collection select -->
    <div class="expansion-panel">
    <!--
        <symbiota2-expansion-panel label="{{'plugins.occurrence.search.sourceSelection.text' | translate}}" required="true">
            <symbiota2-editable-text key='plugins.occurrence.search.sourceSelection.text' hide='true'></symbiota2-editable-text>
            -->
    <symbiota2-expansion-panel>
            <!-- collections -->
            <symbiota2-collection-checkbox-selector
                [collectionIDs]="this.collectionIDs.value"
                (collectionIDsChange)="this.collectionIDs.setValue($event)"
                [selectAll]="true"
            >
            </symbiota2-collection-checkbox-selector>

            <!-- Evidence type -->
            <!-- Curtis removed to make Neil happy
            <select>
                <option selected disabled>{{'plugins.occurrence.search.record.type.text' | translate}}</option>
                <option>Specimens</option>
                <option>Specimens with images</option>
                <option>Type specimens</option>
                <option>Images</option>
                <option>specimens with geographic coordinates</option>
                <option>Specimens with gentic data</option>
                <option>Publication</option>
            </select>
            <symbiota2-editable-text key='plugins.occurrence.search.record.type.text' hide='true'></symbiota2-editable-text>
            -->
        </symbiota2-expansion-panel>

        <mat-divider></mat-divider>
    </div>


    <!-- Fitlers -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel id="filters" label="{{'plugins.occurrence.search.filter.text' | translate}}" expanded=false>
            <symbiota2-editable-text key='plugins.occurrence.search.filter.text' hide='true'></symbiota2-editable-text>
            <div fxLayout="row wrap">
                <div class="form-field">
                    <mat-checkbox [formControl]="limitToSpecimens">
                        <mat-label
                        ><symbiota2-editable-text key='plugins.occurrence.search.limit.specimens.text'></symbiota2-editable-text></mat-label
                        >
                    </mat-checkbox>
                </div>
                <div class="form-field">
                    <mat-checkbox [formControl]="limitToImages">
                        <mat-label><symbiota2-editable-text key='plugins.occurrence.search.limit.images.text'></symbiota2-editable-text></mat-label>
                    </mat-checkbox>
                </div>
                <div class="form-field">
                    <mat-checkbox [formControl]="limitToGenetic">
                        <mat-label><symbiota2-editable-text key='plugins.occurrence.search.limit.genetics.text'></symbiota2-editable-text></mat-label>
                    </mat-checkbox>
                </div>
            </div>
        </symbiota2-expansion-panel>
    </div>

    <div id="buttons2">
        <button
            mat-flat-button
            color="primary"
            [disabled]="searchDisabled"
            (click)="onSearch()"
        >
            {{'plugins.occurrence.search.button.text' | translate}}
        </button>
        <symbiota2-editable-text key='plugins.occurrence.search.button.text' hide='true'></symbiota2-editable-text>
    </div>
</div>
