<div id="main">
    <h1><symbiota2-editable-text key='plugins.occurrence.search.results.download.title'></symbiota2-editable-text></h1>
    <symbiota2-editable-paragraph class="download-description" key='plugins.occurrence.search.results.download.description'></symbiota2-editable-paragraph>


    <div>
        <h2><symbiota2-editable-text key='plugins.occurrence.search.results.download.subtitle'></symbiota2-editable-text></h2>
        <div class="download-box">
            <div class="download-box-options">
                <form #downloadForm="ngForm" (ngSubmit)="processData(downloadForm.value)">
                    <div>
                        <p><symbiota2-editable-text key='plugins.occurrence.search.results.label.structure.text'></symbiota2-editable-text>: </p>
                        <p>
                            <input type="radio" name="structure" value="native" [(ngModel)]="structure"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.symbiota.input'></symbiota2-editable-text> <mat-icon class="icon" 
                            data-md-tooltip="Symbiota native is very similar to Darwin Core 
                            except with the addtion of a few fields such as substrate, 
                            associated collectors, verbatim description.">info</mat-icon><br>
                            <input type="radio" name="structure" value="core" [(ngModel)]="structure"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.darwin.input'></symbiota2-editable-text> <mat-icon class="icon">info</mat-icon><br>
                            <span class="font-size-80">* <symbiota2-editable-text key='plugins.occurrence.search.results.label.darwin_core.text'></symbiota2-editable-text></span>
                        </p>
                    </div>
                    <div>
                        <p><symbiota2-editable-text key='plugins.occurrence.search.results.label.data_extensions.text'></symbiota2-editable-text>: </p>
                        <p>
                            <input type="checkbox" name="history" [value]="includes[0]" [ngModel]="true"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.history.input'></symbiota2-editable-text><br>
                            <input type="checkbox" name="image" [value]="includes[1]" [ngModel]="true"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.records.input'></symbiota2-editable-text> <br>
                            <span class="font-size-80">* <symbiota2-editable-text key='plugins.occurrence.search.results.label.output.text'></symbiota2-editable-text></span>
                        </p>
                    </div>
                    <div>
                        <p><symbiota2-editable-text key='plugins.occurrence.search.results.label.file.text'></symbiota2-editable-text>: </p>
                        <p>
                            <input type="radio" name="format" value="comma" [(ngModel)]="format"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.comma.input'></symbiota2-editable-text> <br>
                            <input type="radio" name="format" value="tab" [(ngModel)]="format"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.tab.input'></symbiota2-editable-text>
                        </p>
                    </div>
                    <div>
                        <p><symbiota2-editable-text key='plugins.occurrence.search.results.label.character_set.text'></symbiota2-editable-text>: </p>
                        <p>
                            <input type="radio" name="set" value="iso" [(ngModel)]="characterSet"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.character_set.iso.input'></symbiota2-editable-text> <br>
                            <input type="radio" name="set" value="utf-8" [(ngModel)]="characterSet" > <symbiota2-editable-text key='plugins.occurrence.search.results.label.character_set.utf.input'></symbiota2-editable-text>
                        </p>
                    </div>
                    <div>
                        <p><symbiota2-editable-text key='plugins.occurrence.search.results.label.compression.text'></symbiota2-editable-text>: </p>
                        <p>
                            <input type="checkbox" name="zip" [value]="file[0]" [ngModel]="true"> <symbiota2-editable-text key='plugins.occurrence.search.results.label.Compressed_file.input'></symbiota2-editable-text>
                        </p>
                    </div>

                    <button mat-raised-button>
                        {{'plugins.occurrence.search.results.button.download.text' | translate}}
                    </button>
                    <symbiota2-editable-text key='plugins.occurrence.search.results.button.download.text' hide='true'></symbiota2-editable-text>
                </form>
            </div>
        </div>
    </div>
</div>


