import { NavBarLink, SymbiotaUiPlugin } from '@symbiota2/ui-common';
import { Route } from '@angular/router';
declare function uiPluginTaxonomyCustomMenu(): NavBarLink[]

export class LocalMenu {

    public static routes(): Route[] {
        return [
        ];
    }

    static navBarLinks(): NavBarLink[] {
        const menu = uiPluginTaxonomyCustomMenu()
        return menu;
    }
}
