import { ApiFindAllQuery } from './common';

export interface ApiChangePasswordData {
    newPassword: string;
}

export interface ApiUserProfileData {
    firstName: string;
    lastName: string;
    title: string;
    institution: string;
    department: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    email: string;
    url: string;
    biography: string;
    isPublic: number;
}

export interface ApiCreateUserData extends ApiUserProfileData {
    username: string;
    password: string;
}

export interface ApiQueryFindUsers extends ApiFindAllQuery {
    username?: string;
    'username[]'?: string[];
    lastName?: string;
    email?: string;
    'email[]'?: string[];
}

// export enum ApiUserRoleName {
//     CHECKLIST_ADMIN = "ClAdmin",
//     CHECKLIST_EDITOR = "ClEditor",
//     COLLECTION_ADMIN = "CollAdmin",
//     COLLECTION_EDITOR = "CollEditor",
//     ROLE_COLLECTION_TAXON_EDITOR = "CollTaxon",
//     ROLE_KEY_ADMIN = "KeyAdmin",
//     ROLE_KEY_EDITOR = "KeyEditor",
//     PROJECT_ADMIN = "ProjAdmin",
//     RARE_SPECIES_ADMIN = "RareSppAdmin",
//     ROLE_RARE_SPP_EDITOR = "RareSppReader",
//     RARE_SPECIES_READER = "RareSppReadAll",
//     SUPER_ADMIN = "SuperAdmin",
//     TAXON_EDITOR = "Taxonomy",
//     TAXON_PROFILE_EDITOR = "TaxonProfile"
// }

export enum ApiUserRoleName {
    CHECKLIST_ADMIN = "ChecklistAdmin",
    CHECKLIST_EDITOR = "ChecklistEditor",
    COLLECTION_ADMIN = "CollectionAdmin",
    COLLECTION_EDITOR = "CollectionEditor",
    ROLE_COLLECTION_TAXON_EDITOR = "RoleCollectionTaxonEditor",
    ROLE_KEY_ADMIN = "RoleKeyAdmin",
    ROLE_KEY_EDITOR = "RoleKeyEditor",
    PROJECT_ADMIN = "ProjectAdmin",
    PROJECT_EDITOR = "ProjectEditor",
    RARE_SPECIES_ADMIN = "RareSpeciesAdmin",
    ROLE_RARE_SPP_EDITOR = "RoleRareSpeciesEditor",
    RARE_SPECIES_READER = "RareSpeciesReader",
    SUPER_ADMIN = "SuperAdmin",
    TAXON_EDITOR = "TaxonEditor",
    TAXON_PROFILE_EDITOR = "TaxonProfileEditor",
    PORTAL_MANAGER = 'PortalManager',
}

export interface ApiCreateUserRoleData {
    name: ApiUserRoleName;
    details?: ApiAssignedRole[]
}

export interface ApiUserRole {
    id: number;
    uid: number;
    name: ApiUserRoleName;
    // tableName: string | null;
    // tablePrimaryKey: number | null;
    // assignedByUID: number | null;
}

export interface ApiUser {
    uid: number;
    username: string;
    firstName: string;
    lastName: string;
    title: string;
    institution: string;
    department: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    email: string;
    url: string;
    biography: string;
    isPublic: boolean;
    initialTimestamp: Date;
    lastLogin: Date;
}

export interface ApiJwtPayload {
    uid: number;
    username: string;
    firstName: string;
    roles: ApiUserRole[];
    assignedRoles: ApiAssignedRole[];
}

export interface ApiLoginRequest {
    username: string;
    password: string;
}

export interface ApiLoginResponse {
    accessToken: string;
}

export interface ApiUserNotification {
    id: number;
    message: string;
    createdAt: Date;
}

export interface ApiAssignedRole {
    tableName: string | null,
    tablePrimaryKey: number | null,
    secondaryVariable: string | null,
    access: ApiUserRoleName | null, 
    assignedByUID: number | null,
    assignedForUID: number | null,
    default: boolean | false,
}
