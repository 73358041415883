import { Component } from '@angular/core';
import { AppConfigService } from '@symbiota2/ui-common';

import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'symbiota2-spatial-module-dashboard',
    templateUrl: './spatial-module-dashboard-page.html',
    styleUrls: ['./spatial-module-dashboard-page.scss']
})
export class SpatialModuleDashboardPage  {

    public dashboardUrl = '' // http://localhost:5601/app/dashboards?auth_provider_hint=anonymous1#/view/3050b510-a931-11ec-8fad-591789a7e073';

    constructor(
        private readonly appConfig: AppConfigService,
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        public sanitizer: DomSanitizer) {

        this.dashboardUrl = appConfig.kibanaServer() + ":" + appConfig.kibanaPort() + appConfig.kibanaDashboardSpatialModule();
    }

    ngOnInit(): void {
        // Navigate directly to the dashboard to avoid CSP errors!
        document.location.href = this.dashboardUrl
    }

}
