import { Injectable } from '@angular/core';
import {
    ApiClientService,
    AlertService,
    UserService,
} from '@symbiota2/ui-common';
import { Comment } from '../dto/Comment.output.dto';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { cpuUsage } from 'node:process';

interface FindAllParams {
    occurrenceID?: string;
    collectionID?: number;
    limit?: number;
    orderBy?: string;
}

@Injectable()
export class CommentService {
    private readonly COMMENT_BASE_URL = `${this.api.apiRoot()}/occurrence-comments`;
    private jwtToken = this.user.currentUser.pipe(map((user) => user.token))

    constructor(
        private readonly api: ApiClientService,
        private readonly user: UserService,
        private readonly alert: AlertService
    ) {}

    getComments(findAllParams?: FindAllParams): Observable<Comment[]> {
        const url = this.api.queryBuilder(this.COMMENT_BASE_URL)
            if (findAllParams?.occurrenceID) {
                url.queryParam('occurrenceID', findAllParams.occurrenceID)
            }
            if (findAllParams?.collectionID) {
                url.queryParam('collectionID', findAllParams.collectionID)
            }
            url.get();
        return this.api.send(url.build()).pipe(
            map((comments: Comment[]) => {
                return comments;
            })
        );

    }

    setPublic(commentData: {id: number, status: 0 | 1}) {
        const url = this.api.queryBuilder(this.COMMENT_BASE_URL)
            url.body(commentData).patch();
        return this.api.send(url.build()).pipe(
            map((comment: Comment) => {
                return comment;
            })
        );

    }

    setStatus(commentData: {id: number, status: 0 | 1}) {
        const message = commentData.status === 0 ? 'Comment marked for review' : 'Comment marked as reviewed';
        const url = this.api.queryBuilder(this.COMMENT_BASE_URL)
        url.body(commentData).patch();
        return this.api.send(url.build()).pipe(
            map((comment: Comment) => {
                this.alert.showMessage(message);
                return comment;
            })
        );
    }

    deleteComment(id: number) {
        const url = `${this.COMMENT_BASE_URL}/${id}`;

        return this.jwtToken.pipe(
            switchMap((token) => {
                const req = this.api
                    .queryBuilder(url)
                    .delete()
                    .addJwtAuth(token)
                    .build()

                return this.api.send(req).pipe(
                    catchError((e) => {
                        console.error(e)
                        return of(null)
                    }),
                    map((blockJson) => {
                        return "success"
                    })
                )
            })
        )
    }
}
