export class Comment {
    constructor(data: Record<string, unknown>) {
        Object.assign(this, data);
    }

    id: number
    occurrenceID: number
    comment: string
    commenter: { firstName: string, lastName: string }
    content: string
    initialTimestamp: string
    reviewStatus: number
    occurrence: { scientificName: string }
}