/* eslint-disable no-var */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CollectionService } from '../../services/collection.service';
import { ROUTE_COLLECTION_NEW } from '../../routes';
import { UserService } from '@symbiota2/ui-common';
import { fromEvent, interval, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, startWith, filter, switchMap, pluck, delay, take, retryWhen, map, tap, exhaustMap, delayWhen, concatMap, distinctUntilKeyChanged } from 'rxjs/operators';

@Component({
    selector: 'symbiota2-collection-list-page',
    templateUrl: './collection-list-page.component.html',
    styleUrls: ['./collection-list-page.component.scss'],
})
export class CollectionListPage implements OnInit, OnDestroy {
    readonly ROUTE_COLLECTION_NEW = ROUTE_COLLECTION_NEW;
    // @ViewChild('filterStats', { static: true }) filterStats: ElementRef;

    expandAll = true;
    isLoading = false;
    categories = this.collectionService.categories;
    refreshStats: Observable<any>;
    collectionStats: Observable<any>;
    collectionStatsSub: Subscription;
    userRoles: Observable<any>;
    isStatsVisible = false;

    // jobId = 'job123';
    // jobProgress: number;

    constructor(
        private readonly user: UserService,
        private readonly collectionService: CollectionService
    ) {}

    ngOnInit() {
        this.collectionService.refreshCategories();
        //const collections = this.collectionService.collectionList;
        this.userRoles = this.user.currentUser.pipe(
            filter(user => !!user),
            map(({roles}) => roles.map(role => role.name)));

        // this.userRoles.subscribe(roles => {
        //     if (roles.includes('SuperAdmin')) {
        //         this.refreshStats = this.collectionService.globalStats
        //     }
        // })

    }

    // ngAfterViewInit() {
    //     if (this.filterStats) {
    //       const check = fromEvent(this.filterStats.nativeElement, 'change').pipe(
    //         pluck('target', 'value')
    //       );
    //       check.subscribe(data => {
    //         if (data > 0) {
    //           this.collectionService.setStatsQueryParams({ catId: Number(data) });
    //         } else if (data == -1) {
    //           this.collectionService.setStatsQueryParams({ uncat: true });
    //         } else {
    //           this.collectionService.setStatsQueryParams();
    //         }
    //       });
    //     }
    //   }

    //TODO: this function is made a lot and should just be built into user service
    //TODO: clean function
    // isAdmin(): Observable<boolean> {
    //     var result;

    //     this.user.currentUser.subscribe(user => {
    //         console.log('check super admin?: ', user)
    //         result = user !== null && user.isSuperAdmin();
    //     })
    //     return result;
    // }

    onExpandCollapse(isExpanded: boolean) {
        this.expandAll = isExpanded;
    }

    filterByCategory(value: any) {
        //console.log()
    }

    setupFilterStats(e: any) {
        // const stats = ()};
        // return function(e:any) {
            
           // if ()
            this.refreshStats = null;
            this.isLoading = true;
            const btn = e.target.value;
            if (btn > 0) {
                this.collectionService.setStatsQueryParams({ catId: Number(btn) });
            } else if (btn == -1) {
                this.collectionService.setStatsQueryParams({ uncat: true });
            } else {
                this.collectionService.setStatsQueryParams();
            }
            this.refreshStats = this.collectionService.globalStats;
            this.isLoading = false;
        //}
      }
      

    toggleStats(value: any) {
        var btn = value.target;
        btn.closest('.collectionStats').classList.toggle('disappear');
        this.isStatsVisible = !this.isStatsVisible;
        if (this.isStatsVisible) {
            btn.textContent = "Hide stats";
        } else {
            btn.textContent = "View stats";
        }
    }

    ngOnDestroy() {
        this.collectionStatsSub?.unsubscribe()
    }

}
 
