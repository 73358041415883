<div
    *ngIf="collection$ | async as collection"
    id="main"
    fxLayout="column"
    fxLayoutAlign="start center"
>
    
        <div class="col-header" fxLayout="row" fxFlex="20"> <!--fxLayout="row"  fxFlex="none"-->
            <div id="logo">
            <symbiota2-collection-logo
                class="collectionLogo"
                [collectionID]="collection.id"
                size="150px"
                style="padding-left: 0px;"
            >
            </symbiota2-collection-logo>
        </div>

        <div class="name_inst" fxLayout="column" fxFlex> <!--fxLayout="column" fxLayoutAlign="space-between"-->
            <div fxLayout="row">
                <h1 id="collectionName">
                    {{ collection.collectionName }}
                </h1>
                <button
                    mat-stroked-button
                    *ngIf="isColAdmin"
                    id="editButton"
                    (click)="openCollectionTools()"
                >
                    <mat-icon>build</mat-icon>{{'collection.admin.action.label' | translate}}
                </button>
                <symbiota2-editable-text key='collection.admin.action.label' hide='true'></symbiota2-editable-text>
            </div>
            <div id="subTitle" fxFlex="auto"> <!--fxLayoutAlign="start end"-->
                <mat-icon>account_balance</mat-icon>
                <div class="italic" *ngIf="collection.institution?.name">
                    <span>{{ collection.institution.name }} </span>
                </div>
                <div
                    class="italic"
                    *ngIf="
                        collection.institution?.code &&
                        collection.collectionCode
                    "
                >
                    ({{ collection.institution.code }}-{{
                        collection.collectionCode
                    }})
                </div>
            </div>
        </div>
    </div>
    <div id="body" fxLayout="column" fxLayoutGap="1rem">
        <div id="links" fxLayoutAlign="space-between">
            <ng-container *ngFor="let link of links$ | async">
                <a
                    mat-stroked-button
                    id="collectionLink"
                    [ngClass]="{
                        primaryLink: link.text == 'Back to collections'
                    }"
                    [routerLink]="link.routerLink"
                    [queryParams]="link.queryParams"
                    >{{ link.text }}</a
                >
            </ng-container>
        </div>
        <mat-card class="symbiota2-info-mat-card" id="collectionDescription">
            <mat-card-title><symbiota2-editable-text key='collection.overview.title'></symbiota2-editable-text></mat-card-title>
            <mat-card-content>
                {{ collection.fullDescription }}
            </mat-card-content>
        </mat-card>
        <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="1rem">
            <mat-card
                id="collectionInfo"
                class="symbiota2-info-mat-card"
                fxFlex="60"
            >
                <mat-card-title fxLayoutAlign="start end">
                    <mat-icon>info</mat-icon>{{'collection.info.title' | translate}}
                    <symbiota2-editable-text key='collection.info.title' hide='true'></symbiota2-editable-text>
                </mat-card-title>
                <mat-card-content>
                    <!-- TODO: i18n -->
                    <symbiota2-collection-field
                        label="{{'collection.info.contact.title' | translate}}"
                        [value]="collection.contact"
                    ></symbiota2-collection-field>
                    <symbiota2-editable-text *ngIf="collection.contact" key='collection.info.contact.title' hide='true'></symbiota2-editable-text>
                    <symbiota2-collection-field
                    label="{{'collection.info.contact.email' | translate}}"
                    [value]="collection.email"
                    ></symbiota2-collection-field>
                    <symbiota2-editable-text *ngIf="collection.email" key='collection.info.contact.email' hide='true'></symbiota2-editable-text>
                    <symbiota2-collection-field
                    label="{{'collection.info.home.page' | translate}}"
                    [href]="collection.homePage"
                    [value]="collection.homePage"
                    ></symbiota2-collection-field>
                    <symbiota2-editable-text *ngIf="collection.homePage" key='collection.info.home.page' hide='true'></symbiota2-editable-text>
                    <symbiota2-collection-field
                    label="{{'collection.info.collection.type' | translate}}"
                    [value]="collection.type"
                    ></symbiota2-collection-field>
                    <symbiota2-editable-text *ngIf="collection.type" key='collection.info.collection.type' hide='true'></symbiota2-editable-text>
                    <symbiota2-collection-field
                    label="{{'collection.info.management' | translate}}"
                    [value]="collection.managementType"
                    ></symbiota2-collection-field>
                    <symbiota2-editable-text *ngIf="collection.managementType" key='collection.info.management' hide='true'></symbiota2-editable-text>
                    <symbiota2-collection-field
                    label="{{'collection.info.usage.rights' | translate}}"
                    [value]="collection.usageTerm"
                    ></symbiota2-collection-field>
                    <symbiota2-editable-text *ngIf="collection.usageTerm" key='collection.info.usage.rights' hide='true'></symbiota2-editable-text>
                    <symbiota2-collection-field
                    label="{{'collection.info.last.update' | translate}}"
                    [value]="
                    collection.collectionStats?.lastModifiedTimestamp
                    | date: 'medium'
                    "
                    ></symbiota2-collection-field>
                    <symbiota2-editable-text *ngIf="collection.collectionStats?.lastModifiedTimestamp" key='collection.info.last.update' hide='true'></symbiota2-editable-text>
                </mat-card-content>
            </mat-card>
            <mat-card
                class="symbiota2-primary-mat-card"
                id="collectionStats"
                fxFlex="40"
            >
                <mat-card-title fxLayoutAlign="start end">
                    <mat-icon>insights</mat-icon>{{'collection.stats.title' | translate}}
                    <symbiota2-editable-text key='collection.stats.title' hide='true'></symbiota2-editable-text>
                </mat-card-title>
                <mat-card-content *ngIf="collection.collectionStats">
                    <ul>
                        <li>
                            {{
                                collection.collectionStats.recordCount | number
                            }}
                            <symbiota2-editable-text key='collection.stats.occurrences'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{
                                collection.collectionStats.georeferencedCount
                                    | number
                            }}
                            ({{ geoReferencedPercent }}%) <symbiota2-editable-text key='collection.stats.georeferenced'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{
                                collection.collectionStats.familyCount | number
                            }}
                            <symbiota2-editable-text key='collection.stats.families'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{ collection.collectionStats.genusCount | number }}
                            <symbiota2-editable-text key='collection.stats.genera'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{
                                collection.collectionStats.speciesCount | number
                            }}
                            <symbiota2-editable-text key='collection.stats.species'></symbiota2-editable-text>
                        </li>
                    </ul>
                    <small
                        ><symbiota2-editable-text key='collection.stats.last.updated'></symbiota2-editable-text>
                        {{
                            collection.collectionStats.lastModifiedTimestamp
                                | date: 'medium'
                        }}
                    </small>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
