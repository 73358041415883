import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import {
    TaxonomicAuthorityService,
    TaxonomicStatusService, TaxonomicUnitService,
    TaxonService, TaxonVernacularService, TaxonomicEnumTreeService
} from '../../services';
import { TaxonListItem, TaxonomicAuthorityListItem } from '../../dto';
import { BehaviorSubject } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { MatListOption } from '@angular/material/list';
import { TaxonIDAuthorNameItem } from '../../dto/taxon-id-author-name-item';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

/**
 * Taxonomic data with nested structure.
 * Each node has a name and an optional list of children.
 * The expanded flag is set if the node is expanded (already has children listed)
 * The synonym flag is set if this is a synonym
 */
export interface TaxonNode {
    name: string
    taxonID: number
    author: string
    level?: number
    parentID?: number
    acceptedID?: number
    rankID?: number
    expanded?: boolean
    synonym?: boolean
    childrenLoaded?: boolean
    children?: TaxonNode[]
}

export interface TaxonTreeNode {
    node: TaxonNode
    level: number
    expandable: boolean
    children: TaxonTreeNode[]
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class TaxonNodeDatabase {
    dataChange = new BehaviorSubject<TaxonNode[]>([]);

    get data(): TaxonNode[] {
        return this.dataChange.value;
    }

    constructor() {
        this.initialize();
    }

    initialize() {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        // const data = this.buildFileTree(TREE_DATA, 0);
        const data = [];
        //console.log(data);

        // Notify the change.
        this.dataChange.next(data);
    }

    addChild(parent: TaxonNode, node: TaxonNode) {
        if (parent.children) {
            parent.children.push(node)
            this.dataChange.next(this.data);
        }
    }

    /*
    insertItem(parent: TaxonNode, name: string) {
        if (parent.children) {
            parent.children.push({ item: name } as TaxonNode);
            this.dataChange.next(this.data);
        }
    }

    updateItem(node: TaxonNode, name: string) {
        node.item = name;
        this.dataChange.next(this.data);
    }
    */
}

@Component({
    selector: 'taxa-viewer',
    templateUrl: './taxa-viewer-page.html',
    styleUrls: ['./taxa-viewer-page.component.scss'],
    providers: [TaxonNodeDatabase],
})

export class TaxaViewerPageComponent implements OnInit {
    nameControl = new FormControl()
    nameOptions: TaxonIDAuthorNameItem[] = []
    allNames: TaxonNode[] = []
    root: TaxonNode = null
    selectedTaxonID = 0
    hasAuthors = false
    includeAuthors = false
    showAllRanks = false
    expandableTree = false
    includeRank = false
    problematicRouting = true
    language = "none"
    kindOfName = "Scientific"
    languageList = []
    taxonomicAuthorityList : TaxonomicAuthorityListItem[] = []
    taxonomicAuthorityID = 1 // Default taxa authority is set in nginit
    //treeControl = new NestedTreeControl<TaxonNode>(node => node.children);
    // dataSource : MatTreeNestedDataSource<TaxonNode> = new MatTreeNestedDataSource<TaxonNode>()


    private _transformer = (node: TaxonNode, level: number) => {
        return node? {
            expandable: !!node.children && node.children.length > 0,
            level: level,
            node: node
        } : {}
    }

    treeControl: FlatTreeControl<TaxonTreeNode> = new FlatTreeControl<TaxonTreeNode>(
        node => node.level,
        node => node.expandable,
    )

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    )

    dataSource = new MatTreeFlatDataSource(
        this.treeControl, this.treeFlattener)
    //dataSource = new ArrayDataSource(TREE_DATA);

    visibleRanksMap : Map<number, boolean> = new Map()
    maxChildRankMap : Map<number, number> = new Map()
    visibleRanks  = new Set([
        'Organism',
        'Kingdom',
        'Order',
        'Class',
        'Genus',
        'Family',
        'Species',
        'Phylum',
        'SubFamily',
        'Subgenus',
        'Suborder',
        'Subspecies',
        'Superfamily',
        'Tribe'])
    dataChange = new BehaviorSubject<TaxonNode[]>([])
    public taxa = []
    public names = []
    private isLoading = false
    isExpanded = ( node: TaxonNode) =>
        node.expanded //&& !!node.children && node.children.length > 0;
    isNotExpanded = ( node: TaxonNode) =>
        !node.expanded //&& !!node.children && node.children.length > 0;
    isSynonym = ( node: TaxonNode) => node.synonym
    hasChildren = (nodeData: TaxonTreeNode) =>
        nodeData.children !== undefined ? nodeData.children.length > 0 : false
    hasNestedChild = (_: number, nodeData: TaxonTreeNode) =>
        //nodeData.expanded && nodeData.children !== undefined ? nodeData.children.length > 0 : false
        //nodeData.expandable
        true
    rankIsVisible = (rankID: number) =>
        this.showAllRanks || this.visibleRanksMap.get(rankID)
    private taxon: TaxonListItem
    nameFound = false
    looking = false
    possibleTaxons  = []
    genusRankID = 1000 // Will be initialized by constructor
    familyRankID = 1000 // Will be initialized by constructor
    nameToRankID
    rankIDToName

    constructor(
        private readonly taxaService: TaxonService,
        private readonly taxonomicEnumTreeService: TaxonomicEnumTreeService,
        private readonly taxonomicStatusService: TaxonomicStatusService,
        private readonly taxonVernacularService: TaxonVernacularService,
        private readonly taxonomicAuthorityService: TaxonomicAuthorityService,
        private readonly taxonomicUnitService: TaxonomicUnitService,
        private router: Router,
        private formBuilder: FormBuilder,
        private currentRoute: ActivatedRoute,
        private readonly translate: TranslateService
    ) {
        // const node : TaxonNode = {name: "foo", children: [], taxonID: 1, author: "bar" }
        this.dataSource.data = []
    }


    /*
    Called when Angular starts
    */
    ngOnInit() {
        // Turn off problematic routing
        this.problematicRouting = false

        // Load the authorities
        this.loadAuthorities()

        // Get the common languages for display in the menu
        this.loadVernacularLanguages()

        this.taxonomicUnitService.findAll().subscribe((units) => {
            this.nameToRankID = new Map()
            this.rankIDToName = new Map()
            units.forEach((unit) => {
                // Set up a default rank
                if (!this.maxChildRankMap.has(unit.directParentRankID)) {
                    this.maxChildRankMap.set(unit.directParentRankID, unit.rankID)
                }
                // Now build the real rank
                if (!this.maxChildRankMap.has(unit.reqParentRankID)) {
                    this.maxChildRankMap.set(unit.reqParentRankID,unit.rankID);
                } else {
                    const currentRank = this.maxChildRankMap.get(unit.reqParentRankID)
                    if (currentRank < unit.rankID) {
                        this.maxChildRankMap.set(unit.reqParentRankID,unit.rankID)
                    }
                }
                if (!this.nameToRankID.has(unit.rankName)) {
                    this.nameToRankID.set(unit.rankName, unit.rankID)
                }
                if (!this.rankIDToName.has(unit.rankID)) {
                    this.rankIDToName.set(unit.rankID, unit.rankName)
                }
                this.visibleRanksMap.set(unit.rankID, this.visibleRanks.has(unit.rankName))
            })
            this.genusRankID = this.nameToRankID.get("Genus")
            this.familyRankID = this.nameToRankID.get("Family")
        })

    }

    rankFor(rankID) {
        return this.rankIDToName.get(rankID)
    }

    /*
    The vernacular language menu has a new choice
     */
    languageChangeAction(language) {
        this.looking = false
        this.language = language
    }

    /*
    Taxonomic authority has a new value
     */
    authorityChangeAction() {
        this.looking = false
        // If the authority changes...
    }

    /*
    Reload the names as needed
     */
    loadNames(partialName) {
        this.looking = false
        if (this.kindOfName == 'Scientific') {
            this.loadScientificNames(partialName)
        } else {
            this.loadCommonNames(partialName)
        }
    }

    /*
    Called when the choice of scientific vs. common is changed
     */
    configureChangeAction() {
        this.nameOptions = []
        this.nameControl.setValue("")
    }

    /*
    Reload the names as a user types
     */
    onKey(event) {
        this.looking = false
        if (event.target.value) {
            const partialName = event.target.value
            this.loadNames(partialName)
        }
    }

    /*
    Load the taxa authorities
     */
    public loadAuthorities() {
        this.taxonomicAuthorityService.findAll()
            .subscribe((authorities) => {
                this.taxonomicAuthorityList = authorities
                this.taxonomicAuthorityList.sort(function (a, b) {
                    return (a.id > b.id ? 1 : -1)
                })
                this.taxonomicAuthorityList.forEach((authority) => {
                    if (authority.isPrimary) {
                        this.taxonomicAuthorityID = authority.id
                    }
                })
            })
    }

    /*
    Load the kingdoms -- currently not implemented or used
     */
    public loadKingdoms() {

    }

    /*
    Load the languages for vernacular names
     */
    public loadVernacularLanguages() {
        this.taxonVernacularService.findAllLanguages(this.taxonomicAuthorityID)
            .subscribe((language) => {
                this.languageList = language
            })
    }

    /*
    Load the common names using the chosen language
     */
    public loadCommonNames(partialName) {
        const language = this.language

        // If the language is not set, load all of the common names
        if (this.language == "none") {
            this.taxonVernacularService.findAllCommonNames(partialName, this.taxonomicAuthorityID)
                .subscribe((names) => {
                    this.nameOptions = names
                })
        } else {
            this.taxonVernacularService.findAllCommonNamesByLanguage(language, partialName, this.taxonomicAuthorityID)
                .subscribe((names) => {
                    this.nameOptions = names
                })
        }
        // remove any duplicate names (without authors)
        if (!this.hasAuthors) {
            this.nameOptions = this.nameOptions.filter((item, pos, arr) => {
                return pos === 0 || item.name !== arr[pos-1].name
            })
        }


    }

    /*
    Load Scientific names that start with partialName into a list
     */
    public loadScientificNames(partialName) {
        this.nameOptions= []
        this.taxaService.findAllScientificNames(partialName, this.taxonomicAuthorityID)
            .subscribe((names) => {

                this.nameOptions = this.hasAuthors ?
                    names
                    : names.filter((item, pos, arr) =>
                    {  // Remove duplicates
                        return pos === 0 || item.name !== arr[pos-1].name;
                    })
            })
    }

    /*
    Find the children and ancestors for the given taxonID
    */
    private buildTree(taxonID: number) {
        this.allNames = []
        var myRankID = 0 // Guess a low one
        this.problematicRouting = false
        this.selectedTaxonID = taxonID

        console.log("buildTree " + taxonID)
        let tree = new Map<number, TaxonNode>()
        let root = null

        this.looking = true

        // First determine if I'm a synonym
        this.taxonomicStatusService.findAll({taxonIDs : [taxonID], taxonomicAuthorityID: this.taxonomicAuthorityID})
            .subscribe( { next: (statuses) => {
                    statuses.forEach((status) => {
                        if (status.taxonID == taxonID) {
                            // Am working with the one I was given, check if a synonym
                            if (status.taxonID == status.taxonIDAccepted) {
                                // Accepted
                            } else {
                                // Synonym
                                taxonID = status.taxonIDAccepted

                            }
                            // Look up the ancestors
                            this.taxaService.findAncestorsWithSynonyms(taxonID,0,10000, this.taxonomicAuthorityID)
                                .subscribe((ancestors) => {
                                    ancestors
                                        .sort(function (a, b) {
                                            //return a.rankID - b.rankID
                                            if (!a.isSynonym && !b.isSynonym) {
                                                return a.rankID - b.rankID
                                            } else if (a.isSynonym && b.isSynonym) {
                                                return a.name >= b.name? 1 : -1
                                            } else if (a.isSynonym) {
                                                return 1
                                            } else {
                                                return -1
                                            }
                                        })
                                        .forEach((ancestor) => {
                                            // console.log("ancestor name is " + ancestor.name + " " + ancestor.isSynonym + " " + ancestor.id + " " + ancestor.parentID)
                                            if (false && ancestor.isSynonym) { // removing this code, ancestor can be synonym
                                                // console.log("synonym ancestor name is " + ancestor.name + " " + ancestor.isSynonym + " " + ancestor.id + " " + ancestor.parentID)
                                                let newNode: TaxonNode = {
                                                    name: ancestor.name,
                                                    taxonID: ancestor.id,
                                                    parentID: ancestor.parentID,
                                                    acceptedID: ancestor.acceptedID,
                                                    author: ancestor.author,
                                                    rankID : ancestor.rankID,
                                                    expanded: true,
                                                    synonym: true,
                                                    childrenLoaded: false,
                                                    children: []
                                                }
                                                tree.set(ancestor.id,newNode)
                                                if (tree.has(ancestor.acceptedID)) {
                                                    tree.get(ancestor.acceptedID).children.push(newNode)
                                                } else {
                                                    this.problematicRouting = true
                                                    console.log("error no such ancestor synonym " + ancestor.name + " " + ancestor.acceptedID)
                                                }
                                            } else {
                                                let newNode: TaxonNode = {
                                                    name: ancestor.name,
                                                    taxonID: ancestor.id,
                                                    parentID: ancestor.parentID,
                                                    acceptedID: ancestor.acceptedID,
                                                    author: ancestor.author,
                                                    rankID : ancestor.rankID,
                                                    expanded: true,
                                                    synonym: ancestor.isSynonym,
                                                    childrenLoaded: false,
                                                    children: []
                                                }
                                                if (root == null) {
                                                    root = newNode
                                                    this.root = newNode
                                                    this.dataSource.data = [this.root]
                                                }
                                                tree.set(ancestor.id,newNode)
                                                if (tree.has(ancestor.parentID) && ancestor.id != ancestor.parentID /* for organism the ids are the same */) {
                                                    tree.get(ancestor.parentID).children.push(newNode)
                                                } else {
                                                    if (ancestor.id != ancestor.parentID) {
                                                        this.problematicRouting = true
                                                        console.log("error no such ancestor parent " + ancestor.name + " " + ancestor.parentID + " " + ancestor.id)
                                                    }
                                                }
                                            }
                                        })
                                    // Self could have synonyms, so let's set up the actual node
                                    let actualNode: TaxonNode
                                    this.taxaService.findSelfWithSynonyms(taxonID,this.taxonomicAuthorityID)
                                        .subscribe((taxons) => {
                                            taxons
                                                .sort(function (a, b) {
                                                    //return a.rankID - b.rankID
                                                    if (!a.isSynonym && !b.isSynonym) {
                                                        return a.rankID - b.rankID
                                                    } else if (a.isSynonym && b.isSynonym) {
                                                        return a.name >= b.name? 1 : -1
                                                    } else if (a.isSynonym) {
                                                        return 1
                                                    } else {
                                                        return -1
                                                    }
                                                })
                                                .forEach((self) => {
                                                    // console.log("Self is " + self.name + " " + self.id + " " + self.isSynonym)

                                                    // this.taxon = taxon
                                                    let newNode: TaxonNode = {
                                                        name: self.name,
                                                        taxonID: self.id,
                                                        parentID: self.parentID,
                                                        acceptedID: self.acceptedID,
                                                        author: self.author,
                                                        rankID : self.rankID,
                                                        expanded: true,
                                                        synonym: self.isSynonym,
                                                        childrenLoaded: true,
                                                        children: []
                                                    }
                                                    // I'm a synonym
                                                    if (self.isSynonym) {
                                                        // We should have seen the accepted node
                                                        if (actualNode != null) {
                                                            actualNode.children.push(newNode)
                                                        } else {
                                                            this.problematicRouting = true
                                                            console.log("error no accepted name for " + self.name)
                                                        }
                                                    } else {
                                                        // Node is the actual node
                                                        this.selectedTaxonID = self.id

                                                        myRankID = self.rankID
                                                        actualNode = newNode
                                                        tree.set(self.id,newNode)
                                                        if (tree.has(self.parentID) && self.id != self.parentID /* for organism the ids are the same */) {
                                                            tree.get(self.parentID).children.push(newNode)
                                                        } else {
                                                            this.problematicRouting = true
                                                            console.log("error no such parent for self " + self.name + " " + self.parentID)
                                                        }
                                                    }
                                                    /*
                                                    myRankID = self.rankID
                                                    tree.set(self.id,newNode)
                                                    if (tree.has(self.parentID) && self.id != self.parentID) {
                                                        tree.get(self.parentID).children.push(newNode)
                                                    } else if (self.isSynonym) {

                                                    }
                                                    else {
                                                        this.problematicRouting = true
                                                        console.log("error no such parent " + self.name + " " + self.parentID)
                                                    }
                                                    */
                                                })
                                            if (myRankID < this.familyRankID) {
                                                // Expand tree to rank of family
                                                this.taxaService.findDescendantsWithSynonyms(taxonID, 0, this.familyRankID, this.taxonomicAuthorityID).
                                                subscribe((descendants) => {
                                                    descendants
                                                        .sort(function (a, b) {
                                                            if (!a.isSynonym && !b.isSynonym) {
                                                                if (a.rankID == b.rankID) {
                                                                    if (a.name <= b.name) {
                                                                        return -1
                                                                    } else {
                                                                        return 1
                                                                    }
                                                                }
                                                                return a.rankID - b.rankID
                                                            } else if (a.isSynonym && b.isSynonym) {
                                                                return a.name >= b.name? 1 : -1
                                                            } else if (a.isSynonym) {
                                                                return 1
                                                            } else {
                                                                return -1
                                                            }
                                                            //return a.rankID >= b.rankID? b.isSynonym? 1 : a.isSynonym? 0 : -1 : -1
                                                        })
                                                        .forEach((descendant) => {
                                                            //zzz console.log("Descendant is " + descendant.name + " " + descendant.id + " " + descendant.isSynonym)
                                                            if (descendant.isSynonym) {
                                                                // console.log("Descendant synonym is " + descendant.name + " " + descendant.id + " " + descendant.isSynonym)
                                                                let newNode: TaxonNode = {
                                                                    name: descendant.name,
                                                                    taxonID: descendant.id,
                                                                    parentID: descendant.parentID,
                                                                    acceptedID: descendant.acceptedID,
                                                                    author: descendant.author,
                                                                    rankID : descendant.rankID,
                                                                    expanded: false,
                                                                    synonym: true,
                                                                    childrenLoaded: false,
                                                                    children: []
                                                                }
                                                                tree.set(descendant.id,newNode)
                                                                if (tree.has(descendant.acceptedID)) {
                                                                    tree.get(descendant.acceptedID).children.push(newNode)
                                                                    tree.get(descendant.acceptedID).expanded = true
                                                                } else {
                                                                    this.problematicRouting = true
                                                                    console.log("error no such descendant synonym " + descendant.name + " " + descendant.acceptedID)
                                                                }
                                                            } else {
                                                                let newNode: TaxonNode = {
                                                                    name: descendant.name,
                                                                    taxonID: descendant.id,
                                                                    parentID: descendant.parentID,
                                                                    acceptedID: descendant.acceptedID,
                                                                    author: descendant.author,
                                                                    rankID : descendant.rankID,
                                                                    expanded: false,
                                                                    synonym: false,
                                                                    children: []
                                                                }
                                                                tree.set(descendant.id,newNode)
                                                                if (tree.has(descendant.parentID) && descendant.id != descendant.parentID /* for organism the ids are the same */) {
                                                                    const parent = tree.get(descendant.parentID)
                                                                    parent.children.push(newNode)
                                                                    parent.expanded = true
                                                                } else {
                                                                    this.problematicRouting = true
                                                                    console.log("error no such parent for descendant " + descendant.name + " " + descendant.parentID)
                                                                }
                                                            }
                                                        })
                                                    console.log(" refresh " + root)
                                                    tree.forEach((node, key) => {
                                                        node.children.sort(function (a, b) {
                                                            if (a.name <= b.name) {
                                                                return -1
                                                            } else {
                                                                return 1
                                                            }
                                                        })
                                                    })
                                                    //this.dataSource.data = root === null? [] : [root]
                                                    this.refreshTree()
                                                })
                                            }
                                            else {
                                                // Expand tree to rank of lowest rank
                                                this.taxaService.findDescendantsWithSynonyms(taxonID, myRankID, Number.MAX_SAFE_INTEGER, this.taxonomicAuthorityID).
                                                subscribe((descendants) => {
                                                    console.log(" descendants " + descendants.length)
                                                    descendants
                                                        .sort(function (a, b) {
                                                            if (a.rankID == b.rankID) {
                                                                if (!a.isSynonym && !b.isSynonym) {
                                                                    if (a.name <= b.name) {
                                                                        return -1
                                                                    } else {
                                                                        return 1
                                                                    }
                                                                } else if (a.isSynonym && b.isSynonym) {
                                                                    return a.name >= b.name? 1 : -1
                                                                } else if (a.isSynonym) {
                                                                    return 1
                                                                } else {
                                                                    return -1
                                                                }
                                                            } else {
                                                                return a.rankID - b.rankID
                                                            }
                                                            /*
                                                            if (!a.isSynonym && !b.isSynonym) {
                                                                if (a.rankID == b.rankID) {
                                                                    if (a.name <= b.name) {
                                                                        return -1
                                                                    } else {
                                                                        return 1
                                                                    }
                                                                }
                                                                return a.rankID - b.rankID
                                                            } else if (a.isSynonym && b.isSynonym) {
                                                                return a.name >= b.name? 1 : -1
                                                            } else if (a.isSynonym) {
                                                                return 1
                                                            } else {
                                                                return -1
                                                            }

                                                             */
                                                            //return a.rankID >= b.rankID? b.isSynonym? 1 : a.isSynonym? 0 : -1 : -1
                                                        })
                                                        .forEach((descendant) => {
                                                            //zzz console.log("My Descendant is " + descendant.name + " " + descendant.id + " " + descendant.isSynonym)
                                                            if (descendant.isSynonym) {
                                                                // console.log("Descendant synonym is " + descendant.name + " " + descendant.id + " " + descendant.isSynonym)
                                                                let newNode: TaxonNode = {
                                                                    name: descendant.name,
                                                                    taxonID: descendant.id,
                                                                    parentID: descendant.parentID,
                                                                    acceptedID: descendant.acceptedID,
                                                                    author: descendant.author,
                                                                    rankID : descendant.rankID,
                                                                    expanded: false,
                                                                    synonym: true,
                                                                    childrenLoaded: false,
                                                                    children: []
                                                                }
                                                                tree.set(descendant.id,newNode)
                                                                if (tree.has(descendant.acceptedID)) {
                                                                    tree.get(descendant.acceptedID).children.push(newNode)
                                                                    tree.get(descendant.acceptedID).expanded = true
                                                                } else {
                                                                    /* If accepted taxon not present this is OK since ancestors are synonyms
                                                                    this.problematicRouting = true
                                                                    console.log("error no such synonym " + descendant.name + " " + descendant.acceptedID)
                                                                 */
                                                                    // try to add as child of parent
                                                                    if (tree.has(descendant.parentID) && descendant.id != descendant.parentID /* for organism the ids are the same */) {
                                                                        const parent = tree.get(descendant.parentID)
                                                                        parent.children.push(newNode)
                                                                        parent.expanded = true
                                                                    } else {
                                                                        this.problematicRouting = true
                                                                        console.log("error no such synonym for a descendant " + descendant.name + " " + descendant.parentID)
                                                                    }
                                                                }
                                                            } else {
                                                                let newNode: TaxonNode = {
                                                                    name: descendant.name,
                                                                    taxonID: descendant.id,
                                                                    parentID: descendant.parentID,
                                                                    acceptedID: descendant.acceptedID,
                                                                    author: descendant.author,
                                                                    rankID : descendant.rankID,
                                                                    expanded: false,
                                                                    synonym: false,
                                                                    children: []
                                                                }
                                                                tree.set(descendant.id,newNode)
                                                                if (tree.has(descendant.parentID) && descendant.id != descendant.parentID /* for organism the ids are the same */) {
                                                                    const parent = tree.get(descendant.parentID)
                                                                    parent.children.push(newNode)
                                                                    parent.expanded = true
                                                                } else {
                                                                    this.problematicRouting = true
                                                                    console.log("error no such parent other descendant " + descendant.name + " " + descendant.parentID)
                                                                }
                                                            }
                                                        })
                                                    // console.log(" refresh " + root)
                                                    tree.forEach((node, key) => {
                                                        node.children.sort(function (a, b) {
                                                            if (a.name <= b.name) {
                                                                return -1
                                                            } else {
                                                                return 1
                                                            }
                                                        })
                                                    })

                                                    this.refreshTree()

                                                    /*
                                                    this.dataSource.data = root === null? [] : [root]
                                                    this.refreshTree()
                                                    this.root = root

                                                     */
                                                })
                                                /*
                                                tree.forEach((node, key) => {
                                                    node.children.sort(function (a, b) {
                                                        if (a.name <= b.name) {
                                                            return -1
                                                        } else {
                                                            return 1
                                                        }
                                                    })
                                                })

                                                this.dataSource.data = root == null? [] : [root]
                                                this.refreshTree()

                                                 */


                                            }
                                        })

                                })
                        }
                    })
                },
                complete: () => {}
            })
    }

    // Depth first traversal of tree populating list of names
    private copyNames(node : TaxonNode, level : number) {
        if (node == null) return
        // console.log("copyinng " + node.name + " " + node.taxonID)
        node.level = level
        this.allNames.push(node)
        const children = node.children
        children.forEach((child) => {this.copyNames(child, level+1)})
    }

    trackByTaxonID(index, item) {
        // console.log("tracking " + item.node.taxonID);
        return item.node.taxonID;
    }

    /*
    Find the children and ancestors for the given taxonID
     */
    /*
    private buildTreeOld(taxonID: number) {
        // console.log("buildTree " + taxonID)
        let children = []
        const childrenSynonyms = new Map<number, number[]>()
        const childrenSynonymNameMap = new Map<string, number[]>()

        this.looking = true
        // Look up the scientific name first
        this.taxaService.findByID(taxonID)
            .subscribe((taxon) => {
                if (taxon) {
                    this.taxon = taxon
                    let taxonID = taxon.id
                    let baseNode: TaxonNode = {
                        name: taxon.scientificName,
                        taxonID: taxonID,
                        author: taxon.author,
                        rankID : taxon.rankID,
                        expanded: true,
                        synonym: false,
                        children: []
                    }

                    // Is this taxon a synonym?
                    this.taxonomicStatusService.findAll(
                        {taxonIDs: [taxon.id], taxonomicAuthorityID: this.taxonomicAuthorityID} )
                        .subscribe((myStatii) => {
                            myStatii.forEach((myStatus) => {
                                if (taxonID != myStatus.taxonIDAccepted) {
                                    // I am a synonym, let's look for the accepted id information
                                    taxonID = myStatus.taxonIDAccepted
                                    this.taxaService.findByID(myStatus.taxonIDAccepted, this.taxonomicAuthorityID)
                                        .subscribe( (myTaxon) => {
                                            baseNode.name = myTaxon.scientificName
                                            baseNode.taxonID = myTaxon.id
                                            baseNode.children = []
                                            baseNode.expanded = true
                                            baseNode.synonym = false
                                        })
                                }
                            })

                            if (taxon.rankID >= this.familyRankID) {
                                // Build the children, first get the children
                                this.taxonomicStatusService.findChildren(taxonID, this.taxonomicAuthorityID)
                                    .subscribe((taxonStatii) => {
                                        // Need a list of the children tids to fetch their names
                                        const childrenTids = []
                                        taxonStatii
                                            .filter((a) => a.taxonID != taxonID)
                                            .forEach(function (rec) {
                                                const acceptedId = rec.taxonIDAccepted
                                                if (rec.taxonID !== acceptedId) {
                                                    // This is a synonym
                                                    if (childrenSynonyms.has(acceptedId)) {
                                                        // Have seen this accepted name before
                                                        const a = childrenSynonyms.get(acceptedId)
                                                        a.push(rec.taxonID)
                                                    } else {
                                                        //childrenTids.push(acceptedId)
                                                        childrenSynonyms.set(acceptedId,[rec.taxonID])
                                                    }
                                                } else {
                                                    childrenTids.push(rec.taxonID)
                                                }
                                            })

                                        // Fetch the scientific names of the children
                                        if (childrenTids.length == 0) {
                                            // There are no children

                                            // Fetch synonyms
                                            this.fetchSynonyms(taxon.id, baseNode, true)
                                            return
                                        }

                                        // Get the names of the children
                                        this.taxaService.findAll(this.taxonomicAuthorityID,{taxonIDs: childrenTids})
                                            .subscribe((t) => {
                                                t.forEach(function (r) {
                                                    const child : TaxonNode = {
                                                        name: r.scientificName,
                                                        taxonID: r.id,
                                                        author: r.author,
                                                        rankID: r.rankID,
                                                        expanded: false,
                                                        synonym: false,
                                                        children: []
                                                    }
                                                    children.push(child)
                                                    // Update the synonym map with the sciname
                                                    if (childrenSynonyms.has(r.id)) {
                                                        childrenSynonymNameMap.set(r.scientificName, childrenSynonyms.get(r.id))
                                                        childrenSynonyms.delete(r.id)
                                                    }
                                                })

                                                // Children array is the scientific names of the children
                                                children = children.sort(function (a, b) {
                                                    return (a.name > b.name ? 1 : -1)
                                                })

                                                children.forEach((childItem) => {
                                                    if (childrenSynonymNameMap.has(childItem.name)) {
                                                        const childList = []
                                                        this.taxaService
                                                            .findAll(
                                                                this.taxonomicAuthorityID,
                                                                { taxonIDs: childrenSynonymNameMap.get(childItem.name) })
                                                            .subscribe((synonymList) => {

                                                                synonymList
                                                                    .sort((a,b) => {return (a.scientificName > b.scientificName) ? 1 : -1 })
                                                                    .forEach(function(synonym) {
                                                                        // Add the synonym to a list of children
                                                                        const childItem: TaxonNode = {
                                                                            name: synonym.scientificName,
                                                                            taxonID: synonym.id,
                                                                            author: synonym.author,
                                                                            rankID: synonym.rankID,
                                                                            expanded: false,
                                                                            synonym: true,
                                                                            children: []
                                                                        }

                                                                        childList.push(childItem)
                                                                    })
                                                            })
                                                        childItem.children = childList
                                                    }
                                                    baseNode.children.push(childItem)
                                                })

                                                baseNode.synonym = false
                                                baseNode.expanded = true

                                                //this.fetchAncestors(taxonID,baseNode)
                                                this.fetchSynonyms(taxonID, baseNode, true)
                                            })
                                    })
                            } else {
                                this.fetchDescendants(baseNode)
                            }

                        })
                } else {
                    // No taxon found, show error message
                    this.nameFound = false
                }

            })

    }
     */

    /*
    Grab the synonyms for the current node and then go on and grab the ancestors
     */
    /*
    private fetchSynonyms(taxonid, baseNode, alsoAncestors) {
        // console.log("fetching synonyms " + baseNode.name)
        this.taxonomicStatusService.findSynonyms(baseNode.taxonID,this.taxonomicAuthorityID)
            .subscribe( (syn) => {
                let synonymList = []
                syn.forEach(function(synonym) {
                    // console.log(" finding synonym " + synonym.taxon.scientificName)
                    // Add the synonym to a list of synonyms
                    const synonymItem: TaxonNode = {
                        name: synonym.taxon.scientificName,
                        taxonID: synonym.taxon.id,
                        author: synonym.taxon.author,
                        rankID: synonym.taxon.rankID,
                        expanded: false,
                        synonym: true,
                        children: []
                    }

                    synonymList.push(synonymItem)
                })
                // Sort the list of synonyms
                synonymList = synonymList.sort(function (a, b) {
                    return 0 - (a.name > b.name ? 1 : -1)
                })

                // Add to children of the baseNode
                synonymList.forEach((syn) => {
                    // console.log(" adding " + syn.name)
                    baseNode.children.unshift(syn)
                })
                // Fetch ancestors
                if (alsoAncestors) {
                    this.fetchAncestors(taxonid,baseNode)
                }
                this.refreshTree()
            })
    }

     */

    /*
    Grab the ancestors for a given taxonid, and use the baseNode for the children
     */
    /*
    private fetchAncestors(taxonid, baseNode) {

        // console.log(" Fetch ancesctors " + baseNode.name)
        // Find the ancestors
        this.taxonomicEnumTreeService
            .findAncestorTaxons(taxonid, this.taxonomicAuthorityID)
            .subscribe((ancTaxa) => {
                const newTree = ancTaxa
                    .filter((a) => a.id != taxonid)
                    .sort(function (a, b) {
                        return b.rankID - a.rankID;
                    })
                    .map((item) => {
                        const itemNode: TaxonNode = {
                            name: item.scientificName,
                            taxonID: item.id,
                            author: item.author,
                            rankID: item.rankID,
                            expanded: false,
                            synonym: false,
                            children: [],
                        }
                        // Process the synonyms
                        if (item.acceptedTaxonStatuses) {
                            let synonymList = []

                            // Run through the list of taxonomic statuses
                            item.acceptedTaxonStatuses.forEach((synonym) => {

                                // Is it really a synonym?
                                if (synonym.taxonIDAccepted != synonym.taxonID) {

                                    // Found a synonym, add it to the list of synonyms
                                    const synonymItem: TaxonNode = {
                                        name: synonym.taxon.scientificName,
                                        taxonID: synonym.taxon.id,
                                        author: synonym.taxon.author,
                                        rankID: synonym.taxon.rankID,
                                        expanded: false,
                                        synonym: true,
                                        children: []
                                    }
                                    synonymList.push(synonymItem)
                                }

                            })

                            // Sort the list of synonyms
                            synonymList = synonymList.sort(function (a, b) {
                                return 0 - (a.name > b.name ? 1 : -1)
                            })

                            // Add the synonym list as children of the itemNode
                            itemNode.children = synonymList
                        }

                        return itemNode
                    })
                    .reduce<TaxonNode[]>((a , b: TaxonNode ) => {

                        // Add the child node in the tree to its parent up the tree
                        b.children.push(a[0])
                        return [b]
                    }, [baseNode])

                // We have all of the ancestors in the tree
                // this.dataSource.data =  newTree

            })

    }

     */

    private findCommonAncestors(name: string) {
        this.looking = true

        // Look up the common name first
        this.taxonVernacularService
            .findByCommonName(name, this.taxonomicAuthorityID)
            .subscribe((items) => {
                if (items.length == 0) {
                    this.nameFound = false
                } else if (items.length > 2) {
                    this.nameFound = true
                    // Need to build a list of taxons to select
                    // lookup its name by tid
                    this.possibleTaxons = []
                    items.forEach((item) => {
                        this.taxaService.findByID(item.taxonID, this.taxonomicAuthorityID)
                            .subscribe((taxon) => {
                                // Found a synonym, add it to the list of synonyms
                                const taxonItem: TaxonNode = {
                                    name: taxon.scientificName,
                                    taxonID: item.taxonID,
                                    author: taxon.author,
                                    rankID: taxon.rankID,
                                    expanded: false,
                                    synonym: false,
                                    children: []
                                }
                                this.possibleTaxons.push(taxonItem)
                            })
                    })
                } else {
                    // Found one
                    const item = items[0]
                    if (item) {
                        this.nameFound = true
                        const tid = item.taxonID

                        // lookup its name by tid
                        this.taxaService.findByID(tid, this.taxonomicAuthorityID)
                            .subscribe((taxonRec) => {

                                // Go find the ancestors for this name
                                this.buildTree(taxonRec.id)
                                // this.buildTree(taxonRec.scientificName)
                            })
                    } else {
                        // Only if list has a null value, which is not possible?
                        this.nameFound = false
                    }
                }
            })
    }

    /*
    Repaint the taxonomy tree in the browser
     */
    public refreshTree() {
        console.log("refreshing tree " + this.root)
        // Cache the current tree
        //const tree = this.dataSource.data
        // Trigger a change to the tree

        //this.dataSource.data = []

        // Trigger another change, redraw cached tree
        this.dataSource.data = [this.root]
        // console.log("this " + this.root.children.length)
        //this.dataChange.next([this.root])
        this.treeControl.expandAll()

        //this.treeControl.renderNodeChanges

        console.log("copy " + this.dataSource._expandedData.value.length + " " + this.dataSource._flattenedData.value.length)
        //this.allNames = []
        //this.copyNames(this.root, 1)
        //console.log("ending copy")
    }

    private findChildrenChildSyn(node: TaxonNode) {
        let tree = new Map<number, TaxonNode>()
        // Add me to the tree
        tree.set(node.taxonID, node)
        node.children = []
        //let root = null

        console.log(" ranks " + this.maxChildRankMap.get(node.rankID) + " " + node.rankID)
        //if (node.rankID < this.familyRankID) {
        // Expand tree to rank of family
        this.taxaService.findChildrenWithSynonyms(node.taxonID, this.taxonomicAuthorityID).
        subscribe( {next: (children) => {
            children
                .sort(function (a, b) {
                    if (!a.isSynonym && !b.isSynonym) {
                        if (a.rankID == b.rankID) {
                            if (a.name <= b.name) {
                                return -1
                            } else {
                                return 1
                            }
                        }
                        return a.rankID - b.rankID
                    } else if (a.isSynonym && b.isSynonym) {
                        return a.name >= b.name? 1 : -1
                    } else if (a.isSynonym) {
                        return 1
                    } else {
                        return -1
                    }
                    //return a.rankID >= b.rankID? b.isSynonym? 1 : a.isSynonym? 0 : -1 : -1
                })
                .forEach((child) => {
                   //zzz console.log("Child is " + child.name + " " + child.id + " " + child.isSynonym)
                    if (child.isSynonym) {
                        // console.log("Descendant synonym is " + descendant.name + " " + descendant.id + " " + descendant.isSynonym)
                        let newNode: TaxonNode = {
                            name: child.name,
                            taxonID: child.id,
                            parentID: child.parentID,
                            acceptedID: child.acceptedID,
                            author: child.author,
                            rankID : child.rankID,
                            expanded: false,
                            synonym: true,
                            childrenLoaded: false,
                            children: []
                        }
                        tree.set(child.id,newNode)
                        if (tree.has(child.acceptedID)) {
                            tree.get(child.acceptedID).children.push(newNode)
                            tree.get(child.acceptedID).expanded = true
                        } else {
                            console.log("skipping synonym " + child.name + " " + child.acceptedID)
                        }
                    } else {
                        let newNode: TaxonNode = {
                            name: child.name,
                            taxonID: child.id,
                            parentID: child.parentID,
                            acceptedID: child.acceptedID,
                            author: child.author,
                            rankID : child.rankID,
                            expanded: false,
                            synonym: false,
                            children: []
                        }
                        tree.set(child.id,newNode)
                        if (tree.has(child.parentID) && child.id != child.parentID /* for organism the ids are the same */) {
                            const parent = tree.get(child.parentID)
                            parent.children.push(newNode)
                            parent.expanded = true
                        } else {
                            console.log("skipping child without parent " + child.name + " " + child.parentID)
                        }
                    }
                })
            // console.log(" refresh " + root)
            tree.forEach((node, key) => {
                node.children.sort(function (a, b) {
                    if (a.name <= b.name) {
                        return -1
                    } else {
                        return 1
                    }
                })
            })
            //this.dataSource.data = root === null? [] : [root]
            //this.refreshTree()
        },
        complete: () => {
            this.refreshTree()
        }})
        //} else {

        //}
        //this.refreshTree()
    }

    private findChildren(node: TaxonNode) {
        let tree = new Map<number, TaxonNode>()
        // Add me to the tree
        tree.set(node.taxonID, node)
        node.children = []
        //let root = null

        console.log(" ranks " + this.maxChildRankMap.get(node.rankID) + " " + node.rankID)
        //if (node.rankID < this.familyRankID) {
            // Expand tree to rank of family
            this.taxaService.findDescendantsWithSynonyms(node.taxonID, 0, this.maxChildRankMap.get(node.rankID)+1/*this.familyRankID*/, this.taxonomicAuthorityID).
            subscribe((descendants) => {
                descendants
                    .sort(function (a, b) {
                        if (!a.isSynonym && !b.isSynonym) {
                            if (a.rankID == b.rankID) {
                                if (a.name <= b.name) {
                                    return -1
                                } else {
                                    return 1
                                }
                            }
                            return a.rankID - b.rankID
                        } else if (a.isSynonym && b.isSynonym) {
                            return a.name >= b.name? 1 : -1
                        } else if (a.isSynonym) {
                            return 1
                        } else {
                            return -1
                        }
                        //return a.rankID >= b.rankID? b.isSynonym? 1 : a.isSynonym? 0 : -1 : -1
                    })
                    .forEach((descendant) => {
                       //zzz console.log("Descendant is " + descendant.name + " " + descendant.id + " " + descendant.isSynonym)
                        if (descendant.isSynonym) {
                            // console.log("Descendant synonym is " + descendant.name + " " + descendant.id + " " + descendant.isSynonym)
                            let newNode: TaxonNode = {
                                name: descendant.name,
                                taxonID: descendant.id,
                                parentID: descendant.parentID,
                                acceptedID: descendant.acceptedID,
                                author: descendant.author,
                                rankID : descendant.rankID,
                                expanded: false,
                                synonym: true,
                                childrenLoaded: false,
                                children: []
                            }
                            tree.set(descendant.id,newNode)
                            if (tree.has(descendant.acceptedID)) {
                                tree.get(descendant.acceptedID).children.push(newNode)
                                tree.get(descendant.acceptedID).expanded = true
                            } else {
                                /* If accepted not present this is OK since just means not here
                                this.problematicRouting = true
                                console.log("error no such synonym 2 " + descendant.name + " " + descendant.acceptedID)
                              */
                                // Try to add as a child of its parent
                                if (tree.has(descendant.parentID) && descendant.id != descendant.parentID /* for organism the ids are the same */) {
                                    const parent = tree.get(descendant.parentID)
                                    parent.children.push(newNode)
                                    parent.expanded = true
                                } else {
                                    this.problematicRouting = true
                                    console.log("error no such parent for descendant synonym " + descendant.name + " " + descendant.parentID)
                                }
                            }

                        } else {
                            let newNode: TaxonNode = {
                                name: descendant.name,
                                taxonID: descendant.id,
                                parentID: descendant.parentID,
                                acceptedID: descendant.acceptedID,
                                author: descendant.author,
                                rankID : descendant.rankID,
                                expanded: false,
                                synonym: false,
                                children: []
                            }
                            tree.set(descendant.id,newNode)
                            if (tree.has(descendant.parentID) && descendant.id != descendant.parentID /* for organism the ids are the same */) {
                                const parent = tree.get(descendant.parentID)
                                parent.children.push(newNode)
                                parent.expanded = true
                            } else {
                                this.problematicRouting = true
                                console.log("error no such parent another descendant " + descendant.name + " " + descendant.parentID)
                            }
                        }
                    })
                // console.log(" refresh " + root)
                tree.forEach((node, key) => {
                    node.children.sort(function (a, b) {
                        if (a.name <= b.name) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                })
                //this.dataSource.data = root === null? [] : [root]
                this.refreshTree()
                //this.dataChange.next(this.dataSource)
            })
        //} else {

        //}
        //this.refreshTree()
    }

    // Build up a tree of descendants nodes, one node at a time
    fillInDescendants(taxonid, descMap, nodeMap) {
        // Check that the node is a parent
        if (!descMap[taxonid]) {
            // I'm a leaf return my children
            return nodeMap[taxonid]
        }
        const node = nodeMap[taxonid]
        descMap[taxonid].forEach((child) => {
                const childNode = this.fillInDescendants(child, descMap, nodeMap)
                node.children.push(childNode)
            }
        )
        return node
    }

    // @ViewChild('tree') tree

    loadChildren(node: TaxonNode, expanded=false) : void {
        if (node.expanded) {
            node.expanded = false
            return
        }
        console.log("Loading children " + node.name)
        if (false && node.expanded) {
            node.expanded = false
            //this.refreshTree()
        } else {
            node.expanded = true
            //if (!node.childrenLoaded) {
                // Always reload children
                this.findChildren(node)
                node.childrenLoaded = true
            //}
            //this.treeControl.collapse(node)
            //his.treeControl.expand(node)
        }
    }

    closeNode(node: TaxonNode) : void {
        console.log("Closing node " + node.name + " " + node.expanded + " " + node.childrenLoaded)
        if (node.expanded) {
            node.expanded = false
            //this.treeControl.collapse(node)
        } else {
            //this.treeControl.collapse(node)
        }
        this.refreshTree()
     }

    selectedSciname(event: MatAutocompleteSelectedEvent): void {
        this.nameFound = true
        // this.dataSource.data = []
        if (this.kindOfName == 'Scientific') {
            const sname = this.hasAuthors? this.nameControl.value.split(' -')[0] : this.nameControl.value
            this.nameListCheck(sname)
        } else {
            this.findCommonAncestors(this.nameControl.value)
        }
    }

    /*
    Called when the taxon is chosen to display
     */
    onSubmit(): void {
        // Turn off problematic routing
        this.problematicRouting = false

        this.nameFound = true
        // this.dataSource.data = []
        if (this.kindOfName == 'Scientific') {
            console.log(" name is " + this.nameControl.value)
            const sname = this.hasAuthors? this.nameControl.value.split(' -')[0] : this.nameControl.value
            //this.buildTree(sname)
            this.nameListCheck(sname)
        } else {
            this.findCommonAncestors(this.nameControl.value)
        }

    }

    nameListChange(options: MatListOption[]) {
        console.log("name list change")
        this.buildTree(+options.map(o => o.value))
    }

    nameListCheck(sciname) {
        this.looking = true
        // Look up the scientific name first
        this.taxaService.findByScientificName(sciname.trim(), this.taxonomicAuthorityID)
            .subscribe((taxons) => {
                if (!taxons) {
                    // No name found
                    this.nameFound = false
                } else if (taxons.length > 1) {
                    // Found several names
                    this.nameFound = true
                    // Need to build a list of taxons to select
                    // lookup its name by tid
                    this.possibleTaxons = []
                    taxons.forEach((item) => {
                        this.taxaService.findByID(item.id, this.taxonomicAuthorityID)
                            .subscribe((taxon) => {
                                // Found a synonym, add it to the list of synonyms
                                const taxonItem: TaxonNode = {
                                    name: taxon.scientificName,
                                    taxonID: item.id,
                                    author: taxon.author,
                                    rankID: taxon.rankID,
                                    expanded: false,
                                    synonym: false,
                                    children: []
                                }
                                this.possibleTaxons.push(taxonItem)
                            })
                    })
                } else {
                    // Found one
                    const taxon = taxons[0]
                    if (taxon) {
                        this.nameFound = true
                        this.buildTree(+taxon.id)
                    } else {
                        // Should never get here
                        this.nameFound = false
                    }
                }
            })
    }

}
