<form [formGroup]="editCollectionForm" fxLayout="column" fxLayoutAlign="start">
    <mat-card id="collection" class="collectionCard" fxLayout="column" fxLayoutGap="1rem">
        <mat-card-title class="title" fxLayoutAlign="start center">
            <mat-icon color="primary" class="instIcon">collections_bookmark</mat-icon>Collection Information
        </mat-card-title>
        <div class="main" fxFlex="50" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
            fxLayoutGap="0rem">
            <div fxLayout="column" fxLayoutAlign="start center">
                <symbiota2-collection-logo size="7rem" src="{{ editCollectionForm.value.icon }}" id="logo">
                </symbiota2-collection-logo>
                <mat-form-field id="icon_url" style="font-size: 8px">
                    <mat-label>Icon URL</mat-label>
                    <input matInput formControlName="icon" />
                </mat-form-field>
            </div>

            <!-- <div class="drop-container"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)">
                <p>Drag and drop an image here, or click to select a file</p>
            </div> -->

            <div id="dropzone" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
                <img *ngIf="imageData" [src]="imageData" alt="Uploaded image">
                <p *ngIf="!imageData">Drop your image here or click to browse</p>
                <p *ngIf="imageData">Uploaded image: {{ imageData.name }}</p>
                <input type="file" (change)="onFileSelect($event)" accept="image/*" style="display: none;">
              </div>


            <div fxFlex>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2rem" fxLayoutGap.lt-md="1rem">
                    <mat-form-field id="name_form" fxFlex="80">
                        <mat-label>Collection Name *</mat-label>
                        <input matInput formControlName="collectionName" />
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('collectionName')
                                                .hasError('required')
                                        ">
                            Collection Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('collectionName')
                                                .hasError('nameTaken')
                                        ">
                            Name already in use
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field id="code_form" fxFlex="25"
                        matTooltip="The name, acronym, or code identifying the collection or data set from which the record was derived. This field is optional. For more details, see Darwin Core definition. "
                        matTooltipClass="primary">
                        <mat-label>Collection Code *</mat-label>
                        <input matInput formControlName="collectionCode" oninput="this.value = this.value.toUpperCase()" />
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('collectionCode')
                                                .hasError('required')
                                        ">
                            Collection Code is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('collectionCode')
                                                .hasError('codeTaken')
                                        ">
                            Code already in use
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0rem">
                    <mat-form-field fxFlex="100" appearance="outline"
                        matTooltip="The name (or acronym) in use by the institution having custody of the occurrence records. This field is required. For more details, see Darwin Core definition. "
                        matTooltipClass="primary">
                        <mat-label>Institution *</mat-label>
                        <mat-select formControlName="institutionID">
                            <mat-option *ngFor="let i of inst$ | async" [value]="i.id">({{ i.code }}) {{ i.name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('institutionID')
                                                .hasError('required')
                                        ">
                            please select an Institution
                        </mat-error>
                    </mat-form-field>
                    <button mat-icon-button (click)="onAddNewInst()">
                        <mat-icon color="primary" class="instIcon">
                            add_circle_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2rem" fxLayoutAlign="space-between" class="content">
            <div fxLayout="column" fxFlex="30">
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Category *</mat-label>
                    <mat-select formControlName="category">
                        <mat-option *ngFor="let cat of categories" value="{{ cat.id }}">{{ cat.category }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('category')
                                            .hasError('required')
                                    ">
                        please select a category
                    </mat-error>
                </mat-form-field> -->
                <mat-form-field appearance="outline"
                    matTooltip="Preserve Specimens means that physical samples exist and can be inspected by researchers. Use Observations when the record is not based on a physical specimen. General Observations are used for setting up group projects where registered users can independently manage their own dataset directly within the single collection. General Observation collections are typically used by field researchers to manage their collection data and print labels prior to depositing the physical material within a collection. Even though personal collections are represented by a physical sample, they are classified as observations until the physical material is deposited within a publicly available collection with active curation. "
                    matTooltipClass="primary">
                    <mat-label>Collection Type *</mat-label>
                    <mat-select formControlName="type">
                        <!-- //TODO: api will handle populating collection types -->
                        <mat-option value="Preserved Specimens">Preserved Specimens</mat-option>
                        <mat-option value="General Observations">General Observations</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('type')
                                            .hasError('required')
                                    ">
                        please select a Collection Type
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline"
                    matTooltip="Use Snapshot when there is a separate in-house database maintained in the collection and the dataset within the Symbiota portal is only a periodically updated snapshot of the central database. A Live dataset is when the data is managed directly within the portal and the central database is the portal data. "
                    matTooltipClass="primary">
                    <mat-label>Management *</mat-label>
                    <mat-select formControlName="managementType">
                        <!-- //TODO: api will handle populating collection types -->
                        <mat-option value="Snapshot">Snapshot</mat-option>
                        <mat-option value="Live Data">Live Data</mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('managementType')
                                            .hasError('required')
                                    ">
                        please select a Management
                    </mat-error>
                </mat-form-field>
                <!-- <mat-checkbox value="publishToAggregators" formControlName="aggregators" id="checkbox">
                    Publish to Aggregators</mat-checkbox> -->
            </div>

            <div fxLayout="column" fxFlex fxLayoutAlign="" fxLayoutGap="2rem">
                <mat-form-field matTooltip="A Url to the homepage of the collection or institution that hosts collection"
                    matTooltipClass="primary">
                    <mat-label>Homepage *</mat-label>
                    <input matInput type="url" formControlName="homePage" />
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('homePage')
                                            .hasError('required')
                                    ">
                        homepage is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field
                    matTooltip="A legal document giving official permission to do something with the resource. This field can be limited to a set of values by modifying the portal's central configuration file. For more details, see Darwin Core definition. "
                    matTooltipClass="primary">
                    <mat-label>License *</mat-label>
                    <input matInput type="url" formControlName="rights" />
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('rights')
                                            .hasError('required')
                                    ">
                        license is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <div class="coords" fxLayout="row" fxLayoutGap="2rem" fxLayoutAlign="end center">
                    <h3>Coordinates:</h3>
                    <mat-form-field fxFlex="22">
                        <mat-label>latitude *</mat-label>
                        <input matInput type="number" step=".0001" formControlName="latitude" />
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('latitude')
                                                .hasError('required')
                                        ">
                            latitude is <strong> required </strong>
                        </mat-error>
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('latitude')
                                                .hasError('min') ||
                                            editCollectionForm
                                                .get('latitude')
                                                .hasError('max')
                                        ">
                            please enter valid latitude
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="22">
                        <mat-label>longitude *</mat-label>
                        <input matInput type="number" step=".0001" formControlName="longitude" />
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('longitude')
                                                .hasError('required')
                                        ">
                            longitude is <strong> required </strong>
                        </mat-error>
                        <mat-error *ngIf="
                                            editCollectionForm
                                                .get('longitude')
                                                .hasError('min') ||
                                            editCollectionForm
                                                .get('longitude')
                                                .hasError('max') ||
                                            editCollectionForm
                                                .get('longitude')
                                                .hasError('number')
                                        ">
                            please enter valid longitude
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="form-subgroup" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="2rem" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start" fxLayoutGap.lt-md="1rem">
            <mat-form-field fxFlex="fill" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="fullDescription" #description></textarea>
            </mat-form-field>
        </div>
    </mat-card>
    <div fxLayout="column" fxLayoutGap="1rem">
        <mat-card id="contact1" class="contact">
            <mat-card-title class="title" fxLayoutAlign="start center">
                <mat-icon color="primary" class="instIcon">account_circle</mat-icon>
                Contact Information
            </mat-card-title>
            <mat-card-content fxLayout="column" fxLayoutAlign="start">
                <h3>*Contact with administration permissions recommended</h3>
                <mat-form-field class="medium-input">
                    <mat-label>Name *</mat-label>
                    <input matInput type="url" formControlName="contact" />
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('contact')
                                            .hasError('required')
                                    ">
                        contact is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="medium-input">
                    <mat-label>Email *</mat-label>
                    <input matInput type="email" formControlName="email" />
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('email')
                                            .hasError('required')
                                    ">
                        an email is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="
                                        editCollectionForm
                                            .get('email')
                                            .hasError('email')
                                    ">
                        email not valid
                    </mat-error>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <!-- <mat-card id="contact2" class="contact">
            <mat-card-title class="title" fxLayoutAlign="start center">
                <mat-icon color="primary" class="instIcon">account_circle</mat-icon>
                Additional Contact Information
            </mat-card-title>
            <mat-card-content fxLayout="column">
                <mat-form-field class="medium-input">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="role2">
                        <mat-option value="admin">Admin</mat-option>
                        <mat-option value="editor">Editor</mat-option>
                        <mat-option value="rareSpeciesReader">
                            Rare Species Reader
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="medium-input">
                    <mat-label>Name</mat-label>
                    <input matInput type="url" formControlName="contact2" />
                </mat-form-field>
                <mat-form-field class="medium-input">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" formControlName="email2" />
                </mat-form-field>
            </mat-card-content>
        </mat-card> -->
    </div>
    <div fxLayoutAlign="start">
        <button id="submit" class="submit-button" mat-raised-button color="primary" type="submit" (click)="onApplyChanges()"
            [disabled]="!editCollectionForm.valid" style="font-size: large">
            Update Collection
        </button>
    </div>
    <!-- <p><b>Form Status:</b> {{ editCollectionForm.status }}</p>
    <pre> <b>Form Values:</b> {{editCollectionForm.value | json}}</pre> 
    <pre> <b>Form Errors:</b> {{editCollectionForm.errors | json}}</pre>  -->
</form>