import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Institution } from '@symbiota2/api-database';

@Component({
    selector: 'symbiota2-delete-comment-dialog',
    templateUrl: './delete-comment-dialog.component.html',
    styleUrls: ['./delete-comment-dialog.component.scss'],
})
export class DeleteCommentDialogComponent {

    constructor(
        private readonly dialogRef: MatDialogRef<DeleteCommentDialogComponent>
    ) {}

    // onSubmit(val: boolean) {
    //     this.dialogRef.close(val);
    // }

    // onClose() {
    //     this.dialogRef.close(null);
    // }
}
