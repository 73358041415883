<div mat-dialog-content>
    <form *ngIf="action == 'Update'; else elseTemplate" class="name-autocomplete-form">
        <div fxLayout="row wrap">
            <div class="form-field w-8">
                <mat-form-field>
                    <mat-label>{{'taxon.description.editor.statement.heading' | translate}}</mat-label>
                    <input matInput
                           maxlength="100"
                           [value]="local_data.heading"
                           [(ngModel)]="local_data.heading"
                           [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <symbiota2-editable-text key='taxon.description.editor.statement.heading' hide='true'></symbiota2-editable-text>
            </div>
        </div>
        <div fxLayout="row wrap">
            <div class="form-field w-7">
                <mat-form-field>
                    <mat-label>{{'taxon.description.editor.statement.displayHeader' | translate}}</mat-label>
                    <input matInput
                           maxlength="1"
                           [value]="local_data.displayHeader"
                           [(ngModel)]="local_data.displayHeader"
                           [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <symbiota2-editable-text key='taxon.description.editor.statement.displayHeader' hide='true'></symbiota2-editable-text>
            </div>
        </div>
        <div fxLayout="row wrap">
            <div class="form-field w-1">
                <mat-form-field>
                    <mat-label>{{'taxon.description.editor.statement.sortSequence' | translate}}</mat-label>
                    <input matInput
                           maxlength="3"
                           [value]="local_data.sortSequence"
                           [(ngModel)]="local_data.sortSequence"
                           [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <symbiota2-editable-text key='taxon.description.editor.statement.sortSequence' hide='true'></symbiota2-editable-text>
            </div>
        </div>
        <div fxLayout="row wrap">
            <div class="form-field w-8">
                <mat-label>{{'taxon.description.editor.statement.statement' | translate}}</mat-label>
                <symbiota2-editable-text key='taxon.description.editor.statement.statement' hide='true'></symbiota2-editable-text>
                <quill-editor [(ngModel)]="local_data.statement" [ngModelOptions]="{standalone: true}">
            </quill-editor>

                <mat-form-field style="display:none">
                    <mat-label>{{'taxon.description.editor.statement.statement' | translate}}</mat-label>
                    <textarea matInput
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="1"
                              [value]="local_data.statement"
                              [(ngModel)]="local_data.statement"
                              [ngModelOptions]="{standalone: true}"></textarea>
                </mat-form-field>
                <symbiota2-editable-text key='taxon.description.editor.statement.statement' hide='true'></symbiota2-editable-text>
            </div>
        </div>
    </form>
    <ng-template #elseTemplate>
        <symbiota2-editable-text key='taxon.editor.dialog.confirm.delete'></symbiota2-editable-text>?
    </ng-template>

</div>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="doAction()">{{action}}</button>
    <button mat-button (click)="closeDialog()" mat-raised-button color="warn">{{'taxon.editor.dialog.cancel' | translate}}</button>
    <symbiota2-editable-text key='taxon.editor.dialog.cancel' hide='true'></symbiota2-editable-text>
</div>
