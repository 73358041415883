import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CommentService } from '../../services/comments.service';
import { Comment } from '../../dto/Comment.output.dto';

@Component({
  selector: 'symbiota2-collection-comment',
  templateUrl: './collection-comment.component.html',
  styleUrls: ['./collection-comment.component.scss']
})
export class CollectionCommentComponent implements OnInit { 

  @Input() comment: Comment;


  constructor() { }

  ngOnInit(): void {}

  formatDate(time) {
    const date = new Date(time);
        const year = date.getFullYear().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');; // getMonth() returns a zero-based index
        const day = date.getDate().toString().padStart(2, '0');;
        return `${year}-${month}-${day}`;
  }

}
