<div id="container">
    <h1 mat-dialog-title>{{ "core.health.popup.title" | translate }}</h1>
    <div *ngIf='apiIsUp;else api_down'>
        <button style='margin: 4px' mat-raised-button color="primary">
            {{'core.health.popup.button.up'| translate}}
        </button>
        {{'core.health.popup.api.label'| translate}}
        <div>
            <div *ngIf='emailIsUp;else email_down'>
                <button style='margin: 4px' mat-raised-button color="primary">
                    {{'core.health.popup.button.up'| translate}}
                </button>
                {{'core.health.popup.email.label'| translate}}
            </div>
            <ng-template #email_down>
                <button style='margin: 4px' mat-raised-button color="warn">
                    {{'core.health.popup.button.down'| translate}}
                </button>
                {{'core.health.popup.email.label'| translate}}
            </ng-template>
        </div>
        <div>
            <div *ngIf='kibanaIsUp;else kibana_down'>
                <button style='margin: 4px' mat-raised-button color="primary">
                    {{'core.health.popup.button.up'| translate}}
                </button>
                {{'core.health.popup.kibana.label'| translate}}
            </div>
            <ng-template #kibana_down>
                <button style='margin: 4px' mat-raised-button color="warn">
                    {{'core.health.popup.button.down'| translate}}
                </button>
                {{'core.health.popup.kibana.label'| translate}}
            </ng-template>
        </div>
        <div>
            <div *ngIf='storageServiceIsUp;else storage_service_down'>
                <button style='margin: 4px' mat-raised-button color="primary">
                    {{'core.health.popup.button.up'| translate}}
                </button>
                {{'core.health.popup.storage.service.label'| translate}}
            </div>
            <ng-template #storage_service_down>
                <button style='margin: 4px' mat-raised-button color="warn">
                    {{'core.health.popup.button.down'| translate}}
                </button>
                {{'core.health.popup.storage.service.label'| translate}}
            </ng-template>
        </div>
        <div>
            <div *ngIf='redisIsUp;else redis_down'>
                <button style='margin: 4px' mat-raised-button color="primary">
                    {{'core.health.popup.button.up'| translate}}
                </button>
                {{'core.health.popup.redis.label'| translate}}
            </div>
            <ng-template #redis_down>
                <button style='margin: 4px' mat-raised-button color="warn">
                    {{'core.health.popup.button.down'| translate}}
                </button>
                {{'core.health.popup.redis.label'| translate}}
            </ng-template>
        </div>
        <div>
            <div *ngIf='dbServerIsUp;else db_server_down'>
                <button style='margin: 4px' mat-raised-button color="primary">
                    {{'core.health.popup.button.up'| translate}}
                </button>
                {{'core.health.popup.db.server.label'| translate}}
            </div>
            <ng-template #db_server_down>
                <button style='margin: 4px' mat-raised-button color="warn">
                    {{'core.health.popup.button.down'| translate}}
                </button>
                {{'core.health.popup.db.server.label'| translate}}
            </ng-template>
        </div>
    </div>
    <ng-template #api_down>
        <button style='margin: 4px' mat-raised-button color="warn">
            {{'core.health.popup.button.down'| translate}}
        </button>
        {{'core.health.popup.api.down.message'| translate}}
    </ng-template>
    <div class="mt-1" fxLayoutAlign="space-around">
        <button mat-raised-button (click)="onClose()">
            {{ "core.health.popup.close" | translate }}
        </button>
    </div>
        <!--
    <div id="links">
        <div>
            <a [routerLink]='ROUTE_CREATE_PROFILE' (click)='onCancel()'>
                {{ 'symbiota-auth.login.no_account_text' | translate }}
            </a>
        </div>
        <div>
            <a [routerLink]='ROUTE_FORGOT_PASSWORD' (click)='onCancel()'>
                {{ 'symbiota-auth.login.cant_remember_password' | translate }}
            </a>
        </div>
        <div>
            <a [routerLink]='ROUTE_FORGOT_USERNAME' (click)='onCancel()'>
                {{ 'symbiota-auth.login.cant_remember_login' | translate }}
            </a>
        </div>
    </div>
    -->
</div>
