import { Component } from '@angular/core';
import { AppConfigService } from '@symbiota2/ui-common';

import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'symbiota2-collection-statistics-dashboard',
    templateUrl: './collection-statistics-dashboard-page.html',
    styleUrls: ['./collection-statistics-dashboard-page.scss']
})
export class CollectionStatisticsDashboardPage {

    public dashboardUrl = '' // http://localhost:5601/app/dashboards?auth_provider_hint=anonymous1#/view/a9f7a9a0-bb79-11ec-87e4-7372471162d9';

    constructor(
        private readonly appConfig: AppConfigService,
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        public sanitizer: DomSanitizer
    ) {
        this.dashboardUrl = appConfig.kibanaServer() + ":" + appConfig.kibanaPort() + appConfig.kibanaDashboardCollectionStats();
    }

    ngOnInit(): void {
        // Navigate directly to the dashboard to avoid CSP errors!
        document.location.href = this.dashboardUrl
    }

}
