// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'http://localhost:8080/api/v1',
    defaultLanguage: 'en',
    appTitle: "Symbiota2 Demo",
    recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    // For mapping functionality
    tilesUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    tilesToken: '',
    enableFooter: true,
    kibanaServer: 'http://localhost',
    kibanaPort: '5601',
    kibanaDashboardImageSearch: '/app/dashboards?auth_provider_hint=anonymous1#/view/fc340bf0-82df-11ec-b9ae-27af917973e5',
    kibanaDashboardCollectionStats: '/app/dashboards?auth_provider_hint=anonymous1#/view/a9f7a9a0-bb79-11ec-87e4-7372471162d9',
    kibanaDashboardSpatialModule: '/app/dashboards?auth_provider_hint=anonymous1#/view/3050b510-a931-11ec-8fad-591789a7e073',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
