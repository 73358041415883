    <mat-form-field appearance="outline" class="main">
        <mat-label>User Search</mat-label>
        <input
            #searchBox
            id="searchBox"
            type="text"
            (input)="search(searchBox.value)"
            placeholder="Select User"
            matInput
            [formControl]="user"
            [matAutocomplete]="auto"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let user of users$ | async" [value]="user.username" (click)="onSelectUser(user)">
                {{ user.username }} ({{ user.lastName }}, {{ user.firstName }})
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>