<div id='main'>
    <div *ngIf="!userCanEdit" class='my2' fxLayout='row' fxLayoutGap='1rem'>
        <symbiota2-editable-text key='image.add.lacks.edit.permission'></symbiota2-editable-text>
    </div>
    <div *ngIf="userCanEdit" class='my2'>

        <!-- Configure kind of upload -->
        <div class="expansion-panel">
            <symbiota2-expansion-panel expanded=true label='{{"taxonomy.image.add.configure.kind.of.upload.label" | translate}}'>
                <symbiota2-editable-text key='taxonomy.image.add.configure.kind.of.upload.label' hide='true'></symbiota2-editable-text>
                <mat-radio-group
                    aria-labelledby="configure-radio-group-label"
                    class="configure-radio-group"
                    [(ngModel)]="whichLocation">
                    <mat-radio-button value="{{BY_LOCAL_FILE}}"><symbiota2-editable-text key='taxonomy.image.add.by.file.upload'></symbiota2-editable-text></mat-radio-button>&nbsp;&nbsp;
                    <mat-radio-button value="{{BY_URL}}"><symbiota2-editable-text key='taxonomy.image.add.by.url'></symbiota2-editable-text></mat-radio-button>
                </mat-radio-group>

                <div fxLayout="row wrap" style='background-color: #FFFF99; padding: 10px'>
                    <div *ngIf="whichLocation == BY_LOCAL_FILE" >
                        <b><symbiota2-editable-text key='image.add.select.message'></symbiota2-editable-text></b>
                        <symbiota2-file-upload-field [formControl]='fileInputControl'></symbiota2-file-upload-field>
                        <br/>
                        <mat-checkbox class="example-margin" [(ngModel)]='keepLarge'
                        ><symbiota2-editable-text key='image.add.image.keep.message'></symbiota2-editable-text></mat-checkbox>
                        <br/>
                        <symbiota2-editable-text key='image.add.image.keep.message.note'></symbiota2-editable-text>
                    </div>
                    <div *ngIf="whichLocation == BY_URL" class="form-field w-6">
                        <mat-form-field>
                            <mat-label>{{"image.details.image.url" | translate}}</mat-label>
                            <input matInput
                                   maxlength="100"
                                   [value]="local_data.url"
                                   [(ngModel)]="local_data.url"
                                   [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <symbiota2-editable-text key='image.details.image.url' hide='true'></symbiota2-editable-text>
                    </div>
                </div>
            </symbiota2-expansion-panel>
        </div>
        <div class="expansion-panel">
            <symbiota2-expansion-panel expanded=true label='{{"taxonomy.image.add.image.details.label" | translate}}'>
                <symbiota2-editable-text key='taxonomy.image.add.image.details.label' hide='true'></symbiota2-editable-text>
                    <form class="name-autocomplete-form">
                        <div fxLayout="row wrap">
                            <div class="form-field w-6">
                                <mat-form-field>
                                    <mat-label>{{"image.details.caption" | translate}}</mat-label>
                                    <input matInput
                                           maxlength="100"
                                           [value]="local_data.caption"
                                           [(ngModel)]="local_data.caption"
                                           [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                                <symbiota2-editable-text key='image.details.caption' hide='true'></symbiota2-editable-text>
                            </div>
                        </div>

                        <div fxLayout="row wrap">
                            <div class="form-field w-6">
                                <mat-form-field>
                                    <mat-label>{{"taxon.image.add.photographer" | translate}}</mat-label>
                                    <mat-select [formControl]="photographerForm">
                                        <mat-option *ngFor="let photographer of photographerOptions" [value]="photographer">{{photographer}}</mat-option>
                                    </mat-select>
                                    <symbiota2-editable-text key='taxon.image.add.photographer' hide='true'></symbiota2-editable-text>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row wrap">
                            <div class="form-field w-6">
                                <mat-form-field>
                                    <mat-label>{{"image.details.image.source.url" | translate}}</mat-label>
                                    <input matInput
                                           maxlength="100"
                                           [value]="local_data.sourceUrl"
                                           [(ngModel)]="local_data.sourceUrl"
                                           [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                                <symbiota2-editable-text key='image.details.image.source.url' hide='true'></symbiota2-editable-text>
                            </div>
                        </div>

                        <div fxLayout="row wrap">
                            <div class="form-field w-6">
                                <mat-form-field>
                                    <mat-label>{{"image.details.image.reference.url" | translate}}</mat-label>
                                    <input matInput
                                           maxlength="100"
                                           [value]="local_data.referenceUrl"
                                           [(ngModel)]="local_data.referenceUrl"
                                           [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                                <symbiota2-editable-text key='image.details.image.reference.url' hide='true'></symbiota2-editable-text>
                            </div>
                        </div>

                        <div fxLayout="row wrap">
                            <div class="form-field w-6">
                                <mat-form-field>
                                    <mat-label>{{"image.details.image.copyright" | translate}}</mat-label>
                                    <input matInput
                                           maxlength="100"
                                           [value]="local_data.copyright"
                                           [(ngModel)]="local_data.copyright"
                                           [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                                <symbiota2-editable-text key='image.details.image.copyright' hide='true'></symbiota2-editable-text>
                            </div>
                        </div>

                        <div fxLayout="row wrap">
                            <div class="form-field w-6">
                                <mat-form-field>
                                    <mat-label>{{"image.details.image.locality" | translate}}</mat-label>
                                    <input matInput
                                           maxlength="100"
                                           [value]="local_data.locality"
                                           [(ngModel)]="local_data.locality"
                                           [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                                <symbiota2-editable-text key='image.details.image.locality' hide='true'></symbiota2-editable-text>
                            </div>
                        </div>

                        <div fxLayout="row wrap">
                            <div class="form-field w-6">
                                <mat-form-field>
                                    <mat-label>{{"image.details.image.notes" | translate}}</mat-label>
                                    <input matInput
                                           maxlength="100"
                                           [value]="local_data.notes"
                                           [(ngModel)]="local_data.notes"
                                           [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                                <symbiota2-editable-text key='image.details.image.notes' hide='true'></symbiota2-editable-text>
                            </div>
                        </div>

                        <div fxLayout="row wrap">
                            <div class="form-field w-1">
                                <mat-form-field>
                                    <mat-label>{{"image.details.image.sortSequence" | translate}}</mat-label>
                                    <input matInput [formControl]='sortSequenceControl'>
                                    <symbiota2-editable-text key='image.details.image.sortSequence' hide='true'></symbiota2-editable-text>
                                    <div *ngIf="sortSequenceControl.invalid && (sortSequenceControl.dirty || sortSequenceControl.touched)">
                                        <mat-error *ngIf="sortSequenceControl.invalid"><symbiota2-editable-text key='image.add.image.sortSequence.error'></symbiota2-editable-text></mat-error>
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>

            </symbiota2-expansion-panel>
        </div>
        <br/><br/>
        <div mat-dialog-actions>
            <button mat-button
                    (click)="doSave()"
                    [disabled]='(whichLocation == BY_LOCAL_FILE && !fileInputControl.value) || (whichLocation == BY_URL && !local_data.sourceUrl)'
                    mat-flat-button color="primary">{{"image.add.dialog.upload" | translate}}
            </button>
            <symbiota2-editable-text key='image.add.dialog.upload' hide='true'></symbiota2-editable-text>
        </div>
    </div>

</div>
