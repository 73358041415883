    <div id="buttons">
        <button mat-flat-button color="primary" (click)="onSearch()">
            {{'plugins.occurrence.search.button.text' | translate}}
        </button>
        <symbiota2-editable-text key='plugins.occurrence.criteria.search.button.text' hide='true'></symbiota2-editable-text>
    </div>

    <!-- Taxonomic criteria -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel label="{{'plugins.occurrence.search.taxonCriteria.title' | translate}}" required>
            <symbiota2-editable-text key='plugins.occurrence.search.taxonCriteria.title' hide='true'></symbiota2-editable-text>
            <div fxLayout="row wrap">
                <div class="form-field w-2">
                    <mat-form-field>
                        <mat-select [formControl]="taxonSearchCriterion">
                            <mat-option
                                *ngFor="let opt of taxonCriteriaOptions"
                                [value]="opt.value"
                            >
                                {{ opt.i18n | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-field w-3">
                    <mat-form-field>
                        <input matInput [formControl]="taxonSearchStr" />
                    </mat-form-field>
                </div>
            </div>
            <div>
                <mat-checkbox  class="normal-font" [checked]="includeSynonyms.value" (change)="toggleIncludeSynonyms()"
                ><symbiota2-editable-text key='plugins.occurrence.search.taxonCriteria.includeSynonyms'></symbiota2-editable-text></mat-checkbox>
            </div>
        </symbiota2-expansion-panel>
    </div>

    <!-- Locality criteria -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel label="{{'plugins.occurrence.search.localityCriteria.title' | translate }}">
            <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.title' hide='true'></symbiota2-editable-text>
            <div fxLayout="row wrap">
                <div class="form-field w-2">
                    <mat-autocomplete #countries="matAutocomplete">
                        <mat-option
                            *ngFor="let country of countryAutoComplete | async"
                            [value]="country.countryTerm"
                        >
                            {{ country.countryTerm }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-form-field>
                        <mat-label>{{'plugins.occurrence.search.localityCriteria.country.label.text' | translate}}</mat-label>
                            <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.country.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            [formControl]="country"
                            [matAutocomplete]="countries"
                        />
                    </mat-form-field>
                </div>
                <div class="form-field w-2">
                    <mat-autocomplete #provinces="matAutocomplete">
                        <mat-option
                            *ngFor="
                                let province of provinceAutoComplete | async
                            "
                            [value]="province.stateTerm"
                        >
                            {{ province.stateTerm }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.state.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.state.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            [formControl]="stateProvince"
                            [matAutocomplete]="provinces"
                        />
                    </mat-form-field>
                </div>
                <div class="form-field w-2">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.county.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.county.label.text' hide='true'></symbiota2-editable-text>
                        <input matInput [formControl]="county" />
                    </mat-form-field>
                </div>
                <div class="form-field w-2">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.locality.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.locality.label.text' hide='true'></symbiota2-editable-text>
                        <input matInput [formControl]="locality" />
                    </mat-form-field>
                </div>
                <div class="form-field w-2">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.minimun_elevation.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.minimun_elevation.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            type="number"
                            [formControl]="minimumElevationInMeters"
                            min="0"
                        />
                    </mat-form-field>
                </div>
                <div class="form-field w-2">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.maximum_elevation.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.maximum_elevation.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            type="number"
                            [formControl]="maximumElevationInMeters"
                            min="0"
                        />
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.minimun_latitude.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.minimun_latitude.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            type="number"
                            [formControl]="minLatitude"
                            step="0.1"
                        />
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.maximum_latitude.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.maximum_latitude.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            type="number"
                            [formControl]="maxLatitude"
                            step="0.1"
                        />
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.minimum_longitude.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.minimum_longitude.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            type="number"
                            [formControl]="minLongitude"
                            step="0.1"
                        />
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.localityCriteria.maximum_longitude.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.localityCriteria.maximum_longitude.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            type="number"
                            [formControl]="maxLongitude"
                            step="0.1"
                        />
                    </mat-form-field>
                </div>
            </div>
        </symbiota2-expansion-panel>
    </div>

    <!-- Collector criteria -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel label="{{'plugins.occurrence.search.collectorCriteria.title' | translate}}">
            <symbiota2-editable-text key='plugins.occurrence.search.collectorCriteria.title' hide='true' hide='true'></symbiota2-editable-text>
            <div fxLayout="row wrap">
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.collectorCriteria.catalog_number.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.collectorCriteria.catalog_number.label.text' hide='true'></symbiota2-editable-text>
                        <input matInput [formControl]="catalogNumber" />
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.collectorCriteria.collector_lastname.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.collectorCriteria.collector_lastname.label.text' hide='true'></symbiota2-editable-text>
                        <input matInput [formControl]="collectorLastName" />
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.collectorCriteria.collected_after.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.collectorCriteria.collected_after.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            [matDatepicker]="minEventDatePicker"
                            [formControl]="minEventDate"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="minEventDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #minEventDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>
                            {{'plugins.occurrence.search.collectorCriteria.collected_before.label.text' | translate}}
                        </mat-label>
                        <symbiota2-editable-text key='plugins.occurrence.search.collectorCriteria.collected_before.label.text' hide='true'></symbiota2-editable-text>
                        <input
                            matInput
                            [matDatepicker]="maxEventDatePicker"
                            [formControl]="maxEventDate"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="maxEventDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #maxEventDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </symbiota2-expansion-panel>
    </div><br>

    <div id="buttons2">
        <button
            mat-flat-button
            color="primary"
            (click)="onSearch()"
        >
            {{'plugins.occurrence.search.button.text' | translate}}
        </button>
        <symbiota2-editable-text key='plugins.occurrence.criteria.search.button.text' hide='true'></symbiota2-editable-text>
    </div>
