<div id='container'>
    <mat-dialog-content>
        <div fxLayout='column' fxLayoutGap='1rem'>
            <ul>
                <li>
                    {{ data.totalRecords | number }}
                    <symbiota2-editable-text key='taxonomy.uploader.total.count'></symbiota2-editable-text>
                </li>
                <li>
                    <symbiota2-editable-text key='taxonomy.uploader.null.count.warning'></symbiota2-editable-text>
                    <ul>
                        <li>
                            {{ data.nullSciNames | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.null.scientific.name.count'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{ data.nullSciNames | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.null.parent.name.count'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{ data.nullSciNames | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.null.accepted.name.count'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{ data.nullSciNames | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.null.kingdom.name.count'></symbiota2-editable-text>
                        </li>
                        <li>
                            {{ data.nullSciNames | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.null.rank.name.count'></symbiota2-editable-text>
                        </li>
                    </ul>
                </li>
                <li>
                    <symbiota2-editable-text key='taxonomy.uploader.name.warning'></symbiota2-editable-text>
                    <ul>
                        <li >
                            {{ data.problemParentNames | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.problem.parent.name.count'></symbiota2-editable-text>
                            <br *ngIf='data.problemParentNames > 0'/>
                            <a *ngIf='data.problemParentNames > 0' href="{{problemParentRoute}}" target="_blank">{{"taxonomy.uploader.problem.parent.name.visit" | translate}}</a>
                        </li>
                        <li >
                            {{ data.problemAcceptedNames | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.problem.accepted.name.count'></symbiota2-editable-text>
                            <br *ngIf='data.problemAcceptedNames > 0'/>
                            <a *ngIf='data.problemAcceptedNames > 0' href="{{problemAcceptedRoute}}" target="_blank">{{"taxonomy.uploader.problem.accepted.name.visit" | translate}}</a>
                        </li>
                        <li >
                            {{ data.problemRanks | number }}
                            <symbiota2-editable-text key='taxonomy.uploader.problem.rank.name.count'></symbiota2-editable-text>
                            <br *ngIf='data.problemRanks > 0' />
                            <a *ngIf='data.problemRanks > 0' href="{{problemRanksRoute}}" target="_blank">{{"taxonomy.uploader.problem.ranks.visit" | translate}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div>
                <symbiota2-editable-text key='taxonomy.uploader.continue.uploading'></symbiota2-editable-text>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color='primary' (click)='onConfirm()'>{{"taxonomy.uploader.confirm.button" | translate}}</button>
        <symbiota2-editable-text key='taxonomy.uploader.confirm.button' hide='true'></symbiota2-editable-text>
        <button mat-raised-button (click)='onCancel()'>{{"taxonomy.uploader.cancel.button" | translate}}</button>
        <symbiota2-editable-text key='taxonomy.uploader.cancel.button' hide='true'></symbiota2-editable-text>
    </mat-dialog-actions>
</div>
