<div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngIf='(collection$ | async) as collection'>
            <div id='collection-icon'>
                <symbiota2-collection-logo
                    [collectionID]='collection.id'
                    [src]='collection.icon'
                >
                </symbiota2-collection-logo>
            </div>
            <div id='collection-name'>
                <h2>
                    {{ collection.collectionName }}
                </h2>
            </div>
        </ng-container>
        <div class="ml-auto">
            <button *ngIf='canEdit | async' class="btn-icon" (click)="onEditClick()">
                <mat-icon>create</mat-icon>
            </button>
            <button class="btn-icon" (click)="onCloseClick()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>
<ng-container *ngIf='(occurrence$ | async) as occurrence'>
    <div mat-dialog-content *ngIf='isEditing; else viewing'>
        <symbiota2-occurrence-editor
            [occurrence]='occurrence'
        >
        </symbiota2-occurrence-editor>
    </div>
    <ng-template #viewing>
        <div mat-dialog-content fxLayout="column">
            <div>
                <mat-card>
                    <mat-card-title>Collector Info</mat-card-title>
                    <mat-card-content>
                        <ng-container *ngFor="let item of occurrence.collectorInfo() | keyvalue">
                            <p *ngIf="item.value">
                                <b>{{ getI18nForField(item.key) | async }}: </b>
                                <span *ngIf='isDate(item.value); else notDate'>
                                    {{ item.value | date }}
                                </span>
                                <ng-template #notDate>
                                    {{ item.value }}
                                </ng-template>
                            </p>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
            <div>
                <mat-card>
                    <mat-card-title>Latest identification</mat-card-title>
                    <mat-card-content>
                        <ng-container *ngFor="let item of occurrence.latestIDInfo() | keyvalue">
                            <p *ngIf="item.value">
                                <b>{{ getI18nForField(item.key) | async }}: </b>
                                <span *ngIf='isDate(item.value); else notDate'>
                                    {{ item.value | date }}
                                </span>
                                <ng-template #notDate>
                                    {{ item.value }}
                                </ng-template>
                            </p>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
            <div>
                <mat-card>
                    <mat-card-title>Locality</mat-card-title>
                    <mat-card-content>
                        <ng-container *ngFor="let item of occurrence.localityInfo() | keyvalue">
                            <p *ngIf="item.value">
                                <b>{{ getI18nForField(item.key) | async }}: </b>
                                <span *ngIf='isNumber(item.value); else notNumber'>
                                    {{ item.value | number }}
                                </span>
                                <ng-template #notNumber>
                                    {{ item.value }}
                                </ng-template>
                            </p>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
            <div>
                <mat-card>
                    <mat-card-title>Miscellaneous</mat-card-title>
                    <mat-card-content>
                        <ng-container *ngFor="let item of occurrence.miscInfo() | keyvalue">
                            <p *ngIf="item.value">
                                <b>{{ getI18nForField(item.key) | async }}: </b>
                                <span *ngIf='isNumber(item.value); else notNumber'>
                                    {{ item.value | number }}
                                </span>
                                <ng-template #notNumber>
                                    {{ item.value }}
                                </ng-template>
                            </p>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
            <div>
                <mat-card>
                    <mat-card-title>Curation</mat-card-title>
                    <mat-card-content>
                        <ng-container *ngFor="let item of occurrence.curationInfo() | keyvalue">
                            <p *ngIf="item.value">
                                <b>{{ getI18nForField(item.key) | async }}: </b>
                                {{ item.value }}
                            </p>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div id='footer'>
            <small *ngIf="occurrence.lastModifiedTimestamp">
                Last updated {{ occurrence.lastModifiedTimestamp | date }}
            </small>
        </div>
    </ng-template>
</ng-container>
