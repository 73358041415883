<div id="add-user-container">
  <mat-card class="add-user-card">
    <mat-card-header>
      <mat-card-title>Add User Form</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form id="add-user-form" [formGroup]="addUserForm">
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field id="example-full-width">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="example-full-width">
            <mat-label>Repeat Password</mat-label>
            <input matInput formControlName="passwordAgain" type="password" required>
          </mat-form-field>
        </p>
        <!-- <p>
          <mat-form-field appearance="fill">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role" (selectionChange)="assignRole($event.value)">
              <mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
            </mat-select>
          </mat-form-field>
        </p> -->
      </form>
    </mat-card-content>

    <mat-card-actions>
      <button mat-raised-button color="primary" [disabled]="addUserForm.invalid" (click)="addUser()">Add User</button>
    </mat-card-actions>

  </mat-card>
  
  
  
  
  
  </div>