import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppConfigService } from '../../app-config/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'symbiota2-kibana-dashboard-link',
  templateUrl: './kibana-dashboard-link.component.html',
  styleUrls: ['./kibana-dashboard-link.component.scss']
})
export class KibanaDashboardLinkComponent {
    @Input() term = '';
    @Input() keyword: string;
    @Input() imageDashboardEnabled: boolean = true;
    @Input() spatialModuleDashboardEnabled: boolean = true;
    @Input() collectionStatsDashboardEnabled: boolean = true;

    imageDashboardUrl: string = ''
    @Output() spatialModuleDashboardUrl: string = ''
    collectionStatsDashboardUrl: string = ''

    constructor(
        private readonly appConfig: AppConfigService,
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        public sanitizer: DomSanitizer,
        private readonly translate: TranslateService) {
        this.imageDashboardUrl = appConfig.kibanaServer() + ":"
            + appConfig.kibanaPort() + appConfig.kibanaDashboardImageSearch()
        this.collectionStatsDashboardUrl = appConfig.kibanaServer() + ":"
            + appConfig.kibanaPort() + appConfig.kibanaDashboardCollectionStats()
        this.spatialModuleDashboardUrl = appConfig.kibanaServer() + ":"
            + appConfig.kibanaPort() + appConfig.kibanaDashboardSpatialModule()
    }

    ngOnInit() {
        console.log("nginit")
        const options = "?_a=(filters:!(),query:(language:kuery,query:'"
            + this.keyword + ":\""
            + this.term + "\"'))"
        this.imageDashboardUrl += options
        this.collectionStatsDashboardUrl += options
        this.spatialModuleDashboardUrl += options
    }

    openMyMenu(menuTrigger: MatMenuTrigger) {
        menuTrigger.openMenu();
    }

    _toolTipCollection = [];
    nToolTip(tooltip, _url?: string): void {
        if (tooltip.isOpen()) {
            if (_url) {
                window.open(_url, '_blank');
            }
        }
        for (const tooltip of this._toolTipCollection) {
            tooltip.close();
        }
        this._toolTipCollection = [];

        tooltip.toggle();
        this._toolTipCollection.push(tooltip);
    }

}
