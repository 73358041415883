<mat-form-field>
    <input matInput
           [placeholder]="placeholder"
           [matDatepicker]="datePicker"
           [ngModel]="value"
           (ngModelChange)="valueChange.emit($event)"
    >
    <mat-datepicker-toggle matSuffix [for]="datePicker">
    </mat-datepicker-toggle>
    <mat-datepicker #datePicker [startView]="startView">
    </mat-datepicker>
</mat-form-field>
