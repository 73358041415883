import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    TaxonomicAuthorityService,
    TaxonomicEnumTreeService, TaxonomicUnitService,
    TaxonService
} from '../../../../../ui-plugin-taxonomy/src/lib/services';
import { TaxonIDAndNameItem, TaxonomicAuthorityListItem } from '../../../../../ui-plugin-taxonomy/src/lib/dto';
import { ImageAndTaxonListItem } from '../../dto';

@Component({
    selector: 'image-library',
    templateUrl: './image-library-page.html',
    styleUrls: ['./image-library-page.component.scss'],
})

export class ImageLibraryPageComponent implements OnInit {
    names: TaxonIDAndNameItem[]
    names2: TaxonIDAndNameItem[]
    taxonID: string
    descendant: number
    level: string = 'Family'
    prefix: string = null
    MAX_LIMIT = 10000  // Max limit on size of list
    DISPLAY_SIZE = 2000
    taxonomicAuthorityList : TaxonomicAuthorityListItem[] = []
    taxonomicAuthorityID = 1 // Default taxa authority is set in nginit

    countOfNames = 0
    page = 0
    size = this.DISPLAY_SIZE
    pageSizeOptions = [this.DISPLAY_SIZE]

    ranksNameLookup

    constructor(
        private readonly taxonService: TaxonService,
        private readonly taxonomicUnitService: TaxonomicUnitService,
        private readonly taxonomicEnumTreeService: TaxonomicEnumTreeService,
        private readonly taxonomicAuthorityService: TaxonomicAuthorityService,
        private router: Router,
        private formBuilder: FormBuilder,
        private currentRoute: ActivatedRoute
    ) { }

    /*
    Called when Angular starts
     */
    ngOnInit() {
        this.descendant = null
        this.level= 'Family'
        this.prefix = null
        this.names = []
        this.currentRoute.paramMap.subscribe(params => {
            const maybeLevel = params.get('level')
            if (maybeLevel) {
                if (maybeLevel == 'Genus' || maybeLevel == 'Family') {
                    this.level = maybeLevel
                } else if (maybeLevel == ':level') {
                    this.level = 'Family'
                } else {
                    if (isNaN(Number(maybeLevel))) {
                        this.prefix = maybeLevel
                        this.level = 'Species'
                    } else {
                        // Load the descendents of the passed taxonID
                        this.level = 'Species'
                        this.descendant = Number(maybeLevel)
                    }
                }

            }
            // Load the authorities
            this.loadAuthorities()

            this.taxonomicUnitService.findAll().subscribe((units) => {
                this.ranksNameLookup = new Map()
                units.forEach((unit) => {
                    const key = unit.rankID + unit.kingdomName
                    this.ranksNameLookup.set(unit.rankName, unit.rankID)
                })
                this.loadNames(this.ranksNameLookup.get(this.level), null, this.prefix, this.descendant)
            })
        })
        // Load the names, comes preloaded with families
        //this.loadNames(this.level, this.prefix, this.descendant)
    }

    /*
    Used in the pagination
    */
    getData(obj) {
        let index=0
        let startingIndex = 0
        let endingIndex = this.size
        if (obj) {
            startingIndex= obj.pageIndex * obj.pageSize
            endingIndex= startingIndex + obj.pageSize
        }


        const currentLength = this.names2.length
        if (startingIndex >= currentLength || endingIndex >= currentLength) {
            // Get more data
            const prefix = this.prefix
            const partialName = prefix ? prefix : ''
            this.taxonService.findScientificNames(partialName, this.ranksNameLookup.get(this.level), null,  this.taxonomicAuthorityID, currentLength, this.MAX_LIMIT).subscribe((myNames) => {
                myNames = myNames.sort((a,b) =>
                    this.sortTaxa(a.name,b.name)) //a.name > b.name ? 1 : -1)
                this.names2 = this.names2.concat(myNames)
                this.names = this.names2.filter(() => {
                    index++;
                    return (index > startingIndex && index <= endingIndex) ? true : false
                })
            })

        } else {
            this.names = this.names2.filter(() => {
                index++;
                return (index > startingIndex && index <= endingIndex) ? true : false
            })
        }

    }

    /*
    Load the taxa authorities
    */
    public loadAuthorities() {
        this.taxonomicAuthorityService.findAll()
            .subscribe((authorities) => {
                this.taxonomicAuthorityList = authorities
                this.taxonomicAuthorityList.sort(function (a, b) {
                    return (a.id > b.id ? 1 : -1)
                })
                this.taxonomicAuthorityList.forEach((authority) => {
                    if (authority.isPrimary) {
                        this.taxonomicAuthorityID = authority.id
                    }
                })
            })
    }

    /*
    Load the names at this level
     */
    loadNames(rankID : number, kingdomName : string, prefix: string, descendant: number) {
        const partialName = prefix ? prefix : ''
        if (descendant) {
            this.loadDescendants(descendant)
        }
        else {
            this.taxonService.findCountScientificNames(partialName, rankID, kingdomName, this.taxonomicAuthorityID).subscribe((count) => {
                this.countOfNames = count.valueOf()
            })
            this.taxonService.findScientificNames(partialName,rankID,kingdomName,  this.taxonomicAuthorityID, 0, this.MAX_LIMIT).subscribe((myNames) => {
                this.names2 = myNames.sort((a,b) =>
                    this.sortTaxa(a.name,b.name)) //a.name > b.name ? 1 : -1)
                let index = 0
                this.names = this.names2.filter(() => {
                    index++;
                    return (index > 0 && index <= this.DISPLAY_SIZE) ? true : false
                })
            })
        }

        this.prefix = null
    }

    sortTaxa(a : String, b : String) {
        if (a .startsWith('(') || a.startsWith('"')) {
            a = a.substring(1)
        }
        if (b.startsWith('(') || b.startsWith('"')) {
            b = b.substring(1)
        }
        return a > b ? 1 : -1
    }

    loadDescendants(taxonID: number) {
        this.taxonomicEnumTreeService.findDescendantsByRank(taxonID, this.ranksNameLookup.get("Species"), 10000).subscribe((myEnumTrees) => {
            const myNames = []
            myEnumTrees.forEach((enumTree) => {
                const name = enumTree.taxon.scientificName
                const taxonID = enumTree.taxonID
                const pair = new TaxonIDAndNameItem()
                pair.name = name
                pair.id = taxonID
                myNames.push(pair)
            })
            this.countOfNames = myNames.length
            this.names = myNames.sort((a,b) =>
                a.name > b.name ? 1 : -1)
        })
        this.prefix = null
    }
}
