<symbiota2-expansion-panel label="{{taxon?.scientificName}}">
  <button *ngIf="userCanEdit" style="float: right" mat-icon-button>
    <mat-icon color="primary" (click)="openDialog('Update',taxon)"
      >edit</mat-icon
    >
  </button>
  <div>
      <b>
          <symbiota2-editable-text key='taxon.taxon.editor.kingdomName'></symbiota2-editable-text>
          {{"" | translate}}
      </b>:
    {{taxon?.kingdomName}}
  </div>
  <div>
      <b>
          <symbiota2-editable-text key='taxon.taxon.editor.rankName'></symbiota2-editable-text>
      </b>: {{rankName}}
  </div>
    <div>
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.unitName1'></symbiota2-editable-text>
    </b>:
    {{taxon?.scientificName?.split(' ')[0]}}
  </div>
  <div>
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.unitName2'></symbiota2-editable-text>
    </b>:
    {{taxon?.scientificName?.split(' ')[1]}}
  </div>
  <div>
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.unitName3'></symbiota2-editable-text>
    </b>:
    {{taxon?.scientificName?.split(' ')[2]}}
  </div>
  <div>
      <b>
          <symbiota2-editable-text key='taxon.taxon.editor.author'></symbiota2-editable-text>
      </b>: {{taxon?.author}}
  </div>
  <div>
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.phyloSortSequence'></symbiota2-editable-text>
    </b>:
    {{taxon?.phyloSortSequence}}
  </div>
  <div>
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.status'></symbiota2-editable-text>
     </b>: {{taxon?.status}}
  </div>
  <div>
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.source'></symbiota2-editable-text>
    </b>: {{taxon?.source}}
  </div>
  <div>
      <b>
          <symbiota2-editable-text key='taxon.taxon.editor.notes'></symbiota2-editable-text>
      </b>: {{taxon?.notes}}</div>
  <div>
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.hybrid'></symbiota2-editable-text>
    </b>: {{taxon?.hybrid}}
  </div>
  <div *ngIf="taxon?.securityStatus">
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.securityStatus'></symbiota2-editable-text>
    </b>:
      <symbiota2-editable-text key='taxon.taxon.editor.locality.hidden'></symbiota2-editable-text>
  </div>
  <div *ngIf="!taxon?.securityStatus">
    <b>
        <symbiota2-editable-text key='taxon.taxon.editor.securityStatus'></symbiota2-editable-text>
    </b>:
      <symbiota2-editable-text key='taxon.taxon.editor.locality.public'></symbiota2-editable-text>
  </div>
</symbiota2-expansion-panel>
