/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {

    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { ApiUserRoleName } from '@symbiota2/data-access';
import { CollectionService } from '@symbiota2/ui-plugin-collection';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CollectionRoleAsyncValidators {    
    static userHasRole(collections: CollectionService): AsyncValidatorFn {
        return (group: AbstractControl): Observable<ValidationErrors> | null => {
            // optional, since using intermediate table for storing uids
            const uid: number = group.get('user').value;
            const role: ApiUserRoleName = group.get('role').value;

            return collections
                .doesRoleExist(undefined, role)
                .pipe(
                    map((result: boolean) => result?({userHasRole : result}): null)
                );
        };
    }
}
