import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { User, UserService, ROUTE_USER_LIST } from '@symbiota2/ui-common';
import { RoleOutputDto, UserOutputDto } from '@symbiota2/api-auth';
import { UserPanel } from './userpanel-data';

import { MatCell, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDataDialogComponent } from '../../components/delete-data-dialog/delete-data-dialog.component';
import { Router } from '@angular/router';
import { ApiUserRoleName } from '@symbiota2/data-access';

@Component({
  selector: 'symbiota2-userlist-page',
  templateUrl: './userlist-page.component.html',
  styleUrls: ['./userlist-page.component.scss'],
})

export class UserlistPageComponent implements OnInit {
  currentUser$ = this.userService.currentUser;
  user: User;
  isSuperAdmin = false;
  userList: UserOutputDto[];
  userPerms: RoleOutputDto[][];
  userPanelList: UserPanel[];
  canDeleteUser = false;
  userData: UserData[]
  row: any;


  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    public dialog: MatDialog,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  displayedColumns: string[] = ['uid', 'firstName', 'lastName', 'username', 'roles', 'actions'];
  dataSource: MatTableDataSource<UserData>

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatCell) mat_cell: QueryList<MatCell>;


  ngOnInit(): void {
    this.userPerms = [];
    this.userPanelList = [];
    this.userData = [];
    //Get user list and load data only they are a superadmin
    //Check if superAdmin
    this.currentUser$.subscribe(user => {
      this.user = user;
      this.isSuperAdmin = user.userRoles(ApiUserRoleName.SUPER_ADMIN).length > 0;
      if (this.user && this.isSuperAdmin) {
        const userList$ = this.userService.getUsers();
        userList$.subscribe(userList => {
          this.userList = userList;
          userList.forEach(async (user) => {
            let userRoles = [];
            userRoles = user.roles;
            //const currUserPerms$ = this.userService.getUserRoleById(user.uid);
            // console.log('currUserPerms: ', currUserPerms$)
            // currUserPerms$.subscribe(currUserRoles => {
              this.userPerms.push(userRoles)
              const currPanel = new UserPanel(user, userRoles);
              this.userPanelList.push(currPanel);
              this.userData.push({
                uid: user.uid,
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username,
                roles:  userRoles[0]?.name ? userRoles.map(role => role.name) : null
              })

              if (userRoles[0]?.name === 'SuperAdmin' && this.user.uid === user.uid) {
                this.canDeleteUser = false;
              } else if (userRoles[0]?.name === 'SuperAdmin') {
                this.canDeleteUser = true;
              }
              this.dataSource = new MatTableDataSource<UserData>(this.userData)
              this.dataSource.paginator = this.paginator;
            // })
          });
        })
      }
    });

  }
  


  deleteUser(uid: number): void {
    const dialogRef = this.dialog.open(DeleteDataDialogComponent, {data: 'user'});

    dialogRef.afterClosed().subscribe(result => {
      if (result === "true") {
        this.userService.deleteUser(uid).subscribe(() => {
          this.router.navigate([ROUTE_USER_LIST])
        })
      }
    })
  }


}


export interface UserData {
  uid: number;
  firstName: string;
  lastName: string;
  username: string;
  roles: string[];
  actions?: {edit: string, delete: string}
}