<div id='main'>
    <div>
        <h1>
            <symbiota2-editable-text key='taxonomy.upload.complete.title'></symbiota2-editable-text>
        </h1>
    </div>

    <button
        mat-flat-button color='primary'
        (click)='onSubmit()'
    >
        {{"taxonomy.uploader.complete.button" | translate}}
    </button>
    <symbiota2-editable-text key='taxonomy.uploader.complete.button' hide='true'></symbiota2-editable-text>

    <div *ngIf='done'>
        <div *ngIf="someSkipped; else noProblems">
            <symbiota2-expansion-panel
                *ngIf="skippedTaxonsDueToMissingName.length > 0"
                expanded=false
                label='{{"taxonomy.uploader.complete.taxons.missing.name" | translate}}'>
                <symbiota2-editable-text key='taxonomy.uploader.complete.taxons.missing.name' hide='true'></symbiota2-editable-text>
                <pre>
            {{skippedTaxonsDueToMissingName}}
        </pre>
            </symbiota2-expansion-panel>
            <symbiota2-expansion-panel
                *ngIf="skippedTaxonsDueToMismatchRank.length > 0"
                expanded=false
                label='{{"taxonomy.uploader.complete.taxons.mismatch.rank" | translate}}'>
                <symbiota2-editable-text key='taxonomy.uploader.complete.taxons.mismatch.rank' hide='true'></symbiota2-editable-text>

                <pre>
            {{skippedTaxonsDueToMismatchRank}}
        </pre>
            </symbiota2-expansion-panel>
            <symbiota2-expansion-panel
                *ngIf="skippedTaxonsDueToMultipleMatch.length > 0"
                expanded=false
                label='{{"taxonomy.uploader.complete.taxons.multiple.matches" | translate}}'>
                <symbiota2-editable-text key='taxonomy.uploader.complete.taxons.multiple.matches' hide='true'></symbiota2-editable-text>

                <pre>
            {{skippedTaxonsDueToMultipleMatch}}
        </pre>
            </symbiota2-expansion-panel>
            <symbiota2-expansion-panel
                *ngIf="skippedStatusesDueToMultipleMatch.length > 0"
                expanded=false
                label='{{"taxonomy.uploader.complete.statuses.multiple.matches" | translate}}'>
                <symbiota2-editable-text key='taxonomy.uploader.complete.statuses.multiple.matches' hide='true'></symbiota2-editable-text>

                <pre>
            {{skippedStatusesDueToMultipleMatch}}
        </pre>
            </symbiota2-expansion-panel>
            <symbiota2-expansion-panel
                *ngIf="skippedStatusesDueToAcceptedMismatch.length > 0"
                expanded=false
                label='{{"taxonomy.uploader.complete.statuses.accepted.mismatches" | translate}}'>
                <symbiota2-editable-text key='taxonomy.uploader.complete.statuses.accepted.mismatches' hide='true'></symbiota2-editable-text>

                <pre>
            {{skippedStatusesDueToAcceptedMismatch}}
        </pre>
            </symbiota2-expansion-panel>
            <symbiota2-expansion-panel
                *ngIf="skippedStatusesDueToParentMismatch.length > 0"
                expanded=false
                label='{{"taxonomy.uploader.complete.statuses.parent.mismatches" | translate}}'>
                <symbiota2-editable-text key='taxonomy.uploader.complete.statuses.parent.mismatches' hide='true'></symbiota2-editable-text>

                <pre>
            {{skippedStatusesDueToParentMismatch}}
        </pre>
            </symbiota2-expansion-panel>
            <symbiota2-expansion-panel
                *ngIf="skippedStatusesDueToTaxonMismatch.length > 0"
                expanded=false
                label='{{"taxonomy.uploader.complete.statuses.taxon.mismatches" | translate}}'>
                <symbiota2-editable-text key='taxonomy.uploader.complete.statuses.taxon.mismatches' hide='true'></symbiota2-editable-text>

                <pre>
            {{skippedStatusesDueToTaxonMismatch}}
        </pre>
            </symbiota2-expansion-panel>

        </div>
        <ng-template #noProblems>
            <symbiota2-editable-text key='taxonomy.uploader.complete.all.processed'></symbiota2-editable-text>
        </ng-template>
    </div>

</div>
