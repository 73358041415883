/// <reference path="../../../../../../node_modules/@types/d3/index.d.ts" />

import {
    Component,
    EventEmitter,
    OnInit,
    Input,
    Output,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild
} from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ApiClientService, UserService } from '@symbiota2/ui-common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import * as d3 from 'd3';


@Component({
    selector: 'basic-graph',
    templateUrl: './basic-graph-page.html',
    styleUrls: ['./basic-graph-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BasicGraphPageComponent implements OnInit {
    private data = [
        {"Framework": "Vue", "Stars": "166443", "Released": "2014"},
        {"Framework": "React", "Stars": "150793", "Released": "2013"},
        {"Framework": "Angular", "Stars": "62342", "Released": "2016"},
        {"Framework": "Backbone", "Stars": "27647", "Released": "2010"},
        {"Framework": "Ember", "Stars": "21471", "Released": "2011"},
    ];
    private svg: any;
    private margin = 50;
    private width = 750 - (this.margin * 2);
    private height = 400 - (this.margin * 2);

    taxonID: string
    nodeDataArray: [
        { key: 'Alpha', color: 'lightblue' },
        { key: 'Beta', color: 'orange' },
        { key: 'Gamma', color: 'lightgreen' },
        { key: 'Delta', color: 'pink' }
    ]


    @Output()
    public onInspectorChange: EventEmitter<any> = new EventEmitter<any>();


    constructor(
        private readonly alertService: AlertService,
        private router: Router,
        private formBuilder: FormBuilder,
        private readonly translate: TranslateService,
        public dialog: MatDialog,
        private readonly apiClient: ApiClientService,
        private currentRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) { }

    /*
    Called when Angular starts
    */
    ngOnInit() {

        this.createSvg();
        this.drawBars(this.data);
        /*
        this.currentRoute.paramMap.subscribe(params => {
            this.taxonID = params.get('taxonID')
            // Load the profile
            this.loadGraph(parseInt(this.taxonID))
        })

         */
    }

    private createSvg(): void {
        this.svg = d3.select("figure#bar")
            .append("svg")
            .attr("width", this.width + (this.margin * 2))
            .attr("height", this.height + (this.margin * 2))
            .append("g")
            .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
    }

    private drawBars(data: any[]): void {
        // Create the X-axis band scale
        const x = d3.scaleBand()
            .range([0, this.width])
            .domain(data.map(d => d.Framework))
            .padding(0.2);

        // Draw the X-axis on the DOM
        this.svg.append("g")
            .attr("transform", "translate(0," + this.height + ")")
            .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "end");

        // Create the Y-axis band scale
        const y = d3.scaleLinear()
            .domain([0, 200000])
            .range([this.height, 0]);

        // Draw the Y-axis on the DOM
        this.svg.append("g")
            .call(d3.axisLeft(y));

        // Create and fill the bars
        this.svg.selectAll("bars")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d: any) => x(d.Framework))
            .attr("y", (d: any) => y(d.Stars))
            .attr("width", x.bandwidth())
            .attr("height", (d: any) => this.height - y(d.Stars))
            .attr("fill", "#d04a35");
    }

    /*
    Load the image details
    */
    loadGraph(taxonID: number) {
     }

    public onInputChange(propAndValObj: any) {
        this.onInspectorChange.emit(propAndValObj)
    }

    initDiagram() {

    }


    /*
    Internal routine to encapsulate the show error message at the bottom in case something goes awry
    */
    private showError(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showError(r)
        })
    }

    /*
    Internal routine to encapsulate the show message at the bottom to confirm things actually happened
    */
    private showMessage(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showMessage(r)
        })
    }

}
