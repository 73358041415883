<button class='kibana-mat-icon-button'
        #menuTrigger1="matMenuTrigger"
        (mouseover)="openMyMenu(menuTrigger1)"
        [matMenuTriggerFor]="myMenu1">Dashboards</button>

<!--
<mat-icon class="icon-display"
          #menuTrigger1="matMenuTrigger"
          [ngStyle]="{'color':'blue'}"
          [matMenuTriggerFor]="myMenu1"
          (mouseover)="openMyMenu(menuTrigger1)">explore</mat-icon>
-->

<mat-menu  #myMenu1="matMenu">
    <ng-template matMenuContent>
        <a style="padding-left:10px; margin: 5px;"
           href='{{spatialModuleDashboardUrl}}'
           *ngIf='spatialModuleDashboardEnabled'
           rel="noopener noreferrer"
           target="_blank"
        >{{'plugins.occurrence.spatialModuleDashboard.short.link' | translate}}</a>
        <br/>
        <a style="padding-left:10px; margin: 5px;"
           href='{{imageDashboardUrl}}'
           *ngIf='imageDashboardEnabled'
           rel="noopener noreferrer"
           target="_blank"
        >{{'core.layout.header.topnav.image_search_dashboard_short_link' | translate}}</a>
        <br/>
        <a style="padding-left:10px; margin: 5px;"
           href='{{collectionStatsDashboardUrl}}'
           *ngIf='collectionStatsDashboardEnabled'
           rel="noopener noreferrer"
           target="_blank"
        >{{'core.layout.header.topnav.collection.statistics.dashboard_quick_link' | translate}}</a>
    </ng-template>
</mat-menu>
