import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertService, UserService } from "@symbiota2/ui-common";
import { PermissionRequestDto } from "@symbiota2/ui-common";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

@Component({
  selector: 'symbiota2-permission-request-form',
  templateUrl: 'permission-request-form.html',
  styleUrls: ['permission-request-form.scss']
})
export class PermissionRequestFormComponent {
    FC_SUBJECT = 'subject';
    FC_MESSAGE = 'message';

    constructor(
        private userService: UserService,
        private alertService: AlertService
    ) {}
  
    public reqPermissionFormGroup = new FormGroup({
        [this.FC_SUBJECT]: new FormControl('', [Validators.required]),
        [this.FC_MESSAGE]: new FormControl('', [Validators.required])
    })
    
    async onRequestPermission() {
        const data =  {
            subject: this.reqPermissionFormGroup.get(this.FC_SUBJECT).value,
            body: this.reqPermissionFormGroup.get(this.FC_MESSAGE).value
        }

        // save request to db

        // send request to portal manager
        this.userService.requestPermission(data).pipe(
            catchError((e: HttpErrorResponse) => {
                this.alertService.showError(e.message)
                return of(false)
            })
        )
        .subscribe((data) => {
            if (data) {
                this.alertService.showMessage('Request sent successfully.')
            }
        })
    }
}
