export const ROUTE_SITEMAP = 'sitemap';
export const ROUTE_USER_LIST = 'userlist';
export const ROUTE_USER_PROFILE = 'viewprofile';
export const ROUTE_ADD_USER = 'adduser';
export const ROUTE_UPDATE_USER = 'user/:id/edit';
export const ROUTE_USER_CREATE = 'createprofile';
export const ROUTE_FORGOT_PASSWORD = 'forgotpassword';
export const ROUTE_FORGOT_USERNAME = 'forgotusername';
export const ROUTE_ROLES_LIST = 'roles';
export const ROUTE_PERMISSION_LIST = 'permissions';
export const ROUTE_ROLE_CREATE = ROUTE_ROLES_LIST + '/new';
export const ROUTE_ROLE_UPDATE = ROUTE_ROLES_LIST + '/:id/edit';
export const ROUTE_ROLES_REQUESTED = ROUTE_ROLES_LIST + '/requested';
export const ROUTE_PERMISSION = 'permission';
export const ROUTE_PERMISSION_CREATE = ROUTE_PERMISSION_LIST + '/new';
export const ROUTE_PERMISSION_UPDATE = ROUTE_PERMISSION_LIST + '/:id/edit';