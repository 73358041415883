<div id="listdiv">
    <ul>
        <h2>{{categoryTitle | translate}}</h2>
        <ng-container *ngFor="let categoryLink of categoryLinks">
            <li>
                <a href="{{categoryLink.url}}">{{categoryLink.name | translate}}</a>
            </li>
        </ng-container>
    </ul>

</div>