<div id="main">

    <div *ngIf="!userCanEdit" class="my2" fxLayout="row" fxLayoutGap="1rem">
      {{"checklist.access.lacks.create.permission" | translate}}
    </div>
    <div *ngIf="userCanEdit && router.url !== '/checklist/create/terminology'" class="my2" fxLayout="row" fxLayoutGap="1rem">
      <!-- <div class="my2" fxLayout="row" fxLayoutGap="1rem"> -->
        <div *ngIf="router.url.includes('inventory')">
          <h1>{{ "checklist.create.form.checklist.inventory.checklist.title" | translate }}</h1>
        </div>
        <div *ngIf="router.url.includes('teaching')">
          <h1>{{ "checklist.create.form.checklist.teaching.checklist.title" | translate }}</h1>
        </div>
        <div *ngIf="router.url.includes('rare')">
          <h1>{{ "checklist.create.form.checklist.rare.checklist.title" | translate }}</h1>
        </div>
        <div mat-dialog-content>
        <form class="example-form" [formGroup]="checklistCreateForm">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.name" | translate }}</mat-label>
            <input type="text" matInput formControlName="name" [errorStateMatcher]="matcher"
                   [placeholder]="'checklist.create.form.checklist.name' | translate ">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.authors" | translate }}</mat-label>
            <input type="text" matInput formControlName="authors" [errorStateMatcher]="matcher"
                   [placeholder]="'checklist.create.form.checklist.authors' | translate ">
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.locality" | translate }}</mat-label>
            <input type="text" matInput formControlName="locality" [errorStateMatcher]="matcher"
                   [placeholder]="'checklist.create.form.checklist.locality' | translate ">
          </mat-form-field>
          <!-- <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.citation" | translate }}</mat-label>
            <input type="text" matInput formControlName="citation" [errorStateMatcher]="matcher"
                   [placeholder]="'checklist.create.form.checklist.citation' | translate ">
          </mat-form-field> -->
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.abstract" | translate }}</mat-label>
            <textarea type="text" matInput formControlName="abstract" [errorStateMatcher]="matcher"
                   [placeholder]="'checklist.create.form.checklist.abstract' | translate "></textarea>
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.notes" | translate }}</mat-label>
            <input type="text" matInput formControlName="notes" [errorStateMatcher]="matcher"
                   [placeholder]="'checklist.create.form.checklist.notes' | translate ">
          </mat-form-field>
          <mat-form-field style="width:auto" class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.reference" | translate }}</mat-label>
            <mat-select>
              <mat-option [value]="">
                {{'make it dynamic'}}
              </mat-option>
              
            </mat-select>
          </mat-form-field> <br>
          <mat-form-field style="width:12%;margin-right: 2%">
            <mat-label>{{ "checklist.create.form.checklist.lat" | translate }}</mat-label>
            <input matInput formControlName="latCentroid" [attr.disabled]="disabled">
          </mat-form-field>
          <mat-form-field style="width:12%;margin-right: 0.5%">
            <mat-label>{{ "checklist.create.form.checklist.lon" | translate }}</mat-label>
            <input matInput formControlName="longCentroid" [attr.disabled]="disabled">
          </mat-form-field>
          <img mat-card-sm-image style="height: 1rem;width:auto;cursor:pointer;margin-top:2rem;box-shadow:0 0 2px 4px 0.0.0.0.3;margin-right:2%" 
                 src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Globe.svg" >
          <mat-form-field style="width:20%;">
            <mat-label>{{ "checklist.create.form.checklist.point" | translate }}</mat-label>
            <input matInput formControlName="pointRadiusMeters" [attr.disabled]="disabled">
          </mat-form-field>

          <mat-card class="example-card" style="width: 40%;float:right;margin-top:-2rem">
            <mat-card-title-group>
              <mat-card-title>{{ "checklist.create.form.checklist.polygon.title" | translate }}</mat-card-title>
              <!-- <mat-card-subtitle>Small</mat-card-subtitle> -->
              <img mat-card-sm-image style="height: 1.4rem;width:auto;cursor:pointer;box-shadow:0 0 2px 4px 0.0.0.0.3" src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Globe.svg" >
            </mat-card-title-group>
            <mat-card-content>
              {{ "checklist.create.form.checklist.polygon.description" | translate }}
            </mat-card-content>
          </mat-card><br><br>

          <section class="example-section">
            <h4>{{ "checklist.create.form.checklist.display.settings" | translate }}</h4>
            <!-- <ng-container *ngFor="let item of defaultSettings">
              <mat-checkbox (change)="item.value = $event.checked" 
              (change)="defaultSettingsToggle($event.checked)" 
              [checked]="item.value">{{ item.key }}</mat-checkbox><br>
            </ng-container> -->
            
            <mat-checkbox 
            (change)="$event.checked === true ? 
            defaultSettings.ddetails = 1 : defaultSettings.ddetails = 0">Show details</mat-checkbox><br>
            <mat-checkbox 
            (change)="$event.checked === true ? 
            defaultSettings.dcommon = 1 : defaultSettings.dcommon = 0">Display common names</mat-checkbox><br>
            <mat-checkbox 
            (change)="$event.checked === true ? 
            defaultSettings.dimages = 1 : defaultSettings.dimages = 0">Display as images</mat-checkbox><br>
            <mat-checkbox 
            (change)="$event.checked === true ? 
            defaultSettings.dvouchers = 1 : defaultSettings.dvouchers = 0">Show notes & vouchers</mat-checkbox><br>
            <mat-checkbox 
            (change)="$event.checked === true ? 
            defaultSettings.dauthors = 1 : defaultSettings.dauthors = 0">Dislay taxon authors</mat-checkbox><br>
            <mat-checkbox 
            (change)="$event.checked === true ? 
            defaultSettings.dalpha = 1 : defaultSettings.dalpha = 0">Display taxa alphabetically</mat-checkbox><br>
          </section><br>

          <mat-form-field style="width: auto;" class="example-full-width" appearance="fill">
            <mat-label>{{ "checklist.create.form.checklist.access" | translate }}</mat-label>
            <mat-select style="height: 1rem" formControlName="access">
              <mat-option value="private" selected>
                {{'Private'}}
              </mat-option>
              
              <mat-option value="public">
                {{'Public'}}
              </mat-option>
            </mat-select>
          </mat-form-field> <br>
          <button mat-raised-button color="primary" (click)="onCreateChecklist()">{{ "checklist.create.form.checklist.submit" | translate }}</button>
          <div class="successMessage" *ngIf="hasSuccess" style="margin-top: 10px">
            {{ successMessage }}
          </div>
          <div *ngIf="hasError" class="errorMessage" style="margin-top: 10px">
            {{ errorMessage | json }}
          </div>
        </form>
          <hr style="margin: 2rem 0; border-top: 1px solid rgb(235, 235, 235)">

          <mat-card class="example-card">
            <mat-card-title-group>
              <mat-card-title>{{ "checklist.create.form.checklist.checklists.assigned" | translate }}</mat-card-title>
            </mat-card-title-group>
            <mat-card-content>
              <mat-list role="list">
                <mat-list-item role="listitem">Pakistan, Balochistan, Quetta District</mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card><br>

          <mat-card class="example-card">
            <mat-card-title-group>
              <mat-card-title>{{ "checklist.create.form.checklist.inventory.project.administration" | translate }}</mat-card-title>
            </mat-card-title-group>
            <mat-card-content>
              <mat-list role="list">
                <mat-list-item role="listitem">There are no Projects for which you have administrative permissions</mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
    </div>
  </div>

  <div *ngIf="userCanEdit && router.url === '/checklist/create/terminology'">
    <h1>Sorry, you can't create a terminology checklist yet!</h1>
</div>
