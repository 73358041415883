<h2 mat-dialog-title>Requet Permission Form</h2>

<div mat-dialog-content>
    <form [formGroup]="reqPermissionFormGroup" style="width:30rem;height: 60vh;">
        <div>
            <mat-form-field>
                <mat-label>
                    {{ "core.user.user_metadata.request_permission.subject" | translate }}
                </mat-label>
                <input
                matInput
                required
                    [formControlName]="FC_SUBJECT"
                >
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label> 
                    {{ "core.user.user_metadata.request_permission.message" | translate }}
                </mat-label>
                <textarea matInput
                required
                [formControlName]="FC_MESSAGE">
                
            </textarea>
            </mat-form-field>
        </div>
        <div id="edit-btns">
            <button
            mat-raised-button
            mat-dialog-close
            mat-dialog-close="true"
            color="primary"
            [disabled]="!reqPermissionFormGroup.valid"
                (click)="onRequestPermission()"
                >
                {{ "core.user.user_metadata.request_permission.button" | translate }}
            </button>
        </div>
    </form>
</div>