<!-- Navbar -->
<symbiota2-navbar id="navbar" [ngClass]="navbarSticky ? 'sticky' : 'noSticky'">
    <mat-card id="app">
        <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
            <!-- Page display area -->
            <div id="router-outlet" fxFlex="1 1">
                <router-outlet></router-outlet>
            </div>

            <!-- Footer -->
            <div id="site-footer" fxLayout="column" fxLayoutAlign="center center">
                <app-footer></app-footer>
                <symbiota2-sitemap></symbiota2-sitemap>
            </div>
        </mat-card-content>
    </mat-card>

    <!-- Loading overlay -->
    <symbiota2-loading-overlay [isLoading]="isLoading">
    </symbiota2-loading-overlay>
</symbiota2-navbar>