<div id="main">
    <div id='btns'>
        <button *ngIf="(userRoles | async)?.includes('SuperAdmin')" mat-raised-button color='accent' [routerLink]="'/' + ROUTE_COLLECTION_NEW" >
            {{'collection.create_collection.title' | translate}}
        </button>
        <symbiota2-editable-text key='collection.create_collection.title' hide='true'></symbiota2-editable-text>
        <button mat-raised-button color='primary' (click)='onExpandCollapse(true)'>
            {{'collection.list.expand.action.label' | translate}}
        </button>
        <symbiota2-editable-text key='collection.list.expand.action.label' hide='true'></symbiota2-editable-text>
        <button mat-raised-button color='primary' (click)='onExpandCollapse(false)'>
            {{'collection.list.collapse.action.label' | translate}}
        </button>
        <symbiota2-editable-text key='collection.list.collapse.action.label' hide='true'></symbiota2-editable-text>
    </div>

    <div class="collectionStats disappear" 
    *ngIf="(userRoles | async)?.includes('SuperAdmin');">
        <div style="display:flex;justify-content: space-between">
            <select (change)="setupFilterStats($event)">
                <option value="category" [selected]="true" [disabled]="true">Filter by category</option>
                <option [defaultSelected]="true" [value]="0">All</option>
                <option *ngFor="let category of (categories | async)" [value]="category.id">{{category.category}}</option>
                </select>
            <span class="statsToggleBtn" (click)="toggleStats($event)">View stats</span>
        </div>
    
        <div *ngIf="(refreshStats | async) as stats; else loadingContent" class="statsbox">
            <p><strong>{{stats[0]?.total}}</strong> occurrences </p>
            <p><strong>{{stats[0]?.hasCoordinates}}</strong> 
                ({{(stats[0]?.hasCoordinates / stats[0]?.total) * 100 | number:'1.0-0'}}%) georeferenced </p>
            <p><strong>{{stats[0]?.family}}</strong> families </p>
            <p><strong>{{stats[0]?.genera}}</strong> genera </p>
            <p><strong>{{stats[0]?.sciName}}</strong> species</p>
        </div>
        <ng-template #loadingContent>
            <div class="statsbox"> {{ !isLoading ? 'Select the stats based on category or all.' : 'loading...'}}</div>
        </ng-template>
    </div>

    <symbiota2-expansion-panel
        class='collectionCategory'
        *ngFor='let category of (categories | async)'
        [label]='category.category'
        [expanded]='expandAll'
    >
        <div fxLayout='row wrap'>
            <symbiota2-collection-card
                *ngFor='let collection of category.collections'
                [collection]='collection'>
            </symbiota2-collection-card>
        </div>
    </symbiota2-expansion-panel>
</div>

