<div id="main">
  <div>
    <h1>
        <symbiota2-editable-text key='taxonomy.editor.entry.title'></symbiota2-editable-text>
    </h1>

  </div>

  <!-- Configure name list -->
  <div class="expansion-panel">
      <symbiota2-editable-text key='taxonomy.viewer.configure.label' hide='true'></symbiota2-editable-text>
      <symbiota2-expansion-panel
      expanded="false"
      label='{{"taxonomy.viewer.configure.label" | translate}}'
    >
      <mat-radio-group
        aria-labelledby="configure-radio-group-label"
        class="configure-radio-group"
        (change)="configureChangeAction()"
        [(ngModel)]="kindOfName"
      >
        <mat-radio-button value="Scientific">
            <symbiota2-editable-text key='taxonomy.viewer.option.scientific'></symbiota2-editable-text>
        </mat-radio-button
        >&nbsp;&nbsp;
        <mat-radio-button value="Common">
            <symbiota2-editable-text key='taxonomy.viewer.option.common'></symbiota2-editable-text>
        </mat-radio-button>
        <div>
          <br />
          <mat-form-field appearance="fill">
            <mat-label>
                <symbiota2-editable-text key='taxonomy.viewer.label.authority'></symbiota2-editable-text>
            </mat-label>
            <mat-select
              field-class="symbiota2-field-class"
              placeholder='{{"taxonomy.viewer.label.authority" | translate}}'
              [(value)]="taxonomicAuthorityID"
              (selectionChange)="authorityChangeAction()"
            >
              <mat-option
                *ngFor="let authority of taxonomicAuthorityList"
                [value]="authority.id"
                >{{ authority.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf='kindOfName == "Scientific"'>
          <mat-checkbox class="example-margin" [(ngModel)]="hasAuthors">
              <symbiota2-editable-text key='taxonomy.viewer.label.checkbox.authors'></symbiota2-editable-text>
          </mat-checkbox>
        </div>

        <div *ngIf='kindOfName == "Common"'>
          <mat-form-field appearance="fill">
            <mat-label>
                <symbiota2-editable-text key='taxonomy.viewer.label.language'></symbiota2-editable-text>
            </mat-label>
            <mat-select
              field-class="symbiota2-field-class"
              placeholder='{{"taxonomy.viewer.label.language" | translate}}'
              [(value)]="language"
              (selectionChange)="languageChangeAction(language)"
            >
              <mat-option *ngFor="let lang of languageList" [value]="lang"
                >{{ lang }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </mat-radio-group>
    </symbiota2-expansion-panel>
  </div>

  <!-- Taxonomy -->
  <div class="expansion-panel">
      <symbiota2-editable-text key='taxonomy.editor.entry.panel.label' hide='true'></symbiota2-editable-text>
      <symbiota2-expansion-panel
      label='{{"taxonomy.editor.entry.panel.label" | translate}}'
    >
      <form class="name-autocomplete-form" (ngSubmit)="onSubmit()">
        <mat-form-field class="name-autocomplete-full-width">
          <mat-label *ngIf='kindOfName == "Scientific"'>
              <symbiota2-editable-text key='taxonomy.viewer.label.name.enter.scientific'></symbiota2-editable-text>
          </mat-label>
          <mat-label *ngIf='kindOfName == "Common"'>
              <symbiota2-editable-text key='taxonomy.viewer.label.name.enter.common'></symbiota2-editable-text>
          </mat-label>
          <input
            *ngIf='kindOfName == "Scientific"'
            type="text"
            placeholder='{{"taxonomy.viewer.label.name.scientific" | translate}}'
            aria-label="String"
            matInput
            (keyup)="onKey($event)"
            [formControl]="nameControl"
            [matAutocomplete]="auto"
          />
            <input
            *ngIf='kindOfName == "Common"'
            type="text"
            placeholder='{{"taxonomy.viewer.label.name.common" | translate}}'
            aria-label="String"
            matInput
            (keyup)="onKey($event)"
            [formControl]="nameControl"
            [matAutocomplete]="auto"
          />
            <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            (optionSelected)="selectedSciname($event)"
          >
            <mat-option
              *ngFor="let option of nameOptions"
              [value]="option.name"
            >
              {{option.name}} <small>{{option.author}}</small>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-label *ngIf="looking && !nameFound" style="color: red">
          <b>
              <symbiota2-editable-text key='taxonomy.viewer.name.error'></symbiota2-editable-text>
          </b>
          <br />
        </mat-label>
      </form>
          <symbiota2-editable-text key='taxonomy.viewer.label.choose.taxon' hide='true'></symbiota2-editable-text>
          <symbiota2-expansion-panel
        *ngIf="possibleTaxons.length > 1"
        label='{{"taxonomy.viewer.label.choose.taxon" | translate}}'
      >
        <mat-selection-list #selected [multiple]="false" (selectionChange)="onSelection(selected.selectedOptions.selected[0].value)">
          <mat-list-option
            *ngFor="let txn of possibleTaxons"
            [value]="txn.taxonID"
          >
            {{txn.name}}
          </mat-list-option>
        </mat-selection-list>
      </symbiota2-expansion-panel>
    </symbiota2-expansion-panel>
  </div>
</div>
