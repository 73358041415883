export class MonitoringQueryBuilder {
    protected baseUrl: string
    protected namesUrl: URL
    protected nameUrl: URL
    protected url: URL

    constructor(apiBaseUrl: string) {
        this.baseUrl = apiBaseUrl
        this.url = new URL(apiBaseUrl + "/health")
    }

    apiUpCheck(): ApiUpCheckBuilder {
        return new ApiUpCheckBuilder(this.baseUrl)
    }

    emailUpCheck(): EmailUpCheckBuilder {
        return new EmailUpCheckBuilder(this.baseUrl)
    }

    kibanaUpCheck(): KibanaUpCheckBuilder {
        return new KibanaUpCheckBuilder(this.baseUrl)
    }

    dbServerUpCheck(): DBServerUpCheckBuilder {
        return new DBServerUpCheckBuilder(this.baseUrl)
    }

    redisServerUpCheck(): RedisServerUpCheckBuilder {
        return new RedisServerUpCheckBuilder(this.baseUrl)
    }

    storageServiceUpCheck(): StorageServiceUpCheckBuilder {
        return new StorageServiceUpCheckBuilder(this.baseUrl)
    }

    build(): string {
        return this.url.toString()
    }

}

class ApiUpCheckBuilder extends MonitoringQueryBuilder {

    build(): string {
        this.url.pathname = `${this.url.pathname}/isAPIUp`;
        return super.build();
    }

}

class DBServerUpCheckBuilder extends MonitoringQueryBuilder {

    build(): string {
        this.url.pathname = `${this.url.pathname}/isDBServerUp`;
        return super.build();
    }

}

class RedisServerUpCheckBuilder extends MonitoringQueryBuilder {

    build(): string {
        this.url.pathname = `${this.url.pathname}/isRedisServerUp`;
        return super.build();
    }

}

class KibanaUpCheckBuilder extends MonitoringQueryBuilder {

    build(): string {
        this.url.pathname = `${this.url.pathname}/isKibanaUp`;
        return super.build();
    }

}

class StorageServiceUpCheckBuilder extends MonitoringQueryBuilder {

    build(): string {
        this.url.pathname = `${this.url.pathname}/isStorageServiceUp`;
        return super.build();
    }

}

class EmailUpCheckBuilder extends MonitoringQueryBuilder {

    build(): string {
        this.url.pathname = `${this.url.pathname}/isEmailUp`;
        return super.build();
    }

}
