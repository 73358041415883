import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { mergeMap, switchMap } from 'rxjs/operators';
import {
    AlertService,
    ROUTE_FORGOT_PASSWORD, ROUTE_FORGOT_USERNAME,
    ROUTE_USER_CREATE,
    UserService
} from '@symbiota2/ui-common';
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { MonitoringService } from '../../../../../../libs/ui-common/src/lib/monitoring';

@Component({
    selector: "symbiota2-health-dialog",
    templateUrl: "./health-dialog.component.html",
    styleUrls: ['./health-dialog.component.scss']
})
export class HealthDialog implements OnInit {

    apiIsUp : boolean = true
    dbServerIsUp : boolean = false
    storageServiceIsUp : boolean = false
    emailIsUp : boolean = false
    kibanaIsUp : boolean = false
    redisIsUp : boolean = false

    constructor(
        private readonly monitoringService: MonitoringService,
        private readonly matDialogRef: MatDialogRef<HealthDialog>,
        private readonly translate: TranslateService,
        private readonly alertService: AlertService) { }

    ngOnInit() {
        // Check if API is up
        this.monitoringService.apiUpCheck().subscribe((result) => {
            // console.log("result is " + result)
            this.apiIsUp = result == 1

            // If API is up then check the other stuff
            this.monitoringService.dbServerUpCheck().subscribe((db) => {
                this.dbServerIsUp = db == 1
            })
            this.monitoringService.redisServerUpCheck().subscribe((db) => {
                this.redisIsUp = db == 1
            })
            this.monitoringService.kibanaUpCheck().subscribe((db) => {
                this.kibanaIsUp = db == 1
            })
            this.monitoringService.emailUpCheck().subscribe((db) => {
                this.emailIsUp = db == 1
            })
            this.monitoringService.storageServiceUpCheck().subscribe((db) => {
                this.storageServiceIsUp = db == 1
            })
        })

    }

    onClose() {
        this.matDialogRef.close();
    }

}
