<div fxLayout="row wrap">
    <div class="form-field w-8">
        <mat-form-field>
            <mat-label>{{'i18n.editable.text.enter' | translate}}</mat-label>
            <input matInput
                   [value]="textValue"
                   [(ngModel)]="textValue"
                   [ngModelOptions]="{standalone: true}" >
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="doAction()">{{'i18n.editable.text.save.button' | translate}}</button>
    <button mat-button (click)="closeDialog()" mat-raised-button color="warn">{{'i18n.editable.text.cancel.button' | translate}}</button>
</div>
<br/>
<div>
    <mat-checkbox class="example-margin" [(ngModel)]='translatable'
    >{{"i18n.editable.translatable.label" | translate}}</mat-checkbox>
</div>
