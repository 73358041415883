export const Q_PARAM_TAXAIDS = 'taxaid[]'
export const Q_PARAM_IDS = 'id[]'
export const Q_PARAM_IMAGE_TYPES = 'type[]'
export const Q_PARAM_IMAGE_TAGS = 'tag[]'
export const Q_PARAM_IMAGE_PHOTOGRAPHER_NAMES = 'photographer[]'
export const Q_PARAM_COUNTRIES = 'country[]'
export const Q_PARAM_PROVINCES = 'province[]'
export const Q_PARAM_KEYWORDS = 'keyword[]'
export const Q_PARAM_SCIENTIFIC_NAMES = 'sciname[]'
export const Q_PARAM_COMMON_NAMES = 'commonname[]'
export const Q_PARAM_START_DATE = 'startDate'
export const Q_PARAM_END_DATE = 'endDate'
export const Q_PARAM_LIMIT_TAXONS = 'limitTaxons'
export const Q_PARAM_LIMIT_OCCURRENCES = 'limitOccurrences'
export const Q_PARAM_LIMIT = 'limit'
export const Q_PARAM_OFFSET = 'offset'
export const Q_PARAM_IMAGEIDS = 'imageId[]'
