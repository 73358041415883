import { Component, OnInit } from "@angular/core";
import { ROUTE_USER_LIST, UserService } from "@symbiota2/ui-common";
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CreateUserData } from '../../dtos/create-user-data';
import { UserRoleValues } from "../../dtos/user-role-name";
import { passwordContainsCharClasses, passwordsMatch } from "../create-user-profile/validators";
import { Observable, of } from 'rxjs';
import { mergeAll, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";

interface Role {
    id: number,
    name: string
}
@Component({
    selector: 'symbiota2-update-user',
    templateUrl: './update-user.page.html',
    styleUrls: ['./update-user.page.scss'],
})
export class UpdateUserPage implements OnInit {
    
    uid: number;
    hasRoles: Role[] = [];
    roleId: number = null;
    roleStatus = 'current';
    roles: Role[] = [];
    defaultValue: string;
    formChange$ = false;
    roleCount = 0;

    readonly PASSWORD_MIN_CHARS = 8;

    readonly firstNameField = new FormControl('', [Validators.required]);
    readonly lastNameField = new FormControl('', [Validators.required]);
    readonly usernameField = new FormControl('', [Validators.required]);
    readonly emailAddressField = new FormControl(
        '',
        [Validators.required, Validators.email]
    );
    readonly roleField = new FormControl(
        '', [Validators.required]
    )

    readonly updateUserForm = new FormGroup({
        username: this.usernameField,
        firstName: this.firstNameField,
        lastName: this.lastNameField,
        email: this.emailAddressField,
        roles: this.roleField,
    });

    constructor(
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    async ngOnInit() {
        this.getAllRoles()
        this.uid = this.route.snapshot.params.id;
        // await this.getRoleById(this.uid);
        this.getUser(this.uid);
        this.updateUserForm.valueChanges.subscribe(() => {
            if (this.updateUserForm.controls.firstName) {
                this.formChange$ = true;
            }
        })
    }

    
    getUser(uid: number) {
        this.userService.getUserById(uid).subscribe(user => {
            if (user.roles.length > 0) {
                this.roleCount = user.roles.length;
                this.hasRoles = user.roles;
            }
            this.updateUserForm.patchValue({
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username,
                email: user.email,
                roles: this.setDefaultValue(),
            });
        })
    }
    
    // async getRoleById(uid: number) {
    //     this.userService.getUserRoleById(uid).subscribe(async roles => {
    //         if (roles) {
    //             // this.role.name = role[0]?.name;
    //             // this.role.id = role[0]?.id;
    //             roles.map(role => {
    //                 this.hasRoles.push({name: role.name, id: role.id})
    //             })
    //         }

    //         console.log('check it here now: ', this.hasRoles)
    //     });
    // }

    async getAllRoles() {
        this.userService.getAllRoles().subscribe(async roles => {
            roles.map(role => {
                this.roles.push({id: role.id, name: role.name});
            })
        })
    }

    updateUser(uid: number) {
        const {role, passwordAgain, ...userData} = this.updateUserForm.getRawValue();
        this.userService.updateUser(uid, userData)
            .pipe(
                map(() => {
                    // if (this.roleStatus === 'add') {
                    //     return this.userService.assignRole(uid, role)
                    // }
                    // else if (this.roleStatus === 'edit') {
                    //     return this.userService.updateUserRole(uid, role)
                    // } else if (this.roleStatus === 'unassign') {
                    //     return this.userService.unassignUserRole(uid)
                    // }
                    if (this.roleCount !== this.hasRoles.length) {
                        if (this.hasRoles.length > 0) {
                            const roleIds = this.hasRoles.map(role => role.id);
                            return this.userService.updateUserRoles(uid, roleIds)
                        }
                        return this.userService.unassignUserRoles(uid)
                    }
                    return of(null)
                }),
                mergeAll()
            )
            .subscribe(() => {
                this.router.navigate([ROUTE_USER_LIST])
            })
    }

    updateRole(id: number) {
        // if (this.hasRoles.length === 0) {
        //     this.roleStatus = 'add';
        //     this.displayRole(ids)
        // } else if (this.hasRoles.length > 0) {
        //     this.roleStatus = 'edit';
        //     this.displayRole(ids)
        // }
        if (!(this.hasRoles.find(role => role.id === id))) 
            this.hasRoles.push(this.roles.filter(role => role.id === id)[0])
    }

    unCheckRole(e: any, id:number) {
        const item = document.getElementById(`${id}`);
        this.hasRoles = this.hasRoles.filter(role => role.id !== id)
        item.style.display = 'none';
    }

    checkhasRoles(roles: any[], id: number) {
        return roles.find(role => role.id === id)
    }

    // displayRole(ids: number[]) {
    //     this.hasRoles = this.roles.filter(role => ids.includes(role.id));
    // }

    setDefaultValue() {
        this.defaultValue = 'update_role';
        return this.defaultValue;
    }

    unassignRoles() {
        this.roleStatus = 'unassign';
        this.hasRoles = [];
    }

    // rolesNames() {
    //     return this.hasRoles;
    // }

    // formChange() {
    //     this.updateUserForm.valueChanges.subscribe(() => {
    //         console.log('update user form ', this.updateUserForm.controls.firstName);
    //     });
    // }
}


// ()=> {
//     if (this.roleStatus === 'add') {
//         this.userService.assignRole(uid, role).subscribe(role => {
//             console.log('role assigned.')
//             this.router.navigate([ROUTE_USER_LIST])
//         })
//     }
//     else if (this.roleStatus === 'edit') {
//         this.userService.updateUserRole(uid, role).subscribe(role => {
//             console.log('role updated.')
//             this.router.navigate([ROUTE_USER_LIST])
//         })
//     } else if (this.roleStatus === 'unassign') {
//         this.userService.unassignUserRole(uid).subscribe(role => {
//             console.log('role unassigned.')
//         })
//     }
//     this.router.navigate([ROUTE_USER_LIST])
// }