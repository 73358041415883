<div id='main'>
    <div *ngIf='!data2 || data2.length == 0'>
        <h2><symbiota2-editable-text key='image.search.none.found'></symbiota2-editable-text></h2>
    </div>
    <div *ngIf='data2?.length > 0'>
        <div fxFill fxLayout="column" fxLayoutAlign="space-between">

            <mat-grid-list cols='{{data.length > 5? 5 : data.length}}' rowHeight='150px' gutterSize='5'>
                <mat-grid-tile  *ngFor="let image of data">
                    <a [routerLink]="['/' + imageDetailsRoute, image.id]" class='imagelink'>
                        <img height="100" [src]="localize(image.url)">
                    </a>
                    <mat-grid-tile-footer style='background-color:white; justify-content:center'>
                        <a [routerLink]="['/' + imageDetailsRoute, image.id]" style='text-align:center'>
                            <i style='text-align:center'>{{image.taxon?.scientificName}}</i>
                        </a>
                    </mat-grid-tile-footer>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-paginator [length]="data2.length"
                           [pageSize]="size"
                           [pageIndex]="page"
                           [pageSizeOptions]="pageSizeOptions"
                           (page)="getData($event)">
            </mat-paginator>
        </div>
    </div>
</div>
