/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
    Exclude,
    Expose,
    plainToClass,
    Transform,
    Type
} from 'class-transformer';
import {
    ApiUserProfileData,
    ApiUserRoleName
} from '@symbiota2/data-access';
import { AssignedRole, UserRole } from './user-role.class';
import { UserRoleService } from 'libs/api-auth/src/user/services/role/user-role.service';
import { Injectable, Injector, Inject  } from '@angular/core';
import { RoleOutputDto } from '@symbiota2/api-auth';

@Injectable()
export class User {

    @Expose({ name: "sub" })
    public readonly uid: number;

    @Type(() => Number)
    @Transform((iat) => new Date(iat * 1000))
    public readonly exp: Date;

    @Type(() => Number)
    @Transform((iat) => new Date(iat * 1000))
    public readonly iat: Date;

    @Type(() => UserRole)
    public readonly roles: UserRole[];

    public readonly assignedRoles: AssignedRole[];

    public readonly username: string;
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly token: string;

    /*
    public isEditing() : Observable<boolean> {
        return of(this.iAmEditing)
    }
     */

    static fromJSON(obj: Record<string, unknown>): User {
        return plainToClass(User, obj);
    }

    private static checkHasRole(roles: any[], reqRole: ApiUserRoleName, tablePk: number = null): boolean {
        const roleObjs = roles.filter(r => {
            if (tablePk !== null) {
                return r.access === reqRole && r.tablePrimaryKey === tablePk;
            }
            return r.name === reqRole;
        })
        return roleObjs.length > 0;
        // roles.map(role => {
        //     if (role instanceof UserRole) globalRoles.push(role);
        //     if (role instanceof AssignedRole) assignedRoles.push(role);
        // })
        // if (tablePk !== null) {
        //     return assignedRoles.filter((r: AssignedRole) => r.access === reqRole).length > 0;
        // }
        // console.log('here global Roles: ', globalRoles)
        // console.log('here assigned roles: ', assignedRoles)
        // return globalRoles.filter((r: UserRole) => r.name === reqRole).length > 0;
    }

    canEditCollection(id: number): boolean {
        const isGlobalEditor = this.hasRole(ApiUserRoleName.COLLECTION_EDITOR);
        const isGlobalAdmin = this.hasRole(ApiUserRoleName.COLLECTION_ADMIN);
        const isAdmin = this.hasRole(ApiUserRoleName.COLLECTION_ADMIN, id);
        const isEditor = this.hasRole(ApiUserRoleName.COLLECTION_EDITOR, id);

        // console.log('isSuperAdmin: ', this.isSuperAdmin())
        // console.log('isGlobalAdmin: ', isGlobalAdmin)
        // console.log('isGlobalEditor: ', isGlobalEditor)
        // console.log('isAssignedAdmin: ', isAdmin)
        // console.log('isAssignedEditor', isEditor)

        return this.isSuperAdmin() || isGlobalEditor || isGlobalAdmin || isAdmin || isEditor;
    }

    canAssignCollectionRole(id: number) {
        const isGlobalAdmin = this.hasRole(ApiUserRoleName.COLLECTION_ADMIN);
        const isAdmin = this.hasRole(ApiUserRoleName.COLLECTION_ADMIN, id);
        // console.log('Can assignRole: isSuperAdmin: ', this.isSuperAdmin())
        // console.log('Can assignRole: isGlobalAdmin: ', isGlobalAdmin)
        // console.log('Can assignRole: isAssignedAdmin: ', isAdmin)
        return this.isSuperAdmin() || isGlobalAdmin || isAdmin;
    }

    canEditTaxonProfile(id: number): boolean {
        const isGlobalAdmin = this.hasRole(ApiUserRoleName.TAXON_PROFILE_EDITOR);
        const isAdmin = this.hasRole(ApiUserRoleName.TAXON_PROFILE_EDITOR, id);

        return this.isSuperAdmin() || isGlobalAdmin || isAdmin;
    }

    canEditTaxon(id: number): boolean {
        const isGlobalAdmin = this.hasRole(ApiUserRoleName.TAXON_EDITOR);
        const isAdmin = this.hasRole(ApiUserRoleName.TAXON_EDITOR, id);

        return this.isSuperAdmin() || isGlobalAdmin || isAdmin;
    }

    canEditProject(id: number): boolean {
        const isGlobalAdmin = this.hasRole(ApiUserRoleName.PROJECT_ADMIN);
        const isAdmin = this.hasRole(ApiUserRoleName.PROJECT_ADMIN, id);

        return this.isSuperAdmin() || isGlobalAdmin || isAdmin;
    }

    canEditChecklist(id: number): boolean {
        const isGlobalAdmin = this.hasRole(ApiUserRoleName.CHECKLIST_ADMIN);
        const isAdmin = this.hasRole(ApiUserRoleName.CHECKLIST_ADMIN, id);

        return this.isSuperAdmin() || isGlobalAdmin || isAdmin;
    }

    public isSuperAdmin(): boolean {
        return this.hasRole(ApiUserRoleName.SUPER_ADMIN);
    }

    public userRoles(role: ApiUserRoleName) {
        return this.roles.filter(r => r.name === role);
    }

    get globalRoles(): UserRole[] {
        // return this.roles.filter(
        //     (role) => role.details.length === 0 || role.details.some(detail => detail.assignedForUID !== this.uid
        // ));
        return this.roles.map(role => role)
    }

    get collectionRoles(): AssignedRole[] {
        // return this.roles.filter((role) => {
        //     return [
        //         ApiUserRoleName.COLLECTION_ADMIN,
        //         ApiUserRoleName.COLLECTION_EDITOR
        //     ].includes(role.name);
        // });
        return this.assignedRoles.filter(role => {
            return [
                ApiUserRoleName.COLLECTION_ADMIN,
                ApiUserRoleName.COLLECTION_EDITOR,
                ApiUserRoleName.RARE_SPECIES_READER
            ].includes(role.access)
        })
    }

    get checklistRoles(): AssignedRole[] {
        return this.assignedRoles.filter(role => {
            return [
                ApiUserRoleName.CHECKLIST_ADMIN,
                ApiUserRoleName.CHECKLIST_EDITOR
            ].includes(role.access)
        })
    }

    get projectRoles(): AssignedRole[] {
        return this.assignedRoles.filter(role => {
            return [
                ApiUserRoleName.PROJECT_ADMIN,
                ApiUserRoleName.PROJECT_EDITOR
            ].includes(role.access)
        })
    }

    isExpired(): boolean {
        return this.exp <= new Date();
    }

    millisUntilExpires(): number {
        return Math.round(this.exp.getTime() - new Date().getTime());
    }

    hasRole(role: ApiUserRoleName, roleTarget: number = null): boolean {
        // checking for global roles
        if (roleTarget === null) {
            return User.checkHasRole(this.globalRoles, role);
        }

        // checking for specific role
        // TODO: Implement more roles
        switch (role) {
            case ApiUserRoleName.CHECKLIST_ADMIN:
                return User.checkHasRole(this.checklistRoles, role, roleTarget);
            case ApiUserRoleName.COLLECTION_ADMIN:
            case ApiUserRoleName.COLLECTION_EDITOR:
                return User.checkHasRole(this.collectionRoles, role, roleTarget);
            case ApiUserRoleName.PROJECT_ADMIN:
                return User.checkHasRole(this.projectRoles, role, roleTarget);
            default:
                return false;
        }
    }
}

@Exclude()
export class UserProfileData implements ApiUserProfileData {
    @Expose()
    firstName: string;

    @Expose()
    lastName: string;

    @Expose()
    email: string;

    @Expose()
    title: string;

    @Expose()
    institution: string;

    @Expose()
    department: string;

    @Expose()
    address: string;

    @Expose()
    phone: string;

    @Expose()
    city: string;

    @Expose()
    state: string;

    @Expose()
    zip: string;

    @Expose()
    country: string;

    @Expose()
    url: string;

    @Expose()
    biography: string;

    @Expose()
    @Transform((isPublic) => {
        if (typeof isPublic === 'boolean') {
            return isPublic ? 1 : 0;
        }
        return isPublic;
    })
    isPublic: number;
}
