<mat-form-field>
    <mat-select
        [ngClass]='{"highlighted": value === ""}'
        [value]='value'
        (valueChange)='valueChanged.emit($event)'
    >
        <mat-option value=''>Ignore field</mat-option>
        <mat-option *ngFor='let option of options' [value]='option.text' [disabled]='option.disabled'>
            {{ option.text }}
        </mat-option>
    </mat-select>
</mat-form-field>
