<div id=main>

    <div>
        <h1> <symbiota2-editable-text key='taxonomy.profiler.entry.title'></symbiota2-editable-text></h1>
    </div>

    <!-- Configure name list -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel expanded=false label='{{"taxonomy.viewer.configure.label" | translate}}'>
            <mat-radio-group
                aria-labelledby="configure-radio-group-label"
                class="configure-radio-group"
                (change)='configureChangeAction()'
                [(ngModel)]="kindOfName">
                <mat-radio-button value="Scientific">{{"taxonomy.viewer.option.scientific" | translate}}</mat-radio-button>&nbsp;&nbsp;
                <mat-radio-button value="Common">{{"taxonomy.viewer.option.common" | translate}}</mat-radio-button>

                <div>
                    <br/>
                    <mat-form-field appearance="fill">
                        <mat-label>{{"taxonomy.viewer.label.authority" | translate}}</mat-label>
                        <mat-select field-class="symbiota2-field-class"
                                    placeholder='{{"taxonomy.viewer.label.authority" | translate}}'
                                    [(value)]="taxonomicAuthorityID"
                                    (selectionChange)="authorityChangeAction()">
                            <mat-option *ngFor="let authority of taxonomicAuthorityList"
                                        [value]="authority.id" >{{ authority.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf='kindOfName == "Scientific"'>
                    <mat-checkbox class="example-margin" [(ngModel)]='hasAuthors'
                    >{{"taxonomy.viewer.label.checkbox.authors" | translate}}</mat-checkbox>
                </div>

                <div *ngIf='kindOfName == "Common"'>
                    <mat-form-field appearance="fill">
                        <mat-label>{{"taxonomy.viewer.label.language" | translate}}</mat-label>
                        <mat-select field-class="symbiota2-field-class"
                                    placeholder='{{"taxonomy.viewer.label.language" | translate}}'
                                    [(value)]="language"
                                    (selectionChange)="languageChangeAction(language)">
                            <mat-option *ngFor="let lang of languageList"
                                        [value]="lang" >{{ lang }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-radio-group>
        </symbiota2-expansion-panel>
    </div>

    <!-- Taxonomy -->
    <div class="expansion-panel">
        <symbiota2-expansion-panel label='{{"taxonomy.profiler.entry.panel.label" | translate}}'>
            <form class="name-autocomplete-form" (ngSubmit)="onSubmit()">
                <mat-form-field class="name-autocomplete-full-width">
                    <mat-label *ngIf='kindOfName == "Scientific"'>{{"taxonomy.viewer.label.name.enter.scientific" | translate}}</mat-label>
                    <mat-label *ngIf='kindOfName == "Common"'>{{"taxonomy.viewer.label.name.enter.common" | translate}}</mat-label>
                    <input *ngIf='kindOfName == "Scientific"' type="text"
                           placeholder='{{"taxonomy.viewer.label.name.scientific" | translate}}'
                           aria-label="String"
                           matInput
                           (keyup)="onKey($event)"
                           [formControl]="nameControl"
                           [matAutocomplete]="auto">
                    <input *ngIf='kindOfName == "Common"' type="text"
                           placeholder='{{"taxonomy.viewer.label.name.common" | translate}}'
                           aria-label="String"
                           matInput
                           (keyup)="onKey($event)"
                           [formControl]="nameControl"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectedSciname($event)">
                        <mat-option *ngFor="let option of nameOptions" [value]="option.name">
                            {{option.name}} <small>{{option.author}}</small>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-label *ngIf="looking && !nameFound" style='color:red'>
                    <b>{{"taxonomy.viewer.name.error" | translate}}</b>
                    <br/>
                </mat-label>
                <!--
                <button class="button" type="submit">{{"taxonomy.profiler.entry.button.label" | translate}}</button>
            -->
            </form>
            <!--
            <br/>
            <mat-checkbox class="example-margin" [(ngModel)]='includeAuthors'>{{"taxonomy.viewer.label.checkbox.include.authors" | translate}}</mat-checkbox>
            -->
            <symbiota2-expansion-panel *ngIf='possibleTaxons.length > 1' label='{{"taxonomy.viewer.label.choose.taxon" | translate}}'>
                <mat-selection-list [multiple]="false">
                    <mat-list-option *ngFor="let txn of possibleTaxons" [value]="txn.taxonID">
                        {{txn.name}}
                    </mat-list-option>
                </mat-selection-list>
            </symbiota2-expansion-panel>
        </symbiota2-expansion-panel>
    </div>

</div>
