import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxonomicUnitService } from '../../services';
import { filter, switchMap, take } from 'rxjs/operators';
import { AlertService, ApiClientService, UserService } from '@symbiota2/ui-common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { TaxonomicUnitListItem } from '../../dto';
import {
    EditUnitDialogComponent,
    DeleteUnitDialogComponent,
} from '../../components';
import { EditableTextComponent } from '../../../../../ui-plugin-i18n/src/lib/components/editable-text/editable-text.component';

@Component({
    selector: 'taxonomic-units-list-page',
    templateUrl: './taxonomic-units-list-page.html',
    styleUrls: ['./taxonomic-units-list-page.scss'],
})

export class TaxonomicUnitsListPage implements OnInit {
    userID : number = null
    userCanEdit: boolean = false
    units : TaxonomicUnitListItem[] = []
    unitsToEdit : TaxonomicUnitListItem[] = []
    nameToRankID
    rankIDToName
    separator = ":"

    constructor(
        private readonly userService: UserService,
        private readonly taxonomicUnitService: TaxonomicUnitService,
        private readonly alertService: AlertService,
        private router: Router,
        private formBuilder: FormBuilder,
        private readonly translate: TranslateService,
        public dialog: MatDialog
    ) {
    }

    /*
    Called when Angular starts
    */
    ngOnInit() {

        this.taxonomicUnitService.findAll().subscribe((units) => {
            this.nameToRankID = new Map()
            this.rankIDToName = new Map()
            units.forEach((unit) => {
                const rankName = unit.kingdomName + this.separator + unit.rankName
                const idName = unit.kingdomName + this.separator + unit.rankID
                this.nameToRankID = new Map()
                this.rankIDToName = new Map()
                if (!this.nameToRankID.has(rankName)) {
                    this.nameToRankID.set(rankName, unit.rankID)
                }
                if (!this.rankIDToName.has(idName)) {
                    this.rankIDToName.set(idName, unit.rankName)
                }
            })
        })

        this.userService.currentUser
            .pipe(filter((user) => user !== null))
            .subscribe((user) => {
                this.userID = user.uid
                this.userCanEdit = user.canEditProject(user.uid)
            })
    }

    doDelete(name) {
        const dialogRef = this.dialog.open(DeleteUnitDialogComponent, {
            width: '90%',
            data: name,

        })

        dialogRef.afterClosed().subscribe(result => {
            if (result.event != 'zzzCancel') {
                this.taxonomicUnitService.delete(name).subscribe((g) => {
                    if (g == "success") {
                        this.showMessage('taxonomic.unit.delete.succeeded')
                    } else {
                        this.showError('taxonomic.unit.delete.failed"')
                    }
                })
            }
        })
    }

    doEdit(name) {
        const dialogRef = this.dialog.open(EditUnitDialogComponent, {
            width: '90%',
            data: name,

        })

        dialogRef.afterClosed().subscribe(result => {
            if (result.event != 'zzzCancel') {
                /*
                this.taxonomicUnitService.edit(name).subscribe((a) => {
                    this.router.navigate(
                        ["" ]
                    )
                })
                 */
            } else {
                this.showError('taxonomic.unit.edit.failed')
            }
        })
    }

    /*
    Internal routine to encapsulate the show error message at the bottom in case something goes awry
    */
    private showError(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showError(r)
        })
    }

    /*
    Internal routine to encapsulate the show message at the bottom to confirm things actually happened
    */
    private showMessage(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showMessage(r)
        })
    }

}
