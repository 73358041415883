
  <!-- Family and taxa display - default -->
  <mat-card class="checklist-taxa-card" *ngIf="filterCheck=== 'default'">
   
    <mat-card-content style="min-height: 730px !important">
      <div>
        <p> Families: {{ data?.family.length }}</p>
        <p> Unknown Families: {{ data?.orphan.length }}</p>
          <p> Genera: {{ data?.generaCount }} </p>
          <p> Species: {{ data?.speciesCount }}</p>
          <p>Total taxa: {{ sortedTaxa.length }} &nbsp; &nbsp; (including subsp. and var.)</p>
        </div>
        <mat-divider></mat-divider>
        

        <nav>
          <ul class="pagination">
            <li class="page-list" 
            >
              <a class="btn page-link" 
              [ngClass]="{disabled: currentPage === 0}"
              routerLink="./"
              (click)="this.currentPage = this.currentPage - 1"
              [queryParams]="{page: currentPage - 1}"
              >Previous 20 familites</a>
            </li>
            <ng-container *ngFor="let p of familyPageList;">
              <li 
                class="page-item" 
                [ngClass]="{active: p === currentPage}"
                
              >
                <a routerLink="./" [queryParams]="{page: p}" class="page-link" (click)="currentPage = p">{{ p+1 }} </a>
              </li>
            </ng-container>
            <li class="page-list">
              <a class=" btn page-link"
              (click)="currentPage = currentPage + 1"
              [ngClass]="{disabled: currentPage === familyPageList?.length - 1}"
              routerLink="./"
              [queryParams]="{page: currentPage + 1}"
              >Next 20 families</a>
            </li>
          </ul>
        </nav>

          <!-- <div *ngFor="let f of data?.family; let i = index">
              <h2 style="margin: 0; ">{{ f }}</h2>
                
                <p *ngFor="let t of taxon[i];">
                  <a href="#">{{ t }}</a>
                </p>
              </div> -->
              
              <!-- <div *ngFor="let family of data?.allData | keyvalue; let i = index">
                <div *ngIf="i < 20">
                  <h2 style="margin: 0; ">{{ family.key }}</h2>
                
              <p *ngFor="let taxon of family.value">
                <a href="#">{{ taxon }}</a>
              </p>
            </div>
        </div> -->

        <div *ngFor="let data of filteredFamily; let i = index">
          <h2 style="margin: 0; ">{{ data[0] }}</h2>
              
          <p *ngFor="let taxon of data[1]">
            <a [routerLink]="['/taxon/profile/' + taxon.id]" target="_blank">{{ taxon.sciName }}</a> 
            <span style="font-size: 85%" *ngIf="taxonAuthor"> {{ taxon.author }}</span>
            <strong *ngIf="commonNames && taxon.commonNames.length > 0"> - {{ taxon.commonNames.join(', ') }}</strong>
          </p>
        </div>
          
      </mat-card-content>

      <nav>
        <ul class="pagination">
          <li class="page-list" 
          >
            <a class="btn page-link" 
            [ngClass]="{disabled: currentPage === 0}"
            routerLink="./"
            (click)="this.currentPage = this.currentPage - 1"
            [queryParams]="{page: currentPage - 1}"
            >Previous 20 familites</a>
          </li>
          <ng-container *ngFor="let p of familyPageList;">
            <li 
              class="page-item" 
              [ngClass]="{active: p === currentPage}"
              
            >
              <a routerLink="./" [queryParams]="{page: p}" class="page-link" (click)="currentPage = p">{{ p+1 }} </a>
            </li>
          </ng-container>
          <li class="page-list">
            <a class=" btn page-link"
            (click)="currentPage = currentPage + 1"
            [ngClass]="{disabled: currentPage === familyPageList?.length - 1}"
            routerLink="./"
            [queryParams]="{page: currentPage + 1}"
            >Next 20 families</a>
          </li>
        </ul>
      </nav>

    </mat-card>




 



    <!-- Taxa only display - sorted alphebatically -->
    <mat-card class="checklist-taxa-card" *ngIf="filterCheck==='taxa'">
      
      <mat-card-content style="min-height: 730px !important">
        <div>
          <!-- <p> Families: {{ data?.family.length }}</p>
          <p> Unknown Families: {{ data?.uFamily.length }}</p>
            <p> Genera: {{ data?.generaCount }} </p> -->
            <!-- <p> Species: {{ data?.speciesCount }}</p> -->
            <p>Species: {{ sortedTaxa?.length }}</p>
          </div>
          <mat-divider></mat-divider>

          <nav>
            <ul class="pagination">
              <li class="page-list" 
              >
                <a class="btn page-link" 
                [ngClass]="{disabled: currentPage === 0}"
                routerLink="./"
                (click)="this.currentPage = this.currentPage - 1"
                [queryParams]="{page: currentPage - 1}"
                >Previous 100 taxa</a>
              </li>
              <ng-container *ngFor="let p of taxaPageList;">
                <li 
                  class="page-item" 
                  [ngClass]="{active: p === currentPage}"
                  
                >
                  <a routerLink="./" [queryParams]="{page: p}" class="page-link" (click)="currentPage = p">{{ p+1 }} </a>
                </li>
              </ng-container>
              <li class="page-list">
                <a class=" btn page-link"
                (click)="currentPage = currentPage + 1"
                [ngClass]="{disabled: currentPage === taxaPageList?.length - 1}"
                routerLink="./"
                [queryParams]="{page: currentPage + 1}"
                >Next 100 taxa</a>
              </li>
            </ul>
          </nav>
    
          <div *ngFor="let taxon of filteredTaxa; let i = index">
            <!-- <h2 style="margin: 0; ">{{ data[0] }}</h2> -->
                
            <p *ngIf="filteredTaxa[0] !== 'Name not found!'; else noData">
              <a 
                [routerLink]="['/taxon/profile/' + taxon.id]" 
                target="_blank">{{ taxon.sciName }}</a>
                <span style="font-size: 85%" *ngIf="taxonAuthor"> {{ taxon.author }}</span>
                <strong *ngIf="commonNames && taxon.commonNames.length > 0"> - {{ taxon.commonNames.join(', ') }}</strong>
            </p>

            <ng-template #noData>
              {{ filteredTaxa[0] }}
            </ng-template>
          </div>
            
        </mat-card-content>
    
        <nav>
          <ul class="pagination">
            <li class="page-list" 
            >
              <a class="btn page-link" 
              [ngClass]="{disabled: currentPage === 0}"
              routerLink="./"
              (click)="this.currentPage = this.currentPage - 1"
              [queryParams]="{page: currentPage - 1}"
              >Previous 100 taxa</a>
            </li>
            <ng-container *ngFor="let p of taxaPageList;">
              <li 
                class="page-item" 
                [ngClass]="{active: p === currentPage}"
                
              >
                <a routerLink="./" [queryParams]="{page: p}" class="page-link" (click)="currentPage = p">{{ p+1 }} </a>
              </li>
            </ng-container>
            <li class="page-list">
              <a class=" btn page-link"
              (click)="currentPage = currentPage + 1"
              [ngClass]="{disabled: currentPage === taxaPageList?.length - 1}"
              routerLink="./"
              [queryParams]="{page: currentPage + 1}"
              >Next 100 taxa</a>
            </li>
          </ul>
        </nav>
    
      </mat-card>




      <!-- image only display -->
    <mat-card *ngIf="filterCheck==='image'">
      
      <mat-card-content style="">
        <div>
          <!-- <p> Families: {{ data?.family.length }}</p>
          <p> Unknown Families: {{ data?.uFamily.length }}</p>
            <p> Genera: {{ data?.generaCount }} </p> -->
            <!-- <p> Species: {{ data?.speciesCount }}</p> -->
            <p>Images: {{ imageCount }}</p>
          </div>
          <mat-divider></mat-divider>

          <nav>
            <ul class="pagination">
              <li class="page-list" 
              >
                <a class="btn page-link" 
                [ngClass]="{disabled: currentPage === 0}"
                routerLink="./"
                (click)="this.currentPage = this.currentPage - 1"
                [queryParams]="{page: currentPage - 1}"
                >Previous 100 taxa</a>
              </li>
              <ng-container *ngFor="let p of imagesPageList;">
                <li 
                  class="page-item" 
                  [ngClass]="{active: p === currentPage}"
                  
                >
                  <a routerLink="./" [queryParams]="{page: p}" class="page-link" (click)="currentPage = p">{{ p+1 }} </a>
                </li>
              </ng-container>
              <li class="page-list">
                <a class=" btn page-link"
                (click)="currentPage = currentPage + 1"
                [ngClass]="{disabled: currentPage === imagesPageList?.length - 1}"
                routerLink="./"
                [queryParams]="{page: currentPage + 1}"
                >Next 100 taxa</a>
              </li>
            </ul>
          </nav>
    
          <div class="taxa-image-box">
             <ng-container *ngFor="let taxon of filteredImages; let i = index">
            <!-- <h2 style="margin: 0; ">{{ data[0] }}</h2> -->
                
            <!-- <p>
              <a [routerLink]="['/taxon/profile/', taxon.split('_')[1]]" target="_blank">{{ taxon.split('_')[0] }}</a> &nbsp; <span style="font-size: 85%" *ngIf="taxonAuthor">{{ taxon.split('_')[2] }}</span>
            </p> -->
              <div class="taxa-image-row">
                <a [routerLink]="['/taxon/profile/' + taxon.taxonID]" target="_blank"><img [src]="taxon.thumbnailUrl?.startsWith('/imglib') ? 'https://openherbarium.org/' + taxon.thumbnailUrl : taxon.thumbnailUrl" [alt]="taxon.alt"></a> 
                <p><a [routerLink]="['/taxon/profile/' + taxon.taxonID]" target="_blank">{{ taxon.title }}</a></p>
                <p>[{{ taxon.family }}]</p>
              </div>
          </ng-container>

          </div>
            
        </mat-card-content>
    
        <nav>
          <ul class="pagination">
            <li class="page-list" 
            >
              <a class="btn page-link" 
              [ngClass]="{disabled: currentPage === 0}"
              routerLink="./"
              (click)="this.currentPage = this.currentPage - 1"
              [queryParams]="{page: currentPage - 1}"
              >Previous 100 taxa</a>
            </li>
            <ng-container *ngFor="let p of imagesPageList;">
              <li 
                class="page-item" 
                [ngClass]="{active: p === currentPage}"
                
              >
                <a routerLink="./" [queryParams]="{page: p}" class="page-link" (click)="currentPage = p">{{ p+1 }} </a>
              </li>
            </ng-container>
            <li class="page-list">
              <a class=" btn page-link"
              (click)="currentPage = currentPage + 1"
              [ngClass]="{disabled: currentPage === imagesPageList?.length - 1}"
              routerLink="./"
              [queryParams]="{page: currentPage + 1}"
              >Next 100 taxa</a>
            </li>
          </ul>
        </nav>
    
      </mat-card>
