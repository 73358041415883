import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UserService, ROUTE_ROLES_LIST, User } from '@symbiota2/ui-common';

import { MatCell, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDataDialogComponent } from '../../components/delete-data-dialog/delete-data-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'symbiota2-requested-roles-page',
  templateUrl: './requested-roles.page.html',
  styleUrls: ['./requested-roles.page.scss'],
})

export class RequestedRolesPage implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatCell) mat_cell: QueryList<MatCell>;
  
  currentUser$ = this.userService.currentUser;
  user: User;
  users = [];
  isSuperAdmin = false;
  requestData;

  displayedColumns: string[] = [
    'requesterUID', 
    'firstName', 
    'lastName', 
    'username',
    'title',
    'description',
    // 'status',
    'date',
    'actions'
  ];
  dataSource: MatTableDataSource<RequestData>

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.requestData = [];
    this.loadUsers();
    this.currentUser$.subscribe(user => {
      this.user = user;
      this.isSuperAdmin = user.isSuperAdmin();
      // this.dataSource = new MatTableDataSource<RequestData>(this.requestData)
      // this.dataSource.paginator = this.paginator;
    })


    const findUser = (users, reqID) => users.find(user => user.uid === reqID)

    this.userService.getAllPermissionRequests()
      .subscribe(async requests => {
        console.log('this.users: ', this.users)
      requests.map(req => {
          this.requestData.push({
            requesterUID: req.requesterUID,
            title: req.title,
            description: req.description,
            status: req.currentStatus,
            date: req.date,
            firstName: findUser(this.users, req.requesterUID).firstName,
            lastName: findUser(this.users, req.requesterUID).lastName,
            username: findUser(this.users, req.requesterUID).username,
          })
            
        })
        this.dataSource = new MatTableDataSource<RequestData>(this.requestData)
        this.dataSource.paginator = this.paginator;
    })

  }

    
  async loadUsers() {
    this.userService.getUsers().subscribe(users => {
      this.users = users;
    })
  }


}

export interface RequestData {
  requesterUID: number;
  firstName: string;
  lastName: string;
  username: string;
  title: string;
  description: string;
  // status: string;
  date: Date;
  actions?: {edit: string, delete: string}
}