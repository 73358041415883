<div id='main'>
    <div>
        <h1>
            <symbiota2-editable-text key='image.folder.upload.complete.title'></symbiota2-editable-text>
        </h1>
    </div>

    <button
        mat-flat-button color='primary'
        (click)='onSubmit()'
    >
        {{"image.folder.uploader.complete.button" | translate}}
    </button>
    <symbiota2-editable-text key='image.folder.uploader.complete.button' hide='true'></symbiota2-editable-text>
    <div *ngIf='done'>
        <div *ngIf="someSkipped; else noProblems">
            <symbiota2-expansion-panel
                *ngIf="skippedImagesDueToProblems.length > 0"
                expanded=false
                label='{{"image.folder.uploader.complete.problem.images" | translate}}'>
                <symbiota2-editable-text key='image.folder.uploader.complete.problem.images' hide='true'></symbiota2-editable-text>
                <pre>
            {{skippedImagesDueToProblems}}
        </pre>
            </symbiota2-expansion-panel>

        </div>
        <ng-template #noProblems>
            <symbiota2-editable-text key='image.folder.uploader.complete.all.processed'></symbiota2-editable-text>
        </ng-template>
    </div>

</div>
