<div mat-dialog-content>
    <form *ngIf="action == 'Update'; else elseTemplate">
        <div fxLayout="row wrap">
            <div class="form-field w-6">
                <mat-form-field>
                    <mat-label>{{'taxon.editor.common.name' | translate}}</mat-label>
                    <input matInput
                           maxlength="100"
                           [value]="local_data.vernacularName"
                           [(ngModel)]="local_data.vernacularName"
                           [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <symbiota2-editable-text key='taxon.editor.common.name' hide='true'></symbiota2-editable-text>
            </div>
        </div>
        <div fxLayout="row wrap">
            <div class="form-field w-4">
                <mat-form-field>
                    <mat-label>{{'taxon.editor.common.language' | translate}}</mat-label>
                    <input matInput
                           maxlength="100"
                           [value]="local_data.language"
                           [(ngModel)]="local_data.language"
                           [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <symbiota2-editable-text key='taxon.editor.common.language' hide='true'></symbiota2-editable-text>
            </div>
        </div>
        <div fxLayout="row wrap">
            <div class="form-field w-8">
                <mat-form-field>
                    <mat-label>{{'taxon.editor.common.notes' | translate}}</mat-label>
                    <input matInput
                           [value]="local_data.notes"
                           [(ngModel)]="local_data.notes"
                           [ngModelOptions]="{standalone: true}">
                    <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                </mat-form-field>
                <symbiota2-editable-text key='taxon.editor.common.notes' hide='true'></symbiota2-editable-text>
            </div>
        </div>
        <div fxLayout="row wrap">
            <div class="form-field w-7">
                <mat-form-field>
                    <mat-label>{{'taxon.editor.common.source' | translate}}</mat-label>
                    <input matInput
                           maxlength="100"
                           [value]="local_data.source"
                           [(ngModel)]="local_data.source"
                           [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <symbiota2-editable-text key='taxon.editor.common.source' hide='true'></symbiota2-editable-text>
            </div>
        </div>
        <div fxLayout="row wrap">
            <div class="form-field w-1">
                <mat-form-field>
                    <mat-label>{{'taxon.editor.common.sortSequence' | translate}}</mat-label>
                    <input matInput
                           maxlength="3"
                           [value]="local_data.sortSequence"
                           [(ngModel)]="local_data.sortSequence"
                           [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <symbiota2-editable-text key='taxon.editor.common.sortSequence' hide='true'></symbiota2-editable-text>
            </div>
        </div>
    </form>
    <ng-template #elseTemplate>
        <symbiota2-editable-text key='taxon.editor.dialog.confirm.delete' hide='true'></symbiota2-editable-text>
        <b>{{local_data.name}}</b>?
    </ng-template>
</div>
<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="doAction()">{{action}}</button>
    <button mat-button (click)="closeDialog()" mat-raised-button color="warn">{{'taxon.editor.dialog.cancel' | translate}}</button>
    <symbiota2-editable-text key='taxon.editor.dialog.cancel' hide='true'></symbiota2-editable-text>
</div>
