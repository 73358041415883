import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'symbiota2-delete-data-dialog',
    templateUrl: './delete-data-dialog.html',
    styleUrls: ['./delete-data-dialog.scss']
  })
  export class DeleteDataDialogComponent {
    constructor( @Inject(MAT_DIALOG_DATA) public data: string ) {}
  }