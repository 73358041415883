<div
    id="main"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="1rem"
>
    <img
        id="logo"
        src="../../../assets/images/layout/logo_green.png"
        alt="{{
            'core.layout.header.banner.symbiota_logo_alt_text' | translate
        }}"
    />
    <h1 *ngIf="title">
        <symbiota2-editable-text key='core.layout.home.title_line' params='{ "title": "{{title}}" }'></symbiota2-editable-text>
    </h1>

    <p><symbiota2-editable-paragraph key='core.layout.home.para_one'></symbiota2-editable-paragraph></p>

    <p><symbiota2-editable-paragraph key='core.layout.home.para_two'></symbiota2-editable-paragraph></p>

</div>
