<a *ngIf='url !== "#"; else noLink' [routerLink]='url'>
    <img
        symbiota2ImgFallback
        [ngStyle]="{ width: size, height: size }"
        class="collection-logo"
        src="{{ src }}"
        alt="collection logo"
    >
</a>
<ng-template #noLink>
    <img
        symbiota2ImgFallback
        [ngStyle]="{ width: size, height: size }"
        class="collection-logo"
        src="{{ src }}"
        alt="collection logo"
    >
</ng-template>
