/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import {  Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@symbiota2/ui-common';

import { filter } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { ChecklistService } from '../../services/checklist/checklist.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }

@Component({
    selector: 'checklist-create',
    templateUrl: './checklist-create-page.html',
    styleUrls: ['./checklist-create-page.component.scss'],
})
export class ChecklistCreatePageComponent implements OnInit {
    checklistCreateForm: FormGroup;

    matcher = new MyErrorStateMatcher();

    // User stuff
    userID: number = null;
    userCanEdit = false;
   
    defaultSettings = { ddetails: 0, dcommon: 0, dimages: 0, dvouchers: 0, dauthors: 0, dalpha: 0}
    

    successMessage = '';
    errorMessage = '';
    hasError = false;
    hasSuccess = false;

    constructor(
            private readonly translate: TranslateService,
            private readonly userService: UserService,
            private readonly checklistService: ChecklistService,
            public router: Router
        ) {
    }

    ngOnInit() {
        this.userService.currentUser
            .pipe(filter((user) => user !== null))
            .subscribe((user) => {
                this.userID = user.uid;
                this.userCanEdit = user.canEditChecklist(user.uid);
            });

        this.checklistCreateForm = new FormGroup({
            name: new FormControl(null, [Validators.required]),
            authors: new FormControl(null),
            locality: new FormControl(null),
            abstract: new FormControl(null),
            notes: new FormControl(null),
            latCentroid: new FormControl({value: null, disabled: true }),
            longCentroid: new FormControl({value: null, disabled: true }),
            pointRadiusMeters: new FormControl({value: null, disabled: true }),
            footprintWKT: new FormControl({value: null, disabled: true }),
            access: new FormControl('private')
        })

    }

    async onCreateChecklist() {

        this.checklistService.createChecklist({...this.checklistCreateForm.value, defaultSettings: this.defaultSettings}).subscribe(() => {
            this.successMessage = 'Checklist successfully created.';
            this.hasSuccess = true;
            setTimeout(() => {
                this.hasSuccess = false;
                this.defaultSettings = null;
                this.checklistCreateForm.reset()
            },15000)
        }, (error) => {
                this.errorMessage = error.error.message
                this.hasError = true;
            });
            setTimeout(() => {
                this.hasError = false;
                this.defaultSettings = null;
                this.checklistCreateForm.reset()
            },15000)
    }
    
}
