<div fxLayout="row" fxLayoutAlign="center center" id='footer'>
    <div id="footer-logo">
        <a href="" target="_blank">
            <img class="NSFLogo"
                 src="/assets/images/layout/NSF_web_transperent.gif"
                 alt="{{ 'core.layout.footer.nsf_logo_alt_text' | translate }}"
            >
        </a>
    </div>
    <div id="footer-text">
        <b>
            {{ 'core.layout.footer.nsf_text' | translate }}
            <a href="" target="_blank">
                {{ 'core.layout.footer.nsf_link' | translate }}
            </a>
        </b>
    </div>
</div>
