import { BehaviorSubject, Observable, of } from 'rxjs';
import { AlertService, ApiClientService, AppConfigService, UserService } from '@symbiota2/ui-common';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { MonitoringQueryBuilder } from './monitoring-query-builder';


@Injectable()
export class MonitoringService {
    private jwtToken = this.user.currentUser.pipe(map((user) => user.token))
    // private readonly _currentUpload = new BehaviorSubject<ApiTaxonomyUpload>(null)
    private creatorUID = null

    constructor(
        private readonly alerts: AlertService,
        private readonly user: UserService,
        private readonly apiClient: ApiClientService,
        private readonly appConfig: AppConfigService)
    {
        //Fill in the current user id
        this.user.currentUser.subscribe((user) => {
            if (user) {
                this.creatorUID = user.uid
            }
        })
    }

    private createQueryBuilder(): MonitoringQueryBuilder {
        return new MonitoringQueryBuilder(this.appConfig.apiUri());
    }

    public getUrl() {
        return this.apiClient.apiRoot()
    }

    /**
     * check if API is up
     * @param none - no params
     * @returns Observable of response from api casted as number
     * @returns `of(null)`
     */
    apiUpCheck(): Observable<number | null> {
        const url = this.createQueryBuilder()
            .apiUpCheck()
            .build()

        const query = this.apiClient.queryBuilder(url).get().build();
        return this.apiClient.send<any, number>(query)
            .pipe(
                catchError((e) => {
                    console.error(e)
                    return of(null)
                }),
                map((result) => {
                    return result;
                })
            )
    }

    /**
     * check if DB Server is up
     * @param none - no params
     * @returns Observable of response
     * @returns `of(null)`
     */
    dbServerUpCheck(): Observable<number | null> {
        const url = this.createQueryBuilder()
            .dbServerUpCheck()
            .build()

        const query = this.apiClient.queryBuilder(url).get().build();
        return this.apiClient.send<any, number>(query)
            .pipe(
                catchError((e) => {
                    console.error(e)
                    return of(null)
                }),
                map((result) => {
                    return result;
                })
            )
    }

    /**
     * check if Redis Server is up
     * @param none - no params
     * @returns Observable of response
     * @returns `of(null)`
     */
    redisServerUpCheck(): Observable<number | null> {
        const url = this.createQueryBuilder()
            .redisServerUpCheck()
            .build()

        const query = this.apiClient.queryBuilder(url).get().build();
        return this.apiClient.send<any, number>(query)
            .pipe(
                catchError((e) => {
                    console.error(e)
                    return of(null)
                }),
                map((result) => {
                    return result;
                })
            )
    }

    /**
     * check if storage service is up
     * @param none - no params
     * @returns Observable of response
     * @returns `of(null)`
     */
    storageServiceUpCheck(): Observable<number | null> {
        const url = this.createQueryBuilder()
            .storageServiceUpCheck()
            .build()

        const query = this.apiClient.queryBuilder(url).get().build();
        return this.apiClient.send<any, number>(query)
            .pipe(
                catchError((e) => {
                    console.error(e)
                    return of(null)
                }),
                map((result) => {
                    return result;
                })
            )
    }

    /**
     * check if email is up
     * @param none - no params
     * @returns Observable of response
     * @returns `of(null)`
     */
    emailUpCheck(): Observable<number | null> {
        const url = this.createQueryBuilder()
            .emailUpCheck()
            .build()

        const query = this.apiClient.queryBuilder(url).get().build();
        return this.apiClient.send<any, number>(query)
            .pipe(
                catchError((e) => {
                    console.error(e)
                    return of(null)
                }),
                map((result) => {
                    return result;
                })
            )
    }

    /**
     * check if Kibana is up
     * @param none - no params
     * @returns Observable of response
     * @returns `of(null)`
     */
    kibanaUpCheck(): Observable<number | null> {
        const url = this.createQueryBuilder()
            .kibanaUpCheck()
            .build()

        const query = this.apiClient.queryBuilder(url).get().build();
        return this.apiClient.send<any, number>(query)
            .pipe(
                catchError((e) => {
                    console.error(e)
                    return of(null)
                }),
                map((result) => {
                    return result;
                })
            )
    }
}
