<div mat-dialog-content>
  <form
    *ngIf="action == 'Update'; else elseTemplate"
    class="name-autocomplete-form"
  >
    <div fxLayout="row wrap">
      <div class="form-field w-5">
        <mat-checkbox
          [(ngModel)]="unit1ind"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-label>{{
            'taxon.taxon.editor.unitName1.indicator' | translate
          }}</mat-label>
        </mat-checkbox>
          <symbiota2-editable-text key='taxon.taxon.editor.unitName1.indicator' hide='true'></symbiota2-editable-text>

          <mat-form-field>
          <mat-label>{{
            'taxon.taxon.editor.unitName1' | translate
          }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.scientificName.split(' ')[0]"
            [(ngModel)]="local_data.scientificName.split(' ')[0]"
            [ngModelOptions]="{ standalone: true }"
          />
              <symbiota2-editable-text key='taxon.taxon.editor.unitName1' hide='true'></symbiota2-editable-text>

          </mat-form-field>
      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-5">
        <mat-checkbox
          [(ngModel)]="unit2ind"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-label>{{
            'taxon.taxon.editor.unitName2.indicator' | translate
          }}</mat-label>
        </mat-checkbox>
          <symbiota2-editable-text key='taxon.taxon.editor.unitName2.indicator' hide='true'></symbiota2-editable-text>

          <mat-form-field>
          <mat-label>{{
            'taxon.taxon.editor.unitName2' | translate
          }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.scientificName.split(' ')[1]"
            [(ngModel)]="local_data.scientificName.split(' ')[1]"
            [ngModelOptions]="{ standalone: true }"
          />
              <symbiota2-editable-text key='taxon.taxon.editor.unitName2' hide='true'></symbiota2-editable-text>

          </mat-form-field>
      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-5">
        <mat-checkbox
          [(ngModel)]="unit3ind"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-label>{{
            'taxon.taxon.editor.unitName3.indicator' | translate
          }}</mat-label>
        </mat-checkbox>
          <symbiota2-editable-text key='taxon.taxon.editor.unitName3.indicator' hide='true'></symbiota2-editable-text>

          <mat-form-field>
          <mat-label>{{
            'taxon.taxon.editor.unitName3' | translate
          }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.scientificName.split(' ')[2]"
            [(ngModel)]="local_data.scientificName.split(' ')[2]"
            [ngModelOptions]="{ standalone: true }"
          />
              <symbiota2-editable-text key='taxon.taxon.editor.unitName3' hide='true'></symbiota2-editable-text>

          </mat-form-field>
      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-4">
        <mat-form-field>
          <mat-label>{{
            'taxon.taxon.editor.kingdomName' | translate
          }}</mat-label>
          <mat-select
            [(ngModel)]="local_data.kingdomName"
            [(value)]="local_data.kingdomName"
            [ngModelOptions]="{ standalone: true }"
          >
            <mat-option *ngFor="let name of kingdomNames" [value]="name">
              {{ name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.kingdomName' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-4">
        <mat-form-field>
          <mat-label>{{ 'taxon.taxon.editor.rankName' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="rankID"
            [(value)]="local_data.rankName"
            [ngModelOptions]="{ standalone: true }"
          >
            <mat-option *ngFor="let rank of rankNames" [value]="rank.id">
              {{ rank.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.rankName' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-4">
        <mat-form-field>
          <mat-label>{{ 'taxon.taxon.editor.author' | translate }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.author"
            [(ngModel)]="local_data.author"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.author' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-1">
        <mat-form-field>
          <mat-label>{{
            'taxon.taxon.editor.phyloSortSequence' | translate
          }}</mat-label>
          <input matInput [formControl]="sortSequence" />
          <div
            *ngIf="
              sortSequence.invalid &&
              (sortSequence.dirty || sortSequence.touched)
            "
          >
            <mat-error *ngIf="sortSequence.invalid"
              >Not a valid number</mat-error
            >
          </div>
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.phyloSortSequence' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-4">
        <mat-form-field>
          <mat-label>{{ 'taxon.taxon.editor.status' | translate }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.status"
            [(ngModel)]="local_data.status"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.status' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-4">
        <mat-form-field>
          <mat-label>{{ 'taxon.taxon.editor.source' | translate }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.source"
            [(ngModel)]="local_data.source"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.source' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-8">
        <mat-form-field>
          <mat-label>{{ 'taxon.taxon.editor.notes' | translate }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.notes"
            [(ngModel)]="local_data.notes"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.notes' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-4">
        <mat-form-field>
          <mat-label>{{ 'taxon.taxon.editor.hybrid' | translate }}</mat-label>
          <input
            matInput
            maxlength="100"
            [value]="local_data.hybrid"
            [(ngModel)]="local_data.hybrid"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
          <symbiota2-editable-text key='taxon.taxon.editor.hybrid' hide='true'></symbiota2-editable-text>

      </div>
    </div>
    <div fxLayout="row wrap">
      <div class="form-field w-7">
        <b
          ><mat-label>{{
            'taxon.taxon.editor.securityStatus' | translate
          }}</mat-label><symbiota2-editable-text key='taxon.taxon.editor.securityStatus' hide='true'></symbiota2-editable-text></b
        >:&nbsp;
        <mat-radio-group (change)="onSecurityStatusChange()">
          <mat-radio-button *ngIf="!isPublic" value="y">
              <symbiota2-editable-text key='taxon.taxon.editor.locality.public'></symbiota2-editable-text>{{
          </mat-radio-button>
          <mat-radio-button *ngIf="isPublic" value="y" checked>
              <symbiota2-editable-text key='taxon.taxon.editor.locality.public'></symbiota2-editable-text>
          </mat-radio-button>
          &nbsp; &nbsp;
          <mat-radio-button *ngIf="!isPublic" value="x" checked>
              <symbiota2-editable-text key='taxon.taxon.editor.locality.hidden'></symbiota2-editable-text>
          </mat-radio-button>
          <mat-radio-button *ngIf="isPublic" value="x">
              <symbiota2-editable-text key='taxon.taxon.editor.locality.hidden'></symbiota2-editable-text>
         </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>
  <ng-template #elseTemplate>
      <symbiota2-editable-text key='taxon.editor.dialog.confirm.delete'></symbiota2-editable-text>
    <b>{{ local_data.name }}</b
    >?
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()">{{ action }}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    {{ 'taxon.editor.dialog.cancel' | translate }}
  </button><symbiota2-editable-text key='taxon.editor.dialog.cancel' hide='true'></symbiota2-editable-text>
</div>
