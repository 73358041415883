/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FlashCardGameService } from "../flash-card.service";

@Component({
    selector: 'flash-card-game',
    templateUrl: './flash-card-game.html',
    styleUrls: ['./flash-card-game.component.scss'],
})
export class FlashCardGameComponent implements OnInit {
    fullSize = false;
    taxa
    images
    imagesWithDetails = []
    family = []
    genera = []
    activeData = null;
    currentImage = 0;
    helpCount = 0;
    activeIndex
    firstTryTrack = 0;
    firstTryCount = 0;
    identifiedImages = 0;
    skippedItem = 0;
    displayCommonName = false;
    filterItems = []
    gameType = 'Species';



    constructor(private flashCardService: FlashCardGameService, private router: Router) {}



    ngOnInit() {
        
        this.taxa = this.flashCardService.getImages()

        this.loadFlashCardGame(this.taxa)
        this.generateRandomImage()
        this.getHelp()
    }



    loadFlashCardGame(data) {

        this.imagesWithDetails = data.filter(taxon => taxon.images.length > 0)

        this.activeData = this.imagesWithDetails;

        data.forEach((taxon, i) => {

            if (taxon.rankID <= 220) {
                
                if (!this.genera.find(name => name.scientificName.split(' ')[0] === taxon.scientificName.split(' ')[0])) {
                    this.genera.push(taxon)
                }
            }
            if (!this.family.includes(taxon.family)) this.family.push(taxon.family)

        })

        this.filterItems = ['All', ...this.genera.map(tx => tx.scientificName.split(' ')[0]), ...this.family]
        
    }



    changeGameType(type: string) {
        this.gameType = type;
        this.resetGame()
    }



    generateRandomImage() {
        this.currentImage = 0;
        this.firstTryTrack = 0;
        this.activeIndex = Math.floor(Math.random() * this.activeData?.length);
    }



    getNextRandomItem(_item = null, _skip = null) {
        console.log('check index: ', this.activeIndex)
        if (_skip) this.skippedItem++;
        this.removeItem(_item)
        this.generateRandomImage()
    }



    checkAnswer(answer, currentItem) {

        if (this.gameType === 'Species') {
            if (currentItem.scientificName === answer) {
                // to get the answer correct at first try
                if (this.firstTryTrack === 0) this.firstTryCount++;
                this.identifiedImages++
                this.removeItem(currentItem)
                this.getNextRandomItem()
                alert('Correct answer! Try next one.')
            } else {
                this.firstTryTrack++;
                alert('Sorry, incorrect. Try again.')
            }
        }

        if (this.gameType === 'Genera') {
            if (currentItem.scientificName.split(' ')[0] === answer) {
                // to get the answer correct at first try
                if (this.firstTryTrack === 0) this.firstTryCount++;
                this.identifiedImages++
                this.removeItem(currentItem)
                this.getNextRandomItem()
                alert('Correct answer! Try next one.')
            } else {
                this.firstTryTrack++;
                alert('Sorry, incorrect. Try again.')
            }
        }

        if (this.gameType === 'Family') {
            if (currentItem.family === answer) {
                // to get the answer correct at first try
                if (this.firstTryTrack === 0) this.firstTryCount++;
                this.identifiedImages++
                this.removeItem(currentItem)
                this.getNextRandomItem()
                alert('Correct answer! Try next one.')
            } else {
                this.firstTryTrack++;
                alert('Sorry, incorrect. Try again.')
            }
        }

    }




    removeItem(item) {
        if (item) {
            this.activeData = this.activeData.filter(taxon => taxon.scientificName !== item.scientificName)
        }
    }



    sortData(data, type) {
        if (type === 'species') {
            const species = data.sort((a, b) => a.scientificName.localeCompare(b.scientificName))
            console.log('species: ', species)
            return species;
        }

        if (type === 'genera') {
            const genera = data.sort((a, b) => a.scientificName.split(' ')[0].localeCompare(b.scientificName.split(' ')[0]))
            console.log('genera: ', genera)
            return genera
        }

        if (type === 'family' || type === 'filter') {
            const family = data.sort((a, b) => a.localeCompare(b))
            console.log('family: ', family)
            return family;
        }
        
    }

    

    filterQuiz(filter = null) {
        if (this.gameType === 'Species') {

            if (filter !== 'All') {
                this.imagesWithDetails = this.taxa.filter(taxon => taxon.images.length > 0);
                this.imagesWithDetails = this.imagesWithDetails.filter(
                    taxon => taxon.scientificName.includes(filter) || 
                    taxon.family === filter
                )
                this.activeData = this.imagesWithDetails.filter(
                    taxon => taxon.scientificName.includes(filter) ||
                    taxon.family === filter
                )
                this.getNextRandomItem()
            } else {
                this.activeData = this.imagesWithDetails = this.taxa.filter(taxon => taxon.images.length > 0)
                this.getNextRandomItem()
                this.resetGame()
            }
        }
    }


    showCommonName(item) {
        return ' — ' + item?.vernacularNames.map(name => name.vernacularName).join(', ')
    }


    AddCommonName(e) {
        this.displayCommonName = e.target.checked;
    }



    getHelp(currentItem = null, countHelp = false) {
        if (countHelp) this.helpCount++;
        if (currentItem !== null) {
            // since navigateByUrl does not support open new tab
            //this.router.navigateByUrl('/taxon/profile/' + currentItem.id)

            window.open(window.location.origin + '/taxon/profile/' + currentItem.id, '_blank')
        }
    }



    resetGame() {
        this.currentImage = 0;
        this.activeIndex = 0;
        this.firstTryTrack = 0;
        this.firstTryCount = 0;
        this.identifiedImages = 0;
        this.skippedItem = 0;
        this.helpCount = 0;
        this.displayCommonName = false;
        this.activeData = this.imagesWithDetails = this.taxa.filter(taxon => taxon.images.length > 0);
        this.generateRandomImage()
    }



} 