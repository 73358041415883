<div *ngIf="!isSuperAdmin" class='my2' fxLayout='row' fxLayoutGap='1rem'>
    {{"core.layout.superadmin.user_list_denied" | translate}}
  </div>
  
  <div *ngIf="isSuperAdmin" class="mat-elevation-z8" style="width: 90vw;margin:0 auto;margin-top: 2rem;">
    <!-- <div style="margin-left: 1rem;padding-top: 1rem;">
      <button mat-raised-button color="primary" routerLink="/adduser">Add New User</button>
    </div> -->
    
    <table mat-table [dataSource]="dataSource">
      
     <!--- <div *ngFor="let userPanel of userPanelList; let i=index"> -->
       <ng-container matColumnDef="requesterUID">
         <th mat-header-cell *matHeaderCellDef> ReqID </th>
         <td mat-cell *matCellDef="let element"> {{element.requesterUID}} </td>
       </ng-container>

       <ng-container matColumnDef="firstName">
         <th mat-header-cell *matHeaderCellDef> First Name </th>
         <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
       </ng-container>
  
       <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
      </ng-container>
  
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> Username </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>
  
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Title </th>
        <td mat-cell *matCellDef="let element">{{element.title}} </td>
      </ng-container>

      <ng-container matColumnDef="description" class="column-description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let element">{{element.description}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">{{element.status}} </td>
      </ng-container> -->

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let element">{{element.date}} </td>
      </ng-container>
  
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Take action </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button mat-raised-button class="color-edit" [routerLink]="['/user', element.requesterUID, 'update']">Take action</button>
          <!-- <button mat-raised-button class="color-delete" [disabled]="element.uid == user.uid" (click)="deleteUser(element.uid)">Unapprove</button> -->
        </td> 
      </ng-container> 
     <!-- </div> -->
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 50, 100]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
    
  </div>