import { Observable, of, Subject } from 'rxjs'
import { AlertService, ApiClientService, AppConfigService, UserService } from '@symbiota2/ui-common'
import { catchError, map, switchMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

export interface TaxonData {
    sciName: string, 
    id: number, 
    author: string, 
    family: string, 
    images: [], 
    commonNames: [], 
    synonym?: {
        id: number,
        scientificName: string
    }[]
}

@Injectable()
export class ChecklistTaxaFilterService {
    filterFormData$ = new Subject()
    // private jwtToken = this.user.currentUser.pipe(map((user) => user.token))

    constructor(
        private readonly alerts: AlertService,
        private readonly user: UserService,
        private readonly apiClient: ApiClientService,
        private readonly httpService: HttpClient,
        private readonly appConfig: AppConfigService) 
    {}


    getFormData(data) {
        this.filterFormData$.next(data)
    }

}
