import { BehaviorSubject, Observable, of } from 'rxjs';
import { AlertService, ApiClientService, AppConfigService, UserService } from '@symbiota2/ui-common';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { I18nQueryBuilder } from './i18n-query-builder'
import { ImageListItem } from '@symbiota2/ui-plugin-image';

interface FindAllParams {
    imageIDs: number[]
    taxonIDs: number[]
    limit?: number
}

@Injectable()
export class I18nService {
    private jwtToken = this.user.currentUser.pipe(map((user) => user.token))
    // private readonly _currentUpload = new BehaviorSubject<ApiTaxonomyUpload>(null)
    private creatorUID = null

    constructor(
        private readonly alerts: AlertService,
        private readonly user: UserService,
        private readonly apiClient: ApiClientService,
        private readonly appConfig: AppConfigService)
    {
        //Fill in the current user id
        this.user.currentUser.subscribe((user) => {
            if (user) {
                this.creatorUID = user.uid
            }
        })
    }

    private createQueryBuilder(): I18nQueryBuilder {
        return new I18nQueryBuilder(this.appConfig.apiUri());
    }

    public getUrl() {
        return this.apiClient.apiRoot()
    }

    findTranslateType(key: string, language: string): Observable<string> {
        const url = this.createQueryBuilder()
            .findTranslateType()
            .language(language)
            .key(key)
            .build()

        const query = this.apiClient.queryBuilder(url).get().build()
        //return this.apiClient.send<any, String>(query)
        return this.apiClient.send(query).pipe(
            catchError((e) => {
                console.error(e)
                return of(null)
            }),
            map((blockJson) => {
                return blockJson
            })
        )
            //.pipe(map((o) => o))
    }

    /**
     * sends request to api to update an i18n file
     * @param language - the two letter name of the language
     * @param key - the key
     * @param value - the new value
     * @param toTranslate - should this value be translatable?
     * @returns Observable of response from api cast as string, will be the updated string
     * @returns `of(null)` if key does not exist or does not have editing permission or api errors
     */
    update(language: string, key: string, value: string, toTranslate: boolean, originallyTranslatable: boolean): Observable<string | null> {

        /*
        if (toTranslate && !originallyTranslatable) {
            // If this was originally not translated but now is translated, then first need to delete the old one
            console.log(" running delete ")
            const deleteUrl =  this.createQueryBuilder()
                .deleteNotranslate()
                .language(language)
                .key(key)
                .value(value)

            console.log(" doing it " + deleteUrl)
            return this.jwtToken.pipe(
                switchMap((token) => {
                    console.log(" goo ")
                    const req = this.apiClient
                        .queryBuilder(deleteUrl.build())
                        .patch()
                        .addJwtAuth(token)
                        .build()

                    console.log(" sending ")
                    return this.apiClient.send(req).pipe(
                        catchError((e) => {
                            console.error(e)
                            return of(null)
                        }),
                        map((result) => {
                            if (result === null) {
                                return null
                            }
                            return result
                        })
                    )
                })
            )

            //console.log("deletePipevalue " + deletePipeValue)
            //return null
            // If a delete error don't do the update, or should we just skip and do the update?
            //if (deletePipeValue == null) return null
        }
         */

        const url = this.createQueryBuilder()
            .upload()
            .language(language)
            .key(key)
            .value(value)

        // Should this be translated?
        if (toTranslate) {
            url.translatable()
        }

        // Was this originally translated?
        if (originallyTranslatable) {
            url.originallyTranslatable()
        }

        return this.jwtToken.pipe(
            switchMap((token) => {
                const req = this.apiClient
                    .queryBuilder(url.build())
                    .patch()
                    .addJwtAuth(token)
                    .build()

                return this.apiClient.send(req).pipe(
                    catchError((e) => {
                        console.error(e)
                        return of(null)
                    }),
                    map((result) => {
                        if (result === null) {
                            return null
                        }
                        return result
                    })
                )
            })
        )


    }


}
