<div class="create-permission-form-container">
    <h2 mat-dialog-title>Create new permission</h2>

    <div mat-dialog-content>
        <form [formGroup]="createPermissionFormGroup">
            <div fxLayout="row">
                <mat-form-field>
                    <mat-label>
                        {{ "core.user.user_metadata.permission.create.name" | translate }}
                    </mat-label>
                    <input
                    matInput
                        [formControlName]="FC_NAME"
                    >
                </mat-form-field>
                <!-- <mat-form-field>
                    <mat-label> 
                        {{ "core.user.user_metadata.request_permission.message" | translate }}
                    </mat-label>
                    <input
                    matInput
                    required
                    [formControlName]="FC_MESSAGE"
                    >
                </mat-form-field> -->
            </div>
            <div id="edit-btns">
                <button
                mat-raised-button
                color="primary"
                [disabled]="!createPermissionFormGroup.valid"
                    (click)="onCreatePermission()"
                    >
                    {{ "core.user.user_metadata.request_permission.button" | translate }}
                </button>
                <a [routerLink]="['permissions']"></a>
            </div>
        </form>
    </div>
</div>