import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { Collection } from '../../dto/Collection.output.dto';
import { CollectionService } from '../../services/collection.service';
import { AlertService } from '@symbiota2/ui-common';
import { PageEvent } from '@angular/material/paginator';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { UserService } from '@symbiota2/ui-common';
import { filter, take } from 'rxjs/operators';
import { Comment } from '../../dto/Comment.output.dto';
import { CommentService } from '../../services/comments.service';
import { Observable, Subscription } from 'rxjs';
import { once } from 'node:events';
import { DeleteCommentDialogComponent } from '../../components/delete-comment-dialog/delete-comment-dialog.component';

@Component({
    selector: 'symbiota2-collection-comment-page',
    templateUrl: './collection-comment-page.component.html',
    styleUrls: ['./collection-comment-page.component.scss'],
})
export class CollectionCommentPage implements OnInit {
    private subscriptions: Subscription = new Subscription();

    public collection$: Observable<Collection>;
    collectionID: number;

    // public commentsObs$: Observable<Comment[]>;
    public commentList: Comment[];
    public commentSlice: Comment[];

    constructor(
        private readonly activeRoute: ActivatedRoute,
        private readonly collections: CollectionService,
        private readonly alerts: AlertService,
        private readonly user: UserService,
        private readonly comments: CommentService,
        public dialog: MatDialog,
        private readonly router: Router, 
        private readonly activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        //setting collection variable for html access
        this.collection$ = this.getCollection();
        console.log('curren rotue: ', typeof this.router.url)

        //once collection is pulled from api pull said collections comments/set data reliant on collection
        this.subscriptions.add(this.collection$.pipe(
            map(collection => {

                this.getCollectionComments(this.collectionID)
                // this.commentsObs$.subscribe(comments => {
                //     this.commentSlice = comments.slice(0, 5);
                //     this.commentList = comments;
                // })
                .subscribe(comments => {
                    console.log('comments: ', comments)
                    this.commentList = comments;
                    this.commentSlice = this.commentList.slice(0, 5);
                });
            })
        ).subscribe())
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getCollection(): Observable<Collection> {
        return this.activeRoute.paramMap
            .pipe(
                map((params) => parseInt(params.get('collectionID'))),
                tap((collectionID) => {
                    this.collectionID = collectionID;
                    this.collections.setCollectionID(collectionID);
                }),
                switchMap(() => {
                    return this.collections.currentCollection;
                })
            );
    }

    private getCollectionComments(collectionID: number): Observable<Comment[]> {
        return this.comments
            .getComments({collectionID})
            .pipe(
                map((comments) => {
                    return comments;
                })
            );
    }

    isAdmin(): boolean {
        // eslint-disable-next-line no-var
        var result;

        this.user.currentUser
            .pipe(filter((user) => user !== null))
            .subscribe((user) => {
                result = user.isSuperAdmin();
            });

        return result;
    }

    onPageChanged(e: PageEvent) {
        const startIndex = e.pageIndex * e.pageSize;
        let endIndex = startIndex + e.pageSize;
        if (endIndex > this.commentList.length) {
            endIndex = this.commentList.length;
        }
        this.commentSlice = this.commentList.slice(startIndex, endIndex);
    }

    togglePublic(comment: Comment) {
        // if (comment.reviewStatus) {
        //     this.comments.setPublic(comment.id, 0);
        // } else {
        //     this.comments.setPublic(comment.id, 1);
        // }
    }

    toggleStatus(comment: Comment) {
        if (Number(comment.reviewStatus)) {
            this.comments.setStatus({id: comment.id, status: 0}).pipe(take(1)).subscribe(() => {
                const cmt = this.commentList.find(cmt => cmt.id === comment.id);
                cmt.reviewStatus = 0;
            }, (error) => this.alerts.showError(`Something happened changing the status: ${error.error}`));

        } else {
            this.comments.setStatus({id: comment.id, status: 1}).pipe(take(1)).subscribe();
            const cmt = this.commentList.find(cmt => cmt.id === comment.id);
                cmt.reviewStatus = 1;
        }
    }

    deleteComment(comment: Comment) {
        const dialogRef = this.dialog.open(DeleteCommentDialogComponent, {
            width: '250px'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == 'true') {
                this.comments.deleteComment(comment.id).subscribe(() => {
                    this.commentList = this.commentList.filter(cmt => cmt.id !== comment.id);
                    this.alerts.showMessage('Comment deleted successfully.');
                    this.reloadComponent(this.router.url);
                },
                (err) => this.alerts.showError(`an error occurred deleting the comment: ${err.error}`))
            }
        })
    }

    reloadComponent(url: string) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([`${url}`], {relativeTo: this.activatedRoute}));
    }
}
