import { Exclude, Expose, plainToClass } from 'class-transformer';

@Exclude()
export class ChecklistDto {
    @Expose() id: number
    @Expose() name: string
    @Expose() title: string
    @Expose() locality: string
    @Expose() publication: string
    @Expose() abstract: string
    @Expose() authors: string
    @Expose() notes: string
    @Expose() type: string
    @Expose() latCentroid: number | null
    @Expose() longCentroid: number | null
    @Expose() pointRadiusMeters: number | null
    @Expose() footprintWKT: string
    @Expose() access: string
    @Expose() defaultSettings: string

    static fromJSON(checklistListJson: Record<string, unknown>): ChecklistDto {
        return plainToClass(
            ChecklistDto,
            checklistListJson,
            { excludeExtraneousValues: true, enableImplicitConversion: true }
        );
    }
}