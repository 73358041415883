<button mat-button [matMenuTriggerFor]="navbarItems">
    {{ title | translate }}
</button>
<mat-menu #navbarItems="matMenu">
    <ng-container *ngFor="let link of items">
        <ng-container *ngIf="!link.subMenu; else menu">
            <ng-container *ngIf="isStringURL(link.url); else route">
                <a mat-menu-item *ngIf='!link.openInNewTab' href="{{link.url}}">
                    {{ link.name | translate }}
                </a>
                <a mat-menu-item *ngIf='link.openInNewTab' href="{{link.url}}" target='_blank'>
                    {{ link.name | translate }}
                </a>
            </ng-container>
            <ng-template #route>
                <a mat-menu-item *ngIf='!link.openInNewTab' [routerLink]="link.url">
                    {{ link.name | translate }}
                </a>
                <a mat-menu-item *ngIf='link.openInNewTab' [routerLink]="link.url" target='_blank'>
                    {{ link.name | translate }}
                </a>
            </ng-template>
        </ng-container>
        <ng-template #menu>
            <button mat-menu-item [matMenuTriggerFor]="subMenu"> {{ link.name | translate }}</button>
            <mat-menu #subMenu="matMenu">
                <ng-container *ngFor="let sub of link.subMenu">
                    <ng-container *ngIf="isStringURL(sub.url); else route">
                        <a mat-menu-item *ngIf='!link.openInNewTab' href="{{sub.url}}">
                            {{ sub.name | translate }}
                        </a>
                        <a mat-menu-item *ngIf='link.openInNewTab' href="{{sub.url}}" target='_blank'>
                            {{ sub.name | translate }}
                        </a>
                    </ng-container>
                    <ng-template #route>
                        <a mat-menu-item *ngIf='!link.openInNewTab' [routerLink]="sub.url">
                            {{ sub.name | translate }}
                        </a>
                        <a mat-menu-item *ngIf='link.openInNewTab' [routerLink]="sub.url" target='_blank'>
                            {{ sub.name | translate }}
                        </a>
                    </ng-template>
                </ng-container>
            </mat-menu>
        </ng-template>
    </ng-container>
</mat-menu>
