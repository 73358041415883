/* eslint-disable @angular-eslint/component-selector */
import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ChecklistService } from "../../services/checklist/checklist.service";
import { ChecklistTaxaFilterService, TaxonData } from "../filter-form-service";
import { FlashCardGameService } from "../flash-card.service";

export interface TaxonImages {
    url?: string,
    thumbnailUrl: string,
    title: string,
    synonyms?: [],
    commonNames?: [],
    family?: string,
    alt?: string,
    taxonID: number,
    error?: string
}

@Component({
    selector: 'checklist-list-taxa',
    templateUrl: './checklist-taxon-list.html',
    styleUrls: ['./checklist-taxon-list.component.scss'],
})
export class ChecklistListTaxaComponent implements OnChanges {
    @Input() data
    @Input() taxon // not needed ??!!!
    @Output() page = new EventEmitter();

    checklistTaxaFilterFormData
    taxonAuthor=false;
    filterCheck = 'default';
    taxonSearchTerm = '';
    taxonDisplayImages = false;
    imagesAsc=false;
    commonNames=false
    hasSynonyms=false

    sortedFamily
    getSortedFamily
    sortedTaxa
    familyPageList
    taxaPageList
    imagesPageList
    allPages
    allImages: TaxonImages[] = [];
    imageIndex
    imageCount
    familyItems=0
    taxonItems=0
    imageItems=0
    currentPage = 0;
    currentRoute
    newPage
    pageCheck = false;
    familyPageCount = 20;
    taxaPageCount = 100;
    imagesPageCount = 150
    filteredFamily
    filteredTaxa
    filteredImages

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private checklistTaxaFilter: ChecklistTaxaFilterService,
        private checklistService: ChecklistService,
    ) {

        this.setDefaultParam()
    }

    // set default param
    setDefaultParam() {
        this.navigateToDefaultPage()
    }


    async ngOnChanges() {

       if (this.data) this.defaultPaginate(this.filterCheck)

       if (this.data) this.filterByTaxa(0, this.taxaPageCount)

    if (this.data) {
        this.checklistTaxaFilter.filterFormData$.subscribe(data => {
            this.checklistTaxaFilterFormData = data

                if (this.checklistTaxaFilterFormData?.taxonSearch) {
                    this.taxonSearchTerm = this.checklistTaxaFilterFormData?.taxonSearch
                } else {
                    this.taxonSearchTerm = ''
                }

                if (this.checklistTaxaFilterFormData?.taxonSynonyms) {
                    this.hasSynonyms = true
                } else {
                    this.hasSynonyms = false
                }

                if (this.checklistTaxaFilterFormData?.filterAuthors) {
                    this.taxonAuthor = true
                } else {
                    this.taxonAuthor = false;
                }

                if (this.checklistTaxaFilterFormData?.taxonCommonNames && 
                    this.checklistTaxaFilterFormData?.taxonSearch || 
                    this.checklistTaxaFilterFormData?.filterCommonNames) {
                    this.commonNames = true
                } else {
                    this.commonNames = false;
                }

                if (this.checklistTaxaFilterFormData?.filterOrderAsc === true && 
                    this.checklistTaxaFilterFormData?.filterImages === true) {
                    this.taxaDisplayImages(true)
                    this.imageCount = this.allImages.filter(image => !image.error).length;
                } else {
                    this.taxaDisplayImages(false)
                    this.imageCount = this.allImages.filter(image => !image.error).length;
                }
                
                if (this.checklistTaxaFilterFormData?.filterImages === true) {
                    this.filterCheck = 'image';
                    
                } else if (this.checklistTaxaFilterFormData?.filterOrderAsc === true) {
                    this.filterCheck = 'taxa';
                    
                } else {
                    this.filterCheck = 'default';
                    this.filterByFamily(0, this.familyPageCount)
                }

                this.navigateToDefaultPage()

                // taxa only display
                if (this.filterCheck === 'taxa') {

                    this.taxonItems = (this.sortedTaxa.length / this.taxaPageCount);
                    this.taxaPageList = Array(Math.ceil(this.taxonItems)).fill(Math.ceil(this.taxonItems)).map((_x, i) => i)
                    this.route.queryParams.subscribe(queryParams => {
                        this.newPage = queryParams['page']
                        this.filterByTaxa(parseInt(this.newPage), this.taxaPageCount)
                    })
                }

                // images only display
                if (this.filterCheck === 'image') {
                    this.imageItems = (this.allImages.length / this.imagesPageCount);
                    this.imagesPageList = Array(Math.ceil(this.imageItems)).fill(Math.ceil(this.imageItems)).map((_x, i) => i)
                    this.route.queryParams.subscribe(queryParams => {
                        this.newPage = queryParams['page']
                        this.filterByImages(parseInt(this.newPage), this.imagesPageCount)
                    })
                }
                
            })

       }

    }


    navigateToDefaultPage() {
        this.currentPage = 0;
        const queryParams: Params = { page: '0' };

        this.router.navigate(
            [],
            {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
        });
    }


    defaultPaginate(filter) {

        this.filterByFamily(0, this.familyPageCount)

        if (filter === 'default') {
            this.familyItems = (this.data.family.length / this.familyPageCount);

            this.familyPageList = Array(Math.ceil(this.familyItems)).fill(Math.ceil(this.familyItems)).map((_x,i)=>i);

            this.route.queryParams.subscribe(queryParams => {

                this.newPage = queryParams['page'];

                this.filterByFamily(parseInt(this.newPage), this.familyPageCount)

            });
        }
    }

    checkPaginationIndex(index: number) {
        return Math.abs(this.currentPage - index) < 1
    }

    async filterByFamily(pageStart, pageLimit) {
        // paginating family + taxa
        const newP = parseInt(pageStart + 1)
        const counter = newP * pageLimit;
       if (counter) this.allPages = Array(counter).fill(counter).map((_x,i)=>i);
        const temp = newP > 1 ? (newP * pageLimit) - pageLimit : 0;
        this.allPages.splice(0, temp);

        // sort taxa
        const sortedTaxa = this.sortTaxa(this.data.allData)

        // sort family
        this.sortedFamily = this.sortFamily(sortedTaxa)

        this.getSortedFamily = this.sortedFamily
        
        //search a single name with family
        if (this.taxonSearchTerm) this.sortedFamily = await this.searchTaxa(this.sortedFamily, this.commonNames, this.hasSynonyms);

        // filter family
        this.filteredFamily = this.sortedFamily.filter((data: [string, []], i) => {
                return i >= this.allPages[0] && i <= this.allPages[this.allPages.length - 1];
        });

    }


    async filterByTaxa(pageStart, pageLimit) {
        // paginating taxa
        const newP = parseInt(pageStart + 1)
        const counter = newP * pageLimit;
        this.allPages = Array(counter).fill(counter).map((_x,i)=>i);
        const temp = newP > 1 ? (newP * pageLimit) - pageLimit : 0;
        this.allPages.splice(0, temp);

        // flatten and sort taxa
        const isArray = Object.entries(this.data.allData)
        const taxa = isArray.map((data: [string, string[]]) => data[1])
        this.sortedTaxa = taxa.reduce((acc, el) => acc.concat(el), []).sort((a: any, b: any) => a.sciName.localeCompare(b.sciName))

        // Search a single name 
        if (this.taxonSearchTerm) this.sortedTaxa = await this.searchTaxa(this.sortedTaxa, this.commonNames, this.hasSynonyms)

        // filter taxa
        this.filteredTaxa = this.sortedTaxa.filter((data, i) => {
            return i >= this.allPages[0] && i <= this.allPages[this.allPages.length - 1]
        })

    }


    async filterByImages(pageStart, pageLimit) {
        // paginating images
        const newP = parseInt(pageStart + 1)
        const counter = newP * pageLimit;
        this.allPages = Array(counter).fill(counter).map((_x,i)=>i);
        const temp = newP > 1 ? (newP * pageLimit) - pageLimit : 0;
        this.allPages.splice(0, temp);

        // search a single name with image
        if (this.taxonSearchTerm) this.allImages = await this.searchTaxa(this.allImages, this.commonNames, this.hasSynonyms)

        // filter images
        this.filteredImages = this.allImages.filter((data, i) => {
            return i >= this.allPages[0] && i <= this.allPages[this.allPages.length - 1]
        })
    }



    // sort taxa
    sortTaxa(taxa) {
        const isArray = Object.entries(taxa);
        this.sortedFamily = isArray.map((taxa: [string, []], i) => {
            taxa[1].sort((a: TaxonData, b: TaxonData) => a.sciName.localeCompare(b.sciName))
        })

        return isArray;
    }
    

    sortFamily(family) {
        return family.sort((a:[string, []], b:[string, []]) => {
            return a[0].localeCompare(b[0]);
        });
    }




    searchTaxa(data, commonName=false, hasSynonyms=false) {

        if (Array.isArray(data[0][1])) {

            const searchResult = data.map((data: [string, TaxonData[]], i) => {
                const taxa = []
                data[1].forEach(taxon => {
                    if (commonName) {
                        if (taxon.commonNames.length > 0) {
                            if (taxon.commonNames.filter((cName: string) => cName.toUpperCase() === this.taxonSearchTerm.toUpperCase()).length > 0) taxa.push(taxon)
                        }
                        //return
                    }
                    if (hasSynonyms) {
                        const allSyns = []
                        taxon.synonym.forEach(syn => allSyns.push(syn.scientificName))
                        if (allSyns.filter(syn => syn.toUpperCase() === this.taxonSearchTerm.toUpperCase()).length > 0) taxa.push(taxon)
                    }
                    if (taxon.sciName.includes(this.taxonSearchTerm)) taxa.push(taxon)
                })
                if (taxa.length > 0) return [data[0], taxa]
              }).filter(taxon => taxon !== undefined)

              if (searchResult.length > 0) return searchResult;
              return [['Name not found!']]
        }

        else if (data[0].taxonID) {
            
            if (commonName) {
                const searchResult = data.filter(image => {
                    if (image.commonNames.filter((cName:string) => cName.toUpperCase() === this.taxonSearchTerm.toUpperCase()).length > 0) return true;
                })
            
                if (searchResult.length> 0) {
                    data = null;
                    return searchResult;
                }
            }
            if (hasSynonyms) {
                const searchResult = data.filter(image => {
                    if (image.synonyms.find(syn => syn.scientificName.toUpperCase() === this.taxonSearchTerm.toUpperCase())) return true
                    return false
                })

                if (searchResult.length > 0) {
                    data = null;
                    return searchResult;
                }
            }

            const searchResult = data.filter(image => image.title.includes(this.taxonSearchTerm))
            if (searchResult.length > 0) {
                data = null;
                return searchResult;
            }
            return [{
                thumbnailUrl: '',
                title: 'Name not found!',
                family: '',
                alt: ''
            }]

        } else {
            if (commonName) {
                const searchResult = data.filter(taxon => {
                    if (taxon.commonNames.filter((cName:string) => cName.toUpperCase() === this.taxonSearchTerm.toUpperCase()).length > 0) return true;
                })
            
                if (searchResult.length> 0) {
                    data = null;
                    return searchResult;
                }
            }
            if (hasSynonyms) {
                const searchResult = data.filter(taxon => {
                    if (taxon.synonym.find(tx => tx.scientificName.toUpperCase() === this.taxonSearchTerm.toUpperCase())) return true
                    return false
                })

                if (searchResult.length> 0) {
                    data = null;
                    return searchResult;
                }
            }
            
            const searchResult = data.filter(taxon => taxon.sciName.includes(this.taxonSearchTerm))
            if (searchResult.length > 0) {
                data = null;
                return searchResult;
            }
            return ['Name not found!']
        }

    }


    taxaDisplayImages(asc) {

        if (!asc) {
            this.allImages = []
            this.getSortedFamily.forEach((data, tIndex) => {
         
                data[1].forEach(taxon => {
                    if (taxon.images.length > 0) {
                        const reduceResult = taxon.images.reduce((prev, current) => (prev.sortSequence < current.sortSequence) ? prev : current )
                        this.allImages.push({
                            thumbnailUrl: reduceResult.thumbnailUrl,
                            title: taxon.sciName,
                            family: taxon.family,
                            alt: taxon.sciName,
                            taxonID: taxon.id,
                            synonyms: taxon.synonym,
                            commonNames: taxon.commonNames
                        })
                    } else {
                        this.allImages.push({
                            thumbnailUrl: '',
                            title: ' No image added yet for: \n' + '  (' + taxon.sciName + ')',
                            alt: '',
                            family:  taxon.family,
                            taxonID: taxon.id,
                            error: 'Image not available yet!',
                            synonyms: taxon.synonym,
                            commonNames: taxon.commonNames
                        })
                    }
                })
            })
            
        } else {
                this.allImages = []
                this.sortedTaxa.forEach((taxon, tIndex) => {
                        
                if (taxon.images.length > 0) {
                    const leastSequenceOrder = taxon.images.reduce((prev, current) => (prev.sortSequence < current.sortSequence) ? prev : current )
                    // taxon.images.forEach((image, iIndex) => {
                    
                        this.allImages.push({
                            thumbnailUrl: leastSequenceOrder.thumbnailUrl,
                            title: taxon.sciName,
                            family: taxon.family,
                            alt: taxon.sciName,
                            taxonID: taxon.id,
                            synonyms: taxon.synonym,
                            commonNames: taxon.commonNames
                        })
                    // })

                } else {
                    this.allImages.push({
                        thumbnailUrl: '',
                        title: ' No image added yet for: \n' + '  (' + taxon.sciName + ')',
                        alt: '',
                        family:  taxon.family,
                        taxonID: taxon.id,
                        error: 'Image not available yet!',
                        synonyms: taxon.synonym,
                        commonNames: taxon.commonNames
                    })
                }
            })
        }
    }

}

