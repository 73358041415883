<form id="form" [formGroup]="createCollectionForm">
    <mat-card id="icon" class="mat-elevation-z0 s2-field">
        <div fxLayout="row" fxLayoutGap=".5rem">
            <symbiota2-collection-logo
                size="7rem"
                src="{{ createCollectionForm.value.icon }}"
            ></symbiota2-collection-logo>
            <div fxLayout="column" fxLayoutAlign="space-between">
                <mat-card-title>
                    <symbiota2-editable-text key='collection.create_collection.icon_field.label'></symbiota2-editable-text>
                </mat-card-title>
                <mat-card-subtitle>
                    <symbiota2-editable-text key='collection.create_collection.icon_field.hint'></symbiota2-editable-text>
                </mat-card-subtitle>
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        placeholder="{{
                            'collection.create_collection.icon_field.example'
                                | translate
                        }}"
                        formControlName="icon"
                    />
                </mat-form-field>
                <symbiota2-editable-text key='collection.create_collection.icon_field.example' hide='true'></symbiota2-editable-text>
            </div>
        </div>
    </mat-card>
    <mat-card id="collection_name_code" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.name_code.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.name_code.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <div id="two-field-row" fxLayout="row" fxLayoutGap=".5rem">
            <mat-form-field appearance="outline">
                <input
                    matInput
                    placeholder="{{
                        'collection.create_collection.name_code.name.example'
                            | translate
                    }}"
                    formControlName="collectionName"
                />
                <mat-error
                    *ngIf="
                        createCollectionForm
                            .get('collectionName')
                            .hasError('required')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.name_code.name.error.required'></symbiota2-editable-text>
                </mat-error>
                <mat-error
                    *ngIf="
                        createCollectionForm
                            .get('collectionName')
                            .hasError('nameTaken')
                    "
                >
                <symbiota2-editable-text key='collection.create_collection.name_code.name.error.taken'></symbiota2-editable-text>
                </mat-error>
                <mat-error
                *ngIf="
                    createCollectionForm
                        .get('collectionName')
                        .hasError('maxlength')
                "
            >
                <symbiota2-editable-text key='collection.create_collection.error.max_length'></symbiota2-editable-text>
            </mat-error>
            </mat-form-field>
            <symbiota2-editable-text key='collection.create_collection.name_code.name.example' hide='true'></symbiota2-editable-text>
            <mat-form-field appearance="outline" class="small-field">
                <input
                    matInput
                    placeholder="{{
                        'collection.create_collection.name_code.code.example'
                            | translate
                    }}"
                    oninput="this.value = this.value.toUpperCase()"
                    formControlName="collectionCode"
                />
                <mat-error
                    *ngIf="
                        createCollectionForm
                            .get('collectionCode')
                            .hasError('required')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.name_code.code.error.required'></symbiota2-editable-text>
                </mat-error>
                <mat-error
                    *ngIf="
                        createCollectionForm
                            .get('collectionCode')
                            .hasError('codeTaken')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.name_code.code.error.taken'></symbiota2-editable-text>
                </mat-error>
            </mat-form-field>
            <symbiota2-editable-text key='collection.create_collection.name_code.code.example' hide='true'></symbiota2-editable-text>
        </div>
    </mat-card>
    <mat-card id="institution" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.institution.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card
            class="mat-elevation-z0 s2-field outline"
        >
            <mat-radio-group formControlName="instOption" fxLayout="column">
                <mat-radio-button value="create"></mat-radio-button>
                <mat-card
                    id="create-inst"
                    class="mat-elevation-z0 s2-field no-padding"
                >
                    <mat-card-title>
                        <symbiota2-editable-text key='collection.create_collection.institution.create.label'></symbiota2-editable-text>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <symbiota2-editable-text key='collection.create_collection.institution.create.hint'></symbiota2-editable-text>
                    </mat-card-subtitle>
                    <div id="two-field-row" fxLayout="row" fxLayoutGap=".5rem">
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                placeholder="{{
                                    'collection.create_collection.institution.create.name.example'
                                        | translate
                                }}"
                                formControlName="institutionName"
                            />
                            <mat-error
                                *ngIf="
                                    createCollectionForm
                                        .get('institutionName')
                                        .hasError('required')
                                "
                            >
                                <symbiota2-editable-text key='collection.create_collection.institution.create.name.error.required'></symbiota2-editable-text>
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    createCollectionForm
                                        .get('institutionName')
                                        .hasError('nameTaken')
                                "
                            >
                                <symbiota2-editable-text key='collection.create_collection.institution.create.name.error.taken'></symbiota2-editable-text>
                            </mat-error>
                        </mat-form-field>
                        <symbiota2-editable-text key='collection.create_collection.institution.create.name.example' hide='true'></symbiota2-editable-text>
                        <mat-form-field
                            appearance="outline"
                            class="small-field"
                        >
                            <input
                                matInput
                                placeholder="{{
                                    'collection.create_collection.institution.create.code.example'
                                        | translate
                                }}"
                                oninput="this.value = this.value.toUpperCase()"
                                formControlName="institutionCode"
                            />
                            <mat-error
                                *ngIf="
                                    createCollectionForm
                                        .get('institutionCode')
                                        .hasError('required')
                                "
                            >
                                <symbiota2-editable-text key='collection.create_collection.institution.create.code.error.required'></symbiota2-editable-text>
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    createCollectionForm
                                        .get('institutionCode')
                                        .hasError('codeTaken')
                                "
                            >
                                <symbiota2-editable-text key='collection.create_collection.institution.create.code.error.taken'></symbiota2-editable-text>
                            </mat-error>
                        </mat-form-field>
                        <symbiota2-editable-text key='collection.create_collection.institution.create.code.example' hide='true'></symbiota2-editable-text>
                    </div>
                </mat-card>
                <mat-radio-button value="select"> </mat-radio-button>
                <mat-card
                    id="select-inst"
                    class="mat-elevation-z0 s2-field no-padding"
                >
                    <mat-card-title>
                        <symbiota2-editable-text key='collection.create_collection.institution.select.label'></symbiota2-editable-text>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <symbiota2-editable-text key='collection.create_collection.institution.select.hint'></symbiota2-editable-text>
                    </mat-card-subtitle>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-select
                            formControlName="institutionID"
                            placeholder="{{
                                'collection.create_collection.institution.select.menu'
                                    | translate
                            }}"
                        >
                            <mat-option
                                *ngFor="let i of inst$ | async"
                                value="{{ i.id }}"
                                >({{ i.code }}) {{ i.name }}</mat-option
                            >
                        </mat-select>
                        <mat-error
                            *ngIf="
                                createCollectionForm
                                    .get('institutionID')
                                    .hasError('required')
                            "
                        >
                            <symbiota2-editable-text key='collection.create_collection.institution.select.error'></symbiota2-editable-text>
                        </mat-error>
                    </mat-form-field>
                    <symbiota2-editable-text key='collection.create_collection.institution.select.menu' hide='true'></symbiota2-editable-text>
                </mat-card>
            </mat-radio-group>
        </mat-card>
    </mat-card>
    <mat-card id="collection_type" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.type.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.type.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <mat-form-field appearance="outline">
            <mat-select
                formControlName="type"
                placeholder="{{
                    'collection.create_collection.type.menu' | translate
                }}"
            >
                <mat-option value="Preserved Specimens">{{
                    'collection.create_collection.type.option.preserved_specimen'
                        | translate
                }}</mat-option>
                <mat-option value="General Observations">{{
                    'collection.create_collection.type.option.general_observations'
                        | translate
                }}</mat-option>
            </mat-select>
            <mat-error
                *ngIf="createCollectionForm.get('type').hasError('required')"
            >
                <symbiota2-editable-text key='collection.create_collection.type.error.required'></symbiota2-editable-text>
            </mat-error>
        </mat-form-field><symbiota2-editable-text key='collection.create_collection.type.menu' hide='true'></symbiota2-editable-text>
    </mat-card>
    <mat-card id="managment" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.management.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.management.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <mat-form-field appearance="outline">
            <mat-select
                formControlName="management"
                placeholder="{{
                    'collection.create_collection.management.menu' | translate
                }}"
            >
                <!-- //TODO: api will handle populating collection types -->
                <mat-option value="snapshot">{{
                    'collection.create_collection.management.menu.option.snapshot'
                        | translate
                }}</mat-option>
                <mat-option value="liveData">{{
                    'collection.create_collection.management.menu.option.live_data'
                        | translate
                }}</mat-option>
            </mat-select>
            <mat-error
                *ngIf="
                    createCollectionForm.get('management').hasError('required')
                "
            >
            <symbiota2-editable-text key='collection.create_collection.management.error.required'></symbiota2-editable-text>
            </mat-error>
        </mat-form-field>
        <symbiota2-editable-text key='plugins.occurrence.search.filter.text' hide='true'></symbiota2-editable-text>
    </mat-card>
    <mat-card id="category" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.category.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.category.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <mat-form-field appearance="outline">
            <mat-select
                formControlName="categoryID"
                placeholder="{{
                    'collection.create_collection.category.menu' | translate
                }}"
            >
                <mat-option
                    *ngFor="let cat of categories$ | async"
                    [value]="cat.id"
                    >{{ cat.category }}</mat-option
                >
            </mat-select>
            <mat-error
                *ngIf="
                    createCollectionForm.get('categoryID').hasError('required')
                "
            >
                <symbiota2-editable-text key='collection.create_collection.category.error'></symbiota2-editable-text>
            </mat-error>
        </mat-form-field>
        <symbiota2-editable-text key='collection.create_collection.category.menu' hide='true'></symbiota2-editable-text>
    </mat-card>
    <mat-card id="homepage" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.homepage.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.homepage.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <mat-form-field appearance="outline">
            <input
                matInput
                formControlName="homePage"
                placeholder="{{
                    'collection.create_collection.homepage.example' | translate
                }}"
            />
        </mat-form-field>
        <symbiota2-editable-text key='collection.create_collection.homepage.example' hide='true'></symbiota2-editable-text>
    </mat-card>
    <mat-card id="license" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.license.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.license.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <mat-form-field appearance="outline">
            <input
                matInput
                formControlName="rights"
                placeholder="{{
                    'collection.create_collection.license.example' | translate
                }}"
            />
            <mat-error
                *ngIf="createCollectionForm.get('rights').hasError('required')"
            >
                <symbiota2-editable-text key='collection.create_collection.license.error'></symbiota2-editable-text>
            </mat-error>
        </mat-form-field>
        <symbiota2-editable-text key='collection.create_collection.license.example' hide='true'></symbiota2-editable-text>
    </mat-card>
    <mat-card id="coordinates" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.coordinates.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.coordinates.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <div fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="start center">
            <h3>
                <symbiota2-editable-text key='collection.create_collection.coordinates.latitude.label'></symbiota2-editable-text>
            </h3>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="number"
                    step=".0001"
                    formControlName="latitude"
                    placeholder="{{
                        'collection.create_collection.coordinates.latitude.example'
                            | translate
                    }}"
                />
                <mat-error
                    *ngIf="
                        createCollectionForm
                            .get('latitude')
                            .hasError('required')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.coordinates.latitude.error.required'></symbiota2-editable-text>
                </mat-error>
                <mat-error
                    *ngIf="
                        createCollectionForm.get('latitude').hasError('min') ||
                        createCollectionForm.get('latitude').hasError('max') ||
                        createCollectionForm.get('latitude').hasError('number')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.coordinates.latitude.error.invalid'></symbiota2-editable-text>
                </mat-error>
            </mat-form-field>
            <symbiota2-editable-text key='collection.create_collection.coordinates.latitude.example' hide='true'></symbiota2-editable-text>
        </div>
        <div fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="start center">
            <h3>
                <symbiota2-editable-text key='collection.create_collection.coordinates.longitude.label'></symbiota2-editable-text>
            </h3>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="number"
                    step=".0001"
                    formControlName="longitude"
                    placeholder="{{
                        'collection.create_collection.coordinates.longitude.example'
                            | translate
                    }}"
                />
                <mat-error
                    *ngIf="
                        createCollectionForm
                            .get('longitude')
                            .hasError('required')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.coordinates.longitude.error.required'></symbiota2-editable-text>
                </mat-error>
                <mat-error
                    *ngIf="
                        createCollectionForm.get('longitude').hasError('min') ||
                        createCollectionForm.get('longitude').hasError('max') ||
                        createCollectionForm.get('longitude').hasError('number')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.coordinates.longitude.error.invalid'></symbiota2-editable-text>
                </mat-error>
            </mat-form-field>
            <symbiota2-editable-text key='collection.create_collection.coordinates.longitude.example' hide='true'></symbiota2-editable-text>
        </div>
    </mat-card>
    <mat-card id="description" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.description.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.description.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <mat-form-field appearance="outline" class="full-width">
            <textarea
                id="description-text-area"
                maxlength="2000"
                rows="10"
                matInput
                formControlName="fullDescription"
                placeholder="{{
                    'collection.create_collection.description.example'
                        | translate
                }}"
            ></textarea>
        </mat-form-field>
        <symbiota2-editable-text key='collection.create_collection.description.example' hide='true'></symbiota2-editable-text>
    </mat-card>
    <mat-card id="administration" class="mat-elevation-z0 s2-field">
        <mat-card-title>
            <symbiota2-editable-text key='collection.create_collection.administrator.label'></symbiota2-editable-text>
        </mat-card-title>
        <mat-card-subtitle>
            <symbiota2-editable-text key='collection.create_collection.administrator.hint'></symbiota2-editable-text>
        </mat-card-subtitle>
        <div fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="start center">
            <h3>
                <symbiota2-editable-text key='collection.create_collection.administrator.name.label'></symbiota2-editable-text>
            </h3>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    formControlName="contact"
                    placeholder="{{
                        'collection.create_collection.administrator.name.example'
                            | translate
                    }}"
                />
                <mat-error
                    *ngIf="
                        createCollectionForm.get('contact').hasError('required')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.administrator.name.error'></symbiota2-editable-text>
                </mat-error>
            </mat-form-field>
            <symbiota2-editable-text key='collection.create_collection.administrator.name.example' hide='true'></symbiota2-editable-text>
        </div>
        <div fxLayout="row" fxLayoutGap=".5rem" fxLayoutAlign="start center">
            <h3>
                <symbiota2-editable-text key='collection.create_collection.administrator.email.label'></symbiota2-editable-text>
            </h3>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="email"
                    formControlName="email"
                    placeholder="{{
                        'collection.create_collection.administrator.email.example'
                            | translate
                    }}"
                />
                <mat-error
                    *ngIf="
                        createCollectionForm.get('email').hasError('required')
                    "
                >
                    <symbiota2-editable-text key='collection.create_collection.administrator.email.error.required'></symbiota2-editable-text>
                </mat-error>
                <mat-error
                    *ngIf="createCollectionForm.get('email').hasError('email')"
                >
                    <symbiota2-editable-text key='collection.create_collection.administrator.email.error.invalid'></symbiota2-editable-text>
                </mat-error>
            </mat-form-field>
            <symbiota2-editable-text key='collection.create_collection.administrator.email.example' hide='true'></symbiota2-editable-text>
        </div>
    </mat-card>
    <mat-card-actions>
        <button
        id="submit"
        class="submit-button"
        mat-flat-button
        color="primary"
        type="submit"
        (click)="onSubmit()"
        [disabled]="!createCollectionForm.valid"
        style="font-size: large"
    >
        {{ "collection.create_collection.submit.label" | translate }}
    </button>
    <symbiota2-editable-text key='collection.create_collection.submit.label' hide='true'></symbiota2-editable-text>
    </mat-card-actions>
</form>
