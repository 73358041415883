<div id="main">
    <div>
        <h1>
            <symbiota2-editable-text key='taxon.editor.title'></symbiota2-editable-text> - {{taxonName}}
            <span>{{taxonAuthor}}</span>
        </h1>
    </div>

    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="{{'taxon.editor.descriptionBlock' | translate}}">
            <symbiota2-editable-text key='taxon.editor.descriptionBlock' hide='true'></symbiota2-editable-text>
            <taxon-description-editor></taxon-description-editor>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.taxon' | translate}}">
            <symbiota2-editable-text key='taxon.editor.taxon' hide='true'></symbiota2-editable-text>
            <taxon-taxon-editor></taxon-taxon-editor>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.taxonomicStatus' | translate}}">
            <symbiota2-editable-text key='taxon.editor.taxonomicStatus' hide='true'></symbiota2-editable-text>
            <taxon-status-editor></taxon-status-editor>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.taxonomic.hierarchy' | translate}}">
            <symbiota2-editable-text key='taxon.editor.taxonomic.hierarchy' hide='true'></symbiota2-editable-text>
            <taxon-taxon-editor></taxon-taxon-editor>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.taxonomic.child' | translate}}">
            <symbiota2-editable-text key='taxon.editor.taxonomic.child' hide='true'></symbiota2-editable-text>
            <taxon-taxon-editor></taxon-taxon-editor>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.images' | translate}}">
            <symbiota2-editable-text key='taxon.editor.images' hide='true'></symbiota2-editable-text>
            <taxon-image-grid ></taxon-image-grid>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.add.image' | translate}}">
            <symbiota2-editable-text key='taxon.editor.add.image' hide='true'></symbiota2-editable-text>
            <taxon-image-add></taxon-image-add>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.common' | translate}}">
            <symbiota2-editable-text key='taxon.editor.common' hide='true'></symbiota2-editable-text>
            <taxon-vernacular-editor></taxon-vernacular-editor>
        </mat-tab>
        <mat-tab label="{{'taxon.editor.delete' | translate}}">
            <symbiota2-editable-text key='taxon.editor.delete' hide='true'></symbiota2-editable-text>
            <taxon-delete-editor></taxon-delete-editor>
        </mat-tab>
    </mat-tab-group>
</div>
