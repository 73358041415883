<mat-card class="checklist-taxon-search-options">
    <mat-card-title-group>
        <div class="title-holder">
          <mat-card-title class="checklist-filter-taxon-title heading-secondary" >Options</mat-card-title>
        </div>
        <!-- <mat-card-subtitle>Small</mat-card-subtitle> -->
    </mat-card-title-group>
    <mat-card-content class="checklist-upload-form-holder">



        <form [formGroup]="checklistSearchOptionsForm" class="checklist-upload-form">

           <div style="background-color: rgb(248, 248, 201);padding: 1rem">
              <mat-form-field appearance="fill"
              [style.width.%]="100">
                <input matInput placeholder="Search:" formControlName="taxonSearch">
              </mat-form-field>
          
              <mat-checkbox class="example-margin" formControlName="taxonCommonNames">Common names</mat-checkbox><br>
              <mat-checkbox class="example-margin" formControlName="taxonSynonyms">Synonyms</mat-checkbox>
            </div>

            <div class="filter-box">
              <mat-card-title class="heading-tertiary">Filter</mat-card-title>
              <mat-form-field appearance="fill">
                <mat-label>Select</mat-label>
                <mat-select formControlName="filterCentral">
                  <mat-option value="original">Original checklist</mat-option>
                  <mat-option value="central">Central thesaurus</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-checkbox class="example-margin" formControlName="filterImages">Display as images</mat-checkbox><br>
              <ng-container *ngIf="!checklistSearchOptionsForm.value.filterImages">
                <mat-checkbox class="example-margin" formControlName="filterCommonNames">Common names</mat-checkbox><br>
                <mat-checkbox class="example-margin" formControlName="filterVouchers"> Notes & Vouchers</mat-checkbox><br>
                <mat-checkbox class="example-margin" formControlName="filterAuthors">Taxon authors</mat-checkbox><br>
              </ng-container>
              <mat-checkbox class="example-margin" formControlName="filterOrderAsc">Show Species Alphabetically</mat-checkbox><br>
            </div>
              <div>
                  <button mat-stroked-button 
                  (click)="onAddTaxon(checklistSearchOptionsForm.value)" 
                  [disabled]="checklistSearchOptionsForm.invalid">Rebuild list</button>
              </div>
          </form>
          <!-- <div>form value: {{ checklistSearchOptionsForm.value | json }}</div> -->
          <div class="successMessage" *ngIf="hasSuccess">
            {{ successMessage }}
          </div>
          <div *ngIf="hasError" class="errorMessage">
            {{ errorMessage }}
          </div>
    </mat-card-content>
  </mat-card>
