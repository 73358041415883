import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { formToQueryParams, LoadingService } from '@symbiota2/ui-common';
import { OccurrenceService } from '../../services/occurrence.service';
import {
    ApiOccurrenceFindAllParams,
    ApiOccurrenceListItem, ApiTaxonSearchCriterion
} from '@symbiota2/data-access';
import { Observable } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { switchMap, tap } from 'rxjs/operators';
import { OccurrenceList } from '../../dto/occurrence-list';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { OccurrenceListItem } from '../../dto';
import { OccurrenceSearchResultModalComponent } from '../../components';
import { ROUTE_COLLECTION_PROFILE } from '@symbiota2/ui-plugin-collection';
import { Q_PARAM_COLLID } from '../../../constants';
import { ROUTE_SEARCH_DOWNLOAD } from '../../routes';
import { FormGroup, FormControl } from '@angular/forms';
import  * as JSZip from 'jszip';
//import { JSZip } from 'jszip'
import * as FileSaver from 'file-saver';
import * as jsonToXML from 'js2xmlparser';
// import { saveAs } from 'file-saver';
//import * as JSZipUtils from '../../../assets/script/jszip-utils.js';
//import { zipFiles } from '@symbiota2/api-common';

// eslint-disable-next-line no-var

@Component({
    selector: "symbiota2-occurrence-specimen-download",
    templateUrl: "./occurrence-specimen-download-page.component.html",
    styleUrls: ["./occurrence-specimen-download-page.component.scss"]
})
export class OccurrenceSpecimenDownloadPage implements OnInit {
    @ViewChild(MatTable) table: MatTable<ApiOccurrenceListItem>;
    downloadOptions: FormGroup;
    structure: string
    format: string
    characterSet: string
    includes: string[]
    file: string[]

    public limit;
    public offset = 0;
    public newLimit;
    tempData

    readonly SHOW_COLUMNS = [
        'occurrenceID',
        'catalogNumber',
        'scientificNameComputed',
        'scientificName',
        'collection',
        'latitude',
        'longitude'
    ];
    occurrences: Observable<OccurrenceList>;
    testData: any

    constructor(
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        private readonly loading: LoadingService,
        private readonly matDialog: MatDialog,
        private readonly occurrenceService: OccurrenceService) {
            console.log('queryParams inside occurrence-search-results-page .ts: ', this.queryParams)
        }

    get queryParams(): ParamMap {
        return this.currentRoute.snapshot.queryParamMap;
    }

    ngOnInit() {
            this.structure = 'native'
            this.format = 'comma'
            this.characterSet = 'utf-8'
            this.includes = ['history', 'image']
            this.file = ['zip']

            this.getMaxLimit()
            // TODO: Clean this up
            const collectionIDs = this.queryParams.getAll(Q_PARAM_COLLID).map(
                (id) => parseInt(id)
            );
            const taxonSearchCriterion = this.queryParams.get('taxonSearchCriterion');
            const taxonSearchStr = this.queryParams.get('taxonSearchStr');
            const includeSynonymsStr = this.queryParams.get('includeSynonyms')
            const includeSynonyms = includeSynonymsStr ? includeSynonymsStr === 'true' : null

            const country = this.queryParams.get('country');
            const stateProvince = this.queryParams.get('stateProvince');
            const county = this.queryParams.get('county');
            const locality = this.queryParams.get('locality');
            const minimumElevationInMeters = parseInt(this.queryParams.get('minimumElevationInMeters'));
            const maximumElevationInMeters = parseInt(this.queryParams.get('maximumElevationInMeters'));

            const minLatitude = parseInt(this.queryParams.get('minLatitude'));
            const minLongitude = parseInt(this.queryParams.get('minLongitude'));

            const maxLatitude = parseInt(this.queryParams.get('maxLatitude'));
            const maxLongitude = parseInt(this.queryParams.get('maxLongitude'));

            const collectorLastName = this.queryParams.get('collectorLastName');

            const minEventDateStr = this.queryParams.get('minEventDate');
            const maxEventDateStr = this.queryParams.get('maxEventDate');

            const minEventDate = minEventDateStr ? new Date(minEventDateStr) : null;
            const maxEventDate = maxEventDateStr ? new Date(maxEventDateStr) : null;

            const catalogNumber = this.queryParams.get('catalogNumber');

            const limitToSpecimensStr = this.queryParams.get('limitToSpecimens');
            const limitToImagesStr = this.queryParams.get('limitToImages');
            const limitToGeneticStr = this.queryParams.get('limitToGenetic');

            const limitToSpecimens = limitToSpecimensStr ? limitToSpecimensStr === 'true' : null;
            const limitToImages = limitToImagesStr ? limitToImagesStr === 'true' : null;
            const limitToGenetic = limitToGeneticStr ? limitToGeneticStr === 'true' : null;

                const findParams: Omit<ApiOccurrenceFindAllParams, 'limit' | 'offset'> = {
                    collectionID: collectionIDs,
                    taxonSearchCriterion: taxonSearchStr ? taxonSearchCriterion as ApiTaxonSearchCriterion : null,
                    taxonSearchStr: taxonSearchStr,
                    includeSynonyms,
                    county,
                    country,
                    stateProvince,
                    locality,
                    minimumElevationInMeters,
                    maximumElevationInMeters,
                    minLatitude,
                    minLongitude,
                    maxLatitude,
                    maxLongitude,
                    catalogNumber,
                    collectorLastName,
                    limitToSpecimens,
                    limitToImages,
                    limitToGenetic,
                    minEventDate: minEventDate ? minEventDate.toISOString() : null,
                    maxEventDate: maxEventDate ? maxEventDate.toISOString() : null,
                };

                //this.testData = findParams;

                // this.occurrences = this.occurrenceService.searchResults.occurrences.pipe(
                //     tap((data) => {
                //         //this.testData = data;
                //     })
                // );


                // Initial fetch, remaining are updated when searchResults.page()
                // is called

                //this.occurrenceService.searchResults.setQueryParams(findParams);
                // this.occurrenceService.searchResults.changePage(this.limit, this.offset);
                //this.occurrenceService.searchResults.occurrences.subscribe(async data => this.newLimit = data.count)

            // else {
            //     this.router.navigate(
            //         ['..'],
            //         {
            //             relativeTo: this.currentRoute,
            //             queryParams: this.currentRoute.snapshot.queryParams
            //         }
            //     );
            // }



    }


    // collectionURL(id: number): string {
    //     return `/${ROUTE_COLLECTION_PROFILE.replace(':collectionID', id.toString())}`;
    // }

    // onPageChanged(e: PageEvent) {
    //     this.limit = e.pageSize;
    //     this.offset = this.limit * e.pageIndex;
    //     this.occurrenceService.searchResults.changePage(this.limit, this.offset);

    //     // console.log('limit: ', this.limit)
    //     // console.log('offset: ', this.offset)
    //     // console.log('event: ', e)
    // }

    // onOccurrenceSelected(occurrence: OccurrenceListItem) {
    //     console.log('check here: ', occurrence)
    //     this.matDialog.open(
    //         OccurrenceSearchResultModalComponent,
    //         {
    //             panelClass: 'mat-dialog-panel',
    //             width: '90vw',
    //             height: '90%',
    //             data: occurrence
    //         }
    //     );
    // }


    async getMaxLimit() {
        this.limit = await this.occurrenceService.getData()
        console.log('this.limit', this.limit)
        console.log('new limit: ', await this.occurrenceService.getData())
        //this.occurrences = this.occurrenceService.searchResults.findAll(this.testData)
        // this.occurrenceService.searchResults.changePage(this.limit, this.offset);
        // this.occurrences = this.occurrenceService.searchResults.occurrences.pipe(
        //     tap((data) => {
        //         //this.testData = data;
        //     })
        // );
    }

    async processData(download) {

        //this.occurrenceService.searchResults.downloadAll(this.testData).subscribe(data => console.log('data: ', data))

        // this.occurrences = this.occurrenceService.searchResults.occurrences.pipe(
        //     tap((data) => {
        //         //this.testData = data;
        //     })
        // );
        // this.occurrenceService.searchResults.occurrences.subscribe(async data => {
        //     this.testData = data['data']
        // })
        //this.occurrences = this.occurrenceService.searchResults.findAll(this.testData)




        this.occurrenceService.searchResults.changePage(this.limit, this.offset);
        this.occurrences = this.occurrenceService.searchResults.occurrences.pipe(
            tap(async (data) => {
                //
            })
        );

        this.occurrences.subscribe(async occurrences => {
            const { data } = occurrences;
            if (data.length === this.limit) {

                const download = data.map(row => ({
                    id: row.id,
                    institutionCode: row.collection.institutionCode,
                    collectionCode: row.collection.collectionCode,
                    ownerInstitutionCode: row.ownerInstitutionCode,
                    basisOfRecord: row.basisOfRecord,
                    occurrenceID: row.occurrenceGUID,
                    catalogNumber: row.catalogNumber,
                    otherCatalogNumber: row.otherCatalogNumbers,
                    kingdom: row.kingdom,
                    phylum: row.phylum,
                    class: row.class,
                    order: row.order,
                    family: row.family,
                    day: row.day,
                    month: row.month,
                    year: row.year,
                    scientificName: row.scientificName,
                    scientificNameComputed: row.scientificNameComputed,
                    taxonID: row.taxonID,
                    scientificNameAuthorship: row.typeStatus,
                    genus: row.genus,
                    specificEpithet: row.specificEpithet,
                    taxonRank: row.taxonRank,
                    intraspecificEpithet: row.infraspecificEpithet,
                    identifiedBy: row.identifiedBy,
                    dateIdentified: row.dateIdentified,
                    identificationReferences: row.identificationReferences,
                    identificationRemarks: row.identificationRemarks,
                    taxonRemarks: row.taxonRemarks,
                    identificationQualifier: row.identificationQualifier,
                    typeStatus: row.typeStatus,
                    recordedBy: row.typeStatus,
                    recordedByID: row.recordedByID,
                    associatedCollectors: row.associatedCollectors,
                    recordNumber: row.recordNumber,
                    eventDate: row.typeStatus,
                    startDayOfYear: row.startDayOfYear,
                    endDayOfYear: row.endDayOfYear,
                    verbatimEventDate: row.verbatimEventDate,
                    occurrenceRemarks: row.occurrenceRemarks,
                    habitat: row.habitat,
                    substrate: row.substrate,
                    verbatimAttributes: row.verbatimAttributes,
                    fieldNumber: row.fieldNumber,
                    informationWithheld: row.informationWithheld,
                    dataGeneralizations: row.dataGeneralizations,
                    dynamicProperties: row.dynamicProperties,
                    associatedTaxa: row.associatedTaxa,
                    reproductiveCondition: row.reproductiveCondition,
                    establishmentMeans: row.establishmentMeans,
                    cultivationStatus: row.cultivationStatus,
                    lifeState: row.lifeStage,
                    sex: row.sex,
                    individualCount: row.individualCount,
                    samplingProtocol: row.samplingProtocol,
                    samplingEffort: row.samplingEffort,
                    preparations: row.preparations,
                    country: row.country,
                    stateProvince: row.typeStatus,
                    county: row.typeStatus,
                    municipality:row.municipality,
                    locality: row.locality,
                    locationRemarks: row.locationRemarks,
                    localitySecurity: row.localitySecurity,
                    localitySecurityReason: row.localitySecurityReason,
                    decimalLatitude: row.typeStatus,
                    decimalLongiitude: row.typeStatus,
                    geodelicDatum: row.geodeticDatum,
                    coordinateUncertaintyInMeters: row.coordinateUncertaintyInMeters,
                    verbatimCoordinates: row.verbatimCoordinates,
                    georeferencedBy: row.typeStatus,
                    georeferenceProtocol: row.georeferenceProtocol,
                    georeferenceSources: row.georeferenceSources,
                    georeferenceVerificationStatus: row.georeferenceVerificationStatus,
                    georeferenceRemarks: row.georeferenceRemarks,
                    minimumElevationInMeters: row.minimumElevationInMeters,
                    maximumElevationInMeters: row.maximumElevationInMeters,
                    minimumDepthInMeters: row.minimumDepthInMeters,
                    maximumDepthInMeters: row.maximumDepthInMeters,
                    verbatimDepth: row.verbatimDepth,
                    verbatimElevation: row.verbatimElevation,
                    disposition: row.disposition,
                    language: row.language,
                    recordEnteredBy: row.recordEnteredBy,
                    modified: row.modified,
                    soucePrimaryKey: row.dbpk,
                    collId: row.collectionID,
                    recordId: row.dbpk,
                    references: row.typeStatus,
                }))

                const csvData = this.objectToCSV(download)
                const xmlData = this.objectToXML(download)
                // console.log('object: ', data)
                // console.log('xmlData', xmlData)
                this.download(csvData, xmlData)
            }
        })

    }

    objectToCSV(data) {
        const csvRows = []

        // get the header
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        // loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = (''+row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            })
            csvRows.push(values.join(','))
        }
        return csvRows.join('\n');
    }

    objectToXML(data) {
        return jsonToXML.parse('occurrences', data)
    }

    download(csv, xml) {
        // const blob = new Blob([data], {type: 'text/csv'});
        // const url = window.URL.createObjectURL(blob)
        // const a = document.createElement('a')
        // a.setAttribute('hidden', '')
        // a.setAttribute('href', url)
        // a.setAttribute('download', 'occurrences.csv');
        // document.body.appendChild(a);
        // a.click()
        // document.body.removeChild(a)

        // const download = url => {
        //     return fetch(url).then(resp => resp.blob());
        // };
        // FIXME
        const files = []
        files.push(csv)
        files.push(xml)
        // console.log('check blob', blob)
        // console.log('check downloads: ', urls)
        // console.log('check a: ', a)

        this.zipFiles(files)
    }

    zipFiles(files) {

    //     zip.file("Myfile", this.csvdata);
    // zip.generateAsync({ type: 'blob' }).then((content) => {
    //    if (content) {
    //         FileSaver.saveAs(content, name);
    //      }
    // });

        //let count = 0;
        // const zip = new JSZip()
        //let zip: JSZip = new JSZip();
        //var zip = require("jszip")
        const zip: JSZip = new (<any>JSZip).default()

        // zip.file("data.csv", links);
        // zip.generateAsync({ type: 'blob' }).then((content) => {
        // if (content) {
        //         FileSaver.saveAs(content, 'ocurrences.zip');
        //     }
        // });

        //for (const link of links) {
        //     const filename = link.split('/')[link.split('/').length - 1];

        //     JSZipUtils.getBinaryContent(link, (err, data) => {
        //         if (err) {
        //             throw err;
        //         }

        //         zip.file(filename, data, {binary: true});
        //         count++;

        //         if (count === links.length) {
        //             zip.generateAsync({type: 'blob'}).then((content) => {
        //                 const objectUrl: string = URL.createObjectURL(content)
        //                 const a: any = document.createElement('a');

        //                 a.download = 'sample-zip-file.zip';
        //                 a.href = objectUrl;
        //                 a.click()
        //             })
        //         }
        //     })
            //let count = 0;
            files.forEach((file, index) => {
                const filename = index === 1 ? 'ocurrences.xml' : 'occurrences.csv';
                zip.file(filename, file);
            })
             //count++;

            // if (count === links.length) {
                zip.generateAsync({ type: 'blob' }).then((content) => {
                if (content) {
                        FileSaver.saveAs(content, 'ocurrences.zip');
                    }
                });
            // }
        // }
    }
}

