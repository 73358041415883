import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KnowledgeGraphService } from '../../services';
import { filter, switchMap, take } from 'rxjs/operators';
import { AlertService, ApiClientService, UserService } from '@symbiota2/ui-common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { KnowledgeGraphListItem } from '../../dto';
import {
    BuildGraphDialogComponent,
    DeleteGraphDialogComponent,
    DownloadGraphDialogComponent,
    RebuildGraphDialogComponent
} from '../../components';
import { IMAGE_FOLDER_UPLOAD_COMPLETE_ROUTE } from '../../../../../ui-plugin-image/src/lib/routes';
import { KNOWLEDGE_GRAPH_BUILD_COMPLETE_ROUTE } from '../../routes';
import { EditableTextComponent } from '../../../../../ui-plugin-i18n/src/lib/components/editable-text/editable-text.component';

@Component({
    selector: 'knowledge-graph-list',
    templateUrl: './knowledge-graph-list-page.html',
    styleUrls: ['./knowledge-graph-list-page.scss'],
})

export class KnowledgeGraphListPage implements OnInit {
    userID : number = null
    userCanEdit: boolean = false
    graphs : KnowledgeGraphListItem[] = []
    graphsToBuild : KnowledgeGraphListItem[] = []

    constructor(
        private readonly userService: UserService,
        private readonly knowledgeGraphService: KnowledgeGraphService,
        private readonly alertService: AlertService,
        private router: Router,
        private formBuilder: FormBuilder,
        private readonly translate: TranslateService,
        public dialog: MatDialog
    ) {
    }

    /*
    Called when Angular starts
    */
    ngOnInit() {
        // First create the list of graphs
        this.createList()
        this.userService.currentUser
            .pipe(filter((user) => user !== null))
            .subscribe((user) => {
                this.userID = user.uid
                this.userCanEdit = user.canEditProject(user.uid)
            })
    }

    // Create the list of graphs
    createList() {
        this.knowledgeGraphService.list().subscribe((g) => {
            g.forEach((graph) => {
                if (graph.updatedAt == null) {
                    this.graphsToBuild.push(graph)
                } else {
                    this.graphs.push(graph)
                }
            })
        })
    }

    doDownload(name) {
        const dialogRef = this.dialog.open(DownloadGraphDialogComponent, {
            width: '90%',
            data: name,

        })

        dialogRef.afterClosed().subscribe(result => {
            if (result.event != 'zzzCancel') {
                this.knowledgeGraphService.download(name).subscribe((url) => {
                    if (url != null && url[0] != null) {
                        window.open(url[0], '_blank').focus();
                        this.showMessage('knowledge.graph.download.succeeded')
                    } else {
                        this.showError('knowledge.graph.download.failed')
                    }
                })
            }
        })
    }

    doDelete(name) {
        const dialogRef = this.dialog.open(DeleteGraphDialogComponent, {
            width: '90%',
            data: name,

        })

        dialogRef.afterClosed().subscribe(result => {
            if (result.event != 'zzzCancel') {
                this.knowledgeGraphService.delete(name).subscribe((g) => {
                    if (g == "success") {
                        // Repopulate the list
                        this.createList()
                        this.showMessage('knowledge.graph.delete.succeeded')
                    } else {
                        this.showError('knowledge.graph.delete.failed"')
                    }
                })
            }
        })
    }

    doBuild(name) {
        const dialogRef = this.dialog.open(BuildGraphDialogComponent, {
            width: '90%',
            data: name,

        })

        dialogRef.afterClosed().subscribe(result => {
            if (result.event != 'zzzCancel') {
                this.knowledgeGraphService.build(name).subscribe((a) => {
                    this.router.navigate(
                        [KNOWLEDGE_GRAPH_BUILD_COMPLETE_ROUTE]
                    )
                })
            } else {
                this.showError('knowledge.graph.build.failed')
            }
        })
    }

    doRebuild(name) {
        // Just start the build again
        this.doBuild(name)
        /*
        const dialogRef = this.dialog.open(RebuildGraphDialogComponent, {
            width: '90%',
            data: name,

        })

        dialogRef.afterClosed().subscribe(result => {
            if (result.event != 'zzzCancel') {
                console.log("rebuilding the graph " + name)
            }
        })

         */
    }

    /*
    Internal routine to encapsulate the show error message at the bottom in case something goes awry
    */
    private showError(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showError(r)
        })
    }

    /*
    Internal routine to encapsulate the show message at the bottom to confirm things actually happened
    */
    private showMessage(s) {
        this.translate.get(s).subscribe((r)  => {
            this.alertService.showMessage(r)
        })
    }

}
