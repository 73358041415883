/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "@symbiota2/ui-common";
import { filter } from "rxjs/operators";
import { ChecklistDto } from "../../dto/checklist";
import { ChecklistService } from "../../services/checklist/checklist.service";
import { TaxonData } from "../filter-form-service";
import { FlashCardGameService } from "../flash-card.service";

@Component({
    selector: 'checklist-display-taxa',
    templateUrl: './checklist-taxa.html',
    styleUrls: ['./checklist-taxa.component.scss'],
})
export class ChecklistDisplayTaxaComponent implements OnInit {
    games = [
        { name: 'Name game', link: '#'},
        { name: 'Flash card quiz', link: 'games/flashcards'}
    ]
    checklist: ChecklistDto;
    filtered
    testData
    limit
    page
    take
    pageStart = 0;
    checklistTaxa;
    familyTaxa
    allTaxa = []
    synonymNames = []
    pageList
    allFamily: {[k: string]: TaxonData[]} = {};
    allCommonNames = []

    checklistNames = {
        family: [],
        taxon: [],
        genera: [],
        species: [],
        uFamily: [],
        orphan: [],
        pageData: {},
        pageList: [],
        allData: {},
        images: [],
        allTaxaIDs: [],
        taxonDetail: {},
        familyCount: 0,
        generaCount: 0,
        speciesCount: 0,
        taxaCount: 0
    };
    upload_taxon_access = false;
    upload_taxon_link_access = false;
    user;
    currentUser$ = this.userService.currentUser;
    userID: number = null;
    userCanEdit = false;
    showmore = false;
    currentPage = 1;
    projectID
    checklistID

    showmorelink = 'Show more';

    constructor(
        private userService: UserService,
        private checklistService: ChecklistService,
        private route: ActivatedRoute,
        private router: Router,
        private flashCardService: FlashCardGameService
    ) {
        this.projectID = parseInt(this.route.params['_value'].projectId);
        this.checklistID = parseInt(this.route.params['_value'].checklistId)


    }

    openDialog(value: string) {
        if (value === 'uploadTaxon') {
            if (this.userCanEdit) this.upload_taxon_access = !this.upload_taxon_access
        }
    }

    async ngOnInit() {
        this.userService.currentUser
            .pipe(filter((user) => user !== null))
            .subscribe((user) => {
                this.userID = user.uid;
                this.userCanEdit = user.canEditChecklist(user.uid);
                if (this.userCanEdit) this.upload_taxon_link_access = true;
            });


        this.checklistService.findChecklistById(this.projectID, this.checklistID).subscribe(
            async (chks) => {

            this.checklist = await chks[0];
            this.checklistTaxa = await chks[1]
            console.log('received taxa: ', this.checklistTaxa)

            this.checklistTaxa.map(async (taxon, i) => {
                this.checklistNames.taxon[i] = []

                if (taxon.rankID == 180) {
                    this.checklistNames.generaCount++;
                    this.checklistNames.genera.push(taxon.scientificName)
                }
                if (taxon.rankID == 220) {
                    this.checklistNames.speciesCount++;
                }
                
                
                this.checklistNames.taxaCount++
                
                const family = taxon.family
                
                if (taxon.acceptedTaxonStatuses) {
                    const ids = []
                    taxon.acceptedTaxonStatuses.forEach(syn => {
                        if (syn.taxonID !== syn.taxonIDAccepted) {
                            ids.push(syn.taxonID)
                        }
                    })

                    this.synonymNames[i] = []
                    if (ids.length > 0) (await this.findSynonymsByIDs(ids)).subscribe(data => data.forEach(syn => this.synonymNames[i].push(syn)))
                }

                this.allCommonNames[i] = []
                taxon.vernacularNames.forEach(n => {
                    if (n.vernacularName) {
                        this.allCommonNames[i].push(n.vernacularName)
                    }
                })

                if (family) {
                    
                    if (!this.checklistNames.family.includes(family)) {

                        this.checklistNames.family.push(family)
                        this.checklistNames.taxon[i].push({
                            sciName: taxon.scientificName,
                            id: taxon.id,
                            author: taxon.author,
                            images: taxon.images,
                        })

                        this.allFamily[family] = []
                        this.allFamily[family].push({
                            sciName: taxon.scientificName,
                            id: taxon.id,
                            author: taxon.author,
                            family: family,
                            images: taxon.images,
                            commonNames: this.allCommonNames[i],
                            synonym: this.synonymNames[i]
                        })
                    } else {
                        this.checklistNames.taxon[this.checklistNames.family.indexOf(family)].push({
                            sciName: taxon.scientificName,
                            id: taxon.id,
                            author: taxon.author,
                            images: taxon.images
                        })
                        this.allFamily[family].push({
                            sciName: taxon.scientificName,
                            id: taxon.id,
                            author: taxon.author,
                            family: this.checklistNames.family[this.checklistNames.family.indexOf(family)],
                            images: taxon.images,
                            commonNames: this.allCommonNames[i],
                            synonym: this.synonymNames[i]
                        })
                    }

                } else {
                    if (!this.checklistNames.orphan.includes(taxon.scientificName)) {
                        this.checklistNames.orphan.push(taxon.scientificName)
                    }
                }

            })
            this.allTaxa = this.checklistNames.taxon.filter(taxon => taxon.length > 0)

            this.checklistNames.allData = this.allFamily;

        })
    }



    async findSynonymsByIDs(ids: number[]) {
        return this.checklistService.findByScientificName(ids)
    }

    navigateTo(value: string) {

        if (value === 'games/flashcards') this.flashCardService.addImages(this.checklistTaxa)
        this.router.navigateByUrl(value);

    }

}
