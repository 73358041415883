<div id="main">
    <div fxLayout='row' fxLayoutAlign='start center'>
        <symbiota2-collection-logo [src]='collectionLogo | async'>
        </symbiota2-collection-logo>
        <h1 id='collectionName'>{{ collectionName | async }}</h1>
    </div>
    <div fxLayout='row'>
        <symbiota2-occurrence-editor (formChanged)='onFormChanged($event)'>
        </symbiota2-occurrence-editor>
    </div>
    <div fxLayout='row'>
        <button mat-flat-button color='primary' (click)='onSubmit()'>{{'plugins.occurrence.create.button.text' | translate}}</button>
        <symbiota2-editable-text key='plugins.occurrence.create.button.text' hide='true'></symbiota2-editable-text>
    </div>
</div>


