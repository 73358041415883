export enum ApiUserPermissionName {
    //////////////////////////////////////////////           VIEW
    VIEW_USER = "view_user",
    VIEW_USERS = "view_users",
    VIEW_ROLES = "view_roles",
    VIEW_PERMISSION = "view_permission",
    VIEW_PERMISSIONS = "view_permissions",
    VIEW_ROLE_AND_PERMISSIONS = "view_role_and_permissions", 
    VIEW_USER_ROLE_AND_PERMISSIONS ="view_user_role_and_permissions",
    ///////////////////////////////////////////////           UPDATE
    UPDATE_USER = "update_user",
    UPDATE_PERMISSION = "update_permission",
    UPDATE_ROLE = "update_role",
    UPDATE_ROLE_PERMISSIONS = "update_role_permissions",
    UPDATE_USER_ROLES = "update_user_roles",
    UPDATE_COLLECTION = "update_collection",
    /////////////////////////////////////////////////     CREATE/ASSIGN
    CREATE_NEW_ROLE = "create_new_role",
    CREATE_USER = "create_user",
    CREATE_NEW_COLLECTION = "create_new_collection",
    CREATE_NEW_PERMISSION = "create_new_permission",
    ASSIGN_ROLE = "assign_role",
    ASSIGN_COLLECTION_ROLE = "assign_collection_role",
    ////////////////////////////////////////////////       REMOVE/UNASSIGN
    REMOVE_PERMISSION = "remove_permission",
    REMOVE_USER = "remove_user",
    REMOVE_ROLE = "remove_role",
    REMOVE_COLLECTION = "remove_collection",
    UNASSIGN_ROLES = "unassign_roles",
    UNASSIGN_COLLECTION_ROLE = "unassign_collection_role"
}






