<mat-form-field>
    <mat-label>{{ title }}</mat-label>
    <mat-select
        [required]="required"
        [disabled]="disabled"
        [value]="value"
        (valueChange)="valueChange.emit($event)"
    >
        <mat-option *ngFor="let opt of options" [value]="opt.value">
            {{ opt.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
