<div id=main>

    <div>
        <h1><symbiota2-editable-text key='taxonomy.utils.entry.title'></symbiota2-editable-text></h1>
    </div>

    <div *ngIf="!userCanEdit" class='my2' fxLayout='row' fxLayoutGap='1rem'>
        <symbiota2-editable-text key='taxonomy.viewer.name.error'></symbiota2-editable-text>
    </div>
    <div *ngIf="userCanEdit" class='my2' fxLayout='row' fxLayoutGap='1rem'>
        <!-- Rebuild the taxonomic enum tree -->
        <div class="expansion-panel">
            <symbiota2-expansion-panel expanded=true label='{{"taxonomy.utils.configure.tree.repair.label" | translate}}'>
                <!-- Chooose the taxonomic authority -->
                <symbiota2-editable-text key='taxonomy.utils.configure.tree.repair.label' hide='true'></symbiota2-editable-text>
                <mat-form-field appearance="fill">
                    <mat-label>{{"taxonomy.viewer.label.authority" | translate}}</mat-label>
                    <mat-select field-class="symbiota2-field-class"
                                placeholder='{{"taxonomy.viewer.label.authority" | translate}}'
                                [(value)]="taxonomicAuthorityID"
                                (selectionChange)="authorityChangeAction()">
                        <mat-option *ngFor="let authority of taxonomicAuthorityList"
                                    value="{{authority.id}}" >{{ authority.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                    <symbiota2-editable-text key='taxonomy.viewer.label.authority' hide='true'></symbiota2-editable-text>
                <p>
                    <symbiota2-editable-text key='taxonomy.utils.rebuild.tree.message'></symbiota2-editable-text>
                </p>
                <div mat-dialog-actions>
                    <button mat-button (click)="doRebuildTree()"
                            mat-flat-button color="primary">{{'taxonomy.utils.rebuild.tree.button' | translate}}
                    </button>
                </div>
                <br/>
                <!-- Find in conflict taxons -->
                <p>
                    <symbiota2-editable-text key='taxonomy.utils.in.conflict.message'></symbiota2-editable-text>
                </p>
                <div mat-dialog-actions>
                    <button mat-button (click)="findInConflict()"
                            mat-flat-button color="primary">{{'taxonomy.utils.in.conflict.button' | translate}}
                    </button>
                </div>
                <div *ngIf='taxons.length > 0'>
                    <ul *ngFor='let taxon of taxons'>
                        <li>
                            <a href='{{taxon_editor_route}}/{{taxon.id}}'>{{taxon.scientificName}}</a>
                        </li>
                    </ul>

                </div>

            </symbiota2-expansion-panel>
        </div>

    </div>

</div>
