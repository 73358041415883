<div *ngIf="!isSuperAdmin" class='my2' fxLayout='row' fxLayoutGap='1rem'>
  {{"core.layout.superadmin.user_list_denied" | translate}}
</div>

<div *ngIf="isSuperAdmin" class="mat-elevation-z8" style="width: 90vw;margin:0 auto;margin-top: 2rem;">
  <div style="margin-left: 1rem;padding-top: 1rem;">
    <button mat-raised-button color="primary" routerLink="/adduser">Add New User</button>
  </div>
  
  <table mat-table [dataSource]="dataSource">
    
   <!--- <div *ngFor="let userPanel of userPanelList; let i=index"> -->
     <ng-container matColumnDef="uid">
       <th mat-header-cell *matHeaderCellDef> ID </th>
       <td mat-cell *matCellDef="let element"> {{element.uid}} </td>
     </ng-container>
     <ng-container matColumnDef="firstName">
       <th mat-header-cell *matHeaderCellDef> First Name </th>
       <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
     </ng-container>

     <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Last Name </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef> Roles </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.roles?.join(', ') }}
        <!-- <ng-template *ngFor="let role of element.roles">{{ role }}</ng-template> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element; let i = index;">
        <button mat-raised-button class="color-edit" [routerLink]="['/user', element.uid, 'edit']">Edit</button>
        <button mat-raised-button class="color-delete" [disabled]="element.uid == user.uid" (click)="deleteUser(element.uid)">Delete</button>
      </td> 
    </ng-container> 
   <!-- </div> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
  
</div>

<!-- <div class="mat-elevation-z8" style="width: 90vw;margin:0 auto;margin-top: 2rem;">
  <table mat-table [dataSource]="userPanelList" >
    <symbiota2-userlist [userList]=userList [userPanelList]=userPanelList></symbiota2-userlist>
  </table>
  
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
  
</div>  -->