export const Q_PARAM_TAXAIDS = 'id[]'
export const Q_PARAM_TAXONIDS = 'taxonID[]'
export const Q_PARAM_TAXAID = 'taxonID'
export const Q_PARAM_RANKID = 'rankID'
export const Q_PARAM_PARENTID = 'parentTaxonID'
export const Q_PARAM_AUTHORITYID = 'taxonAuthorityID'
export const Q_PARAM_PARTIALNAME = 'partialName'
export const Q_PARAM_SCIENTIFICNAME = 'scientificName'
export const Q_PARAM_KINGDOMNAME = 'kingdomName'
export const Q_PARAM_WITHIMAGES = 'withImages'
export const Q_PARAM_LIMIT = 'take'
export const Q_PARAM_OFFSET = 'page'