import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_ROLES_LIST, ROUTE_USER_LIST, UserService } from '@symbiota2/ui-common';
import { AddRolePermissions } from '../../dtos/create-user-data';
import { Permission } from '../../dtos/permission';

@Component({
    selector: 'symbiota2-update-role-page',
    templateUrl: './update-role.page.html',
    styleUrls: ['./update-role.page.scss']
})
export class UpdateRolePage {
    updateRoleForm: FormGroup;
    rid: number = null;
    roleName: string;
    permissions: Permission[] = [];


    readonly nameField = new FormControl('', [Validators.required]);


    constructor(
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.rid = this.route.snapshot.params.id;
        
        this.updateRoleForm = this.formBuilder.group({
            name: '',
            permissions: this.formBuilder.array([])
        })

        this.userService.getAllPermissions().subscribe(permissions => {
            this.permissions = permissions;
            this.permissions.forEach(p => {
                this.permissionArray.push(
                    this.formBuilder.group({
                        value: false,
                        id: p.id
                    })
                )
            })
        })

        this.getRole(this.rid)

    }

    get permissionArray(): FormArray {
        return this.updateRoleForm.get('permissions') as FormArray;
    }

    getRole(id: number) {
        this.userService.getRoleById(id).subscribe(async role => {
            //this.updateRoleForm.controls['name'].setValue(role[0].name);
            const values = this.permissions.map(p => {
                p.name = p.name.replace(p.name[0], p.name[0].toUpperCase()).replace(/_/g, ' ')
                return {
                    value: role[0].permissions.some(r => r.id === p.id),
                    id: p.id
                }
            })
            this.updateRoleForm.patchValue({
                name: role[0].name,
                permissions: values
            })
        })
    }

    updateRole(): void {
        const formData =  this.updateRoleForm.getRawValue();

        const data:AddRolePermissions = {
            role: { name: formData.name },
            permissions: formData.permissions.filter(p => p.value === true).map((p) => p.id)
        }
        this.userService.updateRole(this.rid, data)
            .subscribe(()=> {
            this.router.navigate([ROUTE_ROLES_LIST])
        })
    }

}