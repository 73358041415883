<mat-card class="main" fxLayoutGap="0.8rem" fxLayout="column">
    <mat-card-title
        class="post"
        fxLayout="row wrap"
        fxLayoutGap="1rem"
        fxLayoutAlign="start center"
    >
        <b color="primary"><symbiota2-editable-text key='collection.comment.card.title.occurrence.text'></symbiota2-editable-text>:</b>
        <p>{{ comment.occurrenceID }}</p>
        <p class="divider">|</p>
        <p>{{ comment.occurrence?.scientificName }}</p>
        <p class="divider">|</p>
        <P>{{ formatDate(comment.initialTimestamp) }}</P>
    </mat-card-title>
    <mat-card-subtitle class="commentInfo" fxLayout="row warp" fxLayoutGap="0.5rem" fxLayoutAlign="start center">
        <p *ngIf="this.comment.reviewStatus" id="reviewed"><mat-icon>check_circle</mat-icon><symbiota2-editable-text key='collection.comment.card.subtitle.reviewed.text'></symbiota2-editable-text></p>
        <p *ngIf="!this.comment.reviewStatus" id="private"><mat-icon>lock
        </mat-icon><symbiota2-editable-text key='collection.comment.card.subtitle.private.text'></symbiota2-editable-text></p>
        <p>
            <b>{{ comment?.commenter?.firstName }}</b>{{" "}}<symbiota2-editable-text key='collection.comment.card.subtitle.posted_on.text'></symbiota2-editable-text> {{ formatDate(comment.initialTimestamp) }}
        </p>    
    </mat-card-subtitle>
    <mat-card-content class="comment">{{ comment.comment }}</mat-card-content>
</mat-card>
