<!-- <div *ngIf="!isSuperAdmin" class='my2' fxLayout='row' fxLayoutGap='1rem'>
    {{"core.layout.superadmin.user_list_denied" | translate}}
  </div> -->
  
  <div class="mat-elevation-z8 data-container" style="width: 50vw;margin:0 auto;margin-top: 2rem;">
    <div style="margin-left: 1rem;padding-top: 1rem;">
      <button mat-raised-button color="primary" [routerLink]="['/roles/new']">Add New Role</button>
    </div>
    
    <table mat-table [dataSource]="dataSource">
      
       <ng-container matColumnDef="rid">
         <th mat-header-cell *matHeaderCellDef> ID </th>
         <td mat-cell *matCellDef="let element"> {{element.rid}} </td>
       </ng-container>
       <ng-container matColumnDef="role">
         <th mat-header-cell *matHeaderCellDef> Role </th>
         <td mat-cell *matCellDef="let element"> {{element.role}} </td>
       </ng-container>
  
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button mat-raised-button class="color-edit" [routerLink]="[element.rid, 'edit']">Edit</button>
          <button mat-raised-button class="color-delete" [disabled]="element.rid == user.uid" (click)="deleteRole(element.rid)">Delete</button>
        </td> 
      </ng-container> 
     <!-- </div> -->
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
    
  </div>