<ng-container [formGroup]='form'>
    <div fxLayout='row'>
        <symbiota2-expansion-panel label="{{'plugins.occurrence.fields.record_level.title' | translate}}" expanded>
            <symbiota2-editable-text key='plugins.occurrence.fields.record_level.title' hide='true'></symbiota2-editable-text>
            <div fxLayout.gt-sm='row'>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.catalogNumber'
                        helpLink=''
                        formControlName='catalogNumber'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.catalogNumber' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.recordedByNames'
                        helpLink=''
                        formControlName='recordedBy'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.recordedByNames' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.recordNumber'
                        helpLink=''
                        formControlName='recordNumber'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.recordNumber' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.eventDate'
                        type='date'
                        helpLink=''
                        formControlName='eventDate'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.eventDate' hide='true'></symbiota2-editable-text>
                </div>
            </div>
        </symbiota2-expansion-panel>
    </div>

    <div fxLayout='row'>
        <symbiota2-expansion-panel label="{{'plugins.occurrence.fields.latest_identification.title' | translate}}" expanded>
            <symbiota2-editable-text key='plugins.occurrence.fields.latest_identification.title' hide='true'></symbiota2-editable-text>
            <div fxLayout.gt-sm='row'>
                <div fxLayout.gt-sm='column' fxFlex='60'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.scientificName'
                        helpLink=''
                        formControlName='scientificName'
                        [autoCompleteItems]='autoCompleteTaxa'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.scientificName' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.scientificNameAuthorship'
                        helpLink=''
                        formControlName='scientificNameAuthorship'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.scientificNameAuthorship' hide='true'></symbiota2-editable-text>
                </div>
            </div>
            <div fxLayout.gt-sm='row'>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.family'
                        helpLink=''
                        formControlName='family'
                        disabled='true'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.family' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.identifiedBy'
                        helpLink=''
                        formControlName='identifiedBy'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.identifiedBy' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.dateIdentified'
                        type='date'
                        helpLink=''
                        formControlName='dateIdentified'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.dateIdentified' hide='true'></symbiota2-editable-text>
                </div>
            </div>
        </symbiota2-expansion-panel>
    </div>
    <div fxLayout='row'>
        <symbiota2-expansion-panel label="{{'plugins.occurrence.fields.locality.title' | translate}}" expanded>
            <symbiota2-editable-text key='plugins.occurrence.fields.locality.title' hide='true'></symbiota2-editable-text>
            <div fxLayout.gt-sm='row'>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.country'
                        helpLink=''
                        formControlName='country'
                        [autoCompleteItems]='autoCompleteCountries'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.country' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.stateProvince'
                        helpLink=''
                        formControlName='stateProvince'
                        [autoCompleteItems]='autoCompleteStateProvinces'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.stateProvince' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.county'
                        helpLink=''
                        formControlName='county'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.county' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.municipality'
                        helpLink=''
                        formControlName='municipality'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.municipality' hide='true'></symbiota2-editable-text>
                </div>
            </div>
            <div fxLayout.gt-sm='row'>
                <div fxLayout.gt-sm='column'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.locality'
                        helpLink=''
                        formControlName='locality'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.locality' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column' fxFlex='15'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.latitude'
                        type='number'
                        helpLink=''
                        formControlName='latitude'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.latitude' hide='true'></symbiota2-editable-text>
                </div>
                <div fxLayout.gt-sm='column' fxFlex='15'>
                    <symbiota2-occurrence-field
                        label='plugins.occurrence.fields.longitude'
                        type='number'
                        helpLink=''
                        formControlName='longitude'
                    >
                    </symbiota2-occurrence-field>
                    <symbiota2-editable-text key='plugins.occurrence.fields.longitude' hide='true'></symbiota2-editable-text>
                </div>
            </div>
            <div id='localitySecurity' fxLayout='row' fxLayoutAlign='start center'>
            <span>
                <symbiota2-editable-text key='plugins.occurrence.fields.redacted.check.input'></symbiota2-editable-text>
            </span>
                <a href=''>
                    <mat-icon color='primary'>help</mat-icon>
                </a>
                <mat-checkbox
                    (change)='form.setValue({ locality: $event.checked })'
                >
                </mat-checkbox>
            </div>
        </symbiota2-expansion-panel>
    </div>

</ng-container>
