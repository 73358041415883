<symbiota2-expansion-panel label='{{"taxon.status.accepted.editor.dialog.change.link" | translate}}'>
    <symbiota2-editable-text key='taxon.status.accepted.editor.dialog.change.link' hide='true'></symbiota2-editable-text>
    <form class="name-autocomplete-form">
        <mat-form-field class="name-autocomplete-full-width">
            <mat-label>{{"taxon.status.accepted.editor.dialog.new.accepted" | translate}}</mat-label>
            <symbiota2-editable-text key='taxon.status.accepted.editor.dialog.new.accepted' hide='true'></symbiota2-editable-text>
            <input type="text"
                   placeholder='{{"taxon.status.accepted.editor.dialog.sciname.placeholder" | translate}}'
                   aria-label="String"
                   matInput
                   (keyup)="onKey($event)"
                   [formControl]="nameControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of nameOptions" [value]="option.name">
                    {{option.name}} <small>{{option.author}}</small>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <symbiota2-editable-text key='taxon.status.accepted.editor.dialog.sciname.placeholder' hide='true'></symbiota2-editable-text>
    </form>
    <button mat-button mat-raised-button color="primary" (click)="doAddLinkAction()">{{"taxon.status.accepted.editor.dialog.add.link" | translate}}</button>
    <symbiota2-editable-text key='taxon.status.accepted.editor.dialog.add.link' hide='true'></symbiota2-editable-text>
</symbiota2-expansion-panel>
<p></p>
<symbiota2-expansion-panel label='{{"taxon.status.accepted.editor.dialog.change.accepted" | translate}}'>
    <symbiota2-editable-text key='taxon.status.accepted.editor.dialog.change.accepted' hide='true'></symbiota2-editable-text>
    <mat-checkbox class="example-margin" [(ngModel)]='switchAcceptance'
    ><symbiota2-editable-text key='taxon.status.accepted.editor.dialog.switch.acceptance'></symbiota2-editable-text></mat-checkbox>
    <br/>
    <button mat-button mat-raised-button color="primary" (click)="doMakeAcceptedAction()">{{"taxon.status.accepted.editor.dialog.change.status" | translate}}</button>
    <symbiota2-editable-text key='taxon.status.accepted.editor.dialog.change.status' hide='true'></symbiota2-editable-text>
</symbiota2-expansion-panel>

<div mat-dialog-actions>
    <button mat-button (click)="closeDialog()" mat-raised-button color="warn">{{'taxon.editor.dialog.cancel' | translate}}</button>
    <symbiota2-editable-text key='taxon.editor.dialog.cancel' hide='true'></symbiota2-editable-text>
</div>
